/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';

    angular.module('scFuelsApp')
        .controller('HeadermenuController', HeadermenuController);

    HeadermenuController.$inject = ['$scope','$state', '$rootScope', 'headermenuService'];

    function HeadermenuController ($scope, $state, $rootScope, headermenuService) {
        var headermenuVM = this;
        headermenuVM.account = JSON.parse(localStorage.permissions); 
        headermenuVM.onAccountSelection = onAccountSelection;
        headermenuVM.onAccountToggle = onAccountToggle;
        headermenuVM.onAccountSearch = onAccountSearch;
        headermenuVM.paginationClick = paginationClick;
        headermenuVM.toggleSidebar = toggleSidebar;
        headermenuVM.hasEntertpisePermission = JSON.parse(localStorage.hasEntertpisePermission);
        headermenuVM.gotoChangePassword = gotoChangePassword;
        headermenuVM.gotoChangeQuestion = gotoChangeQuestion;
        headermenuVM.updateMessageReadStatus = updateMessageReadStatus;
        headermenuVM.updateNotificaReadStatus = updateNotificaReadStatus;
        headermenuVM.accountListPagination = accountListPagination;
        headermenuVM.pageNumber = 1;
        headermenuVM.totalAccounts = 0;
        headermenuVM.accounts = [];
        headermenuVM.navigationPane = "Hide Navigation Pane";
        headermenuVM.dropdownMenu = false;
        headermenuVM.searchText = "";
        headermenuVM.maxPage = 2;
        headermenuVM.loading = false;

        if(!headermenuVM.hasEntertpisePermission){
            headermenuVM.selectedAccount = headermenuVM.account[0].name;
            // headermenuVM.selectedAccountId = headermenuVM.account[0].id;
            headermenuVM.selectedAccountId = localStorage.selectedAccount?localStorage.selectedAccount:headermenuVM.account[0].id;
            localStorage.selectedAccount = headermenuVM.selectedAccountId
            // localStorage.selectedAccount = headermenuVM.account[0].id;
        }
        // headermenuVM.unreadMessageCount = 1;

        init();

        /*function declarations*/

        /*initial page load*/
        function init() {
            headermenuVM.currentPage = 1;
            headermenuVM.firstPageItem = 1;
            headermenuVM.secondPageItem = 2;
            getHeaderNotifications();
            getHeaderMessages();
            // getAccountList(headermenuVM.currentPage);
        }
        /*fetches notications to show on header*/
        function getHeaderNotifications() {
            headermenuService.getHeaderNotifications()
            .then(getHeaderNotificationsSuccess, getHeaderNotificationsFailure);
        }
        /*success callback for notification*/
        function getHeaderNotificationsSuccess(apiResponse) {
            if(apiResponse.statusCode === 200){
                headermenuVM.headerNotifications = apiResponse.notificationData;
                headermenuVM.unreadNotificationCount = apiResponse.unreadCount;
                // headermenuVM.unreadNotificationCount = apiResponse.notificationData.length;
            }
        }
        /*error callback for messages*/
        function getHeaderNotificationsFailure() {
             headermenuVM.unreadNotificationCount = null;
        }

        /*fetches messages to show on header*/
        function getHeaderMessages() {
            headermenuService.getHeaderMessages()
            .then(getHeaderMessagesSuccess, getHeaderMessagesFailure);
        }
        /*success callback for messages*/
        function getHeaderMessagesSuccess(apiResponse) {
            if(apiResponse.statusCode === 200){
                headermenuVM.headerMessages = apiResponse.messageData;
                headermenuVM.unreadMessageCount = apiResponse.unreadCount;
            }
        }
        /*error callback for messages*/
        function getHeaderMessagesFailure() {
            headermenuVM.unreadMessageCount = null;
        }
        /*when clicks on change password in settings*/
        function gotoChangePassword() {
            $state.go('root.settingsPassword', {"previousPage": $state.current.name});
        }
        /*when clicks on change security question in settings*/
        function gotoChangeQuestion() {
            $state.go('root.settingsQuestion', {"previousPage": $state.current.name});
        }

        if(localStorage.permissions){
            angular.forEach(JSON.parse(localStorage.permissions), function(item){
                if(item.name === headermenuVM.selectedAccount){
                    localStorage.accountPermission =  JSON.stringify(item.permissions);
                }
            })
        }
        
        /*for toggling the sidebar*/
        function toggleSidebar() {
            $rootScope.sideBarVisible = !$rootScope.sideBarVisible;
            if($rootScope.sideBarVisible){
                headermenuVM.navigationPane = "Hide Navigation Pane";
            }else{
               headermenuVM.navigationPane = "Show Navigation Pane"; 
            }
        }

        /*on account changes*/
        function onAccountSelection(selection) {
        	headermenuVM.selectedAccount = selection.name;
            headermenuVM.selectedAccountId = selection.id;
            localStorage.selectedAccount = selection.id;
            var payload = {
                accountID: selection.id,
                orgID: localStorage.orgID
            }
            headermenuService.getAccountPermission(payload)
            .then(getAccountPermissionSuccess, getAccountPermissionFailure);

        	/*if(localStorage.permissions){
                angular.forEach(JSON.parse(localStorage.permissions), function(item){
                    if(parseInt(item.id,10) === parseInt(localStorage.selectedAccount,10)){
                        localStorage.accountPermission =  JSON.stringify(item.permissions);
                        $rootScope.$broadcast('accountChanged');
                    }
                });
            }*/
        }

        function getAccountPermissionSuccess(apiResponse){
            if(apiResponse.statusCode === 200){
                localStorage.accountPermission =  JSON.stringify(apiResponse.dataModel[0].permissions);
                $rootScope.$broadcast('accountChanged');
            }
        }

        function getAccountPermissionFailure(){

        }
        /*for updating the read status of the messages*/
        function updateMessageReadStatus(msgID){
            var payload = {
                 "messageId": msgID
            }
            headermenuService.updateMessagesReadCount(payload)
            .then(updateMessagesReadCountSuccess, updateMessagesReadCountFailure);
        }
        /*success callback for update message read status*/
        function updateMessagesReadCountSuccess(apiResponse){
            if(apiResponse.statusCode === 200){
                getHeaderMessages();
            }
        }
        /*Error callback for update message read status*/
        function updateMessagesReadCountFailure(){

        }
        /*for updating the read status of the notification*/
        function updateNotificaReadStatus(msgID){
           var payload = {
                 "messageId": msgID
            }
            headermenuService.updateNotificationReadCount(payload)
            .then(updateNotificationReadCountSuccess, updateNotificationReadCountFailure); 
        }
        /*success callback for update notification read status*/
        function updateNotificationReadCountSuccess(apiResponse){
            if(apiResponse.statusCode === 200){
                getHeaderNotifications();
                $state.go('root.payments');
            }
        }
        /*Error callback for update notification read status*/
        function updateNotificationReadCountFailure(){

        }

        /*To get the account list for the user with pagination*/
        function getAccountList(page){
            // var payload = {
            //     orgID: localStorage.orgID,
            //     pageNo: page
            // }
            // headermenuService.getAccountList(payload)
            // .then(getAccountListSuccess, getAccountListFailure);
            // if (headermenuVM.searchText.length > 0) {
                headermenuVM.loading = true;
                var payload = {
                    SearchText: headermenuVM.searchText,
                    PageNo: page,
                    PageSize: 25
                }
                headermenuService.getAccountList(payload)
                    .then(getAccountListSuccess, getAccountListFailure);
            // }
        }

        /*Success callback for account list api*/
        function getAccountListSuccess(apiResponse) {
            if (apiResponse.statusCode === 200) {
                if (apiResponse.dataModel) {
                    headermenuVM.totalAccounts = (apiResponse.totalCount > 0) ? apiResponse.totalCount : 0;
                    headermenuVM.maxPage = Math.ceil(apiResponse.totalCount / 25);
                    if (apiResponse.dataModel) {
                        headermenuVM.accounts = apiResponse.dataModel;
                    } else {
                        headermenuVM.accounts = [];
                    }
                }
            }
            headermenuVM.loading = false;
            // if (apiResponse.statusCode === 200) {
            //     if (apiResponse.dataModel) {
            //         headermenuVM.totalAccounts = (apiResponse.dataModel.totalCount > 0) ? apiResponse.dataModel.totalCount : headermenuVM.totalAccounts;
            //         if (apiResponse.dataModel.accountList) {
            //             angular.forEach(apiResponse.dataModel.accountList, function (item) {
            //                 headermenuVM.accounts.push(item);
            //             });
            //         }
            //     }
            // }
        }
        /*Error callback for account list api*/
        function getAccountListFailure(){
            headermenuVM.loading = false;
        }
        /*Scroll pagination call from the account list UI*/
        function accountListPagination(){
            if(headermenuVM.totalAccounts>10){
                headermenuVM.pageNumber++;
                getAccountList(headermenuVM.pageNumber);
            }
        }

        function onAccountToggle() {
            if (headermenuVM.dropdownMenu) {
                headermenuVM.dropdownMenu = false;
            } else {
                headermenuVM.dropdownMenu = true;
            }
        }

        function onAccountSearch(event) {
            if (!headermenuVM.loading) {
                headermenuVM.firstPageItem = 1;
                headermenuVM.secondPageItem = 2;
                if (headermenuVM.currentPage === 1) {
                    getAccountList(headermenuVM.currentPage);
                } else {
                    headermenuVM.currentPage = 1;
                }
            }
            event.stopPropagation();
        }

        /*watches the changes in the pagination current page*/
        $scope.$watch('headermenuVM.currentPage',function(){
            getAccountList(headermenuVM.currentPage);
        });

        /*pagination*/
        function paginationClick(button, event) {
            if (!headermenuVM.loading) {
                switch (button) {
                    case 'first':
                        if (headermenuVM.firstPageItem > 1) {
                            headermenuVM.firstPageItem = 1;
                            headermenuVM.secondPageItem = 2;
                            headermenuVM.currentPage = 1;
                        }
                        break;
                    case 'previous':
                        if (headermenuVM.firstPageItem > 1) {
                            headermenuVM.firstPageItem--;
                            headermenuVM.secondPageItem--;
                            headermenuVM.currentPage--;
                        }
                        else if (headermenuVM.firstPageItem < headermenuVM.currentPage) {
                            headermenuVM.currentPage--;
                        }
                        break;
                    case 'next':
                        if (headermenuVM.secondPageItem < headermenuVM.maxPage) {
                            headermenuVM.firstPageItem++;
                            headermenuVM.secondPageItem++;
                            headermenuVM.currentPage++;
                        }
                        else if (headermenuVM.currentPage < headermenuVM.secondPageItem) {
                            headermenuVM.currentPage++;
                        }
                        break;
                    case 'last':
                        if (headermenuVM.secondPageItem < headermenuVM.maxPage) {
                            headermenuVM.firstPageItem = headermenuVM.maxPage - 1;
                            headermenuVM.secondPageItem = headermenuVM.maxPage;
                            headermenuVM.currentPage = headermenuVM.maxPage;
                        }
                        break;
                    default:
                        headermenuVM.currentPage = button;
                        break;
                }
            }
            event.stopPropagation();
        }

    }
})();
