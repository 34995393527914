/*jshint browser: true*/
/*global angular: true*/

(function () {
  "use strict";

  angular
    .module("scFuelsApp.cardManagement")
    .controller("newPromptCardController", newPromptCardController);

  newPromptCardController.$inject = [
    "$scope",
    "$state",
    "$rootScope",
    "toastrMessage",
    "cardManagementService",
    "utils",
    "toastr",
    "$window",
    "$confirm",
    "$filter",
  ];

  function newPromptCardController(
    $scope,
    $state,
    $rootScope,
    toastrMessage,
    cardManagementService,
    utils,
    toastr,
    $window,
    $confirm,
    $filter
  ) {
    var promptsVM = this;
    promptsVM.tooltips = toastrMessage.fleetCardsTooltip;
    promptsVM.showDetailView = false;
    promptsVM.disablePooledDriver = true;
    // promptsVM.disablePin = true;
    promptsVM.disableDriverId = false;
    promptsVM.disableLinkedCard = false;
    promptsVM.promptDetails = [];
    promptsVM.promptRestrictions = [];
    promptsVM.restrictionsForm = [];
    promptsVM.cardDetailsForm = [];
    promptsVM.showTimeProfile = [];
    promptsVM.timeProfileDetails = [];
    promptsVM.isAddNewProfile = [];
    promptsVM.promptDetails[0] = {};
    promptsVM.promptRestrictions[0] = {};
    promptsVM.showNewField = false;
    promptsVM.submitClicked = false;
    promptsVM.alreadyLinked = false;
    promptsVM.hstep = 1;
    promptsVM.mstep = 1;
    promptsVM.ismeridian = true;
    promptsVM.profileTemplate =
      "app/modules/cardManagement/promptsManagement/cardDetails/timeProfileTemplate.html";

    promptsVM.onCardTypeChange = onCardTypeChange;
    promptsVM.submitNewCard = submitNewCard;
    promptsVM.manageTimeProfile = manageTimeProfile;
    promptsVM.editProfileDetails = editProfileDetails;
    promptsVM.addNewProfile = addNewProfile;
    promptsVM.saveNewProfile = saveNewProfile;
    promptsVM.cancelAddProfile = cancelAddProfile;
    promptsVM.deleteProfileDetails = deleteProfileDetails;
    promptsVM.changeStatus = changeStatus;
    promptsVM.changeStatusPin = changeStatusPin;
    promptsVM.changeDepartment = changeDepartment;
    promptsVM.getPromptRestrictionDropdownList =
      getPromptRestrictionDropdownList;
    promptsVM.validatePin = validatePin;
    promptsVM.changeLinkedCard = changeLinkedCard;
    promptsVM.validateDriver = validateDriver;
    promptsVM.validateVehicle = validateVehicle;
    promptsVM.getPromptLinkedCardList = getPromptLinkedCardList;
    promptsVM.validateCFNDriver = validateCFNDriver;
    promptsVM.validatePacprideDriver = validatePacprideDriver;
    promptsVM.validateCFSStdPin = validateCFSStdPin;

    promptsVM.disablePin = false;
    promptsVM.autoGeneratePin = false;
    promptsVM.checkAutoGenPin = checkAutoGenPin;
    promptsVM.invalidPin = false;
    var errorMsg = "";
    var toasterMsg = "";

    init();

    /*function declarations*/
    /*intial function call*/
    function init() {
      $rootScope.dataLoading = true;
      getPromptDropdownList();
    }
    /*To get the dropdown values for the advance search*/
    function getPromptDropdownList() {
      cardManagementService
        .getPromptDropdownList(localStorage.selectedAccount)
        .then(getPromptDropdownListSuccess, getPromptDropdownListError);
    }
    /*success callback of getPromptDropdownList*/
    function getPromptDropdownListSuccess(apiResponse) {
      promptsVM.dropDownData = apiResponse.dataModel;
      promptsVM.dropDownData.department.push({
        code: "",
        description: "Add New",
      });
      $rootScope.dataLoading = false;
    }
    /*error callback of getPromptDropdownList*/
    function getPromptDropdownListError() {
      $rootScope.dataLoading = false;
    }

    function getPromptRestrictionDropdownList() {
      $rootScope.dataLoading = true;
      cardManagementService
        .getPromptRestrictionDropdownList(promptsVM.network.code)
        .then(getPromptRestrictionListSuccess, getPromptRestrictionListError);
    }

    function getPromptRestrictionListSuccess(apiResponse) {
      $rootScope.dataLoading = false;
      promptsVM.dropDownRestrictionData = apiResponse.dataModel;
      // promptsVM.dropDownData.productAuthCode = promptsVM.dropDownRestrictionData[0].restrictions;
      for (var i = 0; i < apiResponse.dataModel.length; i++) {
        // if (apiResponse.dataModel[i].name === 'MANUAL ENTRY') {
        //     promptsVM.dropDownRestrictionData.miscEntry = apiResponse.dataModel[i].restrictions;
        // }
        if (apiResponse.dataModel[i].name === "TIME PROFILE") {
          promptsVM.dropDownRestrictionData.timeProfile =
            apiResponse.dataModel[i].restrictions;
        }
        // if (apiResponse.dataModel[i].name === 'PRODUCTS') {
        //     promptsVM.dropDownRestrictionData.products = apiResponse.dataModel[i].restrictions;
        // }
        // if (apiResponse.dataModel[i].name === 'LIMIT CODE') {
        //     promptsVM.dropDownRestrictionData.gallonsPerTransaction = apiResponse.dataModel[i].restrictions;
        // }
        // if (apiResponse.dataModel[i].name === 'PROD CODE') {
        //     promptsVM.dropDownRestrictionData.allowedProducts = apiResponse.dataModel[i].restrictions;
        // }
        // if (apiResponse.dataModel[i].name === 'AUTH CODE') {
        //     promptsVM.dropDownRestrictionData.restrictionCodes = apiResponse.dataModel[i].restrictions;
        // }
      }
    }

    function getPromptRestrictionListError() {
      $rootScope.dataLoading = false;
    }

    function getPromptLinkedCardList() {
      cardManagementService
        .getPromptLinkedCardList(promptsVM.network.code)
        .then(getPromptLinkedCardListSuccess, getPromptLinkedCardListError);
    }

    function getPromptLinkedCardListSuccess(apiResponse) {
      promptsVM.linkedCardList = apiResponse.dataModel;
    }

    function getPromptLinkedCardListError() {}

    function changeDepartment(item) {
      if (item === "Add New") {
        promptsVM.showNewField = true;
      } else {
        promptsVM.showNewField = false;
      }
    }

    function onCardTypeChange() {
      promptsVM.showNewField = false;
      promptsVM.showDetailView = false;
      if (promptsVM.network && promptsVM.linkedCard) {
        getPromptRestrictionDropdownList();
        getPromptLinkedCardList();
        promptsVM.showDetailView = true;
        togglePromptTemplate(promptsVM.network, promptsVM.linkedCard);
      } else {
        promptsVM.showDetailView = false;
      }
    }

    /*To load the corresponding template of selected row*/
    function togglePromptTemplate(network, linkCard) {
      promptsVM.submitClicked = false;
      promptsVM.alreadyLinked = false;
      var cardType = network.description + "-" + linkCard.description;
      promptsVM.promptDetails[0] = {};
      promptsVM.promptRestrictions[0] = {};
      // promptsVM.cardDetails[0].driverLicenseState = promptsVM.dropDownData.licenseState[0].type;
      // promptsVM.promptRestrictions[0].CFNNet = Y;
      promptsVM.promptDetails[0].Status = "A";
      // promptsVM.promptRestrictions[0].DollarsPerDay = 500;
      // promptsVM.promptRestrictions[0].DollarsPerMonth = 1500;
      // promptsVM.promptRestrictions[0].DollarsPerCycle = 1500;
      // promptsVM.promptRestrictions[0].TransactionsPerDay = 3;
      // promptsVM.promptRestrictions[0].dollarsPerDayMode = promptsVM.dropDownData.restrictionLevel[0].type;
      // promptsVM.promptRestrictions[0].dollarsPerWeekMode = promptsVM.dropDownData.restrictionLevel[0].type;
      // promptsVM.promptRestrictions[0].dollarsPerMonthMode = promptsVM.dropDownData.restrictionLevel[0].type;
      // promptsVM.promptRestrictions[0].dollarsPerCycleMode = promptsVM.dropDownData.restrictionLevel[0].type;
      // promptsVM.promptRestrictions[0].transPerDayMode = promptsVM.dropDownData.restrictionLevel[0].type;
      // promptsVM.promptRestrictions[0].transPerWeekMode = promptsVM.dropDownData.restrictionLevel[0].type;
      // promptsVM.promptRestrictions[0].transPerMonthMode = promptsVM.dropDownData.restrictionLevel[0].type;
      // promptsVM.promptRestrictions[0].maxDollarPerTransMode = promptsVM.dropDownData.restrictionLevel[0].type;
      // promptsVM.promptRestrictions[0].purchaseDaysAllowed = promptsVM.dropDownData.purchaseDaysAllowed[0].type;
      promptsVM.showTimeProfile[0] = false;
      var templateURL;
      switch (cardType.toUpperCase()) {
        case "PACPRIDE-POOLED DRIVER": // Pac Pride-Pooled Driver
          templateURL = "pacPridePooledDriverTemplate";
          break;
        case "VOYAGER-DRIVER ID": // VF-Driver ID
          // promptsVM.promptDetails[0].DriversLicenseState = promptsVM.dropDownData.stateRestriction[0].code;
          // promptsVM.promptDetails[0].DriversLicenseState = 'CA';
          // promptsVM.promptRestrictions[0].DollarsPerDay = 500;
          // promptsVM.promptRestrictions[0].DollarsPerMonth = 1500;
          // promptsVM.promptRestrictions[0].DollarsPerCycle = 1500;
          // promptsVM.promptRestrictions[0].TransactionsPerDay = 3;
          promptsVM.promptRestrictions[0].StartTimeA = null;
          promptsVM.promptRestrictions[0].EndTimeA = null;
          templateURL = "vfDriverIdTemplate";
          break;
        case "VOYAGER-VEHICLE ID": // VF-Vehicle ID
          // promptsVM.promptRestrictions[0].DollarsPerDay = 500;
          // promptsVM.promptRestrictions[0].DollarsPerMonth = 1500;
          // promptsVM.promptRestrictions[0].DollarsPerCycle = 1500;
          // promptsVM.promptRestrictions[0].TransactionsPerDay = 3;
          promptsVM.promptRestrictions[0].StartTimeA = null;
          promptsVM.promptRestrictions[0].EndTimeA = null;
          templateURL = "vfVehicleIdTemplate";
          break;
        case "CFS STD-PIN": // CF-Pin
          templateURL = "cfPinTemplate";
          break;
        case "CFN-FLEETWIDE DRIVER":
          promptsVM.promptRestrictions[0].AllowInStoreTrans = "Y";
          // promptsVM.promptRestrictions[0].TimeProfile = promptsVM.dropDownRestrictionData.timeProfile[0].listDescription;
          templateURL = "cfnFleetwideDriverTemplate";
          break;
        case "VOYAGER-PIN": // VF-Pin
          templateURL = "vfPinTemplate";
          break;
        case "VOYAGER-LINKED DRIVER ID": // VF-Pin
          templateURL = "vfPinTemplate";
          break;
        default:
          promptsVM.showDetailView = false;
          break;
      }
      promptsVM.cardDetailView =
        "app/modules/cardManagement/promptsManagement/cardDetails/" +
        templateURL +
        ".html";
    }

    function submitNewCard(network, linkCard) {
      promptsVM.submitClicked = true;
      var cardType = network.description + "-" + linkCard.description;
      var cardData = promptsVM.promptDetails[0];
      var restrictData = promptsVM.promptRestrictions[0];
      var autoGenPin = promptsVM.autoGeneratePin;

      if (cardData.Department === "Add New" || promptsVM.newDepartment) {
        // if (promptsVM.newDepartment) {
        cardData.Department = promptsVM.newDepartment;
      }
      // else {
      //     cardData.Department = '';
      // }
      // }
      cardData.Status = "A";
      switch (cardType.toUpperCase()) {
        case "PACPRIDE-POOLED DRIVER":
          validate_pacPridePooledDriver(cardData, restrictData, 0, autoGenPin);
          break;
        case "VOYAGER-DRIVER ID":
          validate_vfDriverId(cardData, restrictData, 0);
          break;
        case "VOYAGER-VEHICLE ID":
          validate_vfVehicleId(cardData, restrictData, 0);
          break;
        case "CFS STD-PIN":
          validate_cfPin(cardData, restrictData, 0);
          break;
        case "CFN-FLEETWIDE DRIVER":
          validate_cfnFleetwideDriver(cardData, restrictData, 0, autoGenPin);
          break;
        case "VOYAGER-PIN":
          validate_vfPin(cardData, restrictData, 0, autoGenPin);
          break;
        case "VOYAGER-LINKED DRIVER ID":
          validate_vfPin(cardData, restrictData, 0);
          break;
        default:
          errorMsg = toastrMessage.cardControlErrorMessage.cannotEdit;
          toastr.error(errorMsg);
          break;
      }
    }

    /*Pac Pride-Pooled Driver validations*/
    function validate_pacPridePooledDriver(
      cardData,
      resrictData,
      index,
      autoGenPin
    ) {
      if (!autoGenPin) {
        if (
          cardData.PinNo &&
          cardData.PromptDescription &&
          cardData.Status &&
          resrictData.TimeProfile
        ) {
          if (!utils.isValidAlphaNumeric(cardData.PromptDescription)) {
            toastr.error("Enter valid description");
          } else if (
            cardData.Department &&
            !utils.isValidAlphaNumeric(cardData.Department)
          ) {
            toastr.error(
              "Special symbols/characters are not allowed in New Department"
            );
          } else {
            saveCardDetails(cardData, resrictData, autoGenPin);
          }
        } else {
          toastr.error("Please enter all the required fields");
        }
      } else {
        if (
          cardData.PromptDescription &&
          cardData.Status &&
          resrictData.TimeProfile
        ) {
          if (!utils.isValidAlphaNumeric(cardData.PromptDescription)) {
            toastr.error("Enter valid description");
          } else if (
            cardData.Department &&
            !utils.isValidAlphaNumeric(cardData.Department)
          ) {
            toastr.error(
              "Special symbols/characters are not allowed in New Department"
            );
          } else {            
            saveCardDetails(cardData, resrictData, autoGenPin);
          }
        } else {
          toastr.error("Please enter all the required fields");
        }
      }
    }
    /*VF-Driver ID validations*/
    function validate_vfDriverId(cardData, resrictData, index) {
      if (
        promptsVM.restrictionsForm[index].$valid &&
        cardData.PinNo &&
        cardData.PromptDescription
      ) {
        if (cardData.PinNo && !utils.isValidNumberWithZero(cardData.PinNo)) {
          toastr.error("Enter valid Driver Id");
        } else if (
          cardData.PromptDescription &&
          !utils.isValidAlphaNumeric(cardData.PromptDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Driver Name "
          );
        } else if (
          (resrictData.DollarsPerDay &&
            !utils.isValidNumber(resrictData.DollarsPerDay)) ||
          (resrictData.DollarsPerWeek &&
            !utils.isValidNumber(resrictData.DollarsPerWeek)) ||
          (resrictData.DollarsPerMonth &&
            !utils.isValidNumber(resrictData.DollarsPerMonth)) ||
          (resrictData.DollarsPerCycle &&
            !utils.isValidNumber(resrictData.DollarsPerCycle))
        ) {
          toastr.error("Enter valid Dollar value");
        } else if (
          (resrictData.DollarsPerDay && resrictData.DollarsPerDay < 100) ||
          (resrictData.DollarsPerDay && resrictData.DollarsPerDay > 999999999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(errorMsg);
        } else if (
          resrictData.DollarsPerWeek &&
          (resrictData.DollarsPerWeek < 100 ||
            resrictData.DollarsPerWeek > 999999999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(errorMsg);
        } else if (
          resrictData.DollarsPerMonth &&
          (resrictData.DollarsPerMonth < 100 ||
            resrictData.DollarsPerMonth > 999999999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(errorMsg);
        } else if (
          (resrictData.DollarsPerCycle && resrictData.DollarsPerCycle < 100) ||
          (resrictData.DollarsPerCycle &&
            resrictData.DollarsPerCycle > 999999999)
        ) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        } else if (
          resrictData.TransactionsPerDay &&
          !utils.isValidNumber(resrictData.TransactionsPerDay)
        ) {
          toastr.error("Enter valid Trasactions Per Day");
        } else if (
          (resrictData.TransactionsPerDay &&
            resrictData.TransactionsPerDay < 1) ||
          (resrictData.TransactionsPerDay &&
            resrictData.TransactionsPerDay > 99)
        ) {
          toastr.error("Transactions Per Day must be between 1 and 99");
        } else if (
          resrictData.TransactionsPerWeek &&
          !utils.isValidNumber(resrictData.TransactionsPerWeek)
        ) {
          toastr.error("Enter valid Trasactions Per Week");
        } else if (
          parseInt(resrictData.TransactionsPerWeek) < 1 ||
          parseInt(resrictData.TransactionsPerWeek) > 999
        ) {
          toastr.error("Transactions Per Week must be between 1 and 999");
        } else if (
          resrictData.TransactionsPerMonth &&
          !utils.isValidNumber(resrictData.TransactionsPerMonth)
        ) {
          toastr.error("Enter valid Trasactions Per Month");
        } else if (
          parseInt(resrictData.TransactionsPerMonth) < 1 ||
          parseInt(resrictData.TransactionsPerMonth) > 9999
        ) {
          toastr.error("Transactions Per Month must be between 1 and 9999");
        } else if (
          parseInt(resrictData.TransactionsPerCycle) < 1 ||
          parseInt(resrictData.TransactionsPerCycle) > 9999
        ) {
          toastr.error("Transactions Per Cycle must be between 1 and 9999");
        } else if (
          resrictData.MaxDollarsPerTransactions &&
          !utils.isValidNumber(resrictData.MaxDollarsPerTransactions)
        ) {
          toastr.error("Enter valid Max Dollar Per Trasactions");
        } else if (
          parseInt(resrictData.MaxDollarsPerTransactions) < 100 ||
          parseInt(resrictData.MaxDollarsPerTransactions) > 999999999
        ) {
          toastr.error(
            "Max Dollar Per Transactions must be between $100 and $999,999,999"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else {
          if (resrictData.StartTimeA || resrictData.EndTimeA) {
            if (resrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (resrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (resrictData.StartTimeA > resrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(resrictData));
          if (
            resrictData.StartTimeA &&
            typeof resrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(resrictData.StartTimeA)
            );
          }
          if (
            resrictData.EndTimeA &&
            typeof resrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(resrictData.EndTimeA)
            );
          }
          if (cardData.DriversLicenseState) {
            var selectedLicenseState = $filter("filter")(
              promptsVM.dropDownData.stateRestriction,
              { code: cardData.DriversLicenseState },
              true
            );
            if (selectedLicenseState.length > 0) {
              newCardData.DriversLicenseState =
                selectedLicenseState[0].description;
            }
          }
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        if (resrictData.StartTimeA === null || resrictData.EndTimeA === null) {
          if (promptsVM.restrictionsForm[index].$error.hasOwnProperty("time")) {
            toastr.error("Please clear invalid time");
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }
    /*VF-Veh ID validations*/
    function validate_vfVehicleId(cardData, resrictData, index) {
      if (
        promptsVM.restrictionsForm[index].$valid &&
        cardData.PinNo &&
        cardData.PromptDescription
      ) {
        if (
          (resrictData.DollarsPerDay &&
            !utils.isValidNumber(resrictData.DollarsPerDay)) ||
          (resrictData.DollarsPerWeek &&
            !utils.isValidNumber(resrictData.DollarsPerWeek)) ||
          (resrictData.DollarsPerMonth &&
            !utils.isValidNumber(resrictData.DollarsPerMonth)) ||
          (resrictData.DollarsPerCycle &&
            !utils.isValidNumber(resrictData.DollarsPerCycle))
        ) {
          toastr.error("Enter valid Dollar value");
        } else if (
          cardData.License &&
          !utils.isValidAlphaNumeric(cardData.License)
        ) {
          toastr.error("Enter valid License value");
        } else if (
          (resrictData.DollarsPerDay && resrictData.DollarsPerDay < 100) ||
          (resrictData.DollarsPerDay && resrictData.DollarsPerDay > 999999999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(errorMsg);
        } else if (
          resrictData.DollarsPerWeek &&
          (resrictData.DollarsPerWeek < 100 ||
            resrictData.DollarsPerWeek > 999999999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(errorMsg);
        } else if (
          resrictData.DollarsPerMonth &&
          (resrictData.DollarsPerMonth < 100 ||
            resrictData.DollarsPerMonth > 999999999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(errorMsg);
        } else if (
          (resrictData.DollarsPerCycle && resrictData.DollarsPerCycle < 100) ||
          (resrictData.DollarsPerCycle &&
            resrictData.DollarsPerCycle > 999999999)
        ) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        } else if (
          resrictData.TransactionsPerDay &&
          !utils.isValidNumber(resrictData.TransactionsPerDay)
        ) {
          toastr.error("Enter valid Trasactions Per Day");
        } else if (
          (resrictData.TransactionsPerDay &&
            resrictData.TransactionsPerDay < 1) ||
          (resrictData.TransactionsPerDay &&
            resrictData.TransactionsPerDay > 99)
        ) {
          toastr.error("Transactions Per Day must be between 1 and 99");
        } else if (
          resrictData.TransactionsPerWeek &&
          !utils.isValidNumber(resrictData.TransactionsPerWeek)
        ) {
          toastr.error("Enter valid Trasactions Per Week");
        } else if (
          parseInt(resrictData.TransactionsPerWeek) < 1 ||
          parseInt(resrictData.TransactionsPerWeek) > 999
        ) {
          toastr.error("Transactions Per Week must be between 1 and 999");
        } else if (
          resrictData.TransactionsPerMonth &&
          !utils.isValidNumber(resrictData.TransactionsPerMonth)
        ) {
          toastr.error("Enter valid Trasactions Per Month");
        } else if (
          parseInt(resrictData.TransactionsPerMonth) < 1 ||
          parseInt(resrictData.TransactionsPerMonth) > 9999
        ) {
          toastr.error("Transactions Per Month must be between 1 and 9999");
        } else if (
          parseInt(resrictData.TransactionsPerCycle) < 1 ||
          parseInt(resrictData.TransactionsPerCycle) > 9999
        ) {
          toastr.error("Transactions Per Cycle must be between 1 and 9999");
        } else if (
          resrictData.MaxDollarsPerTransactions &&
          !utils.isValidNumber(resrictData.MaxDollarsPerTransactions)
        ) {
          toastr.error("Enter valid Max Dollar Per Trasactions");
        } else if (
          parseInt(resrictData.MaxDollarsPerTransactions) < 100 ||
          parseInt(resrictData.MaxDollarsPerTransactions) > 999999999
        ) {
          toastr.error(
            "Max Dollar Per Transactions must be between $100 and $999,999,999"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else {
          if (resrictData.StartTimeA || resrictData.EndTimeA) {
            if (resrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (resrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (resrictData.StartTimeA > resrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(resrictData));
          if (
            resrictData.StartTimeA &&
            typeof resrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(resrictData.StartTimeA)
            );
          }
          if (
            resrictData.EndTimeA &&
            typeof resrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(resrictData.EndTimeA)
            );
          }
          newCardData.Status === "A";
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        if (resrictData.StartTimeA === null || resrictData.EndTimeA === null) {
          if (promptsVM.restrictionsForm[index].$error.hasOwnProperty("time")) {
            toastr.error("Please clear invalid time");
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }
    /*CF-Pin validations*/
    function validate_cfPin(cardData, resrictData, index) {
      if (promptsVM.cardDetailsForm[index].$valid) {
        if (
          cardData.LinkedCardDesc &&
          !utils.isValidAlphaNumeric(cardData.LinkedCardDesc)
        ) {
          toastr.error("Enter valid Card Description");
        } else if (
          cardData.PromptDescription &&
          !utils.isValidAlphaNumeric(cardData.PromptDescription)
        ) {
          toastr.error("Enter valid Name");
        } else if (
          cardData.TieCard &&
          !utils.isValidNumberWithZero(cardData.TieCard)
        ) {
          toastr.error("Enter valid Linked Card #");
        } else if (promptsVM.alreadyLinked) {
          toastr.warning("This card is already linked");
        } else {
          saveCardDetails(cardData, resrictData);
        }
      } else {
        toastr.error("Please enter all the required fields");
      }
    }

    /*CFN-Fleetwide Driver validations*/
    function validate_cfnFleetwideDriver(
      cardData,
      resrictData,
      index,
      autoGenPin
    ) {
      if (!autoGenPin) {
        if (
          cardData.PromptDescription &&
          resrictData.TimeProfile &&
          cardData.PinNo
        ) {
          if (
            cardData.PromptDescription &&
            !utils.isValidAlphaNumeric(cardData.PromptDescription)
          ) {
            // toastr.error("Enter valid description");
            toasterMsg = toastrMessage.cardControlErrorMessage.validDescription;
            toastr.error(toasterMsg);
          } else if (
            cardData.Department &&
            !utils.isValidAlphaNumeric(cardData.Department)
          ) {
            // toastr.error("Special symbols/characters are not allowed in New Department");
            toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
            toastr.error(toasterMsg);
          }
          // else if (resrictData.CustomPrompt && !utils.isValidAlphaNumeric(resrictData.CustomPrompt)) {
          //     toastr.error("Enter valid custom prompt");
          // }
          else {
            saveCardDetails(cardData, resrictData, autoGenPin);
          }
        } else {
          // toastr.error("Please enter all the required fields");
          toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
          toastr.error(toasterMsg);
        }
      } else {
        if (cardData.PromptDescription && resrictData.TimeProfile) {
          if (
            cardData.PromptDescription &&
            !utils.isValidAlphaNumeric(cardData.PromptDescription)
          ) {
            // toastr.error("Enter valid description");
            toasterMsg = toastrMessage.cardControlErrorMessage.validDescription;
            toastr.error(toasterMsg);
          } else if (
            cardData.Department &&
            !utils.isValidAlphaNumeric(cardData.Department)
          ) {
            // toastr.error("Special symbols/characters are not allowed in New Department");
            toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
            toastr.error(toasterMsg);
          }
          // else if (resrictData.CustomPrompt && !utils.isValidAlphaNumeric(resrictData.CustomPrompt)) {
          //     toastr.error("Enter valid custom prompt");
          // }
          else {
            saveCardDetails(cardData, resrictData, autoGenPin);
          }
        } else {
          // toastr.error("Please enter all the required fields");
          toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
          toastr.error(toasterMsg);
        }
      }
    }

    /*VF-Pin validations*/
    function validate_vfPin(cardData, resrictData, index, autoGenPin) {
      if (!autoGenPin) {
        if (
          promptsVM.cardDetailsForm[index].$valid &&
          cardData.TieCard &&
          cardData.PinNo &&
          cardData.PromptDescription
        ) {
          if (
            cardData.TieCard &&
            !utils.isValidNumberWithZero(cardData.TieCard)
          ) {
            toastr.error("Enter valid Linked card #");
          } else if (promptsVM.alreadyLinked) {
            toastr.warning("This card is already linked");
          } else {
            saveCardDetails(cardData, resrictData, autoGenPin);
          }
        } else {
          toastr.error("Please enter all the required fields");
        }
      } else {
        if (
          promptsVM.cardDetailsForm[index].$valid &&
          cardData.TieCard &&
          cardData.PromptDescription
        ) {
          if (
            cardData.TieCard &&
            !utils.isValidNumberWithZero(cardData.TieCard)
          ) {
            toastr.error("Enter valid Linked card #");
          } else if (promptsVM.alreadyLinked) {
            toastr.warning("This card is already linked");
          } else {
            saveCardDetails(cardData, resrictData, autoGenPin);
          }
        } else {
          toastr.error("Please enter all the required fields");
        }
      }
    }

    /*saves card details and restrict details*/
    function saveCardDetails(cardData, resrictData, autoGenPin) {
      // var payLoad = {
      //     "network": promptsVM.network,
      //     "cardType": promptsVM.linkedCard,
      //     "cardDetails": cardData,
      //     "restrictionDetails": resrictData
      // };

      if(autoGenPin){
        cardData.PromptNo = "AUTO";
      }

      //Api call
      $rootScope.dataLoading = true;      
      var payLoad = {
        AccountNo: localStorage.selectedAccount,
        CardNo: "",
        OrgId: localStorage.orgID,
        CardTypeDescription: promptsVM.linkedCard.description,
        CardTypeCode: promptsVM.linkedCard.code,
        Network: promptsVM.network.description,
        NetworkCode: promptsVM.network.code,
        Model: {
          PromptDetails: cardData,
          PromptRestrictions: resrictData,
        },
        DeltaModel: null,
      };
      //Api call
      cardManagementService
        .saveDriverPinDetails(payLoad)
        .then(saveDriverPinDetailsSuccess, saveDriverPinDetailsError);
    }

    function saveDriverPinDetailsSuccess(apiResponse) {
      if (apiResponse.statusCode === 200) {
        toastr.success("Submitted  Successfully.");
        $state.go("root.prompts");
      }
      $rootScope.dataLoading = false;
    }

    function saveDriverPinDetailsError() {
      $rootScope.dataLoading = false;
      toastr.error("Failed to save");
    }

    /*To get the Time profile list*/
    function manageTimeProfile(index) {
      promptsVM.showTimeProfile[index] = !promptsVM.showTimeProfile[index];
      if (promptsVM.showTimeProfile[index]) {
        promptsVM.timeProfileDetails[index] = [
          {
            profileName: "D001412",
            profileDesc: "Philip",
          },
          {
            profileName: "D001246",
            profileDesc: "Gerald",
          },
          {
            profileName: "D001419",
            profileDesc: "Samuel",
          },
        ];
      }
    }
    /*To edit time profile*/
    function editProfileDetails(parent, index) {
      promptsVM.editProfile = [];
      promptsVM.editProfile[parent] = [];
      if (promptsVM.isAddNewProfile[parent]) {
        promptsVM.isAddNewProfile[parent] = false;
        promptsVM.timeProfileDetails[parent].splice(0, 1);
      }
      promptsVM.editProfile[parent][index] = true;
    }
    /*To add new time profile*/
    function addNewProfile(profileIndex) {
      if (!promptsVM.isAddNewProfile[profileIndex]) {
        var profileObj = {
          profileName: "",
          profileDesc: "",
        };
        promptsVM.timeProfileDetails[profileIndex].splice(0, 0, profileObj);
        promptsVM.isAddNewProfile[profileIndex] = true;
        promptsVM.editProfile = [];
        promptsVM.editProfile[profileIndex] = [];
        promptsVM.editProfile[profileIndex][0] = true;
      }
    }
    /*Save new Time Profile*/
    function saveNewProfile(profileIndex) {
      //save api call
    }
    /*cancel new Time profile*/
    function cancelAddProfile(parent, index) {
      promptsVM.editProfile = [];
      promptsVM.editProfile[parent] = [];
      if (promptsVM.isAddNewProfile[parent]) {
        promptsVM.isAddNewProfile[parent] = false;
        promptsVM.timeProfileDetails[parent].splice(0, 1);
      }
      promptsVM.editProfile[parent][index] = false;
    }
    /*delete Time profile*/
    function deleteProfileDetails(parent, index) {
      //delete api call
    }
    /*confirmation for locking */
    function changeStatusPin(Status, index) {
      if (Status === "A") {
        promptsVM.promptDetails[index].Status = "A";
      } else if (
        Status === "L" &&
        promptsVM.promptDetails[index].Status === "A"
      ) {
        $confirm(
          {
            title: "Lock Card",
            text: "Pin linked to card, would you also like to lock card?",
            ok: "Yes",
            cancel: "No",
          },
          {
            size: "sm",
          }
        )
          .then(function () {
            promptsVM.promptDetails[index].Status = "L";
          })
          .catch(function () {
            promptsVM.promptDetails[index].Status = "A";
          });
      }
    }
    function changeStatus(Status, index) {
      if (Status === "A") {
        promptsVM.promptDetails[index].Status = "A";
      } else if (Status === "L") {
        promptsVM.promptDetails[index].Status = "L";
      }
    }

    function validatePin(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          promptsVM.promptDetails[0].PinNo = null;
          promptsVM.invalidPin = true;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          promptsVM.promptDetails[0].PinNo = null;
          promptsVM.invalidPin = true;
        } else {
          // if (cardNo.length === 4) {
          if (parseInt(cardNo.slice(0, 2)) === 0) {
            toastr.error(toastrMessage.common.voyagerPinValidation);
            promptsVM.promptDetails[0].PinNo = null;
            promptsVM.invalidPin = true;
            return;
          }
          // }
          // else if (cardNo.length > 4) {
          //     if (parseInt(cardNo.slice(0, 1)) === 0) {
          //         toastr.error(toastrMessage.common.voyagerPinValidation);
          //         promptsVM.promptDetails[0].PinNo = null;
          //         return;
          //     }
          // }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: promptsVM.network.code,
          };
          promptsVM.invalidPin = false;
          cardManagementService
            .getPinValidation(requestData)
            .then(getPinValidationSuccess, getPinValidationError);
        }
      }
    }

    function validateCFSStdPin(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          errorMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
          toastr.error(errorMsg);
          promptsVM.promptDetails[0].PinNo = null;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error("Pin must be 4 digit");
          promptsVM.promptDetails[0].PinNo = null;
        } else {
          if (parseInt(cardNo.slice(0, 1)) === 0) {
            errorMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
            toastr.error(errorMsg);
            promptsVM.promptDetails[0].PinNo = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: promptsVM.network.code,
          };
          cardManagementService
            .getPinValidation(requestData)
            .then(getPinValidationSuccess, getPinValidationError);
        }
      }
    }

    function getPinValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isExists) {
        promptsVM.promptDetails[0].PinNo = null;
        toastr.warning("Pin already exist.");
      }
    }

    function getPinValidationError() {}

    function validateDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          promptsVM.promptDetails[0].PinNo = null;
          return;
        }

        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          promptsVM.promptDetails[0].PinNo = null;
        } else {
          // if (cardNo.length === 4) {
          if (parseInt(cardNo.slice(0, 2)) === 0) {
            toastr.error(toastrMessage.common.voyagerPinValidation);
            promptsVM.promptDetails[0].PinNo = null;
            return;
          }
          // }
          // else if (cardNo.length > 4) {
          //     if (parseInt(cardNo.slice(0, 1)) === 0) {
          //         toastr.error(toastrMessage.common.voyagerPinValidation);
          //         promptsVM.promptDetails[0].PinNo = null;
          //         return;
          //     }
          // }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: promptsVM.network.code,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function validateCFNDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.cfnPinValidation);
          promptsVM.promptDetails[0].PinNo = null;
          promptsVM.invalidPin = true;
          return;
        }
        if (cardNo.length < 5) {
          toastr.error(toastrMessage.common.cfnPinValidation);
          promptsVM.promptDetails[0].PinNo = null;
          promptsVM.invalidPin = true;
        } else {
          if (parseInt(cardNo.slice(0, 1)) === 0) {
            toastr.error(toastrMessage.common.cfnPinValidation);
            promptsVM.promptDetails[0].PinNo = null;
            promptsVM.invalidPin = true;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: promptsVM.network.code,
          };
          promptsVM.invalidPin = false;
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function validatePacprideDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.pacpridePinValidation);
          promptsVM.promptDetails[0].PinNo = null;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.pacpridePinValidation);
          promptsVM.promptDetails[0].PinNo = null;
        } else {
          if (parseInt(cardNo.slice(0, 3)) === 0) {
            toastr.error(toastrMessage.common.pacpridePinValidation);
            promptsVM.promptDetails[0].PinNo = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: promptsVM.network.code,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function getDriverValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isExists) {
        promptsVM.promptDetails[0].PinNo = null;
        toastr.warning("Driver already exist.");
      }
    }

    function getDriverValidationError() {}

    function validateVehicle(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          errorMsg = toastrMessage.cardControlErrorMessage.invalidVhclId;
          toastr.error(errorMsg);
          promptsVM.promptDetails[0].PinNo = null;
          return;
        }
        var requestData = {
          AccountNumber: localStorage.selectedAccount,
          PinNo: cardNo,
          NetworkCode: promptsVM.network.code,
        };
        cardManagementService
          .getVehicleValidation(requestData)
          .then(getVehicleValidationSuccess, getVehicleValidationError);
      }
    }

    function getVehicleValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isExists) {
        promptsVM.promptDetails[0].PinNo = null;
        toastr.warning("Vehicle already exist.");
      }
    }

    function getVehicleValidationError() {}

    function changeLinkedCard(item, index) {
      promptsVM.alreadyLinked = false;
      var selectedLinkedCard = $filter("filter")(
        promptsVM.linkedCardList,
        { code: item },
        true
      );
      if (selectedLinkedCard.length > 0) {
        if (selectedLinkedCard[0].linked === "Y") {
          promptsVM.alreadyLinked = true;
          toastr.warning("This card is already linked");
          promptsVM.promptDetails[index].LinkedCardDesc =
            selectedLinkedCard[0].description;
        } else {
          promptsVM.promptDetails[index].LinkedCardDesc =
            selectedLinkedCard[0].description;
        }
      }
    }
    $scope.shouldShow = function (item) {
      // put your authorization logic here
      console.log(item);
      return item.code !== "1" && item.code !== "3";
    };
    /*when account changed*/
    $scope.$on("accountChanged", function () {
      var hasPermission = false;
      if (localStorage.accountPermission) {
        angular.forEach(
          JSON.parse(localStorage.accountPermission),
          function (item, key) {
            if (item.option.toLowerCase().indexOf("card management") !== -1) {
              if (item.displayName === "R" || item.displayName === "W") {
                hasPermission = true;
                init();
              }
            }
            var arrayLength =
              JSON.parse(localStorage.accountPermission).length - 1;
            if (key === arrayLength && !hasPermission) {
              $state.go("root.dashboard");
            }
          }
        );
      }
    });

    function checkAutoGenPin(index) {
      promptsVM.disablePin = !promptsVM.disablePin;
      promptsVM.autoGeneratePin = promptsVM.disablePin;

      if (promptsVM.disablePin == true) {
        promptsVM.invalidPin = false;
        promptsVM.promptDetails[index].PinNo = null;
      }
    }
  }
})();
