/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.userManagement')
        .controller('editUserController', editUserController);

    editUserController.$inject = ['$scope', '$state', 'toastr', '$confirm', '$stateParams', '$rootScope', 'userManagementService', 'utils', 'toastrMessage', '$filter', '$window'];

    function editUserController($scope, $state, toastr, $confirm, $stateParams, $rootScope, userManagementService, utils, toastrMessage, $filter,$window) {
        var editUserVM = this;
        editUserVM.tooltip = 'Able to select Read or Write and update all accounts at once.';
        editUserVM.init = init;
        editUserVM.borderSelection = borderSelection;
        editUserVM.checkAccount = checkAccount;
        editUserVM.moduleAccess = moduleAccess;
        editUserVM.updateUser = updateUser;
        editUserVM.checkEmail = checkEmail;
        editUserVM.onChecked = onChecked;
        editUserVM.buttonClass = buttonClass;
        editUserVM.paginationClick = paginationClick;

        /*function declarations*/

        /*initial loading*/
        function init() {
            editUserVM.currentPage = 1;
            editUserVM.firstPageItem = 1;
            editUserVM.secondPageItem = 2;
            getUserAccountDetails();
            getDefaultPermissions();
        }
        /*when the account has been changed*/
        $scope.$on('accountChanged', function() {
            var hasPermission = false;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item, key) {
                    if (item.option.toLowerCase().indexOf('account') !== -1) {
                        if (item.displayName == 'W') {
                            init();
                        }
                    }
                    if ((key == 3) && !hasPermission) {
                        $state.go('root.dashboard');
                    }
                })
            }
        });
        function paginationClick(button) {
            // var searchRequired = false;
            switch (button) {
                case 'first':
                    if (editUserVM.firstPageItem > 1) {
                        $window.scrollTo(0, 0);
                        editUserVM.firstPageItem = 1;
                        editUserVM.secondPageItem = 2;
                        editUserVM.currentPage = 1;
                        // searchRequired = true;
                    }
                    break;
                case 'previous':
                    if (editUserVM.firstPageItem > 1) {
                        $window.scrollTo(0, 0);
                        editUserVM.firstPageItem--;
                        editUserVM.secondPageItem--;
                        editUserVM.currentPage--;
                        // searchRequired = true;
                    } else if (editUserVM.firstPageItem < editUserVM.currentPage) {
                        $window.scrollTo(0, 0);
                        editUserVM.currentPage--;
                        // searchRequired = true;
                    } else {
                        //sonar issue fix
                    }
                    break;
                case 'next':
                    if (editUserVM.secondPageItem < editUserVM.maxPage) {
                        $window.scrollTo(0, 0);
                        editUserVM.firstPageItem++;
                        editUserVM.secondPageItem++;
                        editUserVM.currentPage++;
                        // searchRequired = true;
                    } else if (editUserVM.currentPage < editUserVM.secondPageItem) {
                        $window.scrollTo(0, 0);
                        editUserVM.currentPage++;
                        // searchRequired = true;
                    } else {
                        //sonar issue fix
                    }
                    break;
                case 'last':
                    if (editUserVM.secondPageItem < editUserVM.maxPage || editUserVM.currentPage < editUserVM.secondPageItem) {
                        $window.scrollTo(0, 0);
                        editUserVM.firstPageItem = editUserVM.maxPage - 1;
                        editUserVM.secondPageItem = editUserVM.maxPage;
                        editUserVM.currentPage = editUserVM.maxPage;
                        // searchRequired = true;
                    }
                    break;
                default:
                    $window.scrollTo(0, 0);
                    editUserVM.currentPage = button;
                    // searchRequired = true;
                    break;
            }
            // if (searchRequired) {
                getUserAccountDetails();
            // }

        }
        /*fetches the account list*/
        function getUserAccountDetails() {
            $rootScope.dataLoading = true;
            var payload = {
                'orgId': localStorage.orgID,
                'Filter': {
                    "PageNo":editUserVM.currentPage,
                    "PageSize": 25
                }
            }
            userManagementService.getAccountList(payload)
                .then(getAccountListSuccess, getAccountListError);
        }
        /*success callback for account list*/
        function getAccountListSuccess(apiResponse) {
            editUserVM.accountList = apiResponse.dataModel;
            editUserVM.searchCount = apiResponse.dataModel.totalCount;
            editUserVM.maxPage = Math.ceil(editUserVM.searchCount / 25);
            getUserDetails();
        }
        /*error callback for account list*/
        function getAccountListError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches the user details*/
        function getUserDetails() {
            $rootScope.dataLoading = true;
            var payload = {
                "userID": $stateParams.userID,
                "orgID": localStorage.orgID
            }
            userManagementService.getUser(payload)
                .then(getUserSuccess, getUserError);
        }

        /*success callback for user details*/
        function getUserSuccess(apiResponse) {
            editUserVM.userDetails = apiResponse.dataModel;
            editUserVM.userDetails.mobilePhone = utils.getPhoneNumberFormat(editUserVM.userDetails.mobilePhone);
            editUserVM.userDetails.workPhone = utils.getPhoneNumberFormat(editUserVM.userDetails.workPhone);
            editUserVM.userDetails.firstname = $filter('capitalize')(editUserVM.userDetails.firstname);
            editUserVM.userDetails.lastname = $filter('capitalize')(editUserVM.userDetails.lastname);
            angular.forEach(editUserVM.accountList.accounts, function(accItem) {
                var isExists = false;
                angular.forEach(editUserVM.userDetails.permissions, function(permissionItem) {
                    if (accItem.number === permissionItem.number) {
                        permissionItem.isChecked = true;
                        isExists = true;
                    }
                    permissionItem.permissions.sort(function(a, b) {
                        return (a.optionId > b.optionId) ? 1 : ((b.optionId > a.optionId) ? -1 : 0);
                    });
                });
                if (!isExists) {
                    accItem.isChecked = false;
                    accItem.permissions = [];
                    accItem.permissions[0] = { "option": "invoice" };
                    accItem.permissions[1] = { "option": "payment" };
                    accItem.permissions[2] = { "option": "card" };
                    accItem.permissions[3] = { "option": "account" };
                    accItem.permissions[4] = { "option": "transaction" };
                    editUserVM.userDetails.permissions.push(accItem);
                }
            });
            $rootScope.dataLoading = false;
        }
        /*error callback for user details*/
        function getUserError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches the default permissions*/
        function getDefaultPermissions() {
            userManagementService.getDefaultPermissions()
                .then(getDefaultPermissionsSuccess, getDefaultPermissionsError);
        }
        /*success callback for default permissions*/
        function getDefaultPermissionsSuccess(apiResponse) {
            editUserVM.defaultPermissions = apiResponse.dataModel.defaultPermissions;
        }
        /*error callback for default permissions*/
        function getDefaultPermissionsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*checks for email validation*/
        function checkEmail(mailID) {
            $rootScope.dataLoading = true;
            userManagementService.validateEmail(mailID)
                .then(validateEmailSuccess, validateEmailError);
        }
        /*success callback for email validation*/
        function validateEmailSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                toastr.success(apiResponse.statusMessage);
            } else if (apiResponse.statusCode === 0) {
                toastr.error(apiResponse.statusMessage);
            }
        }
        /*error callback for email validation*/
        function validateEmailError(errorResponse) {
            $rootScope.dataLoading = false;
            toastr.error(errorResponse);
        }
        /*updates or deletes button click*/
        function updateUser(token, isvalidForm) {
            if (token === 0) {
                editUserVM.deleteToken = false;
                $confirm({
                    title: "Delete",
                    text: toastrMessage.userManagement.deletePrompt + $filter('capitalize')(editUserVM.userDetails.firstname) + " " + $filter('capitalize')(editUserVM.userDetails.lastname) + "?",
                    ok: "Yes",
                    cancel: "No"
                }, {
                    size: 'sm',
                    backdrop: true
                }).then(function() {
                    updateOrDeleteUser(editUserVM.deleteToken);
                });

            } else {
                editUserVM.deleteToken = true;
                if (isvalidForm) {
                    if (!utils.isValidEmail(editUserVM.userDetails.email)) {
                        toastr.error(toastrMessage.common.emailValidation);
                    } else if (editUserVM.userDetails.mobilePhone && !utils.isValidPhoneNumber(editUserVM.userDetails.mobilePhone)) {
                        toastr.error(toastrMessage.common.phoneNumberValidation);
                    } else if (!utils.isValidPhoneNumber(editUserVM.userDetails.workPhone)) {
                        toastr.error(toastrMessage.common.phoneNumberValidation);
                    } else {
                        updateOrDeleteUser(editUserVM.deleteToken);
                    }
                } else {
                    toastr.error(toastrMessage.common.mandatoryValidation);
                }
            }
        }
        /*updates or delete the user*/
        function updateOrDeleteUser(deleteToken) {
            editUserVM.accPermissions = [];
            localStorage.deleteToken = deleteToken;
            angular.forEach(editUserVM.userDetails.permissions, function(permissionItem) {
                if (permissionItem.isChecked) {
                    var accountPermissionObj = {};
                    accountPermissionObj.Id = permissionItem.number;
                    accountPermissionObj.sbu = permissionItem.sbu;
                    accountPermissionObj.Permissions = [];
                    angular.forEach(permissionItem.permissions, function(perItem) {
                        angular.forEach(editUserVM.defaultPermissions, function(defaultItem) {
                            if (perItem.name === defaultItem.name) {
                                accountPermissionObj.Permissions.push({ "Name": defaultItem.name, "OptionId": defaultItem.optionId });
                            }
                        });
                    });
                    editUserVM.accPermissions.push(accountPermissionObj);
                }
            });
            if (editUserVM.accPermissions.length === 0 && JSON.parse(localStorage.deleteToken)) {
                toastr.error(toastrMessage.userManagement.singleUserPermission);
            } else {
                var payload = {
                    "userId": editUserVM.userDetails.userId,
                    "firstName": $filter('capitalize')(editUserVM.userDetails.firstname),
                    "lastName": $filter('capitalize')(editUserVM.userDetails.lastname),
                    "workPhone": editUserVM.userDetails.workPhone,
                    "mobilePhone": editUserVM.userDetails.mobilePhone,
                    "userType": "2",
                    "active": deleteToken,
                    "orgId": localStorage.orgID,
                    "Accounts": editUserVM.accPermissions,
                    "operation": deleteToken ? 'Update user' : 'Delete user'
                }
                $rootScope.dataLoading = true;
                userManagementService.updateUser(payload)
                    .then(updateUserSuccess, updateUserError);
            }
        }
        /*success callback for update or delete*/
        function updateUserSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                if (JSON.parse(localStorage.deleteToken)) {
                    toastr.success($filter('capitalize')(editUserVM.userDetails.firstname) + ' ' + $filter('capitalize')(editUserVM.userDetails.lastname) + toastrMessage.userManagement.UpdateUser);
                } else {
                    toastr.success($filter('capitalize')(editUserVM.userDetails.firstname) + ' ' + $filter('capitalize')(editUserVM.userDetails.lastname) + toastrMessage.userManagement.userDelete);
                }
                $state.go('root.userManagement');
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for update or delete*/
        function updateUserError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*shows the border according to the permission*/
        function borderSelection(selected, isChecked) {
            if (isChecked) {
                if (selected === 'IW' || selected === 'PW' || selected === 'CW' || selected === 'AW' || selected === 'TW') {
                    return 'wrn_write wrn_default_width';
                } else if (selected === 'IR' || selected === 'PR' || selected === 'CR' || selected === 'AR' || selected === 'TR') {
                    return 'wrn_status1 wrn_read wrn_default_width';
                } else {
                    return 'wrn_status2 wrn_noacces wrn_default_width';
                }
            } else {
                return 'wrn_status2 wrn_noacces wrn_default_width';
            }
        }
        /*selects the accounts permission for the user*/
        function checkAccount(checked, index, permission) {
            angular.forEach(editUserVM.userDetails.permissions, function(permissionItem, permissionKey) {
                if (index === permissionKey) {
                    if (permissionItem.isChecked) {
                        if (permission === 'read') {
                            permissionItem.permissions[0].name = 'IR';
                            permissionItem.permissions[1].name = 'PR';
                            permissionItem.permissions[2].name = 'CR';
                            permissionItem.permissions[3].name = 'AR';
                            permissionItem.permissions[4].name = 'TR';
                        } else if (permission === 'write') {
                            permissionItem.permissions[0].name = 'IW';
                            permissionItem.permissions[1].name = 'PW';
                            permissionItem.permissions[2].name = 'CW';
                            permissionItem.permissions[3].name = 'AW';
                            permissionItem.permissions[4].name = 'TW';
                        } else {
                            permissionItem.permissions[0].name = editUserVM.invoicePermission || 'IN';
                            permissionItem.permissions[1].name = editUserVM.paymentPermission || 'PN';
                            permissionItem.permissions[2].name = editUserVM.cardPermission || 'CN';
                            permissionItem.permissions[3].name = editUserVM.accountPermission || 'AN';
                            permissionItem.permissions[4].name = editUserVM.transactionPermission || 'TN';
                        }

                    } else {
                        permissionItem.permissions[0].name = 'IN';
                        permissionItem.permissions[1].name = 'PN';
                        permissionItem.permissions[2].name = 'CN';
                        permissionItem.permissions[3].name = 'AN';
                        permissionItem.permissions[4].name = 'TN';
                    }
                }
            });
        }
        /*shows the module permissions of the user */
        function moduleAccess(modules, accessToken, index) {
            angular.forEach(editUserVM.userDetails.permissions, function(permissionItem, permissionKey) {
                if (index == permissionKey) {
                    angular.forEach(permissionItem.permissions, function(item) {
                        if (item.option.toLowerCase().indexOf(modules.toLowerCase()) != -1) {
                            item.name = accessToken;
                        }
                    });
                }
            });
        }
        /*selects the module permissions for user*/
        function checkModules(module, isChecked, permission) {
            angular.forEach(editUserVM.userDetails.permissions, function(permissionItem) {
                if (module === 'invoice') {
                    if (isChecked) {
                        if (permission === 'read') {
                            permissionItem.permissions[0].name = 'IR';
                        } else if (permission === 'write') {
                            permissionItem.permissions[0].name = 'IW';
                        } else {
                            permissionItem.permissions[0].name = 'IN';
                        }

                    } else {
                        permissionItem.permissions[0].name = 'IN';
                    }
                    editUserVM.invoicePermission = permissionItem.permissions[0].name;
                } else if (module === 'payment') {
                    if (isChecked) {
                        if (permission === 'read') {
                            permissionItem.permissions[1].name = 'PR';
                        } else if (permission === 'write') {
                            permissionItem.permissions[1].name = 'PW';
                        } else {
                            permissionItem.permissions[1].name = 'PN';
                        }

                    } else {
                        permissionItem.permissions[1].name = 'PN';
                    }
                    editUserVM.paymentPermission = permissionItem.permissions[1].name;
                } else if (module === 'card') {
                    if (isChecked) {
                        if (permission === 'read') {
                            permissionItem.permissions[2].name = 'CR';
                        } else if (permission === 'write') {
                            permissionItem.permissions[2].name = 'CW';
                        } else {
                            permissionItem.permissions[2].name = 'CN';
                        }

                    } else {
                        permissionItem.permissions[2].name = 'CN';
                    }
                    editUserVM.cardPermission = permissionItem.permissions[2].name;
                } else if (module === 'account') {
                    if (isChecked) {
                        if (permission === 'read') {
                            permissionItem.permissions[3].name = 'AR';
                        } else if (permission === 'write') {
                            permissionItem.permissions[3].name = 'AW';
                        } else {
                            permissionItem.permissions[3].name = 'AN';
                        }

                    } else {
                        permissionItem.permissions[3].name = 'AN';
                    }
                    editUserVM.accountPermission = permissionItem.permissions[3].name;
                } else if (module === 'transaction') {
                    if (isChecked) {
                        if (permission === 'read') {
                            permissionItem.permissions[4].name = 'TR';
                        } else if (permission === 'write') {
                            permissionItem.permissions[4].name = 'TW';
                        } else {
                            permissionItem.permissions[4].name = 'TN';
                        }

                    } else {
                        permissionItem.permissions[4].name = 'TN';
                    }
                    editUserVM.transactionPermission = permissionItem.permissions[4].name;
                }
            });
        }
        /*selects read or write access for the module*/
        function onChecked(item, checked, permission) {
            switch (item) {
                case 'all':
                    editUserVM.allAccountCheck = checked;
                    angular.forEach(editUserVM.userDetails.permissions, function(permissionItem, permissionKey) {
                        permissionItem.isChecked = checked;
                        checkAccount(checked, permissionKey, permission);
                    });
                    if (checked) {
                        editUserVM.invoicePermission = (permission === 'write') ? 'IW' : 'IR';
                        editUserVM.paymentPermission = (permission === 'write') ? 'PW' : 'PR';
                        editUserVM.cardPermission = (permission === 'write') ? 'CW' : 'CR';
                        editUserVM.accountPermission = (permission === 'write') ? 'AW' : 'AR';
                        editUserVM.transactionPermission = (permission === 'write') ? 'TW' : 'TR';
                    } else {
                        editUserVM.invoicePermission = 'IN';
                        editUserVM.paymentPermission = 'PN';
                        editUserVM.cardPermission = 'CN';
                        editUserVM.accountPermission = 'AN';
                        editUserVM.transactionPermission = 'TN';
                    }
                    break;
                case 'invoice':
                    if (permission === 'read' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Read access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            editUserVM.invoiceCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else if (permission === 'write' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Write access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            editUserVM.invoiceCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else {
                        editUserVM.invoiceCheck = checked;
                        checkModules(item, checked, permission);
                    }
                    break;
                case 'payment':
                    if (permission === 'read' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Read access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            editUserVM.paymentCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else if (permission === 'write' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Write access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            editUserVM.paymentCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else {
                        editUserVM.paymentCheck = checked;
                        checkModules(item, checked, permission);
                    }
                    break;
                case 'card':
                    if (permission === 'read' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Read access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            editUserVM.cardCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else if (permission === 'write' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Write access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            editUserVM.cardCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else {
                        editUserVM.cardCheck = checked;
                        checkModules(item, checked, permission);
                    }
                    break;
                case 'account':
                    if (permission === 'read' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Read access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            editUserVM.accMaintainCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else if (permission === 'write' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Write access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            editUserVM.accMaintainCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else {
                        editUserVM.accMaintainCheck = checked;
                        checkModules(item, checked, permission);
                    }

                    break;
                case 'transaction':
                    if (permission === 'read' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Read access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            editUserVM.transactionCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else if (permission === 'write' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Write access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            editUserVM.transactionCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else {
                        editUserVM.transactionCheck = checked;
                        checkModules(item, checked, permission);
                    }
                    break;
            }
        }
        /*class selection for the account permission*/
        function buttonClass(item, value, permission, isChecked) {
            var styleClass;
            if (isChecked) {
                if (permission === 'W') {
                    if (item === value) {
                        styleClass = 'write-only active';
                    } else {
                        styleClass = 'write-only';
                    }
                } else if (permission === 'R') {
                    if (item === value) {
                        styleClass = 'read-only active';
                    } else {
                        styleClass = 'read-only';
                    }
                } else {
                    if (item === value) {
                        styleClass = 'no-acces-only active';
                    } else {
                        styleClass = 'no-acces-only';
                    }
                }
            } else {
                styleClass = 'disable-button';
            }
            return styleClass;
        }

    }
})();
