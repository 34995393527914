
/*jshint browser: true*/
/*global angular: true*/


(function(){
    'use strict';

    angular
        .module('scFuelsApp.userManagement', ['scFuelsApp.login'])
        .config(routerConfiguration);

    routerConfiguration.$inject = ['$stateProvider'];
    function routerConfiguration ($stateProvider) {
        $stateProvider
            .state('root.userManagement', {
              url: '/userManagement',
              templateUrl: 'app/modules/userManagement/userManagementTemplate.html',
              controller: 'userManagementController',
              controllerAs : 'userManagementVM'
            })
            .state('root.newUser', {
              url: '/newUser',
              templateUrl: 'app/modules/userManagement/newUser/newUserTemplate.html',
              controller: 'newUserController',
              controllerAs : 'newUserVM'
            })
            .state('root.editUser', {
              url: '/editUser/:userID',
              templateUrl: 'app/modules/userManagement/editUser/editUserTemplate.html',
              controller: 'editUserController',
              controllerAs : 'editUserVM'
            });
    }
})();
