(function () {
    'use strict';

    angular.module('scFuelsApp.test',[])
        .controller('TestController', TestController);
    
    TestController.$inject = ['modalService'];

    function TestController(modalService) {
        var testVM = this;

        testVM.openModal = openModal;
        testVM.closeModal = closeModal;

        initController();

        function initController() {
            testVM.bodyText = 'This text can be updated in modal 1';
        }
        
        function openModal(id){
            modalService.Open(id);
        }

        function closeModal(id){
            modalService.Close(id);
        }
    }

})();