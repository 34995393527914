/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.dashBoard')
        .controller('DashboardController', DashboardController);


    DashboardController.$inject = ['$rootScope', '$scope', '$state', 'dashboardService', 'toastr', '$confirm', 'toastrMessage', 'bannerManagementService'];

    function DashboardController($rootScope, $scope, $state, dashboardService, toastr, $confirm, toastrMessage, bannerManagementService) {
        var dashboardVM = this;
        dashboardVM.userName = localStorage.loggedInUser;
        init();

        /*function declarations*/

        /*initial page load*/
        function init() {
            loadRecentActivitiesData();
            loadOpenInvoiceData();
            getCriticalAlerts();
            loadBannerData();
            getGreetingText();
        }

        /*for showing the greeting text according to time*/
        function getGreetingText() {
            var hour = new Date().getHours();
            if (hour < 12) {
                dashboardVM.greetingText = "Good Morning";
            } else if (hour < 17) {
                dashboardVM.greetingText = "Good Afternoon";
            } else {
                dashboardVM.greetingText = "Good Evening";
            }
        }
        /*fetches recent activities*/
        function loadRecentActivitiesData() {
            $rootScope.dataLoading = true;
            var orgId = localStorage.orgID;
            var accId = localStorage.selectedAccount ? localStorage.selectedAccount : '0';
            dashboardService.getRecentActivitiesList(orgId, accId)
                .then(getRecentActivitiesListSuccess, getRecentActivitiesListError);
        }
        /*success callback for recent activities*/
        function getRecentActivitiesListSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            dashboardVM.recentActivitiesList = null;
            if (apiResponse.statusCode === 200) {
                dashboardVM.recentActivitiesList = apiResponse.dataModel.auditList;
                angular.forEach(dashboardVM.recentActivitiesList,function(item){
                    // item.createdDate = new Date(item.createdDate+'UTC').getTime();
                    // var cDate = new Date(item.createdDate);
                    // var utcDate = new Date(cDate.getUTCFullYear(), cDate.getUTCMonth(), cDate.getUTCDate(),  cDate.getUTCHours(), cDate.getUTCMinutes(), cDate.getUTCSeconds());
                    // item.createdDate = utcDate.getTime();
                    // var dateIST = new Date(createdDate);
                    // dateIST.setHours(dateIST.getHours() + 5); 
                    // dateIST.setMinutes(dateIST.getMinutes() + 30);
                    item.createdDate = new Date(item.createdDate);
                });
            } else if (apiResponse.statusCode === 0) {
                // toaster msg
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for recent activities*/
        function getRecentActivitiesListError() {
            $rootScope.dataLoading = false;
            dashboardVM.recentActivitiesList = null;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches current banners*/
        function loadBannerData() {
            $rootScope.dataLoading = true;
            bannerManagementService.getCurrentBanners()
                .then(getCurrentBannersSuccess, getCurrentBannersError);
        }
        /*success callback for current banner*/
        function getCurrentBannersSuccess(apiResponse) {
            if (apiResponse.statusCode === 200) {
                dashboardVM.bannerList = apiResponse.bannerData;
                $rootScope.dataLoading = false;
            }
        }
        /*error callback for current banner*/
        function getCurrentBannersError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*when account has changed*/
        $scope.$on('accountChanged', function() {
            init();
        });
        /*fetches open invoices amount data*/
        function loadOpenInvoiceData() {
            dashboardService.getOpenInvoiceData()
                .then(getOpenInvoiceDataSuccess, getOpenInvoiceDataError);
        }
        /*success callback for open invoice data*/
        function getOpenInvoiceDataSuccess(apiResponse) {
            if (apiResponse.statusCode === 200) {
                dashboardVM.openInvoiceValues = apiResponse.openInvoiceData;
            }
        }
        /*error callback for open invoice data*/
        function getOpenInvoiceDataError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches critical alert if any*/
        function getCriticalAlerts() {
            dashboardService.getCriticalAlert()
                .then(getCriticalAlertSuccess, getCriticalAlertError);
        }
        /*success callback for critical alert*/
        function getCriticalAlertSuccess(apiResponse) {
            if (apiResponse.statusCode === 200) {
                dashboardVM.criticalAlert = apiResponse.alertText;
            }
        }
        /*error callback for critical alert*/
        function getCriticalAlertError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        // $scope.$on('IdleTimeout', function() {
        //     $state.go('login');
        // });
    }
})();
