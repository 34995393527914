
/*jshint browser: true*/
/*global angular: true*/


(function(){
    'use strict';

    angular
        .module('scFuelsApp.marketingInsight', ['scFuelsApp.login'])
        .config(routerConfiguration);

    routerConfiguration.$inject = ['$stateProvider'];
    function routerConfiguration ($stateProvider) {
        $stateProvider
            .state('root.marketingInsight', {
            url: '/marketingInsight',
            templateUrl: 'app/modules/marketingInsight/marketingInsightTemplate.html',
            controller: 'MarketingInsightController',
            controllerAs : 'marketingInsightVM'
        });
    }
})();
