
/*jshint browser: true*/
/*global angular: true*/


(function(){
    'use strict';

    angular
        .module('scFuelsApp.bannerManagement', ['ng-file-model'])
        .config(routerConfiguration);

    routerConfiguration.$inject = ['$stateProvider'];
    function routerConfiguration ($stateProvider) {
        $stateProvider
            .state('root.bannerManagement', {
            url: '/bannerManagement',
            templateUrl: 'app/modules/bannerManagement/bannerManagementTemplate.html',
            controller: 'BannerManagementController',
            controllerAs : 'bannerManagementVM'
        });
    }
})();
