
/*jshint browser: true*/
/*global angular: true*/


(function(){
    'use strict';

    angular
        .module('scFuelsApp.techInsight', ['scFuelsApp.login'])
        .config(routerConfiguration);

    routerConfiguration.$inject = ['$stateProvider'];
    function routerConfiguration ($stateProvider) {
        $stateProvider
            .state('root.techInsight', {
            url: '/techInsight',
            templateUrl: 'app/modules/techInsight/techInsightTemplate.html',
            controller: 'TechInsightController',
            controllerAs : 'techInsightVM'
        });
    }
})();
