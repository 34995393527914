/*jshint browser: true*/
/*global angular: true*/

(function () {
    'use strict';

    angular.module('scFuelsApp.cardManagement')
        .controller('promptsPrintController', promptsPrintController);

    promptsPrintController.$inject = ['$scope', '$state', '$rootScope', 'cardManagementService', 'utils', 'toastr', 'toastrMessage', '$filter', '$window'];

    function promptsPrintController($scope, $state, $rootScope, cardManagementService, utils, toastr, toastrMessage, $filter, $window) {
        var promptsPrintVM = this;
        promptsPrintVM.searchCardNo = "";
        promptsPrintVM.amountTo = "";
        promptsPrintVM.description = "";
        promptsPrintVM.department = "";
        promptsPrintVM.exceptions = false;
        promptsPrintVM.showCardSearchButton = true;
        promptsPrintVM.datePicker = {
            startDate: null,
            endDate: null
        };
        promptsPrintVM.startYear = utils.calenderStartYear;
        promptsPrintVM.endYear = utils.calenderEndYear;
        promptsPrintVM.showProductPopup = false;
        promptsPrintVM.showColumnPopup = false;
        promptsPrintVM.showAdvanceSearch = false;
        promptsPrintVM.showAccordianView = [];
        promptsPrintVM.promptCardList = [];
        promptsPrintVM.cardDetails = [];
        promptsPrintVM.transactionData = [];
        promptsPrintVM.siteDetails = [];
        promptsPrintVM.amountDetails = [];
        promptsPrintVM.getPromptCardList = getPromptCardList;

  
        promptsPrintVM.printTable = printTable;
        promptsPrintVM.backToPrompts = backToPrompts;
        init();
        
        //function declarations

        function init() {
            
            
            // getTransactionParameters();
            // getColumnHeaders();
        }

        $scope.$watch('promptsPrintVM.currentPage', function () {
            getPromptCardList([], promptsPrintVM.searchCardNo, 'Trans. Date');
        });
        
        function getPromptCardList(){
            $rootScope.dataLoading = true;
            var payload = JSON.parse(localStorage.promptsPayload);
                        payload.Filter.PageNo = 0;
                        payload.Filter.PageSize = 0;
             cardManagementService.printPrompts(payload)
                            .then(getPromptCardListSuccess, getPromptCardListError);
            }
        function getPromptCardListSuccess(apiResponse) {
            promptsPrintVM.promptCardList = apiResponse.DataModel;
            // promptsPrintVM.searchCount = apiResponse.TotalRecords;
            // promptsPrintVM.maxPage = Math.ceil(promptsPrintVM.searchCount / 20);
            // promptsPrintVM.dataCopy = JSON.parse(JSON.stringify(apiResponse.DataModel));
            // angular.forEach(promptsPrintVM.promptCardList, function (item) {
            //     item.showIcon = 'icon-Expand';
            //     item.gridData = {
            //         'NetworkDescription': item.promptDetails.NetworkDescription,
            //         'PinNo': item.promptDetails.PinNo,
            //         'PromptDescription': item.promptDetails.PromptDescription,
            //         'Status': item.promptDetails.Status,
            //         'LinkedCardDesc': item.promptDetails.LinkedCardDesc,
            //         'CardDescription': item.promptDetails.CardDescription,
            //         'Department': item.promptDetails.Department
            //     };
            // });
                        $rootScope.dataLoading = false;
            }
            
            function getPromptCardListError() {
                        $rootScope.dataLoading = false;
                        toastr.error(toastrMessage.common.systemNetworkFailure);
                    }
            

        

        function printTable() {
            // window.print();

            var printContents = document.getElementById('printDiv').innerHTML;
            var popupWin = window.open('', '_blank', 'width=800,height=800,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no,top=50');
            popupWin.window.focus();
            popupWin.document.open();
            popupWin.document.write('<!DOCTYPE html><html><head><title>Drivers/PIN Management</title>' +
                '<link rel="stylesheet" type="text/css" href="resources/stylesheets/print.css" />' +
                '</head><body onload="window.print(); window.close();"><div>' +
                printContents + '</div></html>');
            popupWin.document.close();

            // var printContents = document.getElementById('printDiv').innerHTML;
            // var originalContents = document.body.innerHTML;        
            // document.body.innerHTML = printContents;
            // window.print();
            // document.body.innerHTML = originalContents;
        }

        function backToPrompts() {
            $state.go('root.prompts');
        }

        /*when account changed*/
        $scope.$on('accountChanged', function () {
            var hasPermission = false;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function (item, key) {
                    if (item.option.toLowerCase().indexOf('card management') !== -1) {
                        if (item.displayName === 'R' || item.displayName === 'W') {
                            hasPermission = true;
                            init();
                            getPromptCardList([], promptsPrintVM.searchCardNo, 'Trans. Date');
                        }
                    }
                    if ((key === 3) && !hasPermission) {
                        $state.go('root.dashboard');
                    }
                });
            }
        });
    }
})();