/*jshint browser: true*/
/*global angular: true*/

(function () {
    'use strict';

    angular.module('scFuelsApp.cardManagement')
        .controller('transactionsController', transactionsController);

    transactionsController.$inject = ['$scope', '$state', '$rootScope', 'cardManagementService', 'utils', 'toastr', 'toastrMessage', '$filter', '$window'];

    function transactionsController($scope, $state, $rootScope, cardManagementService, utils, toastr, toastrMessage, $filter, $window) {
        var transactionsVM = this;
        transactionsVM.searchCardNo = "";
        transactionsVM.cardNumberFrom = "";
        transactionsVM.cardNumberTo = "";
        transactionsVM.idNumberFrom = "";
        transactionsVM.idNumberTo = "";
        transactionsVM.amountFrom = "";
        transactionsVM.amountTo = "";
        transactionsVM.description = "";
        transactionsVM.department = "";
        transactionsVM.exceptions = false;
        transactionsVM.showCardSearchButton = true;
        transactionsVM.datePicker = {
            startDate: null,
            endDate: null
        };
        transactionsVM.startYear = utils.calenderStartYear;
        transactionsVM.endYear = utils.calenderEndYear;
        transactionsVM.showProductPopup = false;
        transactionsVM.showColumnPopup = false;
        transactionsVM.showAdvanceSearch = false;
        transactionsVM.showAccordianView = [];
        transactionsVM.cardDetails = [];
        transactionsVM.transactionData = [];
        transactionsVM.siteDetails = [];
        transactionsVM.amountDetails = [];
        transactionsVM.productId = "";
        transactionsVM.validTimeOfDayFrom = null;
        transactionsVM.validTimeOfDayTo = null;
        transactionsVM.quantityFrom = "";
        transactionsVM.quantityTo = "";
        transactionsVM.sortBy = "";
        transactionsVM.SortColumn = "";
        transactionsVM.pageSize = 20;

        transactionsVM.getAllCards = getAllCards;
        transactionsVM.toggleSearch = toggleSearch;
        transactionsVM.transactionDetails = transactionDetails;
        // transactionsVM.selectProducts = selectProducts;
        transactionsVM.searchTransactions = searchTransactions;
        transactionsVM.cardSearch = cardSearch;
        transactionsVM.arrangeTableData = arrangeTableData;
        transactionsVM.toggleHeader = toggleHeader;
        transactionsVM.paginationClick = paginationClick;
        transactionsVM.orderByColumn = orderByColumn;
        transactionsVM.clearCalender = clearCalender;
        // transactionsVM.applyProducts = applyProducts;
        transactionsVM.printTable = printTable;
        transactionsVM.exportTable = exportTable;
        transactionsVM.fromTimeClear = fromTimeClear;
        transactionsVM.toTimeClear = toTimeClear;
        init();

        //function declarations

        function init() {
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item) {
                    if (item.option.toLowerCase().indexOf('transaction') !== -1) {
                        transactionsVM.userPermission = item.displayName;
                    }
                });
            }
            transactionsVM.currentPage = 1;
            transactionsVM.firstPageItem = 1;
            transactionsVM.secondPageItem = 2;
            // getTransactionParameters();
            getColumnHeaders();
        }

        $scope.$watch('transactionsVM.currentPage', function () {
            if (transactionsVM.sortBy === '' && transactionsVM.SortColumn === '') {
                getTransactionSearchDetails(transactionsVM.searchCardNo, 'Trans. Date');
            } else {
                getTransactionSearchDetails(transactionsVM.searchCardNo, transactionsVM.SortColumn, transactionsVM.sortBy);
            }
        });

        function getAllCards(searchKey) {
            return cardManagementService.getCardSmartSearchList(searchKey)
                .then(getCardSmartSearchListSuccess, getCardSmartSearchListError);
        }

        function getCardSmartSearchListSuccess(apiResponse) {
            var results = [];
            angular.forEach(apiResponse.invoiceNumbers, function (item) {
                var invoices = item;
                results.push({
                    name: invoices
                });
            });
            $rootScope.dataLoading = false;
            return results;
        }

        function getCardSmartSearchListError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        function toggleSearch() {
            transactionsVM.showAdvanceSearch = !transactionsVM.showAdvanceSearch;
            // transactionsVM.searchCardNo = '';
            if (transactionsVM.showAdvanceSearch) {
                transactionsVM.showCardSearchButton = false;
            } else {
                transactionsVM.showCardSearchButton = true;
            }
            // transactionsVM.searchCardNo = '';
            // transactionsVM.description = '';
            transactionsVM.idNumberFrom = '';
            transactionsVM.idNumberTo = '';
            transactionsVM.exceptions = false;
            transactionsVM.productId = '';
            transactionsVM.quantityFrom = '';
            transactionsVM.quantityTo = '';
            transactionsVM.amountFrom = '';
            transactionsVM.amountTo = '';
            transactionsVM.validTimeOfDayFrom = null;
            transactionsVM.validTimeOfDayTo = null;
            // transactionsVM.datePicker = {
            //     startDate: null,
            //     endDate: null
            // };
        }

        function transactionDetails(cardNumber, iconType, index) {
            transactionsVM.showAccordianView[index] = 'app/modules/cardManagement/transactions/customView/transactionAccordianTemplate.html';
            angular.forEach(transactionsVM.transactionList.dataModel, function (item, key) { //transactionsList
                if (key === index) {
                    if (item.showIcon === 'icon-Expand') {
                        item.showIcon = 'icon-Condense';
                        // return cardManagementService.getTransactionDetails(cardNumber, index)
                        //     .then(getTransactionDetailsSuccess, getTransactionDetailsError);
                        transactionsVM.transactionDetail = item;
                        transactionsVM.transactionData[index] = transactionsVM.transactionDetail;
                    } else {
                        item.showIcon = 'icon-Expand';
                    }
                }
            });
        }

        /*when account changed*/
        $scope.$on('accountChanged', function () {
            transactionsVM.searchCardNo = null;
            transactionsVM.description = null;
            clearCalender();
            transactionsVM.validTimeOfDayFrom = null;
            transactionsVM.validTimeOfDayTo = null;
            transactionsVM.productId = null;
            transactionsVM.quantityFrom = null;
            transactionsVM.quantityTo = null;
            transactionsVM.amountFrom = null;
            transactionsVM.amountTo = null;
            transactionsVM.idNumberFrom = null;
            transactionsVM.idNumberTo = null;
            transactionsVM.exceptions = null;
           
            var hasPermission = false;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function (item, key) {
                    if (item.option.toLowerCase().indexOf('transaction') !== -1) {
                        if (item.displayName === 'R' || item.displayName === 'W') {
                            hasPermission = true;
                            init();
                            transactionsVM.sortBy = '';
                            transactionsVM.SortColumn = '';
                            getTransactionSearchDetails(transactionsVM.searchCardNo, 'Trans. Date');
                        }
                    }
                    var arrayLength = JSON.parse(localStorage.accountPermission).length - 1;
                    if ((key === arrayLength) && !hasPermission) {
                        $state.go('root.dashboard');
                    }
                });
            }
        });

        // function getTransactionDetailsSuccess(apiResponse) {
        //     var index = apiResponse.index;
        //     transactionsVM.transactionDetail = apiResponse;
        //     transactionsVM.cardDetails[index] = transactionsVM.transactionDetail.cardDetails;
        //     transactionsVM.transactionData[index] = transactionsVM.transactionDetail.transactionDetails;
        //     transactionsVM.siteDetails[index] = transactionsVM.transactionDetail.siteDetails;
        //     transactionsVM.amountDetails[index] = transactionsVM.transactionDetail.amountDetails;
        // }

        // function getTransactionDetailsError() {

        // }

        // function getTransactionParameters() {
        //     return cardManagementService.getTransactionParameters()
        //         .then(getTransactionParametersSuccess, getTransactionParametersError);
        // }

        // function getTransactionParametersSuccess(apiResponse) {
        //     transactionsVM.productValues = apiResponse.products;
        //     transactionsVM.productValues.forEach(function(item) {
        //         item.isChecked = false;
        //     });
        // }

        // function getTransactionParametersError() {

        // }

        // function selectProducts() {
        //     transactionsVM.showProductPopup = !transactionsVM.showProductPopup;
        // }

        // $rootScope.$on('hidePopup', function() {
        //     transactionsVM.showProductPopup = false;
        //     transactionsVM.showColumnPopup = false;
        //     $scope.$apply();
        // });

        function searchTransactions() {

            // transactionsVM.productIds = [];
            // angular.forEach(transactionsVM.productValues, function(item) {
            //     if (item.isChecked) {
            //         transactionsVM.productIds.push(item.id);
            //     }
            // });

            // if ((transactionsVM.cardNumberFrom && !utils.isValidNumber(transactionsVM.cardNumberFrom)) || (transactionsVM.cardNumberTo && !utils.isValidNumber(transactionsVM.cardNumberTo))) {
            //     toastr.error("Enter valid card Numbers");
            // } else if (parseInt(transactionsVM.cardNumberFrom, 10) > parseInt(transactionsVM.cardNumberTo, 10)) {
            //     toastr.error("Starting card number should be less");
            // } else 
            if ((transactionsVM.idNumberFrom && !utils.isValidID(transactionsVM.idNumberFrom)) || (transactionsVM.idNumberTo && !utils.isValidID(transactionsVM.idNumberTo))) {
                toastr.error("Enter valid id Numbers");
            } else if (parseInt(transactionsVM.idNumberFrom, 10) > parseInt(transactionsVM.idNumberTo, 10)) {
                toastr.error("Starting Id number should be less");
            } else if ((transactionsVM.amountFrom && isNaN(transactionsVM.amountFrom)) || (transactionsVM.amountTo && isNaN(transactionsVM.amountTo))) {
                toastr.error("Enter valid amount Numbers");
            } else if (parseFloat(transactionsVM.amountFrom) > parseFloat(transactionsVM.amountTo)) {
                toastr.error("Starting amount number should be less");
            } else if (transactionsVM.description && utils.isAlphaNumeric(transactionsVM.description)) {
                toastr.error("Special symbols are not allowed in description");
            } else if (parseFloat(transactionsVM.quantityFrom) > parseFloat(transactionsVM.quantityTo)) {
                toastr.error("Starting quantity number should be less");
            }
            else if ((transactionsVM.idNumberFrom && !transactionsVM.idNumberTo) ||
                (!transactionsVM.idNumberFrom && transactionsVM.idNumberTo)) {
                toastr.error("Please fill ID range from and to");
            }
            else if ((transactionsVM.quantityFrom && !transactionsVM.quantityTo) ||
                (!transactionsVM.quantityFrom && transactionsVM.quantityTo)) {
                toastr.error("Please fill quantity range from and to");
            }
            else if ((transactionsVM.amountFrom && !transactionsVM.amountTo) ||
                (!transactionsVM.amountFrom && transactionsVM.amountTo)) {
                toastr.error("Please fill amount range from and to");
            } else if ((transactionsVM.validTimeOfDayFrom && !transactionsVM.validTimeOfDayTo) ||
                (!transactionsVM.validTimeOfDayFrom && transactionsVM.validTimeOfDayTo)) {
                toastr.error("Please fill transaction time range from and to");
            }
            else {
                transactionsVM.currentPage = 1;
                transactionsVM.firstPageItem = 1;
                transactionsVM.secondPageItem = 2;
                transactionsVM.sortBy = '';
                transactionsVM.SortColumn = '';
                getTransactionSearchDetails(transactionsVM.searchCardNo, 'Trans. Date');
            }
        }

        function getTransactionSearchDetails(cardNumber, sortBy, orderBy) {
            if (transactionsVM.description && utils.isAlphaNumeric(transactionsVM.description)) {
                toastr.error("Special symbols are not allowed in description");
                return;
            }
            $rootScope.dataLoading = true;
            var startDate = transactionsVM.datePicker.startDate === null ? "" : $filter('date')(new Date(transactionsVM.datePicker.startDate.toString()), 'MM/dd/yyyy');
            var endDate = transactionsVM.datePicker.endDate === null ? "" : $filter('date')(new Date(transactionsVM.datePicker.endDate.toString()), 'MM/dd/yyyy');

            // var payload = {
            //     "cardNumFrom": transactionsVM.cardNumberFrom,
            //     "cardNumTo": transactionsVM.cardNumberTo,
            //     "cardDescription": transactionsVM.description,
            //     "product": products,
            //     "idFrom": transactionsVM.idNumberFrom,
            //     "idTo": transactionsVM.idNumberTo,
            //     "department": transactionsVM.department,
            //     "transactionDateFrom": startDate,
            //     "transactionDateTo": endDate,
            //     "amountFrom": transactionsVM.amountFrom,
            //     "amountTo": transactionsVM.amountTo,
            //     "transactionwithExpceptions": transactionsVM.exceptions,
            //     "transactionTimeTo": "",
            //     "transactionTimeFrom": "",
            //     "searchString": cardNumber.name === undefined ? cardNumber : cardNumber.name,
            //     "accntNum": "All Accounts",
            //     "pageNumber": transactionsVM.currentPage,
            //     "sortingParameter": sortBy,
            //     "sortingDirection": orderBy === undefined ? 'asc' : orderBy,
            //     "pageRows": 20
            // };
            var fromTime = null;
            var toTime = null;
            if (transactionsVM.validTimeOfDayFrom) {
                var fromTest = new Date(transactionsVM.validTimeOfDayFrom);
                fromTime = utils.getFromToTimeFormat(fromTest);
            }
            if (transactionsVM.validTimeOfDayTo) {
                var toTest = new Date(transactionsVM.validTimeOfDayTo);
                toTime = utils.getFromToTimeFormat(toTest);
            }
            var payload = {
                "Filter": {
                    "PageNo": transactionsVM.currentPage,
                    "PageSize": transactionsVM.pageSize,
                    "SortColumn": sortBy,
                    "SortOrder": orderBy === undefined ? 'desc' : orderBy
                },
                "Search": {
                    "AccountNo": localStorage.selectedAccount,
                    "CardId": cardNumber,
                    "CardDesc": transactionsVM.description,
                    "DateFrom": startDate,
                    "DateTo": endDate,
                    "TimeFrom": fromTime,
                    "TimeTo": toTime,
                    "ProductId": transactionsVM.productId,
                    "QuantityFrom": transactionsVM.quantityFrom,
                    "QuantityTo": transactionsVM.quantityTo,
                    "AmountFrom": transactionsVM.amountFrom,
                    "AmountTo": transactionsVM.amountTo,
                    "FromId": transactionsVM.idNumberFrom,
                    "ToId": transactionsVM.idNumberTo,
                    "ExceptionTransactions": transactionsVM.exceptions
                }
            };

            localStorage.transactionPaylod = JSON.stringify(payload);
            return cardManagementService.getLastTransactionsList(payload)
                .then(getLastTransactionsSuccess, getLastTransactionsError);
        }

        function getLastTransactionsSuccess(apiResponse) {
            transactionsVM.transactionList = apiResponse;
            transactionsVM.searchCount = apiResponse.totalRecords;
            transactionsVM.maxPage = Math.ceil(transactionsVM.searchCount / transactionsVM.pageSize);
            angular.forEach(transactionsVM.transactionList.dataModel, function (item) { //transactionsVM.transactionList.transactionsList
                item.showIcon = 'icon-Expand';
            });
            $rootScope.dataLoading = false;
        }

        function getLastTransactionsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
            $rootScope.dataLoading = false;
        }

        function cardSearch() {
            // if (!transactionsVM.searchCardNo) {
            //     toastr.error("Enter card #");
            // } else if (transactionsVM.searchCardNo && (transactionsVM.searchCardNo.length < 3)) {
            //     toastr.warning(toastrMessage.userManagement.searchCondition);
            // } else {
            init();
            transactionsVM.sortBy = '';
            transactionsVM.SortColumn = '';
            getTransactionSearchDetails(transactionsVM.searchCardNo, 'Trans. Date');
            // }
        }

        function getColumnHeaders() {
            return cardManagementService.getTransactionColumns()
                .then(getTransactionColumnsSuccess, getTransactionColumnsError);
        }

        function getTransactionColumnsSuccess(apiResponse) {
            transactionsVM.columnHeaders = apiResponse.dataModel; //apiResponse.columnHeaders
            angular.forEach(transactionsVM.columnHeaders, function (item) {
                // item.showColumn = true;
                if (item.strHeader === 'Trans. Date') {
                    item.showIcon = 'ic-filter';
                } else {
                    item.showIcon = 'ic-filterNot';
                }
            });
            transactionsVM.columnHeaderPopup = angular.copy(transactionsVM.columnHeaders);
        }

        function getTransactionColumnsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        function arrangeTableData(popupData) {
            var selected = false;
            angular.forEach(popupData, function (item) {
                if (item.showColumn) {
                    selected = true;
                }
            });
            if (selected) {
                angular.forEach(popupData, function (item, key) {
                    if (item.showColumn) {
                        transactionsVM.columnHeaders[key].showColumn = true;
                    } else {
                        transactionsVM.columnHeaders[key].showColumn = false;
                    }
                });
                transactionsVM.showColumnPopup = false;
                localStorage.makePaymentColumns = JSON.stringify(transactionsVM.columnHeaders);

                var columnMakeTransaction = [];
                transactionsVM.columnHeaders.forEach(function (item) {
                    var listObj = {};
                    listObj.id = item.id;
                    listObj.strHeader = item.strHeader;
                    listObj.showColumn = item.showColumn;
                    columnMakeTransaction.push(listObj);
                });

                var Payload = {
                    "columnHeaders": columnMakeTransaction
                };
                $rootScope.dataLoading = true;
                cardManagementService.saveColumnHeaders(Payload)
                    .then(saveColumnHeadersSuccess, saveColumnHeadersError);
            } else {
                toastr.error("Please select at least one column.");
            }
        }

        function saveColumnHeadersSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            toastr.success('Saved Successfully.');
        }

        function saveColumnHeadersError() {
            $rootScope.dataLoading = false;
            toastr.error('Failed to save');
        }

        function toggleHeader() {
            transactionsVM.showColumnPopup = !transactionsVM.showColumnPopup;
        }

        function paginationClick(button) {
            switch (button) {
                case 'first':
                    if (transactionsVM.firstPageItem > 1) {
                        $window.scrollTo(0, 0);
                        transactionsVM.firstPageItem = 1;
                        transactionsVM.secondPageItem = 2;
                        transactionsVM.currentPage = 1;
                    }
                    break;
                case 'previous':
                    if (transactionsVM.firstPageItem > 1) {
                        $window.scrollTo(0, 0);
                        transactionsVM.firstPageItem--;
                        transactionsVM.secondPageItem--;
                        transactionsVM.currentPage--;
                    } else if (transactionsVM.firstPageItem < transactionsVM.currentPage) {
                        transactionsVM.currentPage--;
                    } else {
                        //sonar issue fix
                    }
                    break;
                case 'next':
                    if (transactionsVM.secondPageItem < transactionsVM.maxPage) {
                        $window.scrollTo(0, 0);
                        transactionsVM.firstPageItem++;
                        transactionsVM.secondPageItem++;
                        transactionsVM.currentPage++;
                    } else if (transactionsVM.currentPage < transactionsVM.secondPageItem) {
                        transactionsVM.currentPage++;
                    } else {
                        //sonar issue fix
                    }
                    break;
                case 'last':
                    if (transactionsVM.secondPageItem < transactionsVM.maxPage || transactionsVM.currentPage < transactionsVM.secondPageItem) {
                        $window.scrollTo(0, 0);
                        transactionsVM.firstPageItem = transactionsVM.maxPage - 1;
                        transactionsVM.secondPageItem = transactionsVM.maxPage;
                        transactionsVM.currentPage = transactionsVM.maxPage;
                    }
                    break;
                default:
                    transactionsVM.currentPage = button;
                    break;
            }
        }

        function orderByColumn(columnHeader, iconType) {
            var orderBy = '';
            // transactionsVM.productIds = [];
            angular.forEach(transactionsVM.columnHeaders, function (item) {
                if (item.strHeader === columnHeader) {
                    if (iconType === 'ic-filterNot' || iconType === 'ic-filterDesc') {
                        item.showIcon = 'ic-filter';
                        orderBy = 'asc';
                    } else {
                        item.showIcon = 'ic-filterDesc';
                        orderBy = 'desc';
                    }
                } else {
                    item.showIcon = 'ic-filterNot';
                }
            });
            // angular.forEach(transactionsVM.productValues, function(item) {
            //     if (item.isChecked) {
            //         transactionsVM.productIds.push(item.id);
            //     }
            // });
            transactionsVM.sortBy = orderBy;
            transactionsVM.SortColumn = columnHeader;
            getTransactionSearchDetails(transactionsVM.searchCardNo, columnHeader, orderBy);
        }

        /*clears the date values from tha calender*/
        function clearCalender() {
            transactionsVM.datePicker = {
                startDate: null,
                endDate: null
            };
            $('#daterange').data('daterangepicker').setStartDate(new Date());
            $('#daterange').data('daterangepicker').setEndDate(new Date());
        }

        // function applyProducts() {
        //     transactionsVM.showProductPopup = false;
        // }

        function printTable() {
            $state.go('root.printTransaction');
            // window.print();
        }

        function exportTable(fileType) {
            // var startDate = transactionsVM.datePicker.startDate === null ? "" : $filter('date')(new Date(transactionsVM.datePicker.startDate.toString()), 'MM/dd/yyyy');
            // var endDate = transactionsVM.datePicker.endDate === null ? "" : $filter('date')(new Date(transactionsVM.datePicker.endDate.toString()), 'MM/dd/yyyy');

            // var columns = [];
            // angular.forEach(transactionsVM.columnHeaders, function (item) {
            //     if (item.showColumn) {
            //         columns.push(item.strHeader);
            //     }
            // });
            // var payload = {
            //     "cardNumFrom": transactionsVM.cardNumberFrom,
            //     "cardNumTo": transactionsVM.cardNumberTo,
            //     "cardDescription": transactionsVM.description,
            //     "product": transactionsVM.productIds,
            //     "idFrom": transactionsVM.idNumberFrom,
            //     "idTo": transactionsVM.idNumberTo,
            //     "department": transactionsVM.department,
            //     "transactionDateFrom": startDate,
            //     "transactionDateTo": endDate,
            //     "amountFrom": transactionsVM.amountFrom,
            //     "amountTo": transactionsVM.amountTo,
            //     "transactionwithExpceptions": transactionsVM.exceptions,
            //     "transactionTimeTo": "",
            //     "transactionTimeFrom": "",
            //     "searchString": transactionsVM.searchCardNo.name === undefined ? transactionsVM.searchCardNo : transactionsVM.searchCardNo.name,
            //     "accntNum": "All Accounts",
            //     "pageNumber": transactionsVM.currentPage,
            //     "sortingParameter": 'Trans. Date',
            //     "sortingDirection": 'asc',
            //     "pageRows": 20
            // };
            var payload = JSON.parse(localStorage.transactionPaylod);
            payload.Filter.PageNo = 0;
            payload.Filter.PageSize = 0;
            // $rootScope.$emit('exportPaymentEvent');
            if (fileType === 'xls') {
                $rootScope.dataLoading = true;
                cardManagementService.exportTransactions(payload)
                    .then(exportTransactionSuccess, exportTransactionError);
            }
        }
        /*success callback for export payment*/
        function exportTransactionSuccess(apiResponse) {
            // paymentVM.checkedInvoicesToExport = [];
            // paymentVM.selectedInvoices=2;
            // localStorage.setItem('paymentMode',JSON.stringify(paymentVM.selectedInvoices));
            $rootScope.dataLoading = false;
            $window.open(apiResponse.filePath);
        }
        /*error callback for export payment*/
        function exportTransactionError() {
            //  paymentVM.selectedInvoices=2;
            // localStorage.setItem('paymentMode',JSON.stringify(paymentVM.selectedInvoices));
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        function fromTimeClear() {
            transactionsVM.validTimeOfDayFrom = null;
        }
        function toTimeClear() {
            transactionsVM.validTimeOfDayTo = null;
        }
    }
})();