/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.contactus')
        .controller('contactUsController', contactUsController);


    contactUsController.$inject = ['$scope', '$state', 'contactUsService', 'toastr', '$rootScope', 'toastrMessage'];

    function contactUsController($scope, $state, contactUsService, toastr, $rootScope, toastrMessage) {
        var contactUsVM = this;
        contactUsVM.submitMessage = submitMessage;

        init();

        /*function declarations*/

        /*initial page load*/
        function init() {
            contactUsVM.contactData = {
                'name': localStorage.loggedInUser,
                'email': localStorage.userEmail
            }
            getContactDetails();
            getContactInformation(); //fetches local json data
        }

        /*fetches dropdown values for contact us*/
        function getContactDetails() {
            $rootScope.dataLoading = true;
            contactUsService.getContactDropdownData()
                .then(getContactDetailsSuccess, getContactDetailsFailure);
        }
        /*success callback for dropdown values*/
        function getContactDetailsSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                contactUsVM.serviceTypes = apiResponse.contactUsRequestData;
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for dropdown values*/
        function getContactDetailsFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches contact details from local json*/
        function getContactInformation() {
            contactUsService.getContactDetails()
                .then(getContactInfoSuccess, getContactInfoFailure);
        }
        /*success callback for contact details*/
        function getContactInfoSuccess(apiResponse) {
            if (apiResponse.statusCode === 200) {
                console.log(apiResponse.contactInfo);
                contactUsVM.contactInfoList = apiResponse.contactInfo;
            }
        }
         /*error callback for contact details*/
        function getContactInfoFailure() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*sends message to customer care*/
        function submitMessage() {
            if (contactUsVM.contactData.name && contactUsVM.contactData.email && contactUsVM.contactData.serviceType && contactUsVM.contactData.reasonType && contactUsVM.contactData.message) {
                var payload = {
                    'name': contactUsVM.contactData.name,
                    'email': contactUsVM.contactData.email,
                    'serviceType': contactUsVM.contactData.serviceType.serviceType,
                    'selectReason': contactUsVM.contactData.reasonType,
                    'message': contactUsVM.contactData.message
                };
                $rootScope.dataLoading = true;
                contactUsService.submitMessage(payload)
                    .then(submitMessageSuccess, submitMessageFailure);
            } else {
                toastr.error(toastrMessage.common.mandatoryValidation);
            }
        }
         /*success callback for send message*/
        function submitMessageSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                toastr.success('Thank you for your information and inquiry. We will get back to you in 24 to 48 business hours. For future reference, your contact confirmation # is '+apiResponse.confirmationId+'. Have a wonderful day!', { "extendedTImeout": "0", "timeout": "0" });
               contactUsVM.contactData.serviceType = ""; 
               contactUsVM.contactData.reasonType = "";
               contactUsVM.contactData.message = "";
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for send message*/
        function submitMessageFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
    }
})();
