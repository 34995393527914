
/*jshint browser: true*/
/*global angular: true*/


(function(w,d,s,g,js,fjs){
    'use strict';
    
    angular
        .module('scFuelsApp.marketingInsight')
        .controller('MarketingInsightController', MarketingInsightController);


    MarketingInsightController.$inject = ['$rootScope','$scope','$state', 'marketingInsightService', 'toastr', '$confirm', 'toastrMessage'];

    function MarketingInsightController($rootScope, $scope, $state, marketingInsightService , toastr, $confirm, toastrMessage){
        var marketingInsightVM = this;
        // old view Id
        marketingInsightVM.viewId = 'ga:133872615';
        // new view Id
        //marketingInsightVM.viewId = 'ga:137929220';
        marketingInsightVM.gaTypes = [];
        marketingInsightVM.gaType = "";
        marketingInsightVM.chartTypes = [];
        marketingInsightVM.chartType = "";
        marketingInsightVM.orgIds = [];
        marketingInsightVM.orgId = "";

        marketingInsightVM.geocompare = {};
        marketingInsightVM.behaviorcompare = {};
        marketingInsightVM.devicecompare = {};
        marketingInsightVM.userbehavior = {};
        
        marketingInsightVM.showGeoChart = true;
        marketingInsightVM.showbehaviorChart = false;
        marketingInsightVM.showDeviceCompareChart = false;
        marketingInsightVM.showUserBehaviorChart = false;

        marketingInsightVM.loadGaCharts = loadGaCharts;
        marketingInsightVM.geoDrillDown = geoDrillDown;
        marketingInsightVM.createChartObject = createChartObject;

        /*function declarations*/

        if(!g){
            loadAnalyticsLib();
        }
        /*to load google analytics library*/
        function loadAnalyticsLib(){
            g=w.gapi||(w.gapi={});g.analytics={q:[],ready:function(cb){this.q.push(cb)}};
            js=d.createElement(s);fjs=d.getElementsByTagName(s)[0];
            js.src='https://apis.google.com/js/platform.js';
            fjs.parentNode.insertBefore(js,fjs);js.onload=function(){g.load('analytics')};
        }


        gapi.analytics.ready(function() {

            init();
            
            // Hook up the components to work together.
	       //  gapi.analytics.auth.on('success', function(response) {
			     // marketingInsightVM.loadGaCharts();
	       //  });
            /*initial loading*/
            function init() {                
    	        marketingInsightService.getGATypes()
    	            .then(getGATypesSuccess, getGATypesError);
            }
            /*success callback for ga types*/
            function getGATypesSuccess(apiResponse) {
                if(apiResponse.statusCode === 200){
                    marketingInsightVM.gaTypes = [];
                    marketingInsightVM.gaTypes = angular.copy(apiResponse.gaTypes);
                    marketingInsightVM.gaType = marketingInsightVM.gaTypes[0];
                    marketingInsightService.getChartTypes()
                        .then(getChartTypesSuccess, getChartTypesError); 
                }           
            }
            /*error callback for ga types*/
            function getGATypesError() {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
           /*success callback for ga chart types*/
            function getChartTypesSuccess(apiResponse) {
                if(apiResponse.statusCode === 200){
                    marketingInsightVM.chartTypes =  angular.copy(apiResponse.chartTypes);
                    marketingInsightVM.chartType = marketingInsightVM.chartTypes[0];
                    marketingInsightService.getOrgIds()
                        .then(getOrgIdsSuccess, getOrgIdsError);
                }              
            }
            /*error callback for ga chart types*/
            function getChartTypesError() {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
            /*success callback for fetch organization id*/
            function getOrgIdsSuccess(apiResponse) {
                 if(apiResponse.statusCode === 200){
                    marketingInsightVM.orgIds =  angular.copy(apiResponse.orgIds);
                     marketingInsightVM.orgId = marketingInsightVM.orgIds[0];
                     if(gapi.analytics.auth.isAuthorized()){
                        marketingInsightVM.loadGaCharts();
                    }
                    else{
                        marketingInsightService.getAuthToken()
                        .then(getAuthTokenSuccess, getAuthTokenFailure);
                    }  
                 }
            }
            /*error callback for fetch organization id*/
            function getOrgIdsError() {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
            /*success callback for fetch auth token*/
            function getAuthTokenSuccess(apiResponse){
               var token = apiResponse.access_token;
               authorizeUser(token);
            }
            /*error callback for fetch auth token*/
            function getAuthTokenFailure(){
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }

            // Authorize the user.
            function authorizeUser(token){
                var CLIENT_ID = '845869513354-4cstdbc98rvvvob9jnr9oc1lta1v6f9r.apps.googleusercontent.com';
                var SCOPES = 'https://www.googleapis.com/auth/analytics.readonly';
                gapi.analytics.auth.authorize({
                    clientId: CLIENT_ID,
                    scopes: SCOPES,
                    serverAuth: {
                      access_token: token
                    }
                });
                marketingInsightVM.loadGaCharts();
            }           
        });
        /*to load ga charts*/
        function loadGaCharts () {
            // ga custom dimension : name: orgId, fieldName: dimension1
            var filterString = "ga:dimension1==";
            var chartObject = {};
            gapi.analytics.ready(function() {
                switch (marketingInsightVM.gaType.type){
                    case 'Geography':{
                        marketingInsightVM.showGeoChart = true;
                        marketingInsightVM.showbehaviorChart = false;
                        marketingInsightVM.showDeviceCompareChart = false;
                        marketingInsightVM.showUserBehaviorChart = false;
                        
                        chartObject = marketingInsightVM.createChartObject();
                        chartObject.chart.type = 'GEO';
                        chartObject.chart.container = 'geocompare';
                        chartObject.chart.options.displayMode = 'regions';
                        chartObject.chart.options.enableRegionInteractivity = true;
                        chartObject.chart.options.region = 'US',
                        chartObject.query.dimensions = 'ga:country';
                        chartObject.query.metrics = 'ga:users';
                        chartObject.query.filters = 'ga:country==United States';
                        if(marketingInsightVM.orgId.value !== 'All'){
                           chartObject.query.filters = filterString + marketingInsightVM.orgId.value;
                        }
                        
                        marketingInsightVM.geocompare = new gapi.analytics.googleCharts.DataChart(chartObject);
                        $rootScope.dataLoading = true;             
                        marketingInsightVM.geocompare.execute();
                        marketingInsightVM.geocompare.on('success', function(response) {
                            $rootScope.dataLoading = false;  
                            if(response.data !== null){
                                marketingInsightVM.geoChart = response.chart;
                                google.visualization.events.addListener(marketingInsightVM.geoChart, 'regionClick', marketingInsightVM.geoDrillDown);
                                $scope.$apply();
                            }
                        });
                        break;
                    }
                    case 'Engagement':{
                        marketingInsightVM.showGeoChart = false;
                        marketingInsightVM.showbehaviorChart = true;
                        marketingInsightVM.showDeviceCompareChart = false;
                        marketingInsightVM.showUserBehaviorChart = false;

                        chartObject = marketingInsightVM.createChartObject();
                        chartObject.chart.type = 'LINE';
                        chartObject.chart.container = 'behaviorcompare';
                        chartObject.query.dimensions = 'ga:date';
                        chartObject.query.metrics = 'ga:avgSessionDuration,ga:sessionsPerUser';
                        if(marketingInsightVM.orgId.value !== 'All'){
                           chartObject.query.filters = filterString + marketingInsightVM.orgId.value;
                        }
                        
                        marketingInsightVM.behaviorcompare = new gapi.analytics.googleCharts.DataChart(chartObject);
                        $rootScope.dataLoading = true;             
                        marketingInsightVM.behaviorcompare.execute();
                            marketingInsightVM.behaviorcompare.on('success', function(response) {
                            $rootScope.dataLoading = false;
                            $scope.$apply();
                        });
                        break;
                    }
                    case 'Computer vs Mobiles':{
                        marketingInsightVM.showGeoChart = false;
                        marketingInsightVM.showbehaviorChart = false;
                        marketingInsightVM.showDeviceCompareChart = true;
                        marketingInsightVM.showUserBehaviorChart = false;

                        chartObject = marketingInsightVM.createChartObject();
                        chartObject.chart.type = 'PIE';
                        chartObject.chart.container = 'devicecompare';
                        chartObject.query.dimensions = 'ga:deviceCategory';
                        chartObject.query.metrics = 'ga:users';
                        if(marketingInsightVM.orgId.value !== 'All'){
                           chartObject.query.filters = filterString + marketingInsightVM.orgId.value;
                        }
                        
                        marketingInsightVM.devicecompare = new gapi.analytics.googleCharts.DataChart(chartObject);
                        $rootScope.dataLoading = true;             
                        marketingInsightVM.devicecompare.execute();
                        marketingInsightVM.devicecompare.on('success', function(response) {
                            $rootScope.dataLoading = false;
                            $scope.$apply();
                        });
                        break;
                    }
                    case 'Page Clicks':{
                        marketingInsightVM.showGeoChart = false;
                        marketingInsightVM.showbehaviorChart = false;
                        marketingInsightVM.showDeviceCompareChart = false;
                        marketingInsightVM.showUserBehaviorChart = true;

                        chartObject = marketingInsightVM.createChartObject();
                        chartObject.chart.type = 'TABLE';
                        chartObject.chart.container = 'userbehavior';
                        chartObject.query.dimensions = 'ga:dimension1,ga:eventAction,ga:eventCategory,ga:eventLabel';
                        chartObject.query.metrics = 'ga:users';
                        if(marketingInsightVM.orgId.value !== 'All'){
                           chartObject.query.filters = filterString + marketingInsightVM.orgId.value;
                        }
                        
                        marketingInsightVM.userbehavior = new gapi.analytics.googleCharts.DataChart(chartObject);
                        $rootScope.dataLoading = true;             
                        marketingInsightVM.userbehavior.execute();
                        marketingInsightVM.userbehavior.on('success', function(response) {
                            $rootScope.dataLoading = false;
                            $scope.$apply();
                        });
                        break;
                    }
                    default:{
                        break;
                    }
                } 
            }); 
        } 

        function geoDrillDown(response){
            console.log(response.region);
            gapi.analytics.ready(function() {
                var filter = 'ga:country==United States';
                // var filter = 'ga:country==India';
                marketingInsightVM.geocompare = new gapi.analytics.googleCharts.DataChart({
                     reportType: 'ga',
                     query: {
                        'ids': marketingInsightVM.viewId,
                        'dimensions': 'ga:region',
                        'metrics': 'ga:users',
                        'start-date': '30daysAgo',
                        'end-date': 'today',
                        'filters':filter
                    },
                    chart: {
                        type: 'GEO',
                        container: 'geocompare',
                        options: {
                            region: response.region,
                            displayMode: 'regions',
                            resolution: 'provinces',
                            enableRegionInteractivity: true,
                            fontSize: 12
                        }
                    }
                });
                marketingInsightVM.geocompare.execute();

                 marketingInsightVM.geocompare.on('success', function(response) {
                    $rootScope.dataLoading = false;
                    if(response.data !== null){
                        marketingInsightVM.geoChart = response.chart;
                        google.visualization.events.addListener(marketingInsightVM.geoChart, 'regionClick', marketingInsightVM.geoDrillDown);
                    }
                });
            });
        }     

        function createChartObject (){
            var chartObject = {};
            var chartQuery = {};

            chartQuery = {
                'ids': marketingInsightVM.viewId,
                'dimensions': '',
                'metrics': '',
                'start-date': '30daysAgo',
                'end-date': 'today'
            };

            chartObject = {
                reportType: 'ga',
                query: chartQuery,
                chart: {
                    type: '',
                    container: '',
                    options: {fontSize:12}
                }
            };
            return chartObject;
        }
    }
})(window,document,'script');