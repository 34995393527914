/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.login')
        .controller('settingsQuestionController', settingsQuestionController);


    settingsQuestionController.$inject = ['$rootScope', '$scope', '$state', 'toastr', '$confirm', 'toastrMessage', 'utils', 'headermenuService', 'loginService', '$stateParams'];

    function settingsQuestionController($rootScope, $scope, $state, toastr, $confirm, toastrMessage, utils, headermenuService, loginService, $stateParams) {
        var questionVM = this;
        questionVM.init = init;
        questionVM.changeSecurityQuestion = changeSecurityQuestion;
        questionVM.optionsFilter = optionsFilter;
        questionVM.optionsFilter2 = optionsFilter2;

        /*function declarations*/

        /*initial loading*/
        function init() {
            questionVM.previousPage = $stateParams.previousPage? $stateParams.previousPage : 'root.dashboard';
            getSecurityQuestionList();
        }
        /*fetches security question list*/
        function getSecurityQuestionList() {
            $rootScope.dataLoading = true;
            loginService.getSecurityQuestionList()
                .then(getSecurityQuestionListSuccess, getSecurityQuestionListFailure);
        }
        /*success callback for security question list*/
        function getSecurityQuestionListSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                questionVM.securityQuestions = apiResponse.dataModel.questions;
                questionVM.questionOne = apiResponse.dataModel.userQuestions[0].id;
                questionVM.questionTwo = apiResponse.dataModel.userQuestions[1].id;
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for security question list*/
        function getSecurityQuestionListFailure(error) {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*save change security question*/
        function changeSecurityQuestion(isValid) {
            if (isValid) {
                $rootScope.dataLoading = true;
                var payload = {
                    "question1": questionVM.questionOne,
                    "answer1": questionVM.answerOne,
                    "question2": questionVM.questionTwo,
                    "answer2": questionVM.answerTwo
                }
                headermenuService.settingsQuestion(payload)
                            .then(settingsQuestionSuccess, settingsQuestionFailure);
            } else {
                toastr.error(toastrMessage.common.mandatoryValidation);
            }
        }
        /*success callback for change security question*/
        function settingsQuestionSuccess(apiResponse){
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                $state.go('root.dashboard');
                toastr.success("Security questions have been successfully updated.");
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for change security question*/
        function settingsQuestionFailure(){
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*filter to remove security question from the list*/
        function optionsFilter(option){
            if (option.id == (questionVM.questionTwo && questionVM.questionTwo)) {
                return false;
             }
            return true;
        }
        /*filter to remove security question from the list*/
        function optionsFilter2(option){
            if (option.id == (questionVM.questionOne && questionVM.questionOne)) {
                return false;
             }
            return true;
        }
    }
})();
