/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';
    
    angular.module('scFuelsApp.marketingInsight')
        .factory('marketingInsightService', marketingInsightService);

    marketingInsightService.$inject = ['$q', '$http', 'apiURLService'];

    function marketingInsightService ($q, $http, apiURLService) {
        var marketingInsightObject = {};
        marketingInsightObject.getGATypes = getGATypes;
        marketingInsightObject.getChartTypes = getChartTypes;
        marketingInsightObject.getAuthToken = getAuthToken;
        marketingInsightObject.getOrgIds = getOrgIds;

        return marketingInsightObject;

        function getGATypes(){
        	var url = apiURLService.getGATypesURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getChartTypes(){
            var url = apiURLService.getChartTypesURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getOrgIds(){
            var url = apiURLService.getOrgIdsURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getAuthToken() {
            //JWT Header
            
            var url = apiURLService.getGaAuthTokenURL();
            var config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
            };

            // base64 encoded hearder
            var pHeader = {"alg":"RS256","typ":"JWT"}
            var sHeader = JSON.stringify(pHeader);

            // base64 encoded claim set
            var pClaim = {};
            pClaim.aud = "https://www.googleapis.com/oauth2/v4/token";
            pClaim.scope = "https://www.googleapis.com/auth/analytics.readonly";
            pClaim.iss = "ga-report-test@composite-helix-149613.iam.gserviceaccount.com";
            pClaim.exp = KJUR.jws.IntDate.get("now + 1hour");
            pClaim.iat = KJUR.jws.IntDate.get("now");

            var sClaim = JSON.stringify(pClaim);

            var key = "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDnc3+yqQESA/dY\nrICA+AeioHPS8IfAtH/+muQTltk0h7ZDisXVDnA3rcBj4BO2zBZaE1fVK3wDDWmD\nNF25M2jda3BE9JkJ4cMzzcKEQsxYdlgVl5zlWNVH26oV6ZXgDJd0fU0sLmPzOwOM\nzrxv7l/DQNhfnGakTamKzvaycb3CH9e1z2zQIAXWpO0fZDamKEw2TtaW3wCQkWfH\nE44F6R7FeGMAtALQYNwcrkEsf+fjtXrQtdD/Rjs2JvQER+c7pT28x2TA0xdjZpLf\nPXjHB3YvpZVOQzLRlIx3q51S3JIn6/qZfIu7bhjLTi6enZxTiAeX4amyAaZOXpVR\nCoP3Hb97AgMBAAECggEBAMRX6sB5Nns9+hqzW+AMiQ2g6CDOA5uqg9pNM6aalXBG\noIvzsZfoQ7I4J7mSYab0wxFuz1f7J5y/R/8R8WAnCJKtjfQxO5JyftH3f0gMEqYI\n3viSQXsePPBUnJkyKv6CMAmWMl2EjS3NpllRF/IsWfidjfCuETTY3TbwE7+VVdDS\n7XtAGszlIeS8vrouUn9pfzeGrrXWeLcf88Dcrr4qqZPO2PbGk0k4QZidQyVh5nuy\nool5WEEK9ThRqbFGbbJVxGrIyQgRK3r0m2RVOyQiceSrd87BMiqq9dskQ+YG5+md\nydWITNk6TDKBY5qobZJA4Ow++rU1lgrpi4+xp0JKKWECgYEA9fKXCZdQAgf7buI9\nnqfrdUATtoDr3PT/BbOlES7Tr2GlPCJm1nSlkUVDAplmml4v8cFUQTZqSticW2lV\nuhWHa+uiHcim5aQPpVj+z1dJ99NT2le3Twqe/xXIjGWkOqYUQEsgKJM8oScb32wr\niMx2Bc9WNzh8wXDd9yJeX0LnA0cCgYEA8Ok6m1a5g0+Bo0PjGtlmhvvNlGWRYT9U\nFAOgXfUeNBuHpoUoWFMO/evjIdRVKfKqQHQYMUSP4M4ViWV57RXkZWvIVn4MF8Ia\nGU0SEqfV/9ju5Nrp97mjKdtnL+Vqhge4N1DytXsnjTSzlGn1h1mL9dMUojb9ZFHR\nVc9ZwwfKdC0CgYEAuyMsvUV+wSzlqYdHr5LBW+O3nCQYU3WvHd2E+cARnd7EZS8f\n5Im4+QIkbLPgGBEWlfKuczg+RFjxD7TkX8TF193g6pykMRNWPE+tAoK+a21G3lnj\nxqakOB+RCxLDlUSNICaJf5YYid5CHQwnS92W5/DM/xNiCaYhnktuVzmGoT0CgYAL\nnqQ2A9vUyovtLQQSMnQwhIqo4rE9aImMoZQllAIN71n+nSOSBweNFciRxCULbenq\nS+8yySTLagU96EgWOF0YyzcRfOjmaHVLzRzXC4NV4Me9ABYS3cBR74QtmjomFs86\n08uxFyyUMfuLaATwr1AB3ll7bX2RTiTzp5mjv4dzTQKBgQCFQSktdn9IvH8XwC3k\n1qrrF/q4t/KbLap5sfJ0ho4oFbMX/8AyV0en7s9n5yvn/Al5iswe8TV/1vFhLK2S\nGzGkDjpnUwsjVHqUMHTxrlZiB82D7PPi08jSVBwLLlB/IcnfZ06pBdlq0cP4XtEh\nTGNMQrdnixh8lz2jt8YwmuwxKA==\n-----END PRIVATE KEY-----\n";

            var sJWS = KJUR.jws.JWS.sign(null, sHeader, sClaim, key);

            var urlEncodedData = "";
            var urlEncodedDataPairs = [];

            urlEncodedDataPairs.push(encodeURIComponent("grant_type") + '=' + encodeURIComponent("urn:ietf:params:oauth:grant-type:jwt-bearer"));
            urlEncodedDataPairs.push(encodeURIComponent("assertion") + '=' + encodeURIComponent(sJWS));
            urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

            return $http.post(url,urlEncodedData,config).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }
    }
})();
