/*jshint browser: true*/
/*global angular: true*/

(function() {
    'use strict';
    angular.module('scFuelsApp')
        .factory('utils', UtilsFactory)
        .service('dataStoreService', dataStoreService)
        .filter('capitalize', capitalize)
        .directive('onlyDigits', onlyDigits)
        .directive('scrollPagination',scrollPagination);

    UtilsFactory.$inject = [];

    function UtilsFactory() {
        var utilObject = {};
        utilObject.isValidEmail = isValidEmail;
        utilObject.isValidPhoneNumber = isValidPhoneNumber;
        utilObject.getPhoneNumberFormat = getPhoneNumberFormat;
        utilObject.isValidNumber = isValidNumber;
        utilObject.isValidID = isValidID;
        utilObject.isValidAlphaNumeric = isValidAlphaNumeric;
        utilObject.isValidPassword = isValidPassword;
        utilObject.isValidZipCode = isValidZipCode;
        utilObject.isAlphaNumeric = isAlphaNumeric;
        utilObject.stringToTime = stringToTime;
        utilObject.calenderStartYear = '01/01/1990';
        utilObject.calenderEndYear = '12/31/2025';
        utilObject.getFromToTimeFormat = getFromToTimeFormat;
        utilObject.isValidNumberWithZero = isValidNumberWithZero;
        utilObject.displayTime = displayTime;
        return utilObject;

        //--------------------------------------
        //function declarations

        function isValidEmail(email) {
            var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(email);
        }

        function isValidPhoneNumber(phoneNumber) {
            var regex = /^((\+\d{1,2}|1)[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
            return regex.test(phoneNumber);
        }

        function getPhoneNumberFormat(number) {
            if (number) {
                var digitNumber = number.replace(/\D+/g, "");
                var validNumber = digitNumber.substring((digitNumber.length - 10), digitNumber.length)
                return validNumber.replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "($1) $2-$3"); //mask numbers (xxx) xxx-xxxx  
            } else {
                return "";
            }
        }

        function isValidNumber(num) {
            var regex = /^(0|[1-9][0-9]*)$/;
            return regex.test(num);
        }

        function isValidNumberWithZero(num) {
            var regex = /^0{0,5}[1-9]\d*$/;
            return regex.test(num);
        }

        function isValidID(num) {
            var regex = /^[0-9]*$/;
            return regex.test(num);
        }

        function isValidAlphaNumeric(num) {
            var regex = /^[a-zA-Z0-9\-\s]+$/;
            return regex.test(num);
        }

        function isValidPassword(password) {
            var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,20}$/;
            return regex.test(password);
        }

        function isValidZipCode(zipcode) {
            var regex = /^\d{5}$|^\d{5}-\d{4}$/;
            return regex.test(zipcode);
        }

        function isAlphaNumeric(text) {
            var regex = /[^A-Za-z0-9 ]/;
            return regex.test(text);
        }

        function stringToTime(time) {
            var timeTokens = time.split(':');
            return new Date(1970, 0, 1, timeTokens[0], timeTokens[1]);
        }

        function getFromToTimeFormat(date){
            var time = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);
            return time;
        }

        /*Time Convertion for display*/
        function displayTime(time) {
            var hour = time.split(":").slice(0, 1);
            var min = time.split(":").slice(1, 2);
            var date = new Date();
            date.setHours(hour);
            date.setMinutes(min);
            return date;
        }
    }

    dataStoreService.$inject = [];

    function dataStoreService() {
        var paymentData = {};
        this.setpaymentData = function(dataValues) {
            paymentData = dataValues;
        };
        this.getpaymentData = function() {
            return paymentData;
        };
    }

    capitalize.inject = [];

    function capitalize() {
        return function(input) {
            if (input.indexOf(' ') !== -1) {
                var inputPieces,
                    i;

                input = input.toLowerCase();
                inputPieces = input.split(' ');

                for (i = 0; i < inputPieces.length; i++) {
                    inputPieces[i] = capitalizeString(inputPieces[i]);
                }

                return inputPieces.toString().replace(/,/g, ' ');
            } else {
                input = input.toLowerCase();
                return capitalizeString(input);
            }

            function capitalizeString(inputString) {
                return inputString.substring(0, 1).toUpperCase() + inputString.substring(1);
            }
        };
    }

    onlyDigits.inject = [];

    function onlyDigits() {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function(scope, element, attrs, ngModel) {
                if (!ngModel)
                    return;
                ngModel.$parsers.unshift(function(inputValue) {
                    var digits = inputValue.split('').filter(function(s) {
                        return (!isNaN(s) && s !== ' ');
                    }).join('');
                    ngModel.$viewValue = digits;
                    ngModel.$render();
                    return digits;
                });
            }
        };
    }

    scrollPagination.inject = [];

    function scrollPagination(){
        return{
            restrict: 'A',
            scope:{
                listScrolled: '&onListScrolled',
            },
            link: function(scope,element,attrs){
                angular.element(element).bind('scroll', function(event){
                    if ((event.target.offsetHeight < event.target.scrollHeight) &&
                         (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 1))) {
                            scope.listScrolled();
                        }
                });
            }
        }
    }


})();
