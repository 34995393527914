/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';
    angular.module('scFuelsApp.dashBoard')
        .factory('dashboardService', dashboardService);

    dashboardService.$inject = ['$q', '$http', 'apiURLService'];

    function dashboardService ($q, $http, apiURLService) {
        var dashboardObject = {};
        dashboardObject.getRecentActivitiesList = getRecentActivitiesList;
        dashboardObject.getOpenInvoiceData = getOpenInvoiceData;
        dashboardObject.getCriticalAlert = getCriticalAlert;

        return dashboardObject;

        function getRecentActivitiesList(orgId, accId){
            var url = apiURLService.getRecentActivitiesListURL()+'/'+ orgId +'/'+ accId;
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getOpenInvoiceData(){
            var url = apiURLService.getOpenInvoiceDataURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getCriticalAlert(){
           var url = apiURLService.getCriticalAlertURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            }); 
        }
    }
})();
