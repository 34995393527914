/*jshint browser: true*/
/*global angular: true*/

(function () {
    'use strict';

    angular.module('scFuelsApp.cardManagement')
        .controller('transactionPrintController', transactionPrintController);

    transactionPrintController.$inject = ['$scope', '$state', '$rootScope', 'cardManagementService', 'utils', 'toastr', 'toastrMessage', '$filter', '$window'];

    function transactionPrintController($scope, $state, $rootScope, cardManagementService, utils, toastr, toastrMessage, $filter, $window) {
        var transactionPrintVM = this;
        transactionPrintVM.searchCardNo = "";
        transactionPrintVM.cardNumberFrom = "";
        transactionPrintVM.cardNumberTo = "";
        transactionPrintVM.idNumberFrom = "";
        transactionPrintVM.idNumberTo = "";
        transactionPrintVM.amountFrom = "";
        transactionPrintVM.amountTo = "";
        transactionPrintVM.description = "";
        transactionPrintVM.department = "";
        transactionPrintVM.exceptions = false;
        transactionPrintVM.showCardSearchButton = true;
        transactionPrintVM.datePicker = {
            startDate: null,
            endDate: null
        };
        transactionPrintVM.startYear = utils.calenderStartYear;
        transactionPrintVM.endYear = utils.calenderEndYear;
        transactionPrintVM.showProductPopup = false;
        transactionPrintVM.showColumnPopup = false;
        transactionPrintVM.showAdvanceSearch = false;
        transactionPrintVM.showAccordianView = [];
        transactionPrintVM.cardDetails = [];
        transactionPrintVM.transactionData = [];
        transactionPrintVM.siteDetails = [];
        transactionPrintVM.amountDetails = [];

        // transactionPrintVM.getAllCards = getAllCards;
        // transactionPrintVM.toggleSearch = toggleSearch;
        // transactionPrintVM.transactionDetails = transactionDetails;
        // transactionPrintVM.selectProducts = selectProducts;
        // transactionPrintVM.searchTransactions = searchTransactions;
        // transactionPrintVM.cardSearch = cardSearch;
        // transactionPrintVM.arrangeTableData = arrangeTableData;
        // transactionPrintVM.toggleHeader = toggleHeader;
        // transactionPrintVM.paginationClick = paginationClick;
        // transactionPrintVM.orderByColumn = orderByColumn;
        // transactionPrintVM.clearCalender = clearCalender;
        // transactionPrintVM.applyProducts = applyProducts;
        transactionPrintVM.printTable = printTable;
        transactionPrintVM.backToTransaction = backToTransaction;
        init();

        //function declarations

        function init() {
            transactionPrintVM.currentPage = 1;
            transactionPrintVM.firstPageItem = 1;
            transactionPrintVM.secondPageItem = 2;
            // getTransactionParameters();
            getColumnHeaders();
        }

        $scope.$watch('transactionPrintVM.currentPage', function () {
            getTransactionSearchDetails([], transactionPrintVM.searchCardNo, 'Trans. Date');
        });

        // function getAllCards(searchKey) {
        //     return cardManagementService.getCardSmartSearchList(searchKey)
        //         .then(getCardSmartSearchListSuccess, getCardSmartSearchListError);
        // }

        // function getCardSmartSearchListSuccess(apiResponse) {
        //     var results = [];
        //     angular.forEach(apiResponse.invoiceNumbers, function(item) {
        //         var invoices = item;
        //         results.push({ name: invoices });
        //     });
        //     $rootScope.dataLoading = false;
        //     return results;
        // }

        // function getCardSmartSearchListError() {
        //     $rootScope.dataLoading = false;
        //     toastr.error(toastrMessage.common.systemNetworkFailure);
        // }

        // function toggleSearch() {
        //     transactionPrintVM.showAdvanceSearch = !transactionPrintVM.showAdvanceSearch;
        //     transactionPrintVM.searchCardNo = '';
        //     if (transactionPrintVM.showAdvanceSearch) {
        //         transactionPrintVM.showCardSearchButton = false;
        //     } else {
        //         transactionPrintVM.showCardSearchButton = true;
        //     }
        // }

        // function transactionDetails(cardNumber, iconType, index) {
        //     transactionPrintVM.showAccordianView[index] = 'app/modules/cardManagement/transactions/customView/transactionAccordianTemplate.html';
        //     angular.forEach(transactionPrintVM.transactionList.dataModel, function(item, key) {
        //         if (key === index) {
        //             if (item.showIcon === 'ic-expand_arrow-down') {
        //                 item.showIcon = 'ic-expand_arrow-up';
        //                 return cardManagementService.getTransactionDetails(cardNumber, index)
        //                     .then(getTransactionDetailsSuccess, getTransactionDetailsError);
        //             } else {
        //                 item.showIcon = 'ic-expand_arrow-down';
        //             }
        //         }
        //     });
        // }

        // function getTransactionDetailsSuccess(apiResponse) {
        //     var index = apiResponse.index;
        //     transactionPrintVM.transactionDetail = apiResponse;
        //     transactionPrintVM.cardDetails[index] = transactionPrintVM.transactionDetail.cardDetails;
        //     transactionPrintVM.transactionData[index] = transactionPrintVM.transactionDetail.transactionDetails;
        //     transactionPrintVM.siteDetails[index] = transactionPrintVM.transactionDetail.siteDetails;
        //     transactionPrintVM.amountDetails[index] = transactionPrintVM.transactionDetail.amountDetails;
        // }

        // function getTransactionDetailsError() {

        // }

        // function getTransactionParameters() {
        //     return cardManagementService.getTransactionParameters()
        //         .then(getTransactionParametersSuccess, getTransactionParametersError);
        // }

        // function getTransactionParametersSuccess(apiResponse) {
        //     transactionPrintVM.productValues = apiResponse.products;
        //     transactionPrintVM.productValues.forEach(function(item) {
        //         item.isChecked = false;
        //     });
        // }

        // function getTransactionParametersError() {

        // }

        // function selectProducts() {
        //     transactionPrintVM.showProductPopup = !transactionPrintVM.showProductPopup;
        // }

        // $rootScope.$on('hidePopup', function() {
        //     transactionPrintVM.showProductPopup = false;
        //     transactionPrintVM.showColumnPopup = false;
        //     $scope.$apply();
        // });

        // function searchTransactions() {
        //     transactionPrintVM.productIds = [];
        //     angular.forEach(transactionPrintVM.productValues, function(item) {
        //         if (item.isChecked) {
        //             transactionPrintVM.productIds.push(item.id);
        //         }
        //     });

        //     if ((transactionPrintVM.cardNumberFrom && !utils.isValidNumber(transactionPrintVM.cardNumberFrom)) || (transactionPrintVM.cardNumberTo && !utils.isValidNumber(transactionPrintVM.cardNumberTo))) {
        //         toastr.error("Enter valid card Numbers");
        //     } else if (parseInt(transactionPrintVM.cardNumberFrom, 10) > parseInt(transactionPrintVM.cardNumberTo, 10)) {
        //         toastr.error("Starting card number should be less");
        //     } else if ((transactionPrintVM.idNumberFrom && !utils.isValidNumber(transactionPrintVM.idNumberFrom)) || (transactionPrintVM.idNumberTo && !utils.isValidNumber(transactionPrintVM.idNumberTo))) {
        //         toastr.error("Enter valid id Numbers");
        //     } else if (parseInt(transactionPrintVM.idNumberFrom, 10) > parseInt(transactionPrintVM.idNumberTo, 10)) {
        //         toastr.error("Starting Id number should be less");
        //     } else if ((transactionPrintVM.amountFrom && isNaN(transactionPrintVM.amountFrom)) || (transactionPrintVM.amountTo && isNaN(transactionPrintVM.amountTo))) {
        //         toastr.error("Enter valid amount Numbers");
        //     } else if (parseFloat(transactionPrintVM.amountFrom) > parseFloat(transactionPrintVM.amountTo)) {
        //         toastr.error("Starting amount number should be less");
        //     } else if (transactionPrintVM.description && !utils.isAlphaNumeric(transactionPrintVM.description)) {
        //         toastr.error("Special symbols are not alloewd in description");
        //     } else {
        //         getTransactionSearchDetails(transactionPrintVM.productIds, transactionPrintVM.searchCardNo, 'Trans. Date');
        //     }
        // }

        function getTransactionSearchDetails(products, cardNumber, sortBy, orderBy) {
            $rootScope.dataLoading = true;
            // var startDate = transactionPrintVM.datePicker.startDate === null ? "" : $filter('date')(new Date(transactionPrintVM.datePicker.startDate.toString()), 'MM/dd/yyyy');
            // var endDate = transactionPrintVM.datePicker.endDate === null ? "" : $filter('date')(new Date(transactionPrintVM.datePicker.endDate.toString()), 'MM/dd/yyyy');

            // var payload = {
            //     "cardNumFrom": transactionPrintVM.cardNumberFrom,
            //     "cardNumTo": transactionPrintVM.cardNumberTo,
            //     "cardDescription": transactionPrintVM.description,
            //     "product": products,
            //     "idFrom": transactionPrintVM.idNumberFrom,
            //     "idTo": transactionPrintVM.idNumberTo,
            //     "department": transactionPrintVM.department,
            //     "transactionDateFrom": startDate,
            //     "transactionDateTo": endDate,
            //     "amountFrom": transactionPrintVM.amountFrom,
            //     "amountTo": transactionPrintVM.amountTo,
            //     "transactionwithExpceptions": transactionPrintVM.exceptions,
            //     "transactionTimeTo": "",
            //     "transactionTimeFrom": "",
            //     "searchString": cardNumber.name === undefined ? cardNumber : cardNumber.name,
            //     "accntNum": "All Accounts",
            //     "pageNumber": transactionPrintVM.currentPage,
            //     "sortingParameter": sortBy,
            //     "sortingDirection": orderBy === undefined ? 'asc' : orderBy,
            //     "pageRows": 20
            // };

            // var payload = {
            //     "Filter": {
            //         "PageNo": 1,
            //         "PageSize": 10,
            //         "SortColumn": sortBy,
            //         "SortOrder": orderBy === undefined ? 'asc' : orderBy
            //     },
            //     "Search": {
            //         "CardId": transactionPrintVM.searchCardNo,
            //         "CardDesc": transactionPrintVM.description,
            //         "DateFrom": startDate,
            //         "DateTo": endDate
            //     }
            // };
            var payload = JSON.parse(localStorage.transactionPaylod);
            payload.Filter.PageNo = 0;
            payload.Filter.PageSize = 0;
            payload.Search.AccountNo = localStorage.selectedAccount;
            return cardManagementService.printTransactions(payload)
                .then(getLastTransactionsSuccess, getLastTransactionsError);
        }

        function getLastTransactionsSuccess(apiResponse) {
            // transactionPrintVM.cardList = apiResponse;
            transactionPrintVM.transactionList = apiResponse;
            // transactionPrintVM.searchCount = apiResponse.totalRecords;
            // transactionPrintVM.maxPage = Math.ceil(transactionPrintVM.searchCount / 10);
            angular.forEach(transactionPrintVM.transactionList.dataModel, function (item) {
                item.showIcon = 'ic-expand_arrow-down';
            });
            $rootScope.dataLoading = false;
        }

        function getLastTransactionsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
            $rootScope.dataLoading = false;
        }

        // function cardSearch() {
        //     if (!transactionPrintVM.searchCardNo) {
        //         toastr.error("Enter card #");
        //     } else if (transactionPrintVM.searchCardNo && (transactionPrintVM.searchCardNo.length < 3)) {
        //         toastr.warning(toastrMessage.userManagement.searchCondition);
        //     } else {
        //         init();
        //         getTransactionSearchDetails([], transactionPrintVM.searchCardNo, 'Trans. Date');
        //     }
        // }

        function getColumnHeaders() {
            return cardManagementService.getTransactionColumns()
                .then(getTransactionColumnsSuccess, getTransactionColumnsError);
        }

        function getTransactionColumnsSuccess(apiResponse) {
            transactionPrintVM.columnHeaders = apiResponse.dataModel;
            angular.forEach(transactionPrintVM.columnHeaders, function (item) {
                // item.showColumn = true;
                if (item.strHeader === 'Trans. Date') {
                    item.showIcon = 'ic-filter';
                } else {
                    item.showIcon = 'ic-filterNot';
                }
            });
            transactionPrintVM.columnHeaderPopup = angular.copy(transactionPrintVM.columnHeaders);
        }

        function getTransactionColumnsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        // function arrangeTableData(popupData) {
        //     $rootScope.dataLoading = true;
        //     angular.forEach(popupData, function(item, key) {
        //         if (item.showColumn) {
        //             transactionPrintVM.columnHeaders[key].showColumn = true;
        //         } else {
        //             transactionPrintVM.columnHeaders[key].showColumn = false;
        //         }
        //     });
        //     $rootScope.dataLoading = false;
        //     transactionPrintVM.showColumnPopup = false;
        // }

        // function toggleHeader() {
        //     transactionPrintVM.showColumnPopup = !transactionPrintVM.showColumnPopup;
        // }

        // function paginationClick(button) {
        //     $window.scrollTo(0, 0);
        //     switch (button) {
        //         case 'first':
        //             if (transactionPrintVM.firstPageItem > 1) {
        //                 transactionPrintVM.firstPageItem = 1;
        //                 transactionPrintVM.secondPageItem = 2;
        //                 transactionPrintVM.currentPage = 1;
        //             }
        //             break;
        //         case 'previous':
        //             if (transactionPrintVM.firstPageItem > 1) {
        //                 transactionPrintVM.firstPageItem--;
        //                 transactionPrintVM.secondPageItem--;
        //                 transactionPrintVM.currentPage--;
        //             } else if (transactionPrintVM.firstPageItem < transactionPrintVM.currentPage) {
        //                 transactionPrintVM.currentPage--;
        //             } else {
        //                 //sonar issue fix
        //             }
        //             break;
        //         case 'next':
        //             if (transactionPrintVM.secondPageItem < transactionPrintVM.maxPage) {
        //                 transactionPrintVM.firstPageItem++;
        //                 transactionPrintVM.secondPageItem++;
        //                 transactionPrintVM.currentPage++;
        //             } else if (transactionPrintVM.currentPage < transactionPrintVM.secondPageItem) {
        //                 transactionPrintVM.currentPage++;
        //             } else {
        //                 //sonar issue fix
        //             }
        //             break;
        //         case 'last':
        //             if (transactionPrintVM.secondPageItem < transactionPrintVM.maxPage) {
        //                 transactionPrintVM.firstPageItem = transactionPrintVM.maxPage - 1;
        //                 transactionPrintVM.secondPageItem = transactionPrintVM.maxPage;
        //                 transactionPrintVM.currentPage = transactionPrintVM.maxPage;
        //             }
        //             break;
        //         default:
        //             transactionPrintVM.currentPage = button;
        //             break;
        //     }
        // }

        // function orderByColumn(columnHeader, iconType) {
        //     var orderBy = '';
        //     transactionPrintVM.productIds = [];
        //     angular.forEach(transactionPrintVM.columnHeaders, function(item) {
        //         if (item.strHeader === columnHeader) {
        //             if (iconType === 'ic-filterNot' || iconType === 'ic-filterDesc') {
        //                 item.showIcon = 'ic-filter';
        //                 orderBy = 'asc';
        //             } else {
        //                 item.showIcon = 'ic-filterDesc';
        //                 orderBy = 'desc';
        //             }
        //         } else {
        //             item.showIcon = 'ic-filterNot';
        //         }
        //     });
        //     angular.forEach(transactionPrintVM.productValues, function(item) {
        //         if (item.isChecked) {
        //             transactionPrintVM.productIds.push(item.id);
        //         }
        //     });
        //     getTransactionSearchDetails(transactionPrintVM.productIds, transactionPrintVM.searchCardNo, columnHeader, orderBy);
        // }

        /*clears the date values from tha calender*/
        // function clearCalender() {
        //     transactionPrintVM.datePicker = { startDate: null, endDate: null };
        //     $('#daterange').data('daterangepicker').setStartDate(new Date());
        //     $('#daterange').data('daterangepicker').setEndDate(new Date());
        // }

        // function applyProducts() {
        //     transactionPrintVM.showProductPopup = false;
        // }

        function printTable() {
            // window.print();

            var printContents = document.getElementById('printDiv').innerHTML;
            var popupWin = window.open('', '_blank', 'width=800,height=800,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no,top=50');
            popupWin.window.focus();
            popupWin.document.open();
            popupWin.document.write('<!DOCTYPE html><html><head><title>Transactions</title>' +
                '<link rel="stylesheet" type="text/css" href="resources/stylesheets/print.css" />' +
                '</head><body onload="window.print(); window.close();"><div>' +
                printContents + '</div></html>');
            popupWin.document.close();

            // var printContents = document.getElementById('printDiv').innerHTML;
            // var originalContents = document.body.innerHTML;        
            // document.body.innerHTML = printContents;
            // window.print();
            // document.body.innerHTML = originalContents;
        }

        function backToTransaction() {
            $state.go('root.transactions');
        }

        /*when account changed*/
        $scope.$on('accountChanged', function () {
            var hasPermission = false;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function (item, key) {
                    if (item.option.toLowerCase().indexOf('transaction') !== -1) {
                        if (item.displayName === 'R' || item.displayName === 'W') {
                            hasPermission = true;
                            init();
                            getTransactionSearchDetails([], transactionPrintVM.searchCardNo, 'Trans. Date');
                        }
                    }
                    var arrayLength = JSON.parse(localStorage.accountPermission).length - 1;
                    if ((key === arrayLength) && !hasPermission) {
                        $state.go('root.dashboard');
                    }
                });
            }
        });
    }
})();