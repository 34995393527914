/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.login', ['scFuelsApp.login'])
        .config(routerConfiguration);

    routerConfiguration.$inject = ['$stateProvider'];

    function routerConfiguration($stateProvider) {
        $stateProvider
            .state('root.settingsPassword', {
                url: '/settingsPassword',
                templateUrl: 'app/modules/layout/headermenuArea/settings/changePassword/changePasswordTemplate.html',
                controller: 'settingsPasswordController',
                controllerAs: 'passwordVM',
                params: {
                    previousPage : null
                }
            })
            .state('root.settingsQuestion', {
                url: '/settingsChangeQuestion',
                templateUrl: 'app/modules/layout/headermenuArea/settings/changeQuestions/changeQuestionTemplate.html',
                controller: 'settingsQuestionController',
                controllerAs: 'questionVM',
                params: {
                    previousPage : null
                }
            })
            .state('root.faq', {
                url: '/faq',
                templateUrl: 'app/modules/layout/headermenuArea/faq/faqTemplate.html',
                controller: 'faqController',
                controllerAs: 'faqVM'
            });
    }
})();
