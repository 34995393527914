/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';
    angular.module('appConfig.module')
        .factory('apiURLService', apiURLService);

    apiURLService.$inject = ['webServiceURL'];

    function apiURLService (webServiceURL) {
        var buildURLObject = {};

        //login, registration
        buildURLObject.getBaseURL = getBaseURL;
        buildURLObject.getLoginURL = getLoginURL;
        buildURLObject.getSecurityQuestionListURL = getSecurityQuestionListURL;
        buildURLObject.getUserRegistrationURL = getUserRegistrationURL;
        buildURLObject.resetPwdLinkURL = resetPwdLinkURL;
        buildURLObject.resetPasswordURL = resetPasswordURL;
        buildURLObject.validateTokenURL = validateTokenURL;
        buildURLObject.expireTokenURL = expireTokenURL;

        //user management
        buildURLObject.getUserAccountDetailsURL = getUserAccountDetailsURL;
        buildURLObject.saveNewUserURL = saveNewUserURL;
        buildURLObject.validateEmailURL = validateEmailURL;
        buildURLObject.getAccountListURL = getAccountListURL;
        buildURLObject.getUserURL = getUserURL;
        buildURLObject.getDefaultPermissionURL = getDefaultPermissionURL;
        buildURLObject.updateUserURL = updateUserURL;

        //invoice & payments
        buildURLObject.getSearchCriteriaURL = getSearchCriteriaURL;
        buildURLObject.getInvoicesURL = getInvoicesURL;
        buildURLObject.getColumnHeadersURL = getColumnHeadersURL;
        buildURLObject.getPaymentHistorySearchURL = getPaymentHistorySearchURL;
        buildURLObject.getInvoiceColumnHeadersURL = getInvoiceColumnHeadersURL;
        buildURLObject.fetchInquiryReasonsURL = fetchInquiryReasonsURL;
        buildURLObject.getOpenInvoiceListURL = getOpenInvoiceListURL;
        buildURLObject.getPaymentListURL = getPaymentListURL;
        buildURLObject.submitInquiryListURL = submitInquiryListURL;
        buildURLObject.exportInvoicePaymentURL = exportInvoicePaymentURL;
        buildURLObject.getPaymentColumnURL = getPaymentColumnURL;
        buildURLObject.exportPaymentsURL = exportPaymentsURL;
        buildURLObject.makePaymentInvoiceListURL = makePaymentInvoiceListURL;
        // buildURLObject.finishPaymentURL = finishPaymentURL;
        buildURLObject.saveColumnHeadersURL = saveColumnHeadersURL;
        buildURLObject.saveMemoInvoicesURL = saveMemoInvoicesURL


        //contact us
        buildURLObject.getContactDetailsURL = getContactDetailsURL;
        buildURLObject.getContactDropdownDataURL = getContactDropdownDataURL;
        buildURLObject.getsubmitMessageURL = getsubmitMessageURL;
        
        // dashboard URLs
        buildURLObject.getRecentActivitiesListURL = getRecentActivitiesListURL;
        buildURLObject.getOpenInvoiceDataURL = getOpenInvoiceDataURL;
        buildURLObject.getCriticalAlertURL = getCriticalAlertURL;

        // marketing insight URLs
        buildURLObject.getGATypesURL = getGATypesURL;
        buildURLObject.getChartTypesURL = getChartTypesURL;
        buildURLObject.getGaAuthTokenURL = getGaAuthTokenURL;
        buildURLObject.getOrgIdsURL = getOrgIdsURL;

        // tech insight URLs
        buildURLObject.getAuditModulesURL = getAuditModulesURL;
        buildURLObject.getAuditColumnHeadersURL = getAuditColumnHeadersURL;
        buildURLObject.getAuditListURL = getAuditListURL;

        //Settings
        buildURLObject.settingsPasswordURL = settingsPasswordURL;
        buildURLObject.settingsQuestionURL = settingsQuestionURL;
        buildURLObject.getHeaderNotificationsURL = getHeaderNotificationsURL;
        buildURLObject.getHeaderMessagesURL = getHeaderMessagesURL;
        buildURLObject.getFAQURL = getFAQURL;
        buildURLObject.updateMessagesReadCountURL = updateMessagesReadCountURL
        buildURLObject.updateNotificationReadCountURL = updateNotificationReadCountURL;
        buildURLObject.getHeaderAccountListURL = getHeaderAccountListURL;
        buildURLObject.getAccountPermissionURL = getAccountPermissionURL;

        //Change Account Info
        buildURLObject.getAccountInfoListURL = getAccountInfoListURL;
        buildURLObject.fetchAccountInformationURL = fetchAccountInformationURL;
        buildURLObject.emailValidationURL = emailValidationURL;
        buildURLObject.saveBillToAddressInfoURL = saveBillToAddressInfoURL;
        buildURLObject.saveContactInformationURL = saveContactInformationURL;
        buildURLObject.savePaperlessInformationURL = savePaperlessInformationURL;
        buildURLObject.deleteContactInfoURL = deleteContactInfoURL;
        buildURLObject.deletePaperlessInfoURL = deletePaperlessInfoURL;
        buildURLObject.getStateListURL = getStateListURL;

        //banner management
        buildURLObject.getCurrentBannersURL = getCurrentBannersURL;
        buildURLObject.saveBannerURL = saveBannerURL;

        //Card Management
        buildURLObject.getCardSmartSearchListURL= getCardSmartSearchListURL;
        buildURLObject.getLastTransactionsListURL = getLastTransactionsListURL;
        buildURLObject.getTransactionDetailsURL = getTransactionDetailsURL;
        buildURLObject.getTransactionParametersURL = getTransactionParametersURL;
        buildURLObject.getTransactionColumnsURL = getTransactionColumnsURL;
        buildURLObject.exportTransactionURL = exportTransactionURL;
        buildURLObject.printTransactionURL = printTransactionURL;
        buildURLObject.saveTransColumnHeadersURL = saveTransColumnHeadersURL;
        buildURLObject.printCardDetailsURL =printCardDetailsURL;
        buildURLObject.exportCardManagementURL=exportCardManagementURL;
        buildURLObject.getExportPromptsListURL =getExportPromptsListURL;
        buildURLObject.getPrintPromptsListURL = getPrintPromptsListURL;

        buildURLObject.getFleetCardListURL = getFleetCardListURL;
        buildURLObject.getFleetCardDetailsURL = getFleetCardDetailsURL;
        buildURLObject.getFleetCardDropdownListURL = getFleetCardDropdownListURL;
        buildURLObject.getCardManagementDropdownListURL = getCardManagementDropdownListURL;
        buildURLObject.getCardRestrictionDropdownListURL = getCardRestrictionDropdownListURL;
        buildURLObject.saveFleetCardDetailsURL = saveFleetCardDetailsURL;
        buildURLObject.editFleetCardDetailsURL = editFleetCardDetailsURL;
        buildURLObject.editAndSaveFleetCardDetailsURL = editAndSaveFleetCardDetailsURL;
        buildURLObject.getFleetSmartSearchListURL = getFleetSmartSearchListURL;
        buildURLObject.getPinValidationURL = getPinValidationURL;

        buildURLObject.getPromptCardListURL = getPromptCardListURL;
        buildURLObject.getPromptCardDetailsURL = getPromptCardDetailsURL;
        buildURLObject.getPromptDropdownListURL = getPromptDropdownListURL;
        buildURLObject.getPromptSmartSearchListURL = getPromptSmartSearchListURL;
        buildURLObject.getPromptRestrictionDropdownListURL = getPromptRestrictionDropdownListURL;
        buildURLObject.saveDriverPinDetailsURL = saveDriverPinDetailsURL;
        buildURLObject.editDriverPinDetailsURL = editDriverPinDetailsURL;
        buildURLObject.getDriverValidationURL = getDriverValidationURL;
        buildURLObject.getVehicleValidationURL = getVehicleValidationURL;
        buildURLObject.getPromptLinkedCardListURL = getPromptLinkedCardListURL;

        //User Activities
        buildURLObject.getUserActivitiesURL = getUserActivitiesURL;
        buildURLObject.getOtherUserActivitiesURL = getOtherUserActivitiesURL;
        return buildURLObject;

        //--------------------------------------
        //function declarations
        function getBaseURL () {
            return webServiceURL.BASE_URL;
        }


        // change BASE_URL_DEV
        //login----------------------------

        function getLoginURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.login.loginUrl;
        }

        function getSecurityQuestionListURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.login.securityQuestionsURL;
        }

        function getUserRegistrationURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.login.userRegistrationURL;
        }

        function resetPwdLinkURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.login.resetPwdLinkURL;
        }

        function resetPasswordURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.login.resetPasswordURL;
        }

        function validateTokenURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.login.validateTokenURL;
        }

        function expireTokenURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.login.expireTokenURL;
        }



        //User Management

        function getUserAccountDetailsURL(){
           return webServiceURL.BASE_URL_DEV + webServiceURL.userManagement.getUserAccountDetailsUrl;
        }

        function saveNewUserURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.userManagement.saveNewUserUrl;
        }

        function validateEmailURL(){
          return webServiceURL.BASE_URL_DEV + webServiceURL.userManagement.validateEmailUrl;
        }

        function getAccountListURL(){
          return webServiceURL.BASE_URL_DEV + webServiceURL.userManagement.getAccountListUrl;
        }

        function getUserURL(){
          return webServiceURL.BASE_URL_DEV + webServiceURL.userManagement.getUserUrl;
        }

        function getDefaultPermissionURL(){
          return webServiceURL.BASE_URL_DEV + webServiceURL.userManagement.getDefaultPermissionUrl;  
        }

        function updateUserURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.userManagement.updateUserUrl; 
        }

        //Invoices And Payments

        function getSearchCriteriaURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.getSearchCriteriaUrl; 
        }

        function getInvoicesURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.getInvoicesUrl; 
        }

        function getColumnHeadersURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.getColumnHeadersUrl; 
        }
        
        function getPaymentHistorySearchURL(){
            // return webServiceURL.BASE_URL + webServiceURL.invoicePayments.getPaymentHistorySearchUrl; 
             return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.getPaymentHistorySearchUrl;         
        }

        function getInvoiceColumnHeadersURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.getInvoiceColumnHeadersUrl; 
        }

        function fetchInquiryReasonsURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.fetchInquiryReasonsUrl;
        }

        function getOpenInvoiceListURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.getOpenInvoiceListUrl;
            // return webServiceURL.BASE_URL + webServiceURL.invoicePayments.getOpenInvoiceListUrl;  
        }

        function getPaymentListURL(){
          return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.getPaymentListUrl;
            // return webServiceURL.BASE_URL + webServiceURL.invoicePayments.getPaymentListUrl;   
        }

        function submitInquiryListURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.submitInquiryListUrl;
        }

        function exportInvoicePaymentURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.exportInvoicePaymentUrl;
        }

        function getPaymentColumnURL(){
           return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.getPaymentColumnUrl; 
        }

        function exportPaymentsURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.exportPaymentsUrl; 
        }

        function makePaymentInvoiceListURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.makePaymentInvoiceListUrl; 
        }
         function saveMemoInvoicesURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.saveMemoInvoicesUrl;
        }

        //  function finishPaymentURL(){
        //     return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.finishPaymentUrl; 
        // }

         function saveColumnHeadersURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.invoicePayments.saveColumnHeadersUrl; 
        }

        //contactus

        function getContactDetailsURL(){
           return webServiceURL.BASE_URL + webServiceURL.contactus.contactDetailsURL;
        }

        function getContactDropdownDataURL(){
           return webServiceURL.BASE_URL_DEV + webServiceURL.contactus.contactDropdownURL;
        }

        function getsubmitMessageURL(){
           return webServiceURL.BASE_URL_DEV + webServiceURL.contactus.submitMessageURL;
        }
      
        // marketing insight

        function getGATypesURL(){
           return webServiceURL.BASE_URL + webServiceURL.marketinginsight.getGATypesURL; 
        }

        function getChartTypesURL(){
           return webServiceURL.BASE_URL + webServiceURL.marketinginsight.gaChartTypesURL; 
        }

        function getOrgIdsURL(){
           return webServiceURL.BASE_URL + webServiceURL.marketinginsight.getOrgIdsURL; 
        }

        function getGaAuthTokenURL() {
            return webServiceURL.marketinginsight.getGaAuthTokenURL;
        }

        // tech insight

        function getAuditModulesURL(){
           return webServiceURL.BASE_URL_DEV + webServiceURL.techinsight.getModulesURL; 
        }

        function getAuditColumnHeadersURL(){
            return webServiceURL.BASE_URL + webServiceURL.techinsight.getAuditColumnHeadersURL;
        }

        function getAuditListURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.techinsight.getAuditListURL;
        }

        // dashboard

        function getRecentActivitiesListURL(){
           return webServiceURL.BASE_URL_DEV + webServiceURL.dashboard.getRecentActivitiesListURL; 
        }

        function getOpenInvoiceDataURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.dashboard.getOpenInvoiceDataUrl;
        }

        function getCriticalAlertURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.dashboard.getCriticalAlertUrl;
        }

        //Settings

        function settingsPasswordURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.headerMenu.settingsPasswordUrl;
        }

        function settingsQuestionURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.headerMenu.settingsQuestionUrl;
        }

        function getHeaderMessagesURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.headerMenu.headerMessagesURL;
        }

        function getHeaderNotificationsURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.headerMenu.headerNotificationsURL;
        }

        function getFAQURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.headerMenu.FAQURL;
        }

        function updateMessagesReadCountURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.headerMenu.updateMessagesReadCountUrl;
        }

        function updateNotificationReadCountURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.headerMenu.updateNotificationReadCountUrl;
        }

        function getHeaderAccountListURL(){
           return webServiceURL.BASE_URL_DEV + webServiceURL.headerMenu.headerAccountListUrl; 
        }

        function getAccountPermissionURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.headerMenu.getAccountPermissionUrl;
        }
        

        //Change Account Info

        function getAccountInfoListURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.accountInfo.getAccountListUrl; 
        }

        function fetchAccountInformationURL(){
           return webServiceURL.BASE_URL_DEV + webServiceURL.accountInfo.fetchAccountInformationUrl;  
        }

        function emailValidationURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.accountInfo.emailValidationUrl;    
        }
        
        function saveBillToAddressInfoURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.accountInfo.saveBillToAddressInfoUrl;  
        }

        function saveContactInformationURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.accountInfo.saveContactInformationUrl; 
        }

        function savePaperlessInformationURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.accountInfo.savePaperlessInformationUrl;  
        }

        function deleteContactInfoURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.accountInfo.deleteContactInfoUrl;
        }

        function deletePaperlessInfoURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.accountInfo.deletePaperlessInfoUrl;
        }

        function getStateListURL(){
             return webServiceURL.BASE_URL + webServiceURL.accountInfo.getStateListUrl;
        }

        //banner management
        function getCurrentBannersURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.manageBanner.getCurrentBannersURL; 
        }
        function saveBannerURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.manageBanner.saveBannerURL; 
        }

        //Card Management
        function getCardSmartSearchListURL(){
            return webServiceURL.BASE_URL + webServiceURL.cardManagement.getCardSmartSearchListUrl;
        }
        
        function getLastTransactionsListURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getLastTransactionsListUrl;
        }

        function getTransactionDetailsURL(){
            return webServiceURL.BASE_URL + webServiceURL.cardManagement.getTransactionDetailsUrl;
        }

        function getTransactionParametersURL(){
            return webServiceURL.BASE_URL + webServiceURL.cardManagement.getTransactionParametersUrl;
        }

        function getTransactionColumnsURL(){
             return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getTransactionColumnsUrl;
        }

        function exportTransactionURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.exportTransactionUrl; 
        }

        function printTransactionURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.printTransactionUrl; 
        }

        function saveTransColumnHeadersURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.saveColumnHeadersUrl; 
        }

        function getFleetCardListURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getFleetCardListUrl;
        }

        function getFleetCardDetailsURL(){
            return webServiceURL.BASE_URL + webServiceURL.cardManagement.getFleetCardDetailsUrl;
        }

        function getFleetCardDropdownListURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getFleetCardDropdownListUrl;
        }

        function getCardManagementDropdownListURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getCardManagementDropdownListUrl;
        }

        function getCardRestrictionDropdownListURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getCardRestrictionDropdownListUrl;
        }

        function saveFleetCardDetailsURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.saveFleetCardDetailsUrl;
        }

        function editFleetCardDetailsURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.editFleetCardDetailsUrl;
        }

        function editAndSaveFleetCardDetailsURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.editAndSaveFleetCardDetailsURL;
        }

        function getFleetSmartSearchListURL(){
            return webServiceURL.BASE_URL + webServiceURL.cardManagement.getFleetSmartSearchListUrl; 
        }

        function getPinValidationURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getPinValidationUrl; 
        }

        function getDriverValidationURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getDriverValidationUrl;
        }

        function getVehicleValidationURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getVehicleValidationUrl;
        }

        function getPromptCardListURL(){
           return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getPromptCardListUrl; 
        }

        function getPromptCardDetailsURL(){
            return webServiceURL.BASE_URL + webServiceURL.cardManagement.getPromptCardDetailsUrl;
        }

        function getPromptDropdownListURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getPromptDropdownListUrl;
        }

        function getPromptSmartSearchListURL(){
           return webServiceURL.BASE_URL + webServiceURL.cardManagement.getPromptSmartSearchListUrl; 
        }

        function getPromptRestrictionDropdownListURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getPromptRestrictionDropdownListUrl;
        }

        function getPromptLinkedCardListURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.getPromptLinkedCardListUrl;
        }

        function saveDriverPinDetailsURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.saveDriverPinDetailsUrl;
        }

        function editDriverPinDetailsURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.editDriverPinDetailsUrl;
        }
        function printCardDetailsURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.printCardDetailsUrl; 
        }
        function exportCardManagementURL(){
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.exportCardManagementUrl; 
        }

        //User Activity
        function getUserActivitiesURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.userActivity.getUserActivity; 
        }

        function getOtherUserActivitiesURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.userActivity.getOtherUserActivity; 
        }
        function getExportPromptsListURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.exportPromptsUrl;
        }
        function getPrintPromptsListURL() {
            return webServiceURL.BASE_URL_DEV + webServiceURL.cardManagement.printPromptsUrl;
        }
    }
})();
