
/*jshint browser: true*/
/*global angular: true*/


(function(){
    'use strict';

    angular
        .module('scFuelsApp.contactus', [])
        .config(routerConfiguration);

    routerConfiguration.$inject = ['$stateProvider'];
    function routerConfiguration ($stateProvider) {
        $stateProvider
            .state('root.contactus', {
            url: '/contactUs',
            templateUrl: 'app/modules/contactUs/contactUsTemplate.html',
            controller: 'contactUsController',
            controllerAs : 'contactUsVM'
        });
    }
})();
