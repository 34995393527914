/*jshint browser: true*/
/*global angular: true*/

(function () {
    'use strict';

    angular.module('scFuelsApp')
        .controller('SidemenuController', SidemenuController);

    SidemenuController.$inject = ['$scope', '$state', '$location', '$confirm', 'loginService', '$rootScope', 'toastr', '$window'];

    function SidemenuController($scope, $state, $location, $confirm, loginService, $rootScope, toastr, $window) {
        var sidemenuVM = this;
        sidemenuVM.onLogout = onLogout;
        sidemenuVM.userName = localStorage.loggedInUser;
        sidemenuVM.userOrg = localStorage.loggedInUserOrg;
        sidemenuVM.loggedInUser = localStorage.userType;
        // init();  
        /*function declarations*/

        /*watch function to check account permission*/
        $scope.$watch(function () {
            return localStorage.accountPermission;
        }, function () {
            if (localStorage.hasEntertpisePermission) {
                init();
            } else {
                $rootScope.unauthorized = true;
                // $state.go('login');
            }
        });

        /*checks if sidemenu is selected*/
        sidemenuVM.isActive = function (token) {
            var isFalse = false;
            var path = $location.path();
            if (path.toLowerCase().indexOf(token) > 0) {
                isFalse = true;
            }
            return isFalse;
        }
        /*navigates to corresponding page*/
        sidemenuVM.navigateTo = function (selectedItem) {
            if ((selectedItem.permission == 'invoice' && (sidemenuVM.invoicePermission == 'R' || sidemenuVM.invoicePermission == 'W')) ||
                (selectedItem.permission == 'payment' && (sidemenuVM.paymentPermission == 'R' || sidemenuVM.paymentPermission == 'W')) ||
                (selectedItem.permission == 'card' && (sidemenuVM.cardPermission == 'R' || sidemenuVM.cardPermission == 'W')) ||
                (selectedItem.permission == 'account' && (sidemenuVM.userPermission == 'R' || sidemenuVM.userPermission == 'W')) ||
                (selectedItem.permission == 'transaction' && (sidemenuVM.transactionPermission == 'R' || sidemenuVM.transactionPermission == 'W')) ||
                (selectedItem.permission == 'none')) {
                if (selectedItem.activeToken === 'website') {
                    $window.open('https://www.scfuels.com/');

                } else {
                    $state.go(selectedItem.state);
                }
            }
        }
        /*initial loading*/
        function init() {
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function (item) {
                    if (item.option.toLowerCase().indexOf('account') !== -1) {
                        sidemenuVM.userPermission = item.displayName;
                    } else if (item.option.toLowerCase().indexOf('invoice') !== -1) {
                        sidemenuVM.invoicePermission = item.displayName;
                    } else if (item.option.toLowerCase().indexOf('payment') !== -1) {
                        sidemenuVM.paymentPermission = item.displayName;
                    } else if (item.option.toLowerCase().indexOf('card') !== -1) {
                        sidemenuVM.cardPermission = item.displayName;
                    } else if (item.option.toLowerCase().indexOf('transaction') !== -1) {
                        sidemenuVM.transactionPermission = item.displayName;
                    }
                })
            }
            if (JSON.parse(localStorage.hasEntertpisePermission)) {
                sidemenuVM.menuList = [{
                    headerItem: "",
                    show: true,
                    headerSubItem: [
                        /*                    {
                                                permission: "none",
                                                show: (localStorage.userType === 'Marketting'),
                                                item: "Marketing Insight",
                                                class: "ic-leftmenu_marketing",
                                                state: "root.marketingInsight",
                                                activeToken: "marketing"
                                            },*/
                        {
                            permission: "none",
                            show: (localStorage.userType === 'ItSupport'),
                            item: "Tech Insight",
                            class: "ic-leftmenu_tech",
                            state: "root.techInsight",
                            activeToken: "techinsight"
                        }, {
                            permission: "none",
                            show: (localStorage.userType === 'Marketting'),
                            item: "Banner Management",
                            class: "ic-leftmenu_banner",
                            state: "root.bannerManagement",
                            activeToken: "bannermanage"
                        }
                    ]
                }];
            } else {
                sidemenuVM.menuList = [{
                    headerItem: "",
                    show: true,
                    headerSubItem: [{
                        permission: "none",
                        show: true,
                        item: "Home",
                        class: "ic-leftmenu_home",
                        state: "root.dashboard",
                        activeToken: "dashboard"
                    },{
                        permission: "none",
                        show: true,
                        item: "User Activity",
                        class: "icon-User-Activity sc-fuels-icn menu-icn",
                        /** need to change */
                        state: "root.userActivity",
                        activeToken: "activity"
                    }]
                },
                
                // {
                //     headerItem: "",
                //     show: true,
                //     headerSubItem: [{
                //         permission: "none",
                //         show: true,
                //         item: "User Activity",
                //         class: "icon-User-Activity sc-fuels-icn menu-icn",
                //         /** need to change */
                //         state: "root.userActivity",
                //         activeToken: "activity"
                //     }]
                // }, 
                
                
                {
                    headerItem: "INVOICE & PAYMENTS",
                    show: (sidemenuVM.invoicePermission == 'R' || sidemenuVM.invoicePermission == 'W' || sidemenuVM.paymentPermission == 'R' || sidemenuVM.paymentPermission == 'W') ? true : false,
                    headerSubItem: [{
                        permission: "invoice",
                        show: (sidemenuVM.invoicePermission == 'R' || sidemenuVM.invoicePermission == 'W') ? true : false,
                        item: "Invoices",
                        class: "ic-leftmenu_invoice",
                        state: "root.invoices.openInvoices",
                        activeToken: "invoice"
                    }, {
                        permission: "payment",
                        show: (sidemenuVM.paymentPermission == 'R' || sidemenuVM.paymentPermission == 'W') ? true : false,
                        item: "Make Payments",
                        class: "ic-leftmenu_payments",
                        state: "root.payments",
                        activeToken: "payment"
                    }]
                }, {
                    headerItem: "CARDLOCK",
                    show: true,
                    headerSubItem: [{
                        permission: "card",
                        show: true,
                        item: "Card Management",
                        class: "ic-leftmenu_fleetcard",
                        state: "root.fleetCard",
                        activeToken: "fleetcard",
                        disable:(sidemenuVM.cardPermission == 'N' || !sidemenuVM.cardPermission) ? true : false,
                    }, {
                        permission: "card",
                        show: true,
                        item: "Drivers/PIN Management",
                        class: "ic-leftmenu_prompts",
                        state: "root.prompts",
                        activeToken: "prompt",
                        disable:(sidemenuVM.cardPermission == 'N' || !sidemenuVM.cardPermission) ? true : false,
                    }, {
                        permission: "transaction",
                        show: true,
                        item: "Transactions",
                        class: "ic-leftmenu_transaction",
                        state: "root.transactions",
                        activeToken: "transaction",
                        disable : (sidemenuVM.transactionPermission == 'N' || !sidemenuVM.transactionPermission) ? true : false,
                    }]
                }, {
                    headerItem: "ACCOUNT MAINTENANCE",
                    show: (sidemenuVM.userPermission == 'R' || sidemenuVM.userPermission == 'W') ? true : false,
                    headerSubItem: [{
                        permission: "account",
                        show: (sidemenuVM.userPermission == 'R' || sidemenuVM.userPermission == 'W') ? true : false,
                        item: "User Management",
                        class: "ic-leftmenu_user",
                        state: "root.userManagement",
                        activeToken: "user"
                    }, {
                        permission: "account",
                        show: (sidemenuVM.userPermission == 'R' || sidemenuVM.userPermission == 'W') && (sidemenuVM.loggedInUser !== 'SuperAdmin') ? true : false,
                        item: "Change Account Info",
                        class: "ic-leftmenu_change-account",
                        state: "root.accountInfo",
                        activeToken: "changeaccount"
                    }]
                }, {
                    headerItem: "",
                    show: true,
                    headerSubItem: [{
                        permission: "none",
                        show: true,
                        item: "Contact Us",
                        class: "ic-leftmenu_contact",
                        state: "root.contactus",
                        activeToken: "contactus"
                    }, {
                        permission: "none",
                        show: true,
                        item: "www.scfuels.com",
                        class: "ic-leftmenu_website",
                        state: "root.dashboard",
                        activeToken: "website"
                    }]
                }];
            }
        }
        /*clicks on log out button*/
        function onLogout() {
            $confirm({
                title: "Logout",
                text: "Are you sure you want to logout?",
                ok: "Yes",
                cancel: "No"
            }, {
                size: 'sm',
                backdrop: true
            }).then(function () {
                $rootScope.dataLoading = true;
                loginService.expireToken()
                    .then(logoutSuccess, logoutFailure);
            });
        }
        /*success callback for log out*/
        function logoutSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                $rootScope.unauthorized = true;
                localStorage.clear();
                $state.go('login');
            } else {
                toastr.error(apiResponse.statusMessage || 'Something went wrong');
            }
        }
        /*error callback for log out*/
        function logoutFailure(error) {
            toastr.error(error.statusMessage || 'Something went wrong');
        }

    }
})();