
/*jshint browser: true*/
/*global angular: true*/


(function(){
    'use strict';

    angular
        .module('scFuelsApp.login')
        .controller('forgotPwdController', forgotPwdController);


    forgotPwdController.$inject = ['$scope','$state', 'loginService', 'toastr', '$timeout', '$rootScope','toastrMessage', 'utils'];

    function forgotPwdController($scope, $state, loginService , toastr, $timeout, $rootScope,toastrMessage, utils){
        var forgotPwdVM = this;
        forgotPwdVM.sendResetLink = sendResetLink;

        /*function declarations*/

        /*clicks on send reset link*/
        function sendResetLink() {
        	if(forgotPwdVM.email){
                if(utils.isValidEmail(forgotPwdVM.email)){
                   $rootScope.dataLoading = true;
                    loginService.sendResetLink(forgotPwdVM.email)
                    .then(sendResetLinkSuccess, sendResetLinkFailure); 
                }else{
                   toastr.error(toastrMessage.common.emailValidation);
                }                
    		}
    		else{
    			toastr.error(toastrMessage.login.emailEmpty);
    		}
        }
        /*success callback for send reset link*/
        function sendResetLinkSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            forgotPwdVM.email = '';
        	if(apiResponse.statusCode == 200){
        		toastr.success(toastrMessage.login.passwordReset);
        	}
        	else if(apiResponse.statusCode == 0){
        		toastr.error(toastrMessage.common.emailValidation);
        	}
            else{
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }        	
        }
        /*error callback for send reset link*/
        function sendResetLinkFailure(error) {
            $rootScope.dataLoading = false;
        	toastr.error(toastrMessage.common.systemNetworkFailure);
        }
    }
})();
