
/*jshint browser: true*/
/*global angular: true*/


(function(){
    'use strict';

    angular
        .module('scFuelsApp.login')
        .controller('resetPasswordController', resetPasswordController);


    resetPasswordController.$inject = ['$scope','$state', 'loginService', 'toastr', '$stateParams','$rootScope', '$timeout','toastrMessage'];

    function resetPasswordController($scope, $state, loginService , toastr, $stateParams, $rootScope, $timeout,toastrMessage){
        var resetPasswordVM = this;
        resetPasswordVM.resetPassword = resetPassword;
        resetPasswordVM.validatePassword = validatePassword;

        init();

        /*function declarations*/

        /*initial loading*/
        function init() {
            if($stateParams.Id){
                $rootScope.dataLoading = true;
                resetPasswordVM.token =  $stateParams.Id;
                loginService.validateToken(resetPasswordVM.token)
                .then(validateTokenSuccess, validateTokenFailure);
            }
            else{
                $state.go('login');
                toastr.error(toastrMessage.login.invalidUrl);
            }
        }
        /*success callback for token validation*/
        function validateTokenSuccess(apiResponse) {
            if(apiResponse.statusCode === 200){
                getSecurityQuestionList();
                resetPasswordVM.email = apiResponse.dataModel.email;
            }else{
                toastr.error(toastrMessage.login.linkExpired);
                $rootScope.dataLoading = false;
            }
        }
        /*error callback for token validation*/
        function validateTokenFailure(error) {
            $state.go('login');
            toastr.error(toastrMessage.login.linkExpired);
        }
        /*fetches security questions*/
        function getSecurityQuestionList() {
        	loginService.getSecurityQuestionList($stateParams.Id)
            .then(getSecurityQuestionListSuccess, getSecurityQuestionListFailure);
        }
        /*success callback for security question*/
        function getSecurityQuestionListSuccess(apiResponse) {
            $rootScope.dataLoading = false;
        	if(apiResponse.statusCode === 200){
                // resetPasswordVM.securityQuestions = apiResponse.dataModel;
                resetPasswordVM.securityQuestions = apiResponse.dataModel.questions;
                resetPasswordVM.questionOne = apiResponse.dataModel.userQuestions[0].id;
                resetPasswordVM.questionTwo = apiResponse.dataModel.userQuestions[1].id;
	        }
	        else{
	        	toastr.error(toastrMessage.common.systemNetworkFailure);
	        }
        }
        /*error callback for security question*/
        function getSecurityQuestionListFailure(error) {
            $rootScope.dataLoading = false;
        	toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*checks for password validation*/
        function validatePassword() {
            var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,20}$/
            if(resetPasswordVM.newPassword && !regex.test(resetPasswordVM.newPassword)){
                toastr.error(toastrMessage.login.passwordValidation);
            }
        }
        /*clicks on reset password button*/
        function resetPassword() {
            var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,20}$/
            if(resetPasswordVM.email){
                if(resetPasswordVM.newPassword && resetPasswordVM.confirmPassword &&
               resetPasswordVM.questionOne && resetPasswordVM.answerOne && resetPasswordVM.questionTwo && resetPasswordVM.answerTwo){
                    if(regex.test(resetPasswordVM.newPassword)){
                        if(resetPasswordVM.newPassword === resetPasswordVM.confirmPassword){
                            $rootScope.dataLoading = true;
                            var params = {
                                "emailId": resetPasswordVM.email,
                                "password": resetPasswordVM.newPassword,
                                "question1": resetPasswordVM.questionOne,
                                "answer1": resetPasswordVM.answerOne,
                                "question2": resetPasswordVM.questionTwo,
                                "answer2": resetPasswordVM.answerTwo
                            }
                            loginService.resetPassword(params)
                            .then(resetPasswordSuccess, resetPasswordFailure);
                        }
                        else{
                            toastr.error(toastrMessage.login.newPasswordValidation);
                        }
                    }
                    else{
                        toastr.error(toastrMessage.login.passwordValidation);
                    }
                }
                else{
                    toastr.error(toastrMessage.common.mandatoryValidation);
                }
            }
            else{
                toastr.error(toastrMessage.login.linkExpired);
            }
        }
        /*success callback for rest password*/
        function resetPasswordSuccess(apiResponse) {
            $rootScope.dataLoading = false;
        	if(apiResponse.statusCode == 200){
        		toastr.success(toastrMessage.login.passwordResetSuccess);
                $timeout(function(){
                    $state.go('login');
                },2000);
        	}
        	else if(apiResponse.statusCode == 0){
        		toastr.error(apiResponse.statusMessage);
        	} 
            else{
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }        	
        }
        /*error callback for rest password*/
        function resetPasswordFailure(error) {
            $rootScope.dataLoading = false;
        	toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*filter to remove security question*/
        $scope.optionsFilter = function(option) {
		    if (option.id == (resetPasswordVM.questionTwo && resetPasswordVM.questionTwo)) {
		        return false;
		     }
		    return true;
		}
        /*filter to remove security question*/
		$scope.optionsFilter2 = function(option) {
		    if (option.id == (resetPasswordVM.questionOne && resetPasswordVM.questionOne)) {
		        return false;
		     }
		    return true;
		}
    }
})();
