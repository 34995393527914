
/*jshint browser: true*/
/*global angular: true*/


(function(){
    'use strict';

    angular
        .module('scFuelsApp.login')
        .controller('registrationController', registrationController);


    registrationController.$inject = ['$scope','$state', 'loginService', 'toastr', '$rootScope', '$stateParams', '$timeout','toastrMessage','$mdDialog','$confirm'];

    function registrationController($scope, $state, loginService , toastr, $rootScope, $stateParams, $timeout,toastrMessage,$mdDialog,$confirm){
        var registrationVM = this;
        registrationVM.submitRegistration = submitRegistration;
        registrationVM.validatePassword = validatePassword;
        registrationVM.termsOfUse = termsOfUse;
        registrationVM.checked = false;

        init();

        /*function declarations*/

        /*initial loading*/
        function init() {
            if($stateParams.Id){
                $rootScope.dataLoading = true;
                registrationVM.token =  $stateParams.Id;
                loginService.validateToken(registrationVM.token)
                .then(validateTokenSuccess, validateTokenFailure);
            }
            else{
                $state.go('login');
                toastr.error(toastrMessage.login.invalidUrl);
            }
        }
        /*success callback for token validation*/
        function validateTokenSuccess(apiResponse) {
            if(apiResponse.statusCode === 200){
                getSecurityQuestionList();
                registrationVM.email = apiResponse.dataModel.email;
                registrationVM.tempPassword = null;
            }else{
                toastr.error(toastrMessage.login.linkExpired);
                registrationVM.email = null;
                registrationVM.tempPassword = null;
                $rootScope.dataLoading = false;
            }
        }
        /*error callback for token validation*/
        function validateTokenFailure(error) {
            $state.go('login');
            toastr.error(toastrMessage.login.linkExpired);
        }
        /*fetches security questions*/
        function getSecurityQuestionList() {
        	loginService.getSecurityQuestionList()
            .then(getSecurityQuestionListSuccess, getSecurityQuestionListFailure);
        }
        /*success callback for security question*/
        function getSecurityQuestionListSuccess(apiResponse) {
            $rootScope.dataLoading = false;
        	if(apiResponse.statusCode === 200){
                registrationVM.securityQuestions = apiResponse.dataModel.questions;
                registrationVM.questionOne = apiResponse.dataModel.userQuestions[0].id;
                registrationVM.questionTwo = apiResponse.dataModel.userQuestions[1].id;
	        }
	        else{
	        	toastr.error(toastrMessage.common.systemNetworkFailure);
	        }
        }
        /*error callback for security question*/
        function getSecurityQuestionListFailure(error) {
            $rootScope.dataLoading = false;
        	toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*checks for password validation*/
        function validatePassword() {
            var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,20}$/
            if(registrationVM.newPassword && !regex.test(registrationVM.newPassword)){
                toastr.error(toastrMessage.login.passwordValidation);
            }
        }
        /*clicks on register button*/
        function submitRegistration() {
            var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,20}$/
        	if(registrationVM.email && registrationVM.tempPassword && registrationVM.newPassword && registrationVM.confirmPassword &&
        	   registrationVM.questionOne && registrationVM.answerOne && registrationVM.questionTwo && registrationVM.answerTwo){
        		if(regex.test(registrationVM.newPassword)){
                    if(registrationVM.newPassword === registrationVM.confirmPassword){
                        $rootScope.dataLoading = true;
                        var params = {
                            "emailId": registrationVM.email,
                            "password": btoa(registrationVM.tempPassword),
                            "newPassword": btoa(registrationVM.newPassword),
                            "question1": registrationVM.questionOne,
                            "answer1": registrationVM.answerOne,
                            "question2": registrationVM.questionTwo,
                            "answer2": registrationVM.answerTwo
                        }
                        loginService.registerUser(params)
                        .then(userRegistrationSuccess, userRegistrationFailure);
                    }
                    else{
                        toastr.error(toastrMessage.login.newPasswordValidation);
                    }
                }
                else{
                    toastr.error(toastrMessage.login.passwordValidation);
                }

        	}
        	else{
        		toastr.error(toastrMessage.common.mandatoryValidation);
        	}
        }
        /*success callback for registration*/
        function userRegistrationSuccess(apiResponse) {
            $rootScope.dataLoading = false;
        	if(apiResponse.statusCode == 200){            
                $timeout(function(){
                    $rootScope.unauthorized = false;
                    localStorage.loggedInToken = apiResponse.dataModel.token;
                    localStorage.userID = parseInt(apiResponse.dataModel.userId,10);
                    localStorage.loggedInUser = apiResponse.dataModel.name;
                    localStorage.loggedInUserOrg = apiResponse.dataModel.orgName;
                    localStorage.permissions = JSON.stringify(apiResponse.dataModel.permissions);
                    localStorage.orgID = apiResponse.dataModel.orgId? parseInt(apiResponse.dataModel.orgId,10) : 0;
                    // localStorage.accountsInfo = JSON.stringify(apiResponse.dataModel.permissions);
                    localStorage.userType = apiResponse.dataModel.userType;
                    if(apiResponse.dataModel.userType!== undefined && (apiResponse.dataModel.userType === 'ItSupport' || apiResponse.dataModel.userType === 'Marketting' || apiResponse.dataModel.userType === 'CreditService')){
                        localStorage.hasEntertpisePermission = true;
                    }else{
                        localStorage.hasEntertpisePermission = false;
                    }
                    if(JSON.parse(localStorage.hasEntertpisePermission)){
                        $state.go('root.marketingInsight');
                    }else{
                        $state.go('root.dashboard');
                    }
                },2000);        		
        	}
        	else{
        		toastr.error(toastrMessage.common.systemNetworkFailure);
        	}        	
        }
        /*error callback for registration*/
        function userRegistrationFailure(error) {
            $rootScope.dataLoading = false;
        	toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*filter to remove security question*/
        $scope.optionsFilter = function(option) {
		    if (option.id == (registrationVM.questionTwo && registrationVM.questionTwo)) {
		        return false;
		     }
		    return true;
		}
        /*filter to remove security question*/
		$scope.optionsFilter2 = function(option) {
		    if (option.id == (registrationVM.questionOne && registrationVM.questionOne)) {
		        return false;
		     }
		    return true;
		}

  //        $scope.showAlert = function(ev) {
  //    $mdDialog.show(
  //     $mdDialog.alert()
  //       .parent(angular.element(document.querySelector('#popupContainer')))
  //       .clickOutsideToClose(true)
  //       .title('Terms Of Use')
  //       .textContent('If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. ')
  //       .ariaLabel('Alert Dialog Demo')
  //       .ok('I Agree')
  //       .targetEvent(ev)
  //   );
  // };
        function termsOfUse(){
            // $confirm({
            //     title: 'Terms Of Use',
            //     text: 'By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.The materials on this web site are provided "as is". SC Fuels makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, SC Fuels does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.SC Fuels may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.',
            //     ok: 'Agree',
            //     cancel: 'Disagree'
            // }, {
            //     size: 'lg',
            //     backdrop: true
            // }).then(function() {
            //     registrationVM.checked = true;
            // },function() {
            //     registrationVM.checked = false;
            // });
            var mylink = document.getElementById("termsLink");
                mylink.setAttribute("href", "/resources/document/CustPortal_TermsofUse_20171120.docx");
                mylink.click();
        }


    }
})();
