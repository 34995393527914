/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.invoicePayments')
        .controller('paymentController', paymentController);


    paymentController.$inject = ['$scope', '$state', 'toastr', '$window', 'invoicePaymentsService', 'toastrMessage', 'utils', '$rootScope', '$filter', 'dataStoreService', '$stateParams', '$uibModal','$confirm'];

    function paymentController($scope, $state, toastr, $window, invoicePaymentsService, toastrMessage, utils, $rootScope, $filter, dataStoreService, $stateParams, $uibModal, $confirm) {
        var paymentVM = this;
        paymentVM.amountDueType = '';
        paymentVM.amountDueDateType = '';
        paymentVM.invoiceCategoryType = '';
        paymentVM.firstDueAmount = '';
        paymentVM.secondDueAmount = '';
        paymentVM.startNumber = '';
        paymentVM.endNumber = '';
        paymentVM.maxPage = 2;
        paymentVM.searchInvoiceNo = '';
        paymentVM.serviceTypeId = [1];
        paymentVM.showInvoiceSearchButton = true;
        paymentVM.showAdvanceSearch = false;
        paymentVM.showEnquiry = false;
        paymentVM.removeGrouping = false;
        paymentVM.showColumnPopup = false;
        paymentVM.showInquiryPopup = false;
        paymentVM.selectAll = false;
        paymentVM.totalToPay = 0;
        paymentVM.showColumnPopup = false;
        paymentVM.showDownloadPopup = [];
        paymentVM.loggedInUser = localStorage.userType;
        paymentVM.startYear = utils.calenderStartYear;
        paymentVM.endYear = utils.calenderEndYear;
        paymentVM.datePicker = { startDate: null, endDate: null };
        paymentVM.init = init;
        paymentVM.getAllInvoices = getAllInvoices;
        paymentVM.toggleSearch = toggleSearch;
        paymentVM.invoiceSearch = invoiceSearch;
        paymentVM.searchPayments = searchPayments;
        paymentVM.checkAllPayment = checkAllPayment;
        paymentVM.paginationClick = paginationClick;
        paymentVM.calculateAmount = calculateAmount;
        paymentVM.print = print;
        paymentVM.makePayments = makePayments;
        paymentVM.exportTo = exportTo;
        paymentVM.toggleHeader = toggleHeader;
        paymentVM.arrangeTableData = arrangeTableData;
        paymentVM.orderByColumn = orderByColumn;
        paymentVM.download = download;
        paymentVM.downloadAttachment = downloadAttachment;
        paymentVM.groupingRemoval = groupingRemoval;
        paymentVM.clearCalender = clearCalender;
        paymentVM.closePopUp = closePopUp;
        paymentVM.selectServiceType = selectServiceType;
        paymentVM.invoiceListLength = 0;
        paymentVM.checkedInvoicesToPay = checkedInvoicesToPay;
        paymentVM.checkedInvoices = [];
        paymentVM.paymentType = 1;
        paymentVM.memoPopup = memoPopup;
      //  paymentVM.isPopUp = [];
        paymentVM.customMemo = "";
        paymentVM.checkedInvoicesToExport = [];
        // paymentVM.saveMemo = saveMemo;
        paymentVM.cancelMemo = cancelMemo;
        paymentVM.indexMemo = 0 ;
        paymentVM.memoLength = 2000;
       // paymentVM.isMemo = [];
        paymentVM.selectedInvoices = 2;
        paymentVM.selectAllInvoices = selectAllInvoices ;
        paymentVM.item = [];


        /*  function declarations */

        /*initial loading*/
        function init() {
            paymentVM.paymentType = 1;
            paymentVM.currentPage = 1;
            paymentVM.firstPageItem = 1;
            paymentVM.secondPageItem = 2;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item) {
                    if (item.option.toLowerCase().indexOf('payment') !== -1) {
                        paymentVM.userPermission = item.displayName;
                    }
                })
            }
            if ($stateParams.session) {
                var tempObj = {};
                var data = decodeURIComponent($stateParams.session).split(" ").join('+');
                var key = CryptoJS.enc.Base64.parse("dsST7wt+T9Kq2M7FaFc+R4Ha1IDM8M8bjRItxnwKrNQ=");
                var iv = CryptoJS.enc.Utf8.parse("X5xwzs2dEGd5DOFX");

                var sessionData = CryptoJS.AES.decrypt(data, key, {
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    keySize: 256,
                    padding: CryptoJS.pad.Pkcs7
                }).toString(CryptoJS.enc.Utf8);

                var tempArr = sessionData.split('&');
                tempArr.forEach(function(item) {
                    if (item) {
                        var tempArr = item.split('=');
                        tempObj[tempArr[0]] = tempArr[1];
                    }
                });
                $rootScope.modalInstance = $uibModal.open({
                    templateUrl: 'myModalContent.html',
                    controller: 'paymentController',
                    controllerAs: 'paymentVM',
                    size: 'sm',
                    backdrop: true,
                    appendTo: $('.right_col')
                });
            }
            getSearchAreaDetails();
        }
        /*closes th pop up*/
        function closePopUp() {
            $rootScope.modalInstance.close();
        }
        /*when the account changed*/
        $scope.$on('accountChanged', function() {
            var hasPermission = false;
            // dataStoreService.setpaymentData(paymentVM.paymentPayload);
            localStorage.selectedPaymentDetails = JSON.stringify(paymentVM.paymentPayload);
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item, key) {
                    if (item.option.toLowerCase().indexOf('payment') !== -1) {
                        if (item.displayName == 'R' || item.displayName == 'W') {
                            hasPermission = true;
                            init();
                        }
                    }
                    if ((key == 3) && !hasPermission) {
                        $state.go('root.dashboard');
                    }
                })
            }
        });

        /*fetches the search area details*/
        function getSearchAreaDetails() {
            $rootScope.dataLoading = true;
            invoicePaymentsService.getSearchCriteria()
                .then(getSearchAreaDetailsSuccess, getSearchAreaDetailsError);
        }
        /*success callback fro search area details*/
        function getSearchAreaDetailsSuccess(apiResponse) {
            paymentVM.searchArea = apiResponse;
            angular.forEach(paymentVM.searchArea.serviceTypes, function(item) {
                item.isChecked = true;
                if (item.id === 2) {
                    item.tooltip = toastrMessage.tootipMessage.bulkWholesale;
                } else if (item.id === 3) {
                    item.tooltip = toastrMessage.tootipMessage.onSiteFuels;
                } else if (item.id === 4) {
                    item.tooltip = toastrMessage.tootipMessage.lubricants;
                } else if (item.id === 5) {
                    item.tooltip = toastrMessage.tootipMessage.fleetCards;
                }
            });
            paymentVM.amountDueType = paymentVM.searchArea.amountDueTypes[0].id;
            paymentVM.amountDueDateType = paymentVM.searchArea.amountDueDateTypes[0].id;
            paymentVM.invoiceCategoryType = paymentVM.searchArea.invoiceCategoryTypes[0].id;
            getColumnHeaders();
        }
        /*error callback fro search area details*/
        function getSearchAreaDetailsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches the column headers for the table*/
        function getColumnHeaders() {
            $rootScope.dataLoading = true;
            invoicePaymentsService.getPaymentColumnHeaders()
                .then(getPaymentColumnHeadersSuccess, getPaymentColumnHeadersError);
        }
        /*success callback for column header*/
        function getPaymentColumnHeadersSuccess(apiResponse) {
            if (localStorage.makePaymentColumns) {
                paymentVM.columnHeaders = JSON.parse(localStorage.makePaymentColumns);
            } else {
                paymentVM.columnHeaders = apiResponse.columnHeaders;
                angular.forEach(paymentVM.columnHeaders, function(item) {
                    item.showColumn = item.showColumn;
                    if (item.strHeader === 'Due Date') {
                        item.showIcon = 'ic-filter';
                    } else {
                        item.showIcon = 'ic-filterNot';
                    }
                });
            }
            paymentVM.columnHeaderPopup = angular.copy(paymentVM.columnHeaders);
            localStorage.makePaymentColumns = JSON.stringify(paymentVM.columnHeaders);
            getPaymentDetails('AdvanceSearch');
        }
        /*error callback for column header*/
        function getPaymentColumnHeadersError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches all invoices for smart search*/
        function getAllInvoices(searchKey) {
            $rootScope.dataLoading = true;
            return invoicePaymentsService.getInvoices(searchKey)
                .then(getInvoicesSuccess, getInvoicesError);
        }
        /*success callback for invoice smart search*/
        function getInvoicesSuccess(apiResponse) {
            var results = [];
            angular.forEach(apiResponse.invoiceNumbers, function(item) {
                var invoices = item;
                results.push({ name: invoices });
            });
            $rootScope.dataLoading = false;
            paymentVM.invoiceListLength = results.length;
            return results;
        }
        /*error callback for invoice smart search*/
        function getInvoicesError(error) {
            $rootScope.dataLoading = false;
            if (error === 404) {} else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*toggles the advance search*/
        function toggleSearch() {
            paymentVM.showAdvanceSearch = !paymentVM.showAdvanceSearch;
            paymentVM.searchInvoiceNo = '';
            if (paymentVM.showAdvanceSearch) {
                paymentVM.showInvoiceSearchButton = false;
            } else {
                paymentVM.showInvoiceSearchButton = true;
            }
        }
        /*clicks on the invoice search button*/
        function invoiceSearch() {
            if (paymentVM.searchInvoiceNo && (paymentVM.searchInvoiceNo.length < 3)) {
                toastr.warning(toastrMessage.userManagement.searchCondition);
            } else {
                paymentVM.paymentType = 1;
                paymentVM.currentPage = 1;
                paymentVM.firstPageItem = 1;
                paymentVM.secondPageItem = 2;
                // dataStoreService.setpaymentData({});
                localStorage.selectedPaymentDetails = JSON.stringify({});
                paymentVM.checkedInvoices = [];
                getPaymentDetails('InvoiceSearch');
            }
        }
        /*clicks on the search button in the advance search*/
        function searchPayments() {
            paymentVM.currentPage = 1;
            paymentVM.firstPageItem = 1;
            paymentVM.secondPageItem = 2;
            paymentVM.serviceTypes = [];
            var amountDueType = '';
            var categoryType = '';
            angular.forEach(paymentVM.searchArea.serviceTypes, function(item) {
                if (item.isChecked) {
                    paymentVM.serviceTypes.push(item.id);
                }
            });
            angular.forEach(paymentVM.searchArea.amountDueTypes, function(item) {
                if (paymentVM.amountDueType === item.id) {
                    amountDueType = item.type;
                }
            });
            angular.forEach(paymentVM.searchArea.invoiceCategoryTypes, function(item) {
                if (paymentVM.invoiceCategoryType === item.id) {
                    categoryType = item.type;
                }
            });
            if ((paymentVM.firstDueAmount && isNaN(paymentVM.firstDueAmount)) || (paymentVM.secondDueAmount && isNaN(paymentVM.secondDueAmount))) {
                toastr.error("Enter a valid " + amountDueType + '.');
            } else if (parseFloat(paymentVM.firstDueAmount) > parseFloat(paymentVM.secondDueAmount)) {
                toastr.error(amountDueType + " first amount should be less.");
            } else if ((paymentVM.startNumber && !utils.isValidAlphaNumeric(paymentVM.startNumber)) || (paymentVM.endNumber && !utils.isValidAlphaNumeric(paymentVM.endNumber))) {
                toastr.error("Enter a valid " + categoryType + '.');
            } else if (parseInt(paymentVM.startNumber, 10) > parseInt(paymentVM.endNumber, 10)) {
                toastr.error(categoryType + " starting number should be less.");
            } else if (paymentVM.searchInvoiceNo && (paymentVM.searchInvoiceNo.length < 3)) {
                toastr.warning(toastrMessage.userManagement.searchCondition);
            } else {
                //api call 
                // dataStoreService.setpaymentData({});
                localStorage.selectedPaymentDetails = JSON.stringify({});
                paymentVM.checkedInvoices = [];
                getPaymentDetails('AdvanceSearch');
            }
        }
        /*loads unpaid payments details*/
        function getPaymentDetails(callFrom) {
            // paymentVM.totalToPay = 0;
            // var paymentData = dataStoreService.getpaymentData();
            paymentVM.paymentType = 1;
            var paymentData = JSON.parse(localStorage.selectedPaymentDetails);
            var serviceTypes = [],
                payload = {},
                startDate = '',
                endDate = '';
            var sortByColumn = '';
            var orderByColumn = '';
            //To get sorting parameter and direction
            paymentVM.columnHeaders.forEach(function(item) {
                if (item.showIcon === 'ic-filter' || item.showIcon === 'ic-filterDesc') {
                    sortByColumn = item.strHeader;
                    orderByColumn = (item.showIcon === 'ic-filter') ? 'asc' : 'desc';
                }
            });
            if (paymentVM.searchArea !== undefined) {
                angular.forEach(paymentVM.searchArea.serviceTypes, function(item) {
                    if (item.isChecked) {
                        serviceTypes.push(item.id);
                    }
                });
                if (serviceTypes.length === 0) {
                    serviceTypes = paymentVM.serviceTypeId;
                }
            } else {
                serviceTypes = paymentVM.serviceTypeId;
            }

            if (callFrom === 'AdvanceSearch') {
                if (Object.keys(paymentData).length !== 0) {
                    //for binding service type,due date type id,due amount type,invoice category id
                    angular.forEach(paymentVM.searchArea.serviceTypes, function(item, key) {
                        if (paymentData.serviceTypeId.indexOf(item.id) > -1) {
                            item.isChecked = true;
                        }
                    });
                    paymentVM.datePicker = {};
                    paymentVM.datePicker.startDate = paymentData.startDate ? paymentData.startDate : null;
                    paymentVM.datePicker.endDate = paymentData.endDate ? paymentData.endDate : null;
                    paymentVM.firstDueAmount = paymentData.fromRange;
                    paymentVM.secondDueAmount = paymentData.toRange;
                    paymentVM.startNumber = paymentData.invoiceNumberFrom;
                    paymentVM.endNumber = paymentData.invoiceNumberTo;
                    paymentVM.searchInvoiceNo = paymentData.invoiceNumber;
                    paymentVM.currentPage = paymentData.pageNumber;
                    paymentVM.amountDueDateType = (paymentData.dueDateTypeId === "") ? paymentVM.searchArea.amountDueDateTypes[0].id : paymentData.dueDateTypeId;
                    paymentVM.amountDueType = (paymentData.dueAmountTypeId === "") ? paymentVM.searchArea.amountDueTypes[0].id : paymentData.dueAmountTypeId;
                    paymentVM.invoiceCategoryType = (paymentData.invoiceCategoryId === "") ? paymentVM.searchArea.invoiceCategoryTypes[0].id : paymentData.invoiceCategoryId;
                    sortByColumn = paymentData.sortingParameter;
                    orderByColumn = paymentData.sortingDirection;
                    paymentVM.removeGrouping = paymentData.removeGroup;
                    paymentVM.firstPageItem = paymentData.pageNumber;
                    paymentVM.secondPageItem = paymentData.pageNumber+1; 
                    paymentVM.paymentType = (paymentData.paymentType)?((paymentData.paymentType === 'CC')?1:2):2;     
                  }
                payload.serviceTypeId = serviceTypes;
                startDate = paymentVM.datePicker.startDate === null ? "" : $filter('date')(new Date(paymentVM.datePicker.startDate.toString()), 'MM/dd/yyyy');
                endDate = paymentVM.datePicker.endDate === null ? "" : $filter('date')(new Date(paymentVM.datePicker.endDate.toString()), 'MM/dd/yyyy');

                payload.dueDateTypeId = paymentVM.amountDueDateType === undefined ? '' : paymentVM.amountDueDateType;
                payload.startDate = startDate;
                payload.endDate = endDate;
                payload.dueAmountTypeId = paymentVM.amountDueType === undefined ? '' : paymentVM.amountDueType;
                payload.fromRange = paymentVM.firstDueAmount;
                payload.toRange = paymentVM.secondDueAmount;
                payload.invoiceCategoryId = paymentVM.invoiceCategoryType === undefined ? '' : paymentVM.invoiceCategoryType;
                payload.invoiceNumberFrom = paymentVM.startNumber;
                payload.invoiceNumberTo = paymentVM.endNumber;
                payload.invoiceNumber = paymentVM.searchInvoiceNo.name === undefined ? paymentVM.searchInvoiceNo : paymentVM.searchInvoiceNo.name;
                payload.pageNumber = paymentVM.currentPage;
                payload.sortingParameter = sortByColumn;
                payload.sortingDirection = orderByColumn;
            } else if (callFrom === 'InvoiceSearch') {
                payload.serviceTypeId = serviceTypes;
                payload.dueDateTypeId = "";
                payload.startDate = "";
                payload.endDate = "";
                payload.dueAmountTypeId = "";
                payload.fromRange = "";
                payload.toRange = "";
                payload.invoiceCategoryId = "";
                payload.invoiceNumberFrom = "";
                payload.invoiceNumberTo = "";
                payload.invoiceNumber = paymentVM.searchInvoiceNo.name === undefined ? paymentVM.searchInvoiceNo : paymentVM.searchInvoiceNo.name;
                payload.pageNumber = 1;
                payload.sortingParameter = sortByColumn;
                payload.sortingDirection = orderByColumn;
            }

            payload.removeGroup = paymentVM.removeGrouping;
            payload.pageRows = 10;
            payload.accountId = localStorage.selectedAccount;
            payload.operation = "btnPaymentSearch";
            paymentVM.paymentPayload = payload;
            $rootScope.dataLoading = true;
            invoicePaymentsService.getPaymentList(payload)
                .then(getPaymentListSuccess, getPaymentListError);
        }
        /*success callback for unpaid payment*/
        function getPaymentListSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            paymentVM.selectAll = false;
            paymentVM.searchCount = 0;
            paymentVM.paymentList = apiResponse;
            if(localStorage.getItem('paymentMode')){
                paymentVM.selectedInvoices = localStorage.getItem('paymentMode');
            }else{
                localStorage.setItem('paymentMode',JSON.stringify(paymentVM.selectedInvoices));
            }
            if (paymentVM.paymentList.accountDetails) {

                if (paymentVM.removeGrouping) {
                    angular.forEach(paymentVM.paymentList.accountDetails,function(accItem){
                            if(accItem.invoices[0].memo != null && accItem.invoices[0].memo != ""){
                                accItem.isMemo = true;
                            }else{
                                accItem.isMemo = false;
                            }
                    }); 
                    angular.forEach(paymentVM.paymentList.accountDetails, function(item) {
                        item.isChecked = false;
                        item.customMemo = "";
                        // item.isMemo = false;
                    });

                    paymentVM.paymentList.accountDetails.forEach(function(dataItem) {
                        var a = new Date();
                        a.setHours(0);
                        a.setMinutes(0);
                        a.setSeconds(0);
                        var dateDiff = (new Date(a).getTime() - new Date(dataItem.dueDate).getTime()) / (1000 * 60 * 60 * 24);
                        dataItem.pastDue = parseInt(dateDiff, 10);
                    });
                    paymentVM.searchCount = paymentVM.paymentList.totalCount;
                    paymentVM.maxPage = Math.ceil(paymentVM.paymentList.totalCount / 10);
                    // var paymentData = dataStoreService.getpaymentData();
                    if(paymentVM.maxPage == paymentVM.currentPage){
                        paymentVM.firstPageItem = paymentVM.maxPage-1;
                        paymentVM.secondPageItem = paymentVM.maxPage; 
                    }
                     paymentVM.selectedInvoices = JSON.parse(localStorage.getItem('paymentMode'));
                    if(paymentVM.selectedInvoices ==1){
                    angular.forEach(paymentVM.paymentList.accountDetails, function(accItem) {
                                accItem.isChecked = true;
                                paymentVM.selectAll = true;
                            });
                }else{


                    var paymentData = JSON.parse(localStorage.selectedPaymentDetails);
                    paymentVM.checkedInvoices = (Object.keys(paymentData).length !== 0 && paymentData.invoiceList)?paymentData.invoiceList:[];
                    if (paymentVM.checkedInvoices) {
                        angular.forEach(paymentVM.paymentList.accountDetails, function(dataItem) {
                            angular.forEach(dataItem.invoices, function(invoiceItem) {
                                angular.forEach(paymentVM.checkedInvoices, function(oldItem) {

                                    if ((oldItem.number === invoiceItem.number) && (oldItem.balanceDue === dataItem.payingAmount)) {
                                        dataItem.isChecked = true;
                                    }
                                });
                            });
                        });
                        checkedInvoicesToPay();
                     }
                        // selectAllInvoices(paymentVM.selectedInvoices);

                    }
                } else {
               
                    angular.forEach(paymentVM.paymentList.accountDetails,function(accItem){
                        angular.forEach(accItem.accountData,function(item){
                            if(item.invoices[0].memo != null && item.invoices[0].memo != ""){
                                 item.isMemo = true;
                             }else{
                                 item.isMemo = false;
                            }
                        }); 
                    });
                    angular.forEach(paymentVM.paymentList.accountDetails, function(accItem) {
                        angular.forEach(accItem.accountData, function(item) {
                            item.isChecked = false;
                            item.customMemo = "";
                            // item.isMemo = false;
                        });
                    });
                    paymentVM.paymentList.accountDetails.forEach(function(item) {
                        var balanceDueAmount = 0;
                        item.accountData.forEach(function(dataItem) {
                            var a = new Date();
                            a.setHours(0);
                            a.setMinutes(0);
                            a.setSeconds(0);
                            var dateDiff = (new Date(a).getTime() - new Date(dataItem.dueDate).getTime()) / (1000 * 60 * 60 * 24);
                            dataItem.pastDue = parseInt(dateDiff, 10);
                            balanceDueAmount += parseFloat(dataItem.balanceDueAmount);
                        });
                        item.accountBalanceDue = balanceDueAmount;
                    });
                    paymentVM.searchCount = paymentVM.paymentList.totalCount;
                    paymentVM.maxPage = Math.ceil(paymentVM.paymentList.totalCount / 10);

                    if(paymentVM.maxPage == paymentVM.currentPage){
                        paymentVM.firstPageItem = paymentVM.maxPage-1;
                        paymentVM.secondPageItem = paymentVM.maxPage; 
                    }
                    // var paymentData = dataStoreService.getpaymentData();
                    paymentVM.selectedInvoices = JSON.parse(localStorage.getItem('paymentMode'));
                    if(paymentVM.selectedInvoices ==1){
                    angular.forEach(paymentVM.paymentList.accountDetails, function(accItem) {
                            angular.forEach(accItem.accountData, function(dataItem) {
                                dataItem.isChecked = true;
                                paymentVM.selectAll = true;
                            });
                        });
                    }else{
                    var paymentData = JSON.parse(localStorage.selectedPaymentDetails);
                    paymentVM.checkedInvoices = (Object.keys(paymentData).length !== 0 && paymentData.invoiceList)?paymentData.invoiceList:[];
                    if (paymentVM.checkedInvoices) {
                        angular.forEach(paymentVM.paymentList.accountDetails, function(accItem) {
                            angular.forEach(accItem.accountData, function(dataItem) {
                                angular.forEach(dataItem.invoices, function(invoiceItem) {
                                    angular.forEach(paymentVM.checkedInvoices, function(oldItem) {
                                        if ((oldItem.number === invoiceItem.number) && (oldItem.balanceDue === dataItem.payingAmount)) {
                                            dataItem.isChecked = true;
                                        }
                                    });
                                });
                            });
                        });
                       checkedInvoicesToPay();
           // selectAllInvoices(paymentVM.selectedInvoices);
                }
            }
                
            }
        }
            // dataStoreService.setpaymentData({});
            localStorage.selectedPaymentDetails = JSON.stringify({});  
            
    }
        /*error callback for unpaid payment*/
    function getPaymentListError() {
        $rootScope.dataLoading = false;
        toastr.error(toastrMessage.common.systemNetworkFailure);
    }
        /*pagination*/
    function paginationClick(button) {
            
            var searchRequired = false;
            $window.scrollTo(0, 0);
            switch (button) {
                case 'first':
                    if (paymentVM.firstPageItem > 1) {
                        paymentVM.firstPageItem = 1;
                        paymentVM.secondPageItem = 2;
                        paymentVM.currentPage = 1;
                        searchRequired = true;
                    }
                    break;
                case 'previous':
                    if (paymentVM.firstPageItem > 1) {
                        paymentVM.firstPageItem--;
                        paymentVM.secondPageItem--;
                        paymentVM.currentPage--;
                        searchRequired = true;
                    } else if (paymentVM.firstPageItem < paymentVM.currentPage) {
                        paymentVM.currentPage--;
                        searchRequired = true;
                    }
                    break;
                case 'next':
                    if (paymentVM.secondPageItem < paymentVM.maxPage) {
                        paymentVM.firstPageItem++;
                        paymentVM.secondPageItem++;
                        paymentVM.currentPage++;
                        searchRequired = true;
                    } else if (paymentVM.currentPage < paymentVM.secondPageItem) {
                        paymentVM.currentPage++;
                        searchRequired = true;
                    }
                    break;
                case 'last':
                    if (paymentVM.secondPageItem < paymentVM.maxPage) {
                        paymentVM.firstPageItem = paymentVM.maxPage - 1;
                        paymentVM.secondPageItem = paymentVM.maxPage;
                        paymentVM.currentPage = paymentVM.maxPage;
                        searchRequired = true;
                    }
                    break;
                default:
                    paymentVM.currentPage = button;
                    searchRequired = true;
                    break;
            }

                angular.forEach(paymentVM.paymentList.accountDetails,function(accData){
                angular.forEach(accData.accountData,function(item){
                item.isPopUp = false;
                // item.isMemo = false;
            });
         }); 
            if (searchRequired) {
                getPaymentDetails('AdvanceSearch');
            }
            //selectAllInvoices( paymentVM.selectedInvoices)
            

        }
        /*clicks on the checkbox in the column header*/
        function checkAllPayment(isChecked) {
            paymentVM.selectedInvoices = 2;
            if (paymentVM.removeGrouping) {
                if (isChecked) {
                    angular.forEach(paymentVM.paymentList.accountDetails, function(item) {
                        item.isChecked = true;
                    });
                } else {
                    angular.forEach(paymentVM.paymentList.accountDetails, function(item) {
                        item.isChecked = false;
                    });
                }
            } else {
                if (isChecked) {
                    angular.forEach(paymentVM.paymentList.accountDetails, function(accItem) {
                        angular.forEach(accItem.accountData, function(item) {
                            item.isChecked = true;
                        });
                    });
                } else {
                    angular.forEach(paymentVM.paymentList.accountDetails, function(accItem) {
                        angular.forEach(accItem.accountData, function(item) {
                            item.isChecked = false;
                        });
                    });
                }
            }
            checkedInvoicesToPay();
        }

        function checkedInvoicesToPay() {
            var allSelected = true;
         if (paymentVM.removeGrouping) {
                paymentVM.checkedInvoicesToExport = [] ;
                angular.forEach(paymentVM.paymentList.accountDetails, function(item) {
                    if (item.isChecked) {
                        var listObj = {};
                        listObj.accountNumber = item.accountNumber;
                        listObj.number = item.invoices[0].number;
                        listObj.balanceDue = item.payingAmount;
                        listObj.isCheckedInvoice = true ;
                        angular.forEach(paymentVM.checkedInvoices, function(invoice, key) {
                            if ((item.accountNumber === invoice.accountNumber) && (item.invoices[0].number === invoice.number)) {
                                paymentVM.checkedInvoices.splice(key, 1);
                            }
                        });
                          angular.forEach(paymentVM.checkedInvoices,function(item){
                            paymentVM.checkedInvoicesToExport.push(item.number);
                          });
                        paymentVM.checkedInvoices.push(listObj);
                    } else {
                        allSelected = false;
                        paymentVM.selectAll = false;
                        paymentVM.selectedInvoices=2;
                        localStorage.setItem('paymentMode',JSON.stringify(paymentVM.selectedInvoices));
                        angular.forEach(paymentVM.checkedInvoices, function(invoice, key) {
                            if ((item.accountNumber === invoice.accountNumber) && (item.invoices[0].number === invoice.number)) {
                                paymentVM.checkedInvoices.splice(key, 1);
                            }
                        });
                    }
                });
            } else {
                paymentVM.checkedInvoicesToExport = [] ;
                angular.forEach(paymentVM.paymentList.accountDetails, function(accItem) {
                    angular.forEach(accItem.accountData, function(item) {
                        if (item.isChecked) {
                            var listObj = {};
                            listObj.accountNumber = accItem.accountNumber;
                            listObj.number = item.invoices[0].number;
                            listObj.balanceDue = item.payingAmount;
                             listObj.isCheckedInvoice = true ;
                            angular.forEach(paymentVM.checkedInvoices, function(invoice, key) {
                                if ((accItem.accountNumber === invoice.accountNumber) && (item.invoices[0].number === invoice.number)) {
                                    paymentVM.checkedInvoices.splice(key, 1);                                }
                            });
                           angular.forEach(paymentVM.checkedInvoices,function(item){
                            paymentVM.checkedInvoicesToExport.push(item.number);
                          });

                            paymentVM.checkedInvoices.push(listObj);
                        } else {
                            allSelected = false;
                            paymentVM.selectAll = false;
                            paymentVM.selectedInvoices=2;
                            localStorage.setItem('paymentMode',JSON.stringify(paymentVM.selectedInvoices));
                            angular.forEach(paymentVM.checkedInvoices, function(invoice, key) {
                                if ((accItem.accountNumber === invoice.accountNumber) && (item.invoices[0].number === invoice.number)) {
                                    paymentVM.checkedInvoices.splice(key, 1);
                                }
                            });
                           angular.forEach(paymentVM.checkedInvoices,function(item){
                            paymentVM.checkedInvoicesToExport.push(item.number);
                          });
                        }
                    });

                });
            }
            
              // if(!paymentVM.selectAll){
              //   paymentVM.selectedInvoices = 2;
              // }
              // paymentVM.item = JSON.parse(localStorage.getItem('invoices'));
              // if(paymentVM.item){
              //    var temp = paymentVM.item;
              // }else{
              //    var temp = [];
              // }
              // if (paymentVM.checkedInvoices && paymentVM.checkedInvoices.length > 0) {
              //       for (var i=0; i < paymentVM.checkedInvoices.length; i++) {
              //           let tempObject = temp.filter((element) =>{
              //             return element.number == paymentVM.checkedInvoices[i].number;
              //           });
              //           if (!(tempObject && tempObject.length > 0)) {
              //               temp.push(paymentVM.checkedInvoices[i]);
              //           }
              //       }
              //   }
              //       localStorage.setItem('invoices',JSON.stringify(temp));

            paymentVM.selectAll = allSelected ? true : false;
            calculateAmount();
        }

        /*calculates the total amount for selected invoices*/
        function calculateAmount() {
            paymentVM.totalToPay = 0;
            angular.forEach(paymentVM.checkedInvoices, function(item) {
                if(item.balanceDue !=null && item.balanceDue != ""){
                    paymentVM.totalToPay = paymentVM.totalToPay + parseFloat(item.balanceDue);
                }else{
                    item.balanceDue = 0;
                   paymentVM.totalToPay = paymentVM.totalToPay + parseFloat(item.balanceDue); 
                }
                
            });
        }
        /*clicks on make payment button*/
        function makePayments() {
               // invoicePaymentsService.setIsAllInvoice(paymentVM.selectedInvoices) ;
                if(paymentVM.selectedInvoices == 2){
                    var invoiceList = paymentVM.checkedInvoices;
                    if (invoiceList.length) {
                         paymentVM.paymentPayload.invoiceList = invoiceList;
                         paymentVM.paymentPayload.paymentType = (paymentVM.paymentType === 1)?'CC':'ACH';
                         localStorage.selectedPaymentDetails = JSON.stringify(paymentVM.paymentPayload);
                         $state.go("root.makePayments");
                  } else {
                         toastr.error("Select an invoice to pay.");
                  }

                }else{
                    var invoiceList = paymentVM.checkedInvoices;
                    paymentVM.paymentPayload.invoiceList = [];
                    paymentVM.paymentPayload.paymentType = (paymentVM.paymentType === 1)?'CC':'ACH';
                    localStorage.selectedPaymentDetails = JSON.stringify(paymentVM.paymentPayload);
                    $state.go("root.makePayments");
                }
           
        }
        /*clicks on export/print button*/
        function exportTo(fileType) {
            var columns = [];
            var invoiceExportList = paymentVM.checkedInvoices ;
                paymentVM.checkedInvoicesToExport = [];
                paymentVM.selectedInvoices = localStorage.getItem('paymentMode');
                if(paymentVM.selectedInvoices==1){
                    paymentVM.checkedInvoicesToExport =[];
                }else{
                    angular.forEach(invoiceExportList,function(item){
                    paymentVM.checkedInvoicesToExport.push(item.number);
                });
                }
               
                angular.forEach(paymentVM.columnHeaders, function(item) {
                if (item.showColumn) {
                    columns.push(item.strHeader);
                }
            });
            var payload = {
                "serviceTypeId": paymentVM.paymentPayload.serviceTypeId,
                "dueDateTypeId": paymentVM.paymentPayload.dueDateTypeId,
                "startDate": paymentVM.paymentPayload.startDate,
                "endDate": paymentVM.paymentPayload.endDate,
                "dueAmountTypeId": paymentVM.paymentPayload.dueAmountTypeId,
                "fromRange": paymentVM.paymentPayload.fromRange,
                "toRange": paymentVM.paymentPayload.toRange,
                "invoiceCategoryId": paymentVM.paymentPayload.invoiceCategoryId,
                "invoiceNumberFrom": paymentVM.paymentPayload.invoiceNumberFrom,
                "invoiceNumberTo": paymentVM.paymentPayload.invoiceNumberTo,
                "listInvoiceNumber":paymentVM.checkedInvoicesToExport,
                "invoiceNumber": paymentVM.searchInvoiceNo.name === undefined ? paymentVM.searchInvoiceNo : paymentVM.searchInvoiceNo.name,
                "columnHeaders": columns,
                "sortingParameter": paymentVM.paymentPayload.sortingParameter,
                "sortingDirection": paymentVM.paymentPayload.sortingDirection,
                "removeGroup": paymentVM.paymentPayload.removeGroup,
                "operation": (fileType === 'xls') ? "btnPaymentExport" : "btnPaymentPrint"
            };
            $rootScope.$emit('exportPaymentEvent');
            if (fileType === 'xls') {
                $rootScope.dataLoading = true;
                invoicePaymentsService.exportPayments(payload)
                    .then(exportPaymentsSuccess, exportPaymentsError);
            }
        }
        /*success callback for export payment*/
        function exportPaymentsSuccess(apiResponse) {
            paymentVM.checkedInvoicesToExport = [];
            paymentVM.selectedInvoices=2;
            localStorage.setItem('paymentMode',JSON.stringify(paymentVM.selectedInvoices));
            $rootScope.dataLoading = false;
            $window.open(apiResponse.filePath);
        }
        /*error callback for export payment*/
        function exportPaymentsError() {
             paymentVM.selectedInvoices=2;
            localStorage.setItem('paymentMode',JSON.stringify(paymentVM.selectedInvoices));
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*toggles the column header pop up*/
        function toggleHeader() {
            paymentVM.showColumnPopup = !paymentVM.showColumnPopup;
        }
        /*clicks on save button on the column header pop up*/
        function arrangeTableData(popupData) {
            var selected = false;
            angular.forEach(popupData, function(item) {
                if (item.showColumn) {
                    selected = true;
                }
            });
            if (selected) {
                angular.forEach(popupData, function(item, key) {
                    if (item.showColumn) {
                        paymentVM.columnHeaders[key].showColumn = true;
                    } else {
                        paymentVM.columnHeaders[key].showColumn = false;
                    }
                });
                paymentVM.showColumnPopup = false;
                localStorage.makePaymentColumns = JSON.stringify(paymentVM.columnHeaders);

                 var columnMakePayment = [];
                 paymentVM.columnHeaders.forEach(function(item){
                 var listObj = {};
                 listObj.id = item.id;
                 listObj.strHeader = item.strHeader;
                 listObj.showColumn = item.showColumn;
                 columnMakePayment.push(listObj);
                });

                 var Payload = {
                "pageType":"MakePayment",
                "columnHeaders":columnMakePayment
                };
                $rootScope.dataLoading = true;
                invoicePaymentsService.saveColumnHeaders(Payload)
                .then(saveColumnHeadersSuccess, saveColumnHeadersError);
            } else {
                toastr.error("Please select at least one column.");
            }
        }

        function saveColumnHeadersSuccess(apiResponse){
             $rootScope.dataLoading = false;
           toastr.success('Saved Successfully.');

        }

        function saveColumnHeadersError(){
            $rootScope.dataLoading = false;
            toastr.error('Failed to save');
        }
        
        /*function to order the data by column header*/
        function orderByColumn(columnHeader, iconType) {
            angular.forEach(paymentVM.columnHeaders, function(item) {
                if (item.strHeader === columnHeader) {
                    if (iconType === 'ic-filterNot' || iconType === 'ic-filterDesc') {
                        item.showIcon = 'ic-filter';
                    } else {
                        item.showIcon = 'ic-filterDesc';
                    }
                } else {
                    item.showIcon = 'ic-filterNot';
                }
            });
            localStorage.makePaymentColumns = JSON.stringify(paymentVM.columnHeaders);
            getPaymentDetails('AdvanceSearch');
        }
        /*clicks on the attachment button*/
        function download(attachments, index) {
            paymentVM.showDownloadPopup = [];
            paymentVM.showDownloadPopup[index] = true;
            paymentVM.attachments = attachments;
        }
        /*opens the attachment*/
        function downloadAttachment(filePath) {
            paymentVM.showDownloadPopup = [];
            $window.open(filePath);
        }
        /*clicks on the remove grouping checkbox*/
        function groupingRemoval(isChecked) {
            angular.forEach(paymentVM.paymentList.accountDetails,function(accData){
                angular.forEach(accData.accountData,function(item){
                item.isPopUp = false;
                // item.isMemo = false;
            });
         }); 
            angular.forEach(paymentVM.columnHeaders, function(item) {
                if (item.strHeader === 'Due Date') {
                    item.showIcon = 'ic-filter';
                } else {
                    item.showIcon = 'ic-filterNot';
                }
            });
            getPaymentDetails('AdvanceSearch');
        }
        /*clears the date values from the calender*/
        function clearCalender() {
            paymentVM.datePicker = { startDate: null, endDate: null };
            $('#daterange').data('daterangepicker').setStartDate(new Date());
            $('#daterange').data('daterangepicker').setEndDate(new Date());
        }
        /*clears the search parameters from advance search*/
        function clearSearchParameters() {
            paymentVM.serviceTypeId = [1, 2, 3, 4, 5];
            paymentVM.amountDueType = '';
            paymentVM.amountDueDateType = '';
            paymentVM.invoiceCategoryType = '';
            paymentVM.firstDueAmount = '';
            paymentVM.secondDueAmount = '';
            paymentVM.startNumber = '';
            paymentVM.endNumber = '';
            paymentVM.searchInvoiceNo = '';
            paymentVM.totalToPay = 0;
            paymentVM.datePicker = { startDate: null, endDate: null };
        }

        $rootScope.$on('hidePopup', function() {
            paymentVM.showDownloadPopup = [];
            paymentVM.showColumnPopup = false;
            $scope.$apply();
        });

        $scope.$on('$stateChangeSuccess', onStateChangeStart);
        /*state change handler for stateChangeSuccess*/
        function onStateChangeStart(event, toState, toParams, fromState) {
            if (fromState.name !== 'root.makePayments' && toState.name !== 'root.makePayments') {
                clearSearchParameters();
                paymentVM.checkedInvoices = [];
                // dataStoreService.setpaymentData({});
                localStorage.selectedPaymentDetails = JSON.stringify({});
            }
        }

        /*Service Type Selection*/
        function selectServiceType(serviceType, isChecked) {
            var selectAll = true;
            if (serviceType === 1) {
                angular.forEach(paymentVM.searchArea.serviceTypes, function(item) {
                    item.isChecked = isChecked ? true : false;
                });
            } else {
                angular.forEach(paymentVM.searchArea.serviceTypes, function(item) {
                    if (serviceType === item.id) {
                        if (isChecked) {
                            item.isChecked = true;
                        }
                    }
                });
            }
            angular.forEach(paymentVM.searchArea.serviceTypes, function(item) {
                if (item.id > 1 && !item.isChecked) {
                    selectAll = false;
                    paymentVM.searchArea.serviceTypes[0].isChecked = false;
                }
            });
            paymentVM.searchArea.serviceTypes[0].isChecked = selectAll ? true : false;
        }
        function memoPopup(accountData,index){
            if(paymentVM.removeGrouping){
                paymentVM.indexMemo = index;
                angular.forEach(paymentVM.paymentList.accountDetails,function(accData){
                        if(accountData.invoices[0].number == accData.invoices[0].number){
                            accData.isPopUp = true;
                        }else{
                            accData.isPopUp = false;
                        }
                });
            }else{
                paymentVM.indexMemo = index;
                angular.forEach(paymentVM.paymentList.accountDetails,function(accData){
                    angular.forEach(accData.accountData,function(item){
                        if(accountData.invoices[0].number == item.invoices[0].number){
                            item.isPopUp = true;
                        }else{
                            item.isPopUp = false;
                        }
                
                    });
                });
            }
        }
        
    
        function cancelMemo(index){
            if(paymentVM.removeGrouping){
                 angular.forEach(paymentVM.paymentList.accountDetails,function(accData){
                accData.isPopUp = false;
            });
            }else{
                 angular.forEach(paymentVM.paymentList.accountDetails,function(accData){
                angular.forEach(accData.accountData,function(item){
                item.isPopUp = false;
            });
         });
            }
           
            // paymentVM.isPopUp = [];
            // var indexNumberCancel = paymentVM.indexMemo;
           // paymentVM.isMemo[indexNumberCancel] = false;
           
        }

        function selectAllInvoices(isAllSelected){
            paymentVM.totalToPay = 0;
             paymentVM.checkedInvoices = [];
            paymentVM.checkedInvoicesToExport = [];
            paymentVM.selectedInvoices = isAllSelected;
              if (paymentVM.removeGrouping) {
                if(isAllSelected == 1) {
                    angular.forEach(paymentVM.paymentList.accountDetails, function(item) {
                        item.isChecked = true;
                        paymentVM.selectAll = true;
                    });
                } else {
                    angular.forEach(paymentVM.paymentList.accountDetails, function(item) {
                        item.isChecked = false;
                        paymentVM.selectAll = false;
                    });
                }
            } else {
               if(isAllSelected == 1) {
                    angular.forEach(paymentVM.paymentList.accountDetails, function(accItem) {
                        angular.forEach(accItem.accountData, function(item) {
                            item.isChecked = true;
                            paymentVM.selectAll = true;
                        });
                    });
                } else {
                    angular.forEach(paymentVM.paymentList.accountDetails, function(accItem) {
                        angular.forEach(accItem.accountData, function(item) {
                            item.isChecked = false;
                             paymentVM.selectAll = false;
                        });
                    });
                }
            }
                localStorage.setItem('paymentMode',JSON.stringify(paymentVM.selectedInvoices));

         }

         $rootScope.$on('exportPaymentEvent', function(){
            paymentVM.selectAll = false;
            paymentVM.checkedInvoices = [];
            paymentVM.checkedInvoicesToExport = [];
            if(paymentVM.removeGrouping){
                angular.forEach(paymentVM.paymentList.accountDetails, function(accItem) {
                    accItem.isChecked = false;
                });

            }else{
                angular.forEach(paymentVM.paymentList.accountDetails, function(accItem) {
                angular.forEach(accItem.accountData, function(item) {
                    item.isChecked = false;
                });
            });
            }
            
        })
    }
})();
