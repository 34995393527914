/*jshint browser: true*/
/*global angular: true*/


(function () {
    'use strict';

    angular
        .module('scFuelsApp.cardManagement', [])
        .config(routerConfiguration);

    routerConfiguration.$inject = ['$stateProvider'];

    function routerConfiguration($stateProvider) {
        $stateProvider
            .state('root.fleetCard', {
                url: '/fleetCardManagement',
                templateUrl: 'app/modules/cardManagement/fleetCardManagement/fleetCardTemplate.html',
                controller: 'fleetCardController',
                controllerAs: 'fleetCardVM'
            })
            .state('root.newFleetCard', {
                url: '/newFleetCard',
                templateUrl: 'app/modules/cardManagement/fleetCardManagement/newCard/newFleetCardTemplate.html',
                controller: 'newFleetCardController',
                controllerAs: 'fleetCardVM'
            })
            .state('root.prompts', {
                url: '/promptsManagement',
                templateUrl: 'app/modules/cardManagement/promptsManagement/promptsTemplate.html',
                controller: 'promptsController',
                controllerAs: 'promptsVM'
            })
            .state('root.newPromptCard', {
                url: '/newPromptCard',
                templateUrl: 'app/modules/cardManagement/promptsManagement/newCard/newPromptCardTemplate.html',
                controller: 'newPromptCardController',
                controllerAs: 'promptsVM'
            })
            .state('root.transactions', {
                url: '/transactions',
                templateUrl: 'app/modules/cardManagement/transactions/transactionsTemplate.html',
                controller: 'transactionsController',
                controllerAs: 'transactionsVM'
            })
            .state('root.printTransaction', {
                url: '/printTransaction',
                templateUrl: 'app/modules/cardManagement/transactions/printView/transactionPrintTemplate.html',
                controller: 'transactionPrintController',
                controllerAs: 'transactionPrintVM'
            })
            .state('root.printCard', {
                url: '/printCard',
                templateUrl: 'app/modules/cardManagement/fleetCardManagement/cardPrintView/cardPrintTemplate.html',
                controller: 'cardPrintController',
                controllerAs: 'fleetCardPrintVM'
            })
            .state('root.printPrompt',{
                url:'/printPrompt',
                templateUrl: 'app/modules/cardManagement/promptsManagement/promptsPrintView/promptsPrintTemplate.html',
                controller:'promptsPrintController',
                controllerAs:'promptsPrintVM'
            });
    }
})();