/*jshint browser: true*/
/*global angular: true*/

(function () {
  "use strict";

  angular
    .module("scFuelsApp.cardManagement")
    .controller("newFleetCardController", newFleetCardController);

  newFleetCardController.$inject = [
    "$scope",
    "$state",
    "$rootScope",
    "toastrMessage",
    "cardManagementService",
    "utils",
    "toastr",
    "$window",
    "$confirm",
    "$filter",
  ];

  function newFleetCardController(
    $scope,
    $state,
    $rootScope,
    toastrMessage,
    cardManagementService,
    utils,
    toastr,
    $window,
    $confirm,
    $filter
  ) {
    var fleetCardVM = this;
    fleetCardVM.tooltips = toastrMessage.fleetCardsTooltip;
    fleetCardVM.showAccordianView = [];
    fleetCardVM.cardDetails = [];
    fleetCardVM.restrictionDetails = [];
    fleetCardVM.showManageDriver = [];
    fleetCardVM.addNewDriver = [];
    fleetCardVM.editDriver = [];
    fleetCardVM.isAddNewDriver = [];
    fleetCardVM.showTimeProfile = [];
    fleetCardVM.timeProfileDetails = [];
    fleetCardVM.isAddNewProfile = [];
    fleetCardVM.profileTemplate =
      "app/modules/cardManagement/fleetCardManagement/cardDetails/timeProfileTemplate.html";
    fleetCardVM.disablePin = false;
    fleetCardVM.disableLinkedPin = false;
    fleetCardVM.disableAuthCode = false;
    fleetCardVM.disableDriverId = false;
    fleetCardVM.stateRestriction = [];
    fleetCardVM.showStatePopup = [];
    fleetCardVM.disableCard = false;
    fleetCardVM.showNewField = false;
    fleetCardVM.newDepartment = "";
    fleetCardVM.submitClicked = false;
    fleetCardVM.disableReset = false;
    fleetCardVM.hstep = 1;
    fleetCardVM.mstep = 1;
    fleetCardVM.ismeridian = true;
    fleetCardVM.restrictionsForm = [];
    fleetCardVM.select = null;

    fleetCardVM.onCardTypeChange = onCardTypeChange;
    fleetCardVM.submitNewCard = submitNewCard;
    fleetCardVM.managePinClick = managePinClick;
    fleetCardVM.editDriverDetails = editDriverDetails;
    fleetCardVM.addNewDriver = addNewDriver;
    fleetCardVM.cancelAddDriver = cancelAddDriver;
    fleetCardVM.saveDriverDetails = saveDriverDetails;
    fleetCardVM.deleteDriverDetails = deleteDriverDetails;
    fleetCardVM.manageTimeProfile = manageTimeProfile;
    fleetCardVM.editProfileDetails = editProfileDetails;
    fleetCardVM.addNewProfile = addNewProfile;
    fleetCardVM.saveNewProfile = saveNewProfile;
    fleetCardVM.cancelAddProfile = cancelAddProfile;
    fleetCardVM.deleteProfileDetails = deleteProfileDetails;
    fleetCardVM.changeStatus = changeStatus;
    fleetCardVM.changePIN = changePIN;
    fleetCardVM.applyStateRestriction = applyStateRestriction;
    fleetCardVM.selectStateRestrictions = selectStateRestrictions;
    fleetCardVM.addNewZipcode = addNewZipcode;
    fleetCardVM.checkDuplicate = checkDuplicate;
    fleetCardVM.deleteZipcode = deleteZipcode;
    fleetCardVM.applyPopupDropdown = applyPopupDropdown;
    fleetCardVM.validatePin = validatePin;
    fleetCardVM.changeEmail = changeEmail;
    fleetCardVM.changeDriverPIN = changeDriverPIN;
    fleetCardVM.changeOdometer = changeOdometer;
    fleetCardVM.changeDepartment = changeDepartment;
    fleetCardVM.AppendZeroes = AppendZeroes;
    fleetCardVM.addZeroes = addZeroes;
    fleetCardVM.onResetChange = onResetChange;
    fleetCardVM.validateDriver = validateDriver;
    fleetCardVM.validateVehicle = validateVehicle;
    fleetCardVM.validateVoyagerCardDriver = validateVoyagerCardDriver;
    fleetCardVM.changeEmailReceipt = changeEmailReceipt;
    fleetCardVM.validateCFNDriver = validateCFNDriver;
    fleetCardVM.validatePacprideDriver = validatePacprideDriver;
    fleetCardVM.validateCFSStdDriver = validateCFSStdDriver;
    fleetCardVM.validateNFDriver = validateNFDriver;
    fleetCardVM.gallonChange = gallonChange;
    fleetCardVM.gallonWeekendChange = gallonWeekendChange;
    fleetCardVM.selectAllStates = selectAllStates;

    fleetCardVM.showShipping = true;
    fleetCardVM.IsDefaultAddress = "true";
    fleetCardVM.ShippingCarrier = "usps";
    fleetCardVM.changeAddr = changeAddr;
    fleetCardVM.changeShipping = changeShipping;
    fleetCardVM.autoGeneratePin = false;
    fleetCardVM.checkAutoGenPin = checkAutoGenPin;
    fleetCardVM.invalidPin = false;
    var errorMsg = "";

    init();

    function init() {
      fleetCardVM.submitClicked = false;
      fleetCardVM.disableReset = false;
      $rootScope.dataLoading = true;
      getFleetCardDropdownList();
      // var cardType = 'PPSI DIRECT ID';
    }

    function changeOdometer(resDet) {
      if (resDet.ValidateOdometerMileageYN !== "Yes") {
        resDet.ValidateOdometerMileage = 0;
        resDet.ValidateOdometerMileageLimitHS = "";
      }
    }

    function getFleetCardDropdownList() {
      cardManagementService
        .getCardManagementDropdownList(localStorage.selectedAccount)
        .then(getFleetCardDropdownListSuccess, getFleetCardDropdownListError);
      // cardManagementService.getFleetCardDropdownList()
      //     .then(getFleetCardDropdownListSuccess, getFleetCardDropdownListError);
    }

    function getFleetCardDropdownListSuccess(apiResponse) {
      // debugger;
      $rootScope.dataLoading = false;
      fleetCardVM.dropDownData = apiResponse.dataModel;
      fleetCardVM.dropDownData.resetDay = [
        "Daily",
        "Day of Week",
        "Day of Month",
        "End Of Month",
      ];
      fleetCardVM.dropDownData.reset = [
        { code: "D", Description: "Daily" },
        { code: "W", Description: "Weekly" },
        { code: "M", Description: "Monthly" },
        //     { code: 'M', Description: 'Monthly' },
        // { code: 'W', Description: 'Weekly' },
        // { code: 'D', Description: 'Daily' }
      ];
      fleetCardVM.dropDownData.miscEntry = ["Test1", "Test2", "Test3", "Test4"];
      // fleetCardVM.dropDownData.miscEntry = [{ 'name': '05-DIN/ODOM', 'id': 1 },
      // { 'name': '07-DIN/ODOM/MISC', 'id': 2 }];
      // fleetCardVM.dropDownRestrictionData.products = [{ 'name': 'prod 1', 'id': 1 },
      // { 'name': 'prod 2', 'id': 2 }];
      // fleetCardVM.dropDownData.department = [{ 'id': 1, 'name': 'department1' },
      //     { 'id': 2, 'name': 'department2' },
      //     { 'id': 3, 'name': 'department3' }
      // ];
      fleetCardVM.dropDownData.department = apiResponse.dataModel.department;
      fleetCardVM.dropDownData.department.push({
        code: "Add New",
        description: "Add New",
      });
      // fleetCardVM.dropDownData.gallonsPerTransaction = [{ 'name': 'gall 1', 'id': 1 },
      // { 'name': 'gall 2', 'id': 2 }]
      // $rootScope.dataLoading = false;
      // fleetCardVM.dropDownData = apiResponse;
      // fleetCardVM.dropDownData.purchaseDaysAllowed = [{'isChecked': false, 'type': 'Mon'},
      // {'isChecked': false, 'type': 'Tue'},{'isChecked': false, 'type': 'Wed'},{'isChecked': false, 'type': 'Thur'},
      // {'isChecked': false, 'type': 'Fri'},{'isChecked': false, 'type': 'Sat'},{'isChecked': false, 'type': 'Sun'},];
      // fleetCardVM.dropDownData.gallonsPerTransaction = apiResponse.gallons;
      // fleetCardVM.dropDownData.department = apiResponse.department;
      // fleetCardVM.dropDownData.allowedProducts = apiResponse.products;
      // fleetCardVM.dropDownData.siteRestrictions = apiResponse.stateRestrictions;
      // fleetCardVM.dropDownData.stateRestrictions = [{ 'id': 1, 'name': 'US' },
      //     { 'id': 2, 'name': 'Canada' },
      //     { 'id': 3, 'name': 'Australia' }];
      fleetCardVM.dropDownData.siteRestriction =
        apiResponse.dataModel.siteRestriction;
      fleetCardVM.dropDownData.purchaseDay = apiResponse.dataModel.purchaseDay;
      fleetCardVM.dropDownData.stateRestrictions =
        apiResponse.dataModel.stateRestriction;
      if (fleetCardVM.dropDownData.stateRestrictions) {
        angular.forEach(
          fleetCardVM.dropDownData.stateRestrictions,
          function (dropdownItem) {
            dropdownItem.isChecked = false;
          }
        );
      }
    }

    function getFleetCardDropdownListError() {}

    function getCardRestrictionDropdownList() {
      // debugger;
      cardManagementService
        .getCardRestrictionDropdownList(fleetCardVM.network.code)
        .then(getCardRestrictionListSuccess, getCardRestrictionListError);
    }

    function getCardRestrictionListSuccess(apiResponse) {
      // debugger;
      $rootScope.dataLoading = false;
      fleetCardVM.dropDownRestrictionData = apiResponse.dataModel;
      // fleetCardVM.dropDownData.productAuthCode = fleetCardVM.dropDownRestrictionData[0].restrictions;
      // fleetCardVM.dropDownRestrictionData.purchaseDaysAllowed = [{'isChecked': false, 'type': 'Mon'},
      // {'isChecked': false, 'type': 'Tue'},{'isChecked': false, 'type': 'Wed'},{'isChecked': false, 'type': 'Thur'},
      // {'isChecked': false, 'type': 'Fri'},{'isChecked': false, 'type': 'Sat'},{'isChecked': false, 'type': 'Sun'}];
      // fleetCardVM.dropDownRestrictionData.siteRestrictions = [{ 'id': 1, 'name': 'US' },
      //     { 'id': 2, 'name': 'Canada' },
      //     { 'id': 3, 'name': 'Australia' }
      // ];
      for (var i = 0; i < apiResponse.dataModel.length; i++) {
        if (apiResponse.dataModel[i].name === "PRODUCT AUTH CODE") {
          fleetCardVM.dropDownData.productAuthCode =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "MANUAL ENTRY") {
          fleetCardVM.dropDownRestrictionData.miscEntry =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "TIME PROFILE") {
          fleetCardVM.dropDownRestrictionData.timeProfile =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "PRODUCTS") {
          fleetCardVM.dropDownRestrictionData.products =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "LIMIT CODE") {
          fleetCardVM.dropDownRestrictionData.gallonsPerTransaction =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "PROD CODE") {
          fleetCardVM.dropDownRestrictionData.allowedProducts =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "AUTH CODE") {
          fleetCardVM.dropDownRestrictionData.restrictionCodes =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "SITES ALLOWED") {
          fleetCardVM.dropDownRestrictionData.sitesAllowed =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "LIMIT PROFILE") {
          fleetCardVM.dropDownRestrictionData.limitProfile =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "PROMPTS") {
          fleetCardVM.dropDownRestrictionData.prompts =
            apiResponse.dataModel[i].restrictions;
        }
      }
    }

    function getCardRestrictionListError() {}

    function onCardTypeChange() {
      fleetCardVM.showNewField = false;
      fleetCardVM.submitClicked = false;
      fleetCardVM.disableReset = false;
      if (fleetCardVM.showStatePopup[0]) {
        fleetCardVM.showStatePopup[0] = false;
      }
      if (fleetCardVM.network && fleetCardVM.cardType) {
        getCardRestrictionDropdownList();
        fleetCardVM.showDetailView = true;
        toggleFleetTemplate(fleetCardVM.cardType.description);
        fleetCardVM.showNFMessage = false;
      } else {
        fleetCardVM.showDetailView = false;
        if (fleetCardVM.network && fleetCardVM.network.code === "B") {
          fleetCardVM.showNFMessage = true;
        } else {
          fleetCardVM.showNFMessage = false;
        }
      }
    }

    function toggleFleetTemplate(cardType) {
      fleetCardVM.cardDetails[0] = {};
      fleetCardVM.restrictionDetails[0] = {};
      // debugger;
      fleetCardVM.newDepartment = "";
      fleetCardVM.cardDetails[0].CardStatus = "A";
      // fleetCardVM.restrictionDetails[0].strictControl = 'No';
      // fleetCardVM.restrictionDetails[0].posSite = 'Yes';
      // fleetCardVM.restrictionDetails[0].PerFillLimit = 150;
      // fleetCardVM.restrictionDetails[0].GallonLimit = 300;
      if (cardType === "VOYAGER CARD + VEHICLE") {
        fleetCardVM.restrictionDetails[0].TransactionsPerDay = 3;
        fleetCardVM.restrictionDetails[0].DollarsPerDay = 150;
        fleetCardVM.restrictionDetails[0].DollarsPerCycle = 1500;
      }
      // fleetCardVM.restrictionDetails[0].DollarsPerWeek = 1500;
      // fleetCardVM.restrictionDetails[0].DollarsPerMonth = 1500;
      if (
        cardType === "PPSI DIRECT ID" ||
        cardType === "VOYAGER CARD + VEHICLE"
      ) {
        // fleetCardVM.cardDetails[0].PinType = 'N';
      }
      if (cardType === "PPSI DIRECT ID" || cardType === "PPSI POOLED VEHICLE") {
        // fleetCardVM.restrictionDetails[0].ResetType = 'Monthly';
      }
      fleetCardVM.stateRestriction[0] = "";
      // fleetCardVM.restrictionDetails[0].zipCodeLockout = [];
      fleetCardVM.showManageDriver[0] = false;
      // debugger;
      // fleetCardVM.restrictionDetails[0].purchaseDaysAllowed = {'id': 1, 'type': 'Mon'};
      if (fleetCardVM.dropDownData.stateRestrictions) {
        angular.forEach(
          fleetCardVM.dropDownData.stateRestrictions,
          function (dropdownItem) {
            dropdownItem.isChecked = false;
          }
        );
      }
      var templateURL;
      switch (cardType) {
        case "PPSI DIRECT ID": //'PPSI DIRECT ID':
          fleetCardVM.restrictionDetails[0].PerFillLimit = 50;
          fleetCardVM.restrictionDetails[0].GallonLimit = 100;
          fleetCardVM.cardDetails[0].PinType = "SINGLE";
          fleetCardVM.restrictionDetails[0].Products = "ALL FUEL NO DYE + DEF";
          fleetCardVM.restrictionDetails[0].RestrictMultiFueling = "N";
          fleetCardVM.restrictionDetails[0].TimeProfile =
            "ALL DAYS 24 HOURS 3 TRANS";
          fleetCardVM.dropDownData.reset = [
            { code: "D", Description: "Daily" },
            { code: "W", Description: "Weekly" },
            { code: "M", Description: "Monthly" },
            //     { code: 'M', Description: 'Monthly' },
            // { code: 'W', Description: 'Weekly' },
            // { code: 'D', Description: 'Daily' }
          ];
          // fleetCardVM.restrictionDetails[0].ResetType = 'M';
          fleetCardVM.restrictionDetails[0].ResetType = "D";
          templateURL = "prideAdvantageDirectIDTemplate";
          break;
        case "PPSI POOLED VEHICLE": //'PPSI POOLED VEHICLE':
          fleetCardVM.restrictionDetails[0].GallonLimit = 100;
          fleetCardVM.restrictionDetails[0].PerFillLimit = 50;
          fleetCardVM.restrictionDetails[0].RestrictMultiFueling = "N";
          fleetCardVM.restrictionDetails[0].Products = "ALL FUEL NO DYE + DEF";
          fleetCardVM.dropDownData.reset = [
            { code: "D", Description: "Daily" },
            { code: "W", Description: "Weekly" },
            { code: "M", Description: "Monthly" },
            //     { code: 'M', Description: 'Monthly' },
            // { code: 'W', Description: 'Weekly' },
            // { code: 'D', Description: 'Daily' }
          ];
          // fleetCardVM.restrictionDetails[0].ResetType = 'M';
          fleetCardVM.restrictionDetails[0].ResetType = "D";

          templateURL = "prideAdvantagePooledVehicleCardTemplate";
          break;
        case "CFN FLEETWIDE VEHICLE":
          fleetCardVM.restrictionDetails[0].PerFillLimit = 50;
          fleetCardVM.cardDetails[0].CardStatus = "";
          fleetCardVM.restrictionDetails[0].RestrictMultiFueling = "N";
          fleetCardVM.restrictionDetails[0].AllowInStoreTrans = "Y";
          fleetCardVM.restrictionDetails[0].GallonLimit = 100;
          fleetCardVM.restrictionDetails[0].emailChecked = false;
          fleetCardVM.cardDetails[0].CardStatus = "A";
          fleetCardVM.restrictionDetails[0].emailChecked = false;
          fleetCardVM.restrictionDetails[0].ResetType = "End Of Month";
          fleetCardVM.restrictionDetails[0].ProductAuthDesc = "DSL, UNL, OILS";
          fleetCardVM.dropDownData.resetDay = [
            "Daily",
            "Day of Week",
            "Day of Month",
            "End Of Month",
          ];
          onResetChange(fleetCardVM.restrictionDetails[0].ResetType);
          templateURL = "fleetWideVehicleCardTemplate";
          break;
        case "CFN SINGLE":
          fleetCardVM.restrictionDetails[0].PerFillLimit = 50;
          fleetCardVM.restrictionDetails[0].GallonLimit = 100;
          fleetCardVM.restrictionDetails[0].RestrictMultiFueling = "N";
          fleetCardVM.restrictionDetails[0].AllowInStoreTrans = "Y";
          fleetCardVM.restrictionDetails[0].emailChecked = false;
          fleetCardVM.restrictionDetails[0].TimeProfile = "OPEN-3 TRANS";
          fleetCardVM.restrictionDetails[0].ResetType = "End Of Month";
          fleetCardVM.restrictionDetails[0].ProductAuthDesc = "DSL, UNL, OILS";
          fleetCardVM.dropDownData.resetDay = [
            "Daily",
            "Day of Week",
            "Day of Month",
            "End Of Month",
          ];
          onResetChange(fleetCardVM.restrictionDetails[0].ResetType);
          templateURL = "fleetWideSingleCardTemplate";
          break;
        case "CF CARD + VEHICLE + PIN":
          fleetCardVM.cardDetails[0].PinType = "SINGLE";
          fleetCardVM.restrictionDetails[0].StartTimeA = null;
          fleetCardVM.restrictionDetails[0].EndTimeA = null;
          templateURL = "CFTemplate";
          break;
        case "VOYAGER SECURE":
          fleetCardVM.restrictionDetails[0].LimitCode = "55";
          fleetCardVM.restrictionDetails[0].TransactionsPerDay = 3;
          fleetCardVM.restrictionDetails[0].DollarsPerCycle = 1500;
          fleetCardVM.restrictionDetails[0].RestrictionCode = "31";
          fleetCardVM.restrictionDetails[0].StartTimeA = null;
          fleetCardVM.restrictionDetails[0].EndTimeA = null;
          templateURL = "voyagerSecureTemplate";
          break;
        case "VOYAGER PLUS":
          fleetCardVM.restrictionDetails[0].TransactionsPerDay = 3;
          fleetCardVM.restrictionDetails[0].DollarsPerDay = 150;
          fleetCardVM.restrictionDetails[0].DollarsPerCycle = 1500;
          fleetCardVM.restrictionDetails[0].RestrictionCode = "31";
          fleetCardVM.restrictionDetails[0].LimitCode = "55";
          fleetCardVM.restrictionDetails[0].StartTimeA = null;
          fleetCardVM.restrictionDetails[0].EndTimeA = null;
          templateURL = "voyagerPlusTemplate";
          break;
        case "VOYAGER CARD UNTIED":
          templateURL = "classicUntiedCardTemplate";
          fleetCardVM.restrictionDetails[0].RestrictionCode = "31";
          fleetCardVM.restrictionDetails[0].TransactionsPerDay = 3;
          fleetCardVM.restrictionDetails[0].DollarsPerDay = 150;
          fleetCardVM.restrictionDetails[0].DollarsPerCycle = 1500;
          fleetCardVM.restrictionDetails[0].StartTimeA = null;
          fleetCardVM.restrictionDetails[0].EndTimeA = null;
          break;
        case "VOYAGER CARD + DRIVER":
          fleetCardVM.restrictionDetails[0].TransactionsPerDay = 3;
          fleetCardVM.restrictionDetails[0].RestrictionCode = "31";
          fleetCardVM.restrictionDetails[0].DollarsPerDay = 150;
          fleetCardVM.restrictionDetails[0].DollarsPerCycle = 1500;
          fleetCardVM.cardDetails[0].LicenseState = "CA";
          fleetCardVM.restrictionDetails[0].StartTimeA = null;
          fleetCardVM.restrictionDetails[0].EndTimeA = null;
          templateURL = "classicDriverTiedCardTemplate";
          break;
        case "VOYAGER 1-8 CARD + VEHICLE":
          fleetCardVM.restrictionDetails[0].RestrictionCode = "31";
          fleetCardVM.restrictionDetails[0].TransactionsPerDay = 3;
          fleetCardVM.restrictionDetails[0].DollarsPerDay = 150;
          fleetCardVM.restrictionDetails[0].DollarsPerCycle = 1500;
          fleetCardVM.restrictionDetails[0].StartTimeA = null;
          fleetCardVM.restrictionDetails[0].EndTimeA = null;
          templateURL = "1-8VehicleTiedCardTemplate";
          break;
        case "VOYAGER 1-8 CARD UNTIED":
          templateURL = "1-8UntiedCardTemplate";
          fleetCardVM.restrictionDetails[0].RestrictionCode = "31";
          fleetCardVM.restrictionDetails[0].TransactionsPerDay = 3;
          fleetCardVM.restrictionDetails[0].DollarsPerDay = 150;
          fleetCardVM.restrictionDetails[0].DollarsPerCycle = 1500;
          fleetCardVM.restrictionDetails[0].StartTimeA = null;
          fleetCardVM.restrictionDetails[0].EndTimeA = null;
          break;
        case "VOYAGER 1-8 CARD + DRIVER":
          fleetCardVM.restrictionDetails[0].RestrictionCode = "31";
          fleetCardVM.restrictionDetails[0].TransactionsPerDay = 3;
          fleetCardVM.restrictionDetails[0].DollarsPerDay = 150;
          fleetCardVM.restrictionDetails[0].DollarsPerCycle = 1500;
          fleetCardVM.cardDetails[0].DriversLicenseState = "CA";
          fleetCardVM.restrictionDetails[0].StartTimeA = null;
          fleetCardVM.restrictionDetails[0].EndTimeA = null;
          templateURL = "1-8DriverTiedCardTemplate";
          break;
        case "VOYAGER CARD + VEHICLE":
          fleetCardVM.restrictionDetails[0].RestrictionCode = "31";
          fleetCardVM.restrictionDetails[0].StartTimeA = null;
          fleetCardVM.restrictionDetails[0].EndTimeA = null;
          templateURL = "classicVehicleTiedCardTemplate";
          break;
        case "SINGLE CARD":
          fleetCardVM.cardDetails[0].Prompt = "PIN AND ODOM";
          fleetCardVM.restrictionDetails[0].Products = "DIESEL ONLY";
          fleetCardVM.restrictionDetails[0].LimitProfile = "3T 50G EACH";
          fleetCardVM.dropDownData.miscEntry = [
            "Test1",
            "Test2",
            "Test3",
            "Test4",
          ];
          fleetCardVM.cardDetails[0].PinType = "SINGLE";
          fleetCardVM.restrictionDetails[0].TimeProfile = "ALL 24/7";
          templateURL = "NFTemplate";
          break;
        default:
          fleetCardVM.showDetailView = false;
          break;
      }
      fleetCardVM.cardDetailView =
        "app/modules/cardManagement/fleetCardManagement/cardDetails/" +
        templateURL +
        ".html";
    }

    function submitNewCard(cardDetails) {
      // debugger;
      fleetCardVM.submitClicked = true;
      var cardType = cardDetails;
      var cardData = fleetCardVM.cardDetails[0];
      var restrictData = fleetCardVM.restrictionDetails[0];
      var autoGenPin = fleetCardVM.autoGeneratePin;
      if (cardData.Department === "Add New" || fleetCardVM.newDepartment) {
        // if(fleetCardVM.newDepartment){
        cardData.Department = fleetCardVM.newDepartment;
      }
      // else {
      //     cardData.Department ='';
      // }
      // }
      cardData.CardStatus = "A";
      switch (cardType) {
        case "PPSI DIRECT ID":
          validatePrideAdvantageDirectId(cardData, restrictData, 0, autoGenPin);
          break;
        case "PPSI POOLED VEHICLE":
          validatePrideAdvantagePooledVehicleCard(cardData, restrictData, 0);
          break;
        case "CFN FLEETWIDE VEHICLE":
          validateFleetwideVehicleCard(cardData, restrictData, 0);
          break;
        case "CFN SINGLE":
          validateFleetwideSingleCard(cardData, restrictData, 0, autoGenPin);
          break;
        case "CF CARD + VEHICLE + PIN":
          validateCF(cardData, restrictData, 0);
          break;
        case "VOYAGER SECURE":
          validateVoyagerSecure(cardData, restrictData, 0);
          break;
        case "VOYAGER PLUS":
          validateVoyagerPlus(cardData, restrictData, 0);
          break;
        case "VOYAGER CARD UNTIED":
          validateClassicUnitedCard(cardData, restrictData, 0);
          break;
        case "VOYAGER CARD + DRIVER":
          validateClassicDriverTiedCard(cardData, restrictData, 0);
          break;
        case "VOYAGER 1-8 CARD + VEHICLE":
          validateVehicleTiedToCard(cardData, restrictData, 0);
          break;
        case "VOYAGER 1-8 CARD UNTIED":
          validateUnitedCard(cardData, restrictData, 0);
          break;
        case "VOYAGER 1-8 CARD + DRIVER":
          validateDriverTiedToCard(cardData, restrictData, 0);
          break;
        case "VOYAGER CARD + VEHICLE":
          validateClassicVehicleTiedCard(cardData, restrictData, 0, autoGenPin);
          break;
        case "SINGLE CARD":
          validateNfSingleCard(cardData, restrictData, 0);
          break;
        default:
          errorMsg = toastrMessage.cardControlErrorMessage.cannotEdit;
          toastr.error(errorMsg);
          break;
      }
    }

    function validatePrideAdvantageDirectId(
      cardData,
      restrictData,
      index,
      autoGenPin
    ) {
      if (
        cardData.CardDescription &&
        cardData.MiscEntry &&
        cardData.PinType &&
        restrictData.TimeProfile &&
        restrictData.GallonLimit &&
        restrictData.ProductAuthCode &&
        restrictData.PerFillLimit &&
        restrictData.ResetType
      ) {
        //&& cardData.linkedPin
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(errorMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else if (
          cardData.PinType === "SINGLE" &&
          !cardData.LinkedPin &&
          !autoGenPin
        ) {
          toastr.error("Driver Pin Must be Entered");
        } else if (
          cardData.LinkedPin &&
          cardData.LinkedPin.length < 4 &&
          !autoGenPin
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
        } else if (
          restrictData.ZipCodeLockOut1 &&
          restrictData.ZipCodeLockOut1.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut2 &&
          restrictData.ZipCodeLockOut2.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut3 &&
          restrictData.ZipCodeLockOut3.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut4 &&
          restrictData.ZipCodeLockOut4.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut5 &&
          restrictData.ZipCodeLockOut5.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        }
        // else if (cardData.zipCodeLockout.length) {
        //     var validZip = true;
        //     angular.forEach(cardData.zipCodeLockout, function(item) {
        //         if (item.name && !utils.isValidZipCode(item.name)) {
        //             validZip = false;
        //         }
        //     });
        //     if (!validZip) {
        //         toastr.error("Zipcode requires 5 digits");
        //     }
        // }
        else {
          alert(
            "This request has been submitted. Please allow 2 business days to process this request"
          );
          //api call
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          newCardData.CardStatus = "A";
          if (autoGenPin) newCardData.LinkedPin = "AUTO";
          else newCardData.LinkedPin = fleetCardVM.cardDetails[index].LinkedPin;
          if (restrictData.ResetType) {
            var selectedResetType = $filter("filter")(
              fleetCardVM.dropDownData.reset,
              { code: restrictData.ResetType },
              true
            );
            if (selectedResetType.length > 0) {
              newRestrictData.ResetType = selectedResetType[0].Description;
            }
          }
          if (restrictData.ProductAuthCode) {
            var selectedProducts = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.products,
              { listValue: restrictData.ProductAuthCode },
              true
            );
            if (selectedProducts) {
              if (selectedProducts.length > 0) {
                newRestrictData.ProductAuthDesc =
                  selectedProducts[0].listDescription;
              }
            }
          }
          if (newRestrictData.Products) {
            delete newRestrictData.Products;
            newRestrictData.Products = newRestrictData.ProductAuthDesc;
          }

          // newCardData.Department = cardData.Department.description;
          // newCardData.MiscEntry = cardData.MiscEntry.listDescription;
          // newRestrictData.Products = restrictData.Products.listDescription;
          // newRestrictData.TimeProfile = restrictData.TimeProfile.listDescription;
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        toastr.error("Please enter all the required fields");
      }
    }

    function validatePrideAdvantagePooledVehicleCard(
      cardData,
      restrictData,
      index
    ) {
      if (
        cardData.CardDescription &&
        restrictData.PerFillLimit &&
        restrictData.ResetType &&
        restrictData.ProductAuthCode &&
        restrictData.GallonLimit &&
        restrictData.ResetType &&
        restrictData.RestrictMultiFueling
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else if (
          restrictData.ZipCodeLockOut1 &&
          restrictData.ZipCodeLockOut1.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut2 &&
          restrictData.ZipCodeLockOut2.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut3 &&
          restrictData.ZipCodeLockOut3.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut4 &&
          restrictData.ZipCodeLockOut4.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut5 &&
          restrictData.ZipCodeLockOut5.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        }
        // else if (restrictData.zipCodeLockout.length) {
        //     var validZip = true;
        //     angular.forEach(restrictData.zipCodeLockout, function(item) {
        //         if (item.name && !utils.isValidZipCode(item.name)) {
        //             validZip = false;
        //         }
        //     });
        //     if (!validZip) {
        //         toastr.error("Zipcode requires 5 digits");
        //     }
        // }
        else {
          //api call
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          newCardData.CardStatus = "A";
          if (restrictData.ResetType) {
            var selectedResetType = $filter("filter")(
              fleetCardVM.dropDownData.reset,
              { code: restrictData.ResetType },
              true
            );
            if (selectedResetType.length > 0) {
              newRestrictData.ResetType = selectedResetType[0].Description;
            }
          }
          if (restrictData.ProductAuthCode) {
            var selectedProducts = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.products,
              { listValue: restrictData.ProductAuthCode },
              true
            );
            if (selectedProducts) {
              if (selectedProducts.length > 0) {
                newRestrictData.ProductAuthDesc =
                  selectedProducts[0].listDescription;
              }
            }
          }
          if (newRestrictData.Products) {
            delete newRestrictData.Products;
            newRestrictData.Products = newRestrictData.ProductAuthDesc;
          }
          // newCardData.Department = cardData.Department.description;
          // newCardData.MiscEntry = cardData.MiscEntry.listDescription;
          // newRestrictData.Products = restrictData.Products.listDescription;
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        toastr.error("Please enter all the required fields");
      }
    }

    function validateFleetwideVehicleCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.PerFillLimit &&
        restrictData.GallonLimit &&
        restrictData.ProductAuthDesc &&
        restrictData.RestrictMultiFueling &&
        restrictData.ResetType
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else if (
          restrictData.PerFillLimit < 1 &&
          restrictData.PerFillLimit > 9999
        ) {
          toastr.error("Per Fill Gallon Limit must be between 1 and 9999");
        } else if (
          restrictData.notiffyGallonLimitCheck &&
          !restrictData.NotifyLimit
        ) {
          toastr.error("Notify When Value Must be Entered");
        } else if (
          restrictData.notiffyGallonLimitWeekendCheck &&
          !restrictData.notifyWeekendGallonLimit
        ) {
          toastr.error("NotifyWeekend When Value Must be Entered");
        } else if (
          restrictData.emailChecked &&
          restrictData.NotifyLimitEmail &&
          !utils.isValidEmail(restrictData.NotifyLimitEmail)
        ) {
          toastr.error("Enter valid Email id");
        } else if (
          restrictData.emailChecked &&
          !restrictData.NotifyLimitEmail
        ) {
          toastr.error("Notify Email ID Must be Entered");
        } else if (
          (restrictData.NotifyLimit && parseInt(restrictData.NotifyLimit)) >
          (restrictData.GallonLimit && parseInt(restrictData.GallonLimit))
        ) {
          toastr.error("Notify When Value Must be less than GallonLimit ");
        } else if (
          (restrictData.notifyWeekendGallonLimit &&
            parseInt(restrictData.notifyWeekendGallonLimit)) >
          (restrictData.GallonLimitWeekEnd &&
            parseInt(restrictData.GallonLimitWeekEnd))
        ) {
          toastr.error(
            "Notify When Value Must be less than GallonLimit Weekend"
          );
        } else if (
          restrictData.ResetType === "Day of Week" &&
          (!restrictData.ResetTypeValue ||
            restrictData.ResetTypeValue < 1 ||
            restrictData.ResetTypeValue > 7)
        ) {
          toastr.error(" Reset Value Must be b/w 1-7");
        } else if (
          restrictData.ResetType === "Day of Month" &&
          (!restrictData.ResetTypeValue ||
            restrictData.ResetTypeValue < 1 ||
            restrictData.ResetTypeValue > 31)
        ) {
          toastr.error(" Reset Value Must be b/w 1-31");
        } else if (
          restrictData.ZipCodeLockOut1 &&
          restrictData.ZipCodeLockOut1.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut2 &&
          restrictData.ZipCodeLockOut2.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut3 &&
          restrictData.ZipCodeLockOut3.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut4 &&
          restrictData.ZipCodeLockOut4.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        } else if (
          restrictData.ZipCodeLockOut5 &&
          restrictData.ZipCodeLockOut5.length < 5
        ) {
          toastr.error("Zip Code Must be 5 Digits");
        }
        // else if (restrictData.zipCodeLockout.length) {
        //     var validZip = true;
        //     angular.forEach(restrictData.zipCodeLockout, function(item) {
        //         if (item.name && !utils.isValidZipCode(item.name)) {
        //             validZip = false;
        //         }
        //     });
        //     if (!validZip) {
        //         toastr.error("Zipcode requires 5 digits");
        //     }
        // }
        else {
          //api call
          // var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          // var newCardData = JSON.parse(JSON.stringify(cardData));
          if (restrictData.ProductAuthCode) {
            delete restrictData.ProductAuthCode;
          }
          // if (cardData.Department) {
          //     newCardData.Department = cardData.Department.description;
          //     newCardData.DeptId = cardData.Department.code;
          // }
          if (restrictData.emailChecked === false) {
            if (
              restrictData.notiffyGallonLimitCheck ||
              restrictData.notiffyGallonLimitWeekendCheck
            ) {
              restrictData.NotifyLimitEmail = localStorage.userEmail;
            } else {
              restrictData.emailChecked = null;
            }
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            restrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          cardData.CardStatus = "A";
          saveCardDetails(cardData, restrictData);
        }
      } else {
        toastr.error("Please enter all the required fields");
      }
    }

    function validateFleetwideSingleCard(
      cardData,
      restrictData,
      index,
      autoGenPin
    ) {
      if (autoGenPin == false) {
        if (
          cardData.CardDescription &&
          cardData.CardStatus &&
          restrictData.PerFillLimit &&
          restrictData.GallonLimit &&
          cardData.LinkedPin &&
          restrictData.TimeProfile &&
          restrictData.RestrictMultiFueling &&
          restrictData.ProductAuthDesc &&
          restrictData.ResetType
        ) {
          if (
            cardData.CardDescription &&
            !utils.isValidAlphaNumeric(cardData.CardDescription)
          ) {
            toastr.error(
              "Special symbols/characters are not allowed in Card Description"
            );
          } else if (
            cardData.Department &&
            !utils.isValidAlphaNumeric(cardData.Department)
          ) {
            toastr.error(
              "Special symbols/characters are not allowed in New Department"
            );
          } else if (
            restrictData.PerFillLimit < 1 &&
            restrictData.PerFillLimit > 9999
          ) {
            toastr.error("Per Fill Gallon Limit must be between 1 and 9999");
          } else if (
            restrictData.NotifyGallonLimitCheck &&
            !restrictData.NotifyLimit
          ) {
            toastr.error("Notify When Value Must be Entered");
          } else if (
            restrictData.NotifyGallonLimitWeekendCheck &&
            !restrictData.NotifyLimitWeekEnd
          ) {
            toastr.error("NotifyWeekend When Value Must be Entered");
          } else if (
            restrictData.emailChecked &&
            restrictData.NotifyLimitEmail &&
            !utils.isValidEmail(restrictData.NotifyLimitEmail)
          ) {
            toastr.error("Enter valid Email id");
          } else if (
            restrictData.emailChecked &&
            !restrictData.NotifyLimitEmail
          ) {
            toastr.error("Notify Email ID Must be Entered");
          } else if (cardData.LinkedPin.length < 4) {
            errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
            toastr.error(errorMsg);
            // toastr.error("Pin must be 5 digit");
          } else if (
            (restrictData.NotifyLimit && parseInt(restrictData.NotifyLimit)) >
            (restrictData.GallonLimit && parseInt(restrictData.GallonLimit))
          ) {
            toastr.error("Notify When Value Must be less than GallonLimit ");
          } else if (
            (restrictData.NotifyLimitWeekEnd &&
              parseInt(restrictData.NotifyLimitWeekEnd)) >
            (restrictData.GallonLimitWeekEnd &&
              parseInt(restrictData.GallonLimitWeekEnd))
          ) {
            toastr.error(
              "Notify When Value Must be less than GallonLimit Weekend"
            );
          } else if (
            restrictData.ResetType === "Day of Week" &&
            (!restrictData.ResetTypeValue ||
              restrictData.ResetTypeValue < 1 ||
              restrictData.ResetTypeValue > 7)
          ) {
            toastr.error(" Reset Value Must be b/w 1-7");
          } else if (
            restrictData.ResetType === "Day of Month" &&
            (!restrictData.ResetTypeValue ||
              restrictData.ResetTypeValue < 1 ||
              restrictData.ResetTypeValue > 31)
          ) {
            toastr.error(" Reset Value Must be b/w 1-31");
          } else if (
            restrictData.ZipCodeLockOut1 &&
            restrictData.ZipCodeLockOut1.length < 5
          ) {
            toastr.error("Zip Code Must be 5 Digits");
          } else if (
            restrictData.ZipCodeLockOut2 &&
            restrictData.ZipCodeLockOut2.length < 5
          ) {
            toastr.error("Zip Code Must be 5 Digits");
          } else if (
            restrictData.ZipCodeLockOut3 &&
            restrictData.ZipCodeLockOut3.length < 5
          ) {
            toastr.error("Zip Code Must be 5 Digits");
          } else if (
            restrictData.ZipCodeLockOut4 &&
            restrictData.ZipCodeLockOut4.length < 5
          ) {
            toastr.error("Zip Code Must be 5 Digits");
          } else if (
            restrictData.ZipCodeLockOut5 &&
            restrictData.ZipCodeLockOut5.length < 5
          ) {
            toastr.error("Zip Code Must be 5 Digits");
          } else {
            alert(
              "This request has been submitted. Please allow 2 business days to process this request"
            );
            //     //api call
            var newRestrictData = JSON.parse(JSON.stringify(restrictData));
            // var newCardData = JSON.parse(JSON.stringify(cardData));

            // if(restrictData.TimeProfile){
            // newRestrictData.TimeProfile = restrictData.TimeProfile.listValue;
            // }
            if (restrictData.ProductAuthCode) {
              // newRestrictData.ProductAuthCode = restrictData.ProductAuthCode.listValue;
              // newRestrictData.ProductAuthDesc  = restrictData.ProductAuthCode.listDescription;
              delete restrictData.ProductAuthCode;
            }
            // if(cardData.Department){
            // newCardData.Department = cardData.Department.description;
            // newCardData.DeptId = cardData.Department.code;
            // }
            if (restrictData.emailChecked === false) {
              if (
                restrictData.NotifyGallonLimitCheck ||
                restrictData.NotifyGallonLimitWeekendCheck
              ) {
                newRestrictData.NotifyLimitEmail = localStorage.userEmail;
              } else {
                newRestrictData.emailChecked = null;
              }
            }

            if (
              restrictData.StateRestrictions &&
              typeof restrictData.StateRestrictions !== "string"
            ) {
              newRestrictData.StateRestrictions =
                restrictData.StateRestrictions.join(",");
            }
            cardData.CardStatus = "A";
            saveCardDetails(cardData, newRestrictData);
          }
        } else {
          toastr.error("Please enter all the required fields");
        }
      } else {
        if (
          cardData.CardDescription &&
          cardData.CardStatus &&
          restrictData.PerFillLimit &&
          restrictData.GallonLimit &&
          restrictData.TimeProfile &&
          restrictData.RestrictMultiFueling &&
          restrictData.ProductAuthDesc &&
          restrictData.ResetType
        ) {
          if (
            cardData.CardDescription &&
            !utils.isValidAlphaNumeric(cardData.CardDescription)
          ) {
            toastr.error(
              "Special symbols/characters are not allowed in Card Description"
            );
          } else if (
            cardData.Department &&
            !utils.isValidAlphaNumeric(cardData.Department)
          ) {
            toastr.error(
              "Special symbols/characters are not allowed in New Department"
            );
          } else if (
            restrictData.PerFillLimit < 1 &&
            restrictData.PerFillLimit > 9999
          ) {
            toastr.error("Per Fill Gallon Limit must be between 1 and 9999");
          } else if (
            restrictData.NotifyGallonLimitCheck &&
            !restrictData.NotifyLimit
          ) {
            toastr.error("Notify When Value Must be Entered");
          } else if (
            restrictData.NotifyGallonLimitWeekendCheck &&
            !restrictData.NotifyLimitWeekEnd
          ) {
            toastr.error("NotifyWeekend When Value Must be Entered");
          } else if (
            restrictData.emailChecked &&
            restrictData.NotifyLimitEmail &&
            !utils.isValidEmail(restrictData.NotifyLimitEmail)
          ) {
            toastr.error("Enter valid Email id");
          } else if (
            restrictData.emailChecked &&
            !restrictData.NotifyLimitEmail
          ) {
            toastr.error("Notify Email ID Must be Entered");
          } else if (
            (restrictData.NotifyLimit && parseInt(restrictData.NotifyLimit)) >
            (restrictData.GallonLimit && parseInt(restrictData.GallonLimit))
          ) {
            toastr.error("Notify When Value Must be less than GallonLimit ");
          } else if (
            (restrictData.NotifyLimitWeekEnd &&
              parseInt(restrictData.NotifyLimitWeekEnd)) >
            (restrictData.GallonLimitWeekEnd &&
              parseInt(restrictData.GallonLimitWeekEnd))
          ) {
            toastr.error(
              "Notify When Value Must be less than GallonLimit Weekend"
            );
          } else if (
            restrictData.ResetType === "Day of Week" &&
            (!restrictData.ResetTypeValue ||
              restrictData.ResetTypeValue < 1 ||
              restrictData.ResetTypeValue > 7)
          ) {
            toastr.error(" Reset Value Must be b/w 1-7");
          } else if (
            restrictData.ResetType === "Day of Month" &&
            (!restrictData.ResetTypeValue ||
              restrictData.ResetTypeValue < 1 ||
              restrictData.ResetTypeValue > 31)
          ) {
            toastr.error(" Reset Value Must be b/w 1-31");
          } else if (
            restrictData.ZipCodeLockOut1 &&
            restrictData.ZipCodeLockOut1.length < 5
          ) {
            toastr.error("Zip Code Must be 5 Digits");
          } else if (
            restrictData.ZipCodeLockOut2 &&
            restrictData.ZipCodeLockOut2.length < 5
          ) {
            toastr.error("Zip Code Must be 5 Digits");
          } else if (
            restrictData.ZipCodeLockOut3 &&
            restrictData.ZipCodeLockOut3.length < 5
          ) {
            toastr.error("Zip Code Must be 5 Digits");
          } else if (
            restrictData.ZipCodeLockOut4 &&
            restrictData.ZipCodeLockOut4.length < 5
          ) {
            toastr.error("Zip Code Must be 5 Digits");
          } else if (
            restrictData.ZipCodeLockOut5 &&
            restrictData.ZipCodeLockOut5.length < 5
          ) {
            toastr.error("Zip Code Must be 5 Digits");
          } else {
            alert(
              "This request has been submitted. Please allow 2 business days to process this request"
            );
            //     //api call
            var newRestrictData = JSON.parse(JSON.stringify(restrictData));
            // var newCardData = JSON.parse(JSON.stringify(cardData));

            // if(restrictData.TimeProfile){
            // newRestrictData.TimeProfile = restrictData.TimeProfile.listValue;
            // }
            if (restrictData.ProductAuthCode) {
              // newRestrictData.ProductAuthCode = restrictData.ProductAuthCode.listValue;
              // newRestrictData.ProductAuthDesc  = restrictData.ProductAuthCode.listDescription;
              delete restrictData.ProductAuthCode;
            }
            // if(cardData.Department){
            // newCardData.Department = cardData.Department.description;
            // newCardData.DeptId = cardData.Department.code;
            // }
            if (restrictData.emailChecked === false) {
              if (
                restrictData.NotifyGallonLimitCheck ||
                restrictData.NotifyGallonLimitWeekendCheck
              ) {
                newRestrictData.NotifyLimitEmail = localStorage.userEmail;
              } else {
                newRestrictData.emailChecked = null;
              }
            }

            if (
              restrictData.StateRestrictions &&
              typeof restrictData.StateRestrictions !== "string"
            ) {
              newRestrictData.StateRestrictions =
                restrictData.StateRestrictions.join(",");
            }
            cardData.CardStatus = "A";
            cardData.LinkedPin = "AUTO";
            saveCardDetails(cardData, newRestrictData);
          }
        } else {
          toastr.error("Please enter all the required fields");
        }
      }
    }

    function validateCF(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.LimitCode &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          toastr.error("Driver Pin Must be Entered");
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
        } else {
          //api call
          /** Instead of cardData, we can create another object with wanted keys from cardData, then pass it to API (If you wants only form specific keys) */
          // var newCardData = {};
          // var newRestrictData = {};
          // newRestrictData.ValidTimeofDay = {
          //     from: null,
          //     to: null
          // }
          // newCardData.CardDescription = cardData.CardDescription;
          // newCardData.cardStatus = cardData.cardStatus;
          // newCardData.LinkedPin = cardData.LinkedPin;
          // newRestrictData.purchaseDaysAllowed = restrictData.purchaseDaysAllowed;
          // if (cardData.Department)
          //     newCardData.Department = cardData.Department.name;
          // if (cardData.gallonsPerTransaction)
          //     newCardData.gallonsPerTransaction = cardData.gallonsPerTransaction.name;
          // if (restrictData.allowedProducts)
          //     newRestrictData.allowedProducts = restrictData.allowedProducts.name;
          // if (restrictData.siteRestrictions)
          //     newRestrictData.siteRestrictions = restrictData.siteRestrictions.name;
          // if (restrictData.StartTimeA)
          //     newRestrictData.ValidTimeofDay.from = utils.getFromToTimeFormat(new Date(restrictData.StartTimeA));
          // if (restrictData.EndTimeA)
          //     newRestrictData.ValidTimeofDay.to = utils.getFromToTimeFormat(new Date(restrictData.EndTimeA));

          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          // newCardData.Department = cardData.Department.description;
          // newCardData.MaxDollarsPerTransactions = cardData.MaxDollarsPerTransactions.listDescription;
          if (restrictData.LimitCode) {
            var selectedLimitCode = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
              { listValue: restrictData.LimitCode },
              true
            );
            if (selectedLimitCode.length > 0) {
              newRestrictData.LimitCode = selectedLimitCode[0].listDescription;
            }
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (restrictData.ProdCode) {
            var selectedProduct = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.allowedProducts,
              { listValue: restrictData.ProdCode },
              true
            );
            if (selectedProduct) {
              if (selectedProduct.length > 0) {
                newRestrictData.Products = selectedProduct[0].listDescription;
              }
            }
          }

          newCardData.CardStatus = "A";
          // newRestrictData.Products = restrictData.Products.listDescription;
          // newRestrictData.SiteGroupCode = restrictData.SiteGroupCode.description;
          // newRestrictData.PurchaseDays = restrictData.PurchaseDays.description;
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            errorMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(errorMsg);
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }

    function validateVoyagerSecure(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        cardData.PinType &&
        restrictData.TransactionsPerDay &&
        fleetCardVM.restrictionsForm[index].$valid &&
        restrictData.LimitCode &&
        restrictData.DollarsPerCycle &&
        restrictData.ProdCode &&
        restrictData.RestrictionCode
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else if (
          restrictData.TransactionsPerDay < 1 ||
          restrictData.TransactionsPerDay > 99
        ) {
          toastr.error("Transaction Per Day must be between 1 and 99");
        } else if (restrictData.DollarsPerCycle < 100) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          toastr.error("Driver Pin Must be Entered");
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
        } else if (cardData.VIN && !utils.isValidAlphaNumeric(cardData.VIN)) {
          toastr.error("Special symbols/characters are not allowed in VIN");
        }
        /********************************** CHANGE START FROM HERE************** */
        // else if (restrictData.authCode && !utils.isValidAlphaNumeric(restrictData.authCode)) {
        //     toastr.error("Special symbols/characters are not allowed in Auth Code");
        // }
        else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          if (restrictData.LimitCode) {
            var selectedGallonsPerTransaction = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
              { listValue: restrictData.LimitCode },
              true
            );
            if (selectedGallonsPerTransaction.length > 0) {
              newRestrictData.LimitCode =
                selectedGallonsPerTransaction[0].listDescription;
            }
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (restrictData.RestrictionCode) {
            var selectedRestrictionCode = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.restrictionCodes,
              { listValue: restrictData.RestrictionCode },
              true
            );
            if (selectedRestrictionCode.length > 0) {
              newRestrictData.RestrictionCode =
                selectedRestrictionCode[0].listDescription;
            }
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          if (restrictData.ProdCode) {
            var selectedProduct = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.allowedProducts,
              { listValue: restrictData.ProdCode },
              true
            );
            if (selectedProduct) {
              if (selectedProduct.length > 0) {
                newRestrictData.Products = selectedProduct[0].listDescription;
              }
            }
          }
          if (cardData.LicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.LicenseState },
              true
            );
            if (selectedLicenseState) {
              if (selectedLicenseState.length > 0) {
                newCardData.LicenseState = selectedLicenseState[0].description;
              }
            }
          }
          // newCardData.Department = cardData.Department.description;
          // newRestrictData.MaxDollarsPerTransactions = restrictData.MaxDollarsPerTransactions.listDescription;
          // newRestrictData.Products = restrictData.Products.listDescription;
          // newRestrictData.SiteGroupCode = restrictData.SiteGroupCode.name;
          // newRestrictData.RestrictionCode = restrictData.RestrictionCode.listValue;
          newCardData.CardStatus = "A";
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            errorMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(errorMsg);
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }

    function validateVoyagerPlus(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        cardData.PinType &&
        restrictData.TransactionsPerDay &&
        restrictData.LimitCode &&
        restrictData.DollarsPerDay &&
        restrictData.DollarsPerCycle &&
        restrictData.RestrictionCode &&
        restrictData.ProdCode &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toastr.error("Transaction Per Day must be between 1 and 99");
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toastr.error("Transaction Per Week must be between 1 and 999");
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toastr.error("Transaction Per Month must be between 1 and 9999");
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        } else if (cardData.VIN && !utils.isValidAlphaNumeric(cardData.VIN)) {
          toastr.error("Special symbols/characters are not allowed in VIN");
        }
        // else if (restrictData.MaxDollarsPerTransactions && restrictData.MaxDollarsPerTransactions < 100) {
        //     toastr.error("Max Dollars Per Transactions must be between $100 and $999,999,999");
        // }
        else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          toastr.error("Driver Pin Must be Entered");
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
        }
        // else if (restrictData.authCode && !utils.isValidAlphaNumeric(restrictData.authCode)) {
        //     toastr.error("Special symbols/characters are not allowed in Auth Code");
        // }
        else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (restrictData.LimitCode) {
            var selectedGallonsPerTransaction = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
              { listValue: restrictData.LimitCode },
              true
            );
            if (selectedGallonsPerTransaction.length > 0) {
              newRestrictData.LimitCode =
                selectedGallonsPerTransaction[0].listDescription;
            }
          }
          if (restrictData.RestrictionCode) {
            var selectedRestrictionCode = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.restrictionCodes,
              { listValue: restrictData.RestrictionCode },
              true
            );
            if (selectedRestrictionCode.length > 0) {
              newRestrictData.RestrictionCode =
                selectedRestrictionCode[0].listDescription;
            }
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (restrictData.ProdCode) {
            var selectedProduct = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.allowedProducts,
              { listValue: restrictData.ProdCode },
              true
            );
            if (selectedProduct) {
              if (selectedProduct.length > 0) {
                newRestrictData.Products = selectedProduct[0].listDescription;
              }
            }
          }
          if (cardData.LicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.LicenseState },
              true
            );
            if (selectedLicenseState) {
              if (selectedLicenseState.length > 0) {
                newCardData.LicenseState = selectedLicenseState[0].description;
              }
            }
          }
          newCardData.CardStatus = "A";
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            errorMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(errorMsg);
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }

    function validateClassicUnitedCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        restrictData.DollarsPerCycle &&
        restrictData.RestrictionCode &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department "
          );
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toastr.error("Transaction Per Day must be between 1 and 99");
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toastr.error("Transaction Per Week must be between 1 and 999");
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toastr.error("Transaction Per Month must be between 1 and 9999");
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        }
        // else if (restrictData.MaxDollarsPerTransactions && restrictData.MaxDollarsPerTransactions < 100) {
        //     toastr.error("Max Dollars Per Transactions must be between $100 and $999,999,999");
        // }
        else if (
          restrictData.authCode &&
          !utils.isValidAlphaNumeric(restrictData.authCode)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.noSpecialCharAuth;
          toastr.error(errorMsg);
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {          
          errorMsg = toastrMessage.cardControlErrorMessage.enterDriverPin;
          toastr.error(errorMsg);
        } else if (cardData.PinStatus === "N" && !cardData.PinType) {
          errorMsg = toastrMessage.cardControlErrorMessage.allDriver;
          toastr.error(errorMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          var newCardData = JSON.parse(JSON.stringify(cardData));
          // if(restrictData.RestrictionCode){
          // newRestrictData.RestrictionCode = restrictData.RestrictionCode.listValue;
          // }
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode) {
            if (selectedRestrictionCode.length > 0) {
              newRestrictData.RestrictionCode =
                selectedRestrictionCode[0].listDescription;
            }
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          // if(cardData.Department){
          //     newCardData.Department = cardData.Department.description;
          //     newCardData.DeptId = cardData.Department.code;
          // }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          newCardData.CardStatus = "A";
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            errorMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(errorMsg);
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }

    function validateClassicDriverTiedCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        restrictData.DollarsPerCycle &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        //restrictData.RestrictionCode
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department "
          );
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toastr.error("Transaction Per Day must be between 1 and 99");
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toastr.error("Transaction Per Week must be between 1 and 999");
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toastr.error("Transaction Per Month must be between 1 and 9999");
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        }
        // else if (restrictData.MaxDollarsPerTransactions && restrictData.MaxDollarsPerTransactions < 100) {
        //     toastr.error("Max Dollars Per Transactions must be between $100 and $999,999,999");
        // }
        else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          errorMsg = toastrMessage.cardControlErrorMessage.enterDriverPin;
          toastr.error(errorMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
        } else if (cardData.Driver && cardData.Driver.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.minDriverId;
          toastr.error(errorMsg);
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          // newCardData.Department = cardData.Department.description;
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode.length > 0) {
            newRestrictData.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (cardData.LicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.LicenseState },
              true
            );
            if (selectedLicenseState.length > 0) {
              newCardData.LicenseState = selectedLicenseState[0].description;
            }
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          newCardData.CardStatus = "A";
          // if(cardData.CardDescription){
          //     newCardData.DriverName = cardData.CardDescription;
          // }
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            errorMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(errorMsg);
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }

    function validateVehicleTiedToCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.RestrictionCode &&
        cardData.PinType &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        restrictData.DollarsPerCycle &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          errorMsg = toastrMessage.cardControlErrorMessage.enterDriverPin;
          toastr.error(errorMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
        } else if (cardData.VIN && !utils.isValidAlphaNumeric(cardData.VIN)) {
          toastr.error("Special symbols/characters are not allowed in VIN");
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toastr.error("Transaction Per Day must be between 1 and 99");
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toastr.error("Transaction Per Week must be between 1 and 999");
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toastr.error("Transaction Per Month must be between 1 and 9999");
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        }
        // else if (restrictData.MaxDollarsPerTransactions && restrictData.MaxDollarsPerTransactions < 100) {
        //     toastr.error("Max Dollars Per Transactions must be between $100 and $999,999,999");
        // }
        // else if (restrictData.authCode && !utils.isValidAlphaNumeric(restrictData.authCode)) {
        //     toastr.error("Special symbols/characters are not allowed in Auth Code");
        // }
        else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode.length > 0) {
            newRestrictData.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
          if (restrictData.StateRestrictions) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (cardData.LicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.LicenseState },
              true
            );
            if (selectedLicenseState.length > 0) {
              newCardData.LicenseState = selectedLicenseState[0].description;
            }
          }
          newCardData.CardStatus = "A";
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            errorMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(errorMsg);
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }

    function validateUnitedCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        fleetCardVM.restrictionsForm[index].$valid &&
        restrictData.RestrictionCode &&
        restrictData.DollarsPerCycle
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toastr.error("Transaction Per Day must be between 1 and 99");
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toastr.error("Transaction Per Week must be between 1 and 999");
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toastr.error("Transaction Per Month must be between 1 and 9999");
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        } else if (
          restrictData.authCode &&
          !utils.isValidAlphaNumeric(restrictData.authCode)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.noSpecialCharAuth;
          toastr.error(errorMsg);
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          errorMsg = toastrMessage.cardControlErrorMessage.enterDriverPin;
          toastr.error(errorMsg);
        } else if (cardData.PinStatus === "N" && !cardData.PinType) {
          errorMsg = toastrMessage.cardControlErrorMessage.allDriver;
          toastr.error(errorMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          var newCardData = JSON.parse(JSON.stringify(cardData));
          // if (cardData.Department) {
          // newCardData.Department = cardData.Department.description;
          // newCardData.DeptId = cardData.Department.code;
          // }
          // if(restrictData.RestrictionCode.listValue){
          // newRestrictData.RestrictionCode = restrictData.RestrictionCode.listValue;
          // }
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode.length > 0) {
            newRestrictData.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          newCardData.CardStatus = "A";
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            errorMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(errorMsg);
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }

    function validateDriverTiedToCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        restrictData.RestrictionCode &&
        restrictData.DollarsPerCycle &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.DriverName &&
          !utils.isValidAlphaNumeric(cardData.DriverName)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.noSpecialCharName;
          toastr.error(errorMsg);
        } else if (cardData.driverID && cardData.driverID.length < 3) {
          toastr.error("Driver ID should be minimum 3 characters");
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department "
          );
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toastr.error("Transaction Per Day must be between 1 and 99");
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toastr.error("Transaction Per Week must be between 1 and 999");
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toastr.error("Transaction Per Month must be between 1 and 9999");
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          errorMsg = toastrMessage.cardControlErrorMessage.enterDriverPin;
          toastr.error(errorMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
        } else if (cardData.Driver && cardData.Driver.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.minDriverId;
          toastr.error(errorMsg);
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          // newCardData.Department = cardData.Department.description;
          // newCardData.DriversLicenseState = cardData.DriversLicenseState.type;
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode.length > 0) {
            newRestrictData.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (cardData.DriversLicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.DriversLicenseState },
              true
            );
            if (selectedLicenseState.length > 0) {
              newCardData.DriversLicenseState =
                selectedLicenseState[0].description;
            }
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          // newRestrictData.PurchaseDaysAllowed = restrictData.PurchaseDaysAllowed.name;
          // newRestrictData.StateRestrictions = restrictData.StateRestrictions.name;
          newCardData.CardStatus = "A";
          // if(cardData.CardDescription){
          //     newCardData.DriverName = cardData.CardDescription;
          // }
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            errorMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(errorMsg);
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }

    function validateClassicVehicleTiedCard(
      cardData,
      restrictData,
      index,
      autoGenPin
    ) {
      if (
        cardData.CardDescription &&
        restrictData.RestrictionCode &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        restrictData.DollarsPerCycle &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else if (cardData.VIN && !utils.isValidAlphaNumeric(cardData.VIN)) {
          toastr.error("Special symbols/characters are not allowed in VIN");
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toastr.error("Transaction Per Day must be between 1 and 99");
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toastr.error("Transaction Per Week must be between 1 and 999");
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toastr.error("Transaction Per Month must be between 1 and 9999");
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(errorMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        }
        // else if (restrictData.MaxDollarsPerTransactions && restrictData.MaxDollarsPerTransactions < 100) {
        //     toastr.error("Max Dollars Per Transactions must be between $100 and $999,999,999");
        // }
        else if (
          cardData.PinType === "SINGLE" &&
          !fleetCardVM.currentLinkedPin &&
          !autoGenPin
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.enterDriverPin;
          toastr.error(errorMsg);
        } else if (
          // cardData.LinkedPin &&
          // cardData.LinkedPin.length < 4 &&
          fleetCardVM.currentLinkedPin &&
          fleetCardVM.currentLinkedPin.length < 4 &&
          !autoGenPin
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
        }
        // else if (restrictData.authCode && !utils.isValidAlphaNumeric(restrictData.authCode)) {
        //     toastr.error("Special symbols/characters are not allowed in Auth Code");
        // }
        else {
          alert(
            "This request has been submitted. Please allow 2 business days to process this request"
          );
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          // if (cardData.Department) {
          //     newCardData.Department = cardData.Department.description;
          // }
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode.length > 0) {
            newRestrictData.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
          if (restrictData.StateRestrictions) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (cardData.LicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.LicenseState },
              true
            );
            if (selectedLicenseState.length > 0) {
              newCardData.LicenseState = selectedLicenseState[0].description;
            }
          }
          newCardData.CardStatus = "A";
          if (autoGenPin) newCardData.LinkedPin = "AUTO";
          else newCardData.LinkedPin = fleetCardVM.currentLinkedPin;
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            errorMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(errorMsg);
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }

    function validateNfSingleCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.ProductAuthCode &&
        cardData.Prompt &&
        restrictData.LimitProfile
      ) {
        //cardData.Prompt && restrictData.LimitDesc
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Card Description"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          errorMsg = toastrMessage.cardControlErrorMessage.enterDriverPin;
          toastr.error(errorMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
        } else if (
          cardData.EmailReceiptChecked === "Y" &&
          cardData.ReceiptEmailID &&
          !utils.isValidEmail(cardData.ReceiptEmailID)
        ) {
          toastr.error("Enter Valid Email ID");
        } else if (
          cardData.EmailReceiptChecked === "Y" &&
          !cardData.ReceiptEmailID
        ) {
          toastr.error("Please Enter Email ID");
        } else {
          //api call
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          // newCardData.Department = cardData.Department.description;
          // newCardData.Prompt = cardData.Prompt.name;
          // newRestrictData.SiteAllowed = restrictData.SiteAllowed.listDescription;
          // newRestrictData.Products = restrictData.Products.listDescription;
          // newRestrictData.LimitDesc = restrictData.LimitDesc.name;
          // newRestrictData.TimeProfile = restrictData.TimeProfile.listDescription;
          newCardData.CardStatus = "A";
          if (restrictData.ProductAuthCode) {
            var selectedProducts = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.products,
              { listValue: restrictData.ProductAuthCode },
              true
            );
            if (selectedProducts) {
              if (selectedProducts.length > 0) {
                newRestrictData.ProductAuthDesc =
                  selectedProducts[0].listDescription;
              }
            }
          }
          if (newRestrictData.Products) {
            delete newRestrictData.Products;
            newRestrictData.Products = newRestrictData.ProductAuthDesc;
          }
          saveCardDetails(newCardData, newRestrictData);
        }
      } else {
        toastr.error("Please enter all the required fields");
      }
    }

    /*saves card details and restrict details*/
    function saveCardDetails(cardData, restrictData) {
      $rootScope.dataLoading = true;

      // var payLoad = {
      //     "network": fleetCardVM.network,
      //     "cardType": fleetCardVM.cardType,
      //     "cardDetails": cardData,
      //     "restrictionDetails": restrictData
      // };
      //Api call

      // debugger;
      // var newCardData = JSON.parse(JSON.stringify(cardData));
      // var newRestrictData = JSON.parse(JSON.stringify(restrictData));
      // newCardData.MiscEntry = cardData.MiscEntry.name;
      // newRestrictData.Products = restrictData.Products.name;
      var payLoad = {
        AccountNo: localStorage.selectedAccount,
        CardNo: "",
        OrgId: localStorage.orgID,
        CardTypeDescription: fleetCardVM.cardType.description,
        CardTypeCode: fleetCardVM.cardType.code,
        Network: fleetCardVM.network.description,
        NetworkCode: fleetCardVM.network.code,
        Model: {
          CardDetails: cardData,
          RestrictionDetails: restrictData,
        },
        DeltaModel: null,
      };
      // var payLoad = {
      //     "CardDetails":{
      //             "CardDescription": "BUS 15",
      //             "CardStatus": "Active",
      //             "DriverPin": "000342",
      //             "VehicleId": "000156",
      //             "License": "Test License",
      //             "VIN": "000968356",
      //             "Department": "Department 1"
      //     },
      //     "RestrictionDetails":{
      //         "TransactionsPerDay":{
      //             "value":"10","limit":"Hard"
      //         },
      //         "TransactionsPerWeek":{
      //             "value":"30","limit":"Soft"
      //         },
      //         "TransactionsPerMonth":{
      //             "value":"150","limit":"Hard"
      //         },
      //         "ValidTimeofDay":{
      //             "from":"10.00","to":"18.00"
      //         },
      //         "PurchaseDaysAllowed":"Monday-Friday"
      //     }
      //     }
      cardManagementService
        .saveFleetCardDetails(payLoad)
        .then(saveFleetCardDetailsSuccess, saveFleetCardDetailsError);
    }

    function saveFleetCardDetailsSuccess(apiResponse) {
      if (apiResponse.statusCode === 200) {
        $rootScope.dataLoading = false;
        toastr.success("Saved Successfully.");
        $state.go("root.fleetCard");
      }
    }

    function saveFleetCardDetailsError() {
      $rootScope.dataLoading = false;
      toastr.error("Failed to save");
    }

    function managePinClick(index) {
      fleetCardVM.showManageDriver[index] =
        !fleetCardVM.showManageDriver[index];
      if (fleetCardVM.showManageDriver[index]) {
        fleetCardVM.cardDetails[index].driverDetails = [
          {
            driverId: "D001412",
            firstName: "Philip",
            lastName: "Hart",
            linkStatus: "Available",
          },
          {
            driverId: "D001246",
            firstName: "Gerald",
            lastName: "Harrison",
            linkStatus: "Available",
          },
          {
            driverId: "D001419",
            firstName: "Samuel",
            lastName: "Ryes",
            linkStatus: "Linked",
          },
        ];
      }
    }

    function editDriverDetails(parent, index) {
      fleetCardVM.editDriver = [];
      fleetCardVM.editDriver[parent] = [];
      if (fleetCardVM.isAddNewDriver[parent]) {
        fleetCardVM.isAddNewDriver[parent] = false;
        fleetCardVM.cardDetails[parent].driverDetails.splice(0, 1);
      }
      fleetCardVM.editDriver[parent][index] = true;
    }

    function addNewDriver(driverIndex) {
      if (!fleetCardVM.isAddNewDriver[driverIndex]) {
        var tempObj = {
          driverId: "",
          firstName: "",
          lastName: "",
          linkStatus: "",
        };
        fleetCardVM.cardDetails[driverIndex].driverDetails.splice(
          0,
          0,
          tempObj
        );
        fleetCardVM.isAddNewDriver[driverIndex] = true;
        fleetCardVM.editDriver = [];
        fleetCardVM.editDriver[driverIndex] = [];
        fleetCardVM.editDriver[driverIndex][0] = true;
      }
    }

    function saveDriverDetails(index) {}

    function cancelAddDriver(driverIndex, index) {
      fleetCardVM.editDriver = [];
      fleetCardVM.editDriver[driverIndex] = [];
      if (fleetCardVM.isAddNewDriver[driverIndex]) {
        fleetCardVM.isAddNewDriver[driverIndex] = false;
        fleetCardVM.cardDetails[driverIndex].driverDetails.splice(0, 1);
      }
      fleetCardVM.editDriver[driverIndex][index] = false;
    }

    function deleteDriverDetails(parent, index) {
      deleteDriverSuccess(parent, index);
      // $confirm({
      //     title: "Delete",
      //     text: "Do you want to delete this driver from list?",
      //     ok: "Yes",
      //     cancel: "No"
      // }, {
      //     size: 'sm',
      //     backdrop: true
      // }).then(function() {
      //     // cardManagementService.deleteDriver()
      //     // .then(deleteDriverSuccess, deleteDriverFailure);
      //     // deleteDriverSuccess();
      // });
    }

    function deleteDriverSuccess(parent, index) {
      fleetCardVM.cardDetails[parent].driverDetails.splice(index, 1);
    }

    function deleteDriverFailure() {}
    /*To get the Time profile list*/
    function manageTimeProfile(index) {
      fleetCardVM.showTimeProfile[index] = !fleetCardVM.showTimeProfile[index];
      if (fleetCardVM.showTimeProfile[index]) {
        fleetCardVM.timeProfileDetails[index] = [
          {
            profileName: "D001412",
            profileDesc: "Philip",
          },
          {
            profileName: "D001246",
            profileDesc: "Gerald",
          },
          {
            profileName: "D001419",
            profileDesc: "Samuel",
          },
        ];
      }
    }
    /*To edit time profile*/
    function editProfileDetails(parent, index) {
      fleetCardVM.editProfile = [];
      fleetCardVM.editProfile[parent] = [];
      if (fleetCardVM.isAddNewProfile[parent]) {
        fleetCardVM.isAddNewProfile[parent] = false;
        fleetCardVM.timeProfileDetails[parent].splice(0, 1);
      }
      fleetCardVM.editProfile[parent][index] = true;
    }
    /*To add new time profile*/
    function addNewProfile(profileIndex) {
      if (!fleetCardVM.isAddNewProfile[profileIndex]) {
        var profileObj = {
          profileName: "",
          profileDesc: "",
        };
        fleetCardVM.timeProfileDetails[profileIndex].splice(0, 0, profileObj);
        fleetCardVM.isAddNewProfile[profileIndex] = true;
        fleetCardVM.editProfile = [];
        fleetCardVM.editProfile[profileIndex] = [];
        fleetCardVM.editProfile[profileIndex][0] = true;
      }
    }
    /*Save new Time Profile*/
    function saveNewProfile(profileIndex) {
      //save api call
    }
    /*cancel new Time profile*/
    function cancelAddProfile(parent, index) {
      fleetCardVM.editProfile = [];
      fleetCardVM.editProfile[parent] = [];
      if (fleetCardVM.isAddNewProfile[parent]) {
        fleetCardVM.isAddNewProfile[parent] = false;
        fleetCardVM.timeProfileDetails[parent].splice(0, 1);
      }
      fleetCardVM.editProfile[parent][index] = false;
    }
    /*delete Time profile*/
    function deleteProfileDetails(parent, index) {
      //delete api call
    }
    /*confirmation for locking */
    function changeStatus(status, index) {
      // debugger;
      if (status === "A") {
        fleetCardVM.cardDetails[index].CardStatus = "A";
      } else if ((status = "L")) {
        fleetCardVM.cardDetails[index].CardStatus = "L";
      }

      // else if (status === 'L' && fleetCardVM.cardDetails[index].CardStatus === 'A') {
      //     $confirm({
      //         title: "Lock Card",
      //         text: "Pin linked to card, would you also like to lock card?",
      //         ok: "Yes",
      //         cancel: "No"
      //     }, {
      //             size: 'sm'
      //         }).then(function () {
      //             fleetCardVM.cardDetails[index].CardStatus = 'L';
      //         }).catch(function () {
      //             fleetCardVM.cardDetails[index].CardStatus = 'A';
      //         });
      // }
    }
    /*confirmation for pin */
    function changePIN(status, index) {
      fleetCardVM.cardDetails[index].PinStatus = "";
      if (fleetCardVM.cardDetails[index].PinType) {
        fleetCardVM.cardDetails[index].PinType = "";
      }
      if (fleetCardVM.cardDetails[index].LinkedPin) {
        fleetCardVM.cardDetails[index].LinkedPin = null;
      }
      if (status === "SINGLE") {
        fleetCardVM.cardDetails[index].PinType = "SINGLE";
        toastr.warning(
          "By selecting 'Yes' to the Driver Pin, only the assigned Driver Pin can be used with this card."
        );
      } else if (status === "FLOAT DRIVER") {
        fleetCardVM.cardDetails[index].PinType = "FLOAT DRIVER";
        toastr.warning(
          "By Selecting 'No' the Driver Pin, all active/newly added Driver IDs in Driver Management can be used with this card."
        );
      } else if (status === "FLOAT VEHICLE") {
        fleetCardVM.cardDetails[index].PinType = "FLOAT VEHICLE";
      } else if (status === "N") {
        fleetCardVM.cardDetails[index].PinStatus = "N";
      }
    }

    /*confirmation for driver pin */
    function changeDriverPIN(status, index) {
      fleetCardVM.cardDetails[index].PinType = status;
    }

    /*Apply button click in State Restriction*/
    function applyStateRestriction(index) {
      if (fleetCardVM.network.description !== "CFN") {
        toastr.warning(
          "State restrictions entered will not appear as SAVED in the Portal, but the changes will be effective in the Network"
        );
      }
      fleetCardVM.showStatePopup[index] = false;
      fleetCardVM.restrictionDetails[index].StateRestrictions = [];
      angular.forEach(
        fleetCardVM.dropDownData.stateRestrictions,
        function (item) {
          if (item.isChecked) {
            fleetCardVM.restrictionDetails[index].StateRestrictions.push(
              item.description
            );
          }
        }
      );
      fleetCardVM.stateRestriction[index] =
        fleetCardVM.restrictionDetails[index].StateRestrictions.toString();
    }
    function selectStateRestrictions(index, states) {
      fleetCardVM.showStatePopup[index] = !fleetCardVM.showStatePopup[index];
      if (fleetCardVM.showStatePopup[index]) {
        if (fleetCardVM.dropDownData.stateRestrictions) {
          angular.forEach(
            fleetCardVM.dropDownData.stateRestrictions,
            function (dropdownItem) {
              dropdownItem.isChecked = false;
              fleetCardVM.select = false;
            }
          );
        }
        var stateList = [];
        if (states) {
          stateList = states.split(",");
          angular.forEach(stateList, function (listItem) {
            angular.forEach(
              fleetCardVM.dropDownData.stateRestrictions,
              function (item) {
                if (listItem.indexOf(item.description) === 0) {
                  item.isChecked = true;
                }
              }
            );
          });
        }
      }
    }

    function selectAllStates(item) {
      console.log("item", item);
      // fleetCardVM.select = !fleetCardVM.select;
      if (item) {
        angular.forEach(
          fleetCardVM.dropDownData.stateRestrictions,
          function (item) {
            item.isChecked = true;
          }
        );
      } else {
        angular.forEach(
          fleetCardVM.dropDownData.stateRestrictions,
          function (item) {
            item.isChecked = false;
          }
        );
      }
    }

    /*Adds new zipcode*/
    function addNewZipcode(index) {
      var isNull = false;
      angular.forEach(
        fleetCardVM.restrictionDetails[index].zipCodeLockout,
        function (item) {
          if (!item.name) {
            isNull = true;
          }
        }
      );
      if (isNull) {
        errorMsg = toastrMessage.cardControlErrorMessage.enterZip;
        toastr.error(errorMsg);
      } else {
        isNull = false;
        fleetCardVM.restrictionDetails[index].zipCodeLockout.push({ name: "" });
      }
    }
    /*Checks if there is duplicate zipcode entries*/
    function checkDuplicate(zipcode, parent, index) {
      var isExists = false;
      angular.forEach(
        fleetCardVM.restrictionDetails[parent].zipCodeLockout,
        function (item, key) {
          if (key !== index && zipcode === item.name) {
            isExists = true;
          }
        }
      );
      if (isExists) {
        isExists = false;
        toastr.error("Zipcode already exists");
      }
    }

    function deleteZipcode(zipcode, index) {
      $confirm(
        {
          title: "Delete",
          text: "Do you want to delete this Zipcode from list?",
          ok: "Yes",
          cancel: "No",
        },
        {
          size: "sm",
          backdrop: true,
        }
      ).then(function () {
        angular.forEach(
          fleetCardVM.restrictionDetails[index].zipCodeLockout,
          function (item, key) {
            if (zipcode === item.name) {
              fleetCardVM.restrictionDetails[index].zipCodeLockout.splice(
                key,
                1
              );
            }
          }
        );
      });
    }

    /*event handling of pop up clicks*/
    // $rootScope.$on('hidePopup', function() {
    //     fleetCardVM.showDaysPopup = false;
    //     $scope.$apply();
    // });

    /*apply button click on the pop up network,status,linked card*/
    function applyPopupDropdown(popupItem) {
      // debugger;
      fleetCardVM.restrictionDetails[0].PurchaseDays = [];
      for (
        var i = 0;
        i < fleetCardVM.dropDownRestrictionData.purchaseDaysAllowed.length;
        i++
      ) {
        if (
          fleetCardVM.dropDownRestrictionData.purchaseDaysAllowed[i].isChecked
        ) {
          fleetCardVM.restrictionDetails[0].PurchaseDays.push(
            fleetCardVM.dropDownRestrictionData.purchaseDaysAllowed[i].type
          );
        }
      }
      fleetCardVM.showDaysPopup = false;

      // promptsVM.showNetworkPopup = false;
      // promptsVM.showStatusPopup = false;
      // promptsVM.showLinkedPopup = false;
      // if (popupItem === 'network') {
      //     promptsVM.searchParams.network = [];
      //     angular.forEach(promptsVM.dropDownData.network, function(networkItem) {
      //         if (networkItem.isChecked) {
      //             promptsVM.searchParams.network.push(networkItem.type);
      //         }
      //     });
      //     console.log(promptsVM.searchParams.network);
      // }
      // if (popupItem === 'status') {
      //     promptsVM.searchParams.status = [];
      //     angular.forEach(promptsVM.dropDownData.status, function(statusItem) {
      //         if (statusItem.isChecked) {
      //             promptsVM.searchParams.status.push(statusItem.type);
      //         }
      //     });
      //     console.log(promptsVM.searchParams.status);
      // }
      // if (popupItem === 'linkedCard') {
      //     promptsVM.searchParams.linkedCard = [];
      //     angular.forEach(promptsVM.dropDownData.linkedCard, function(linkedItem) {
      //         if (linkedItem.isChecked) {
      //             promptsVM.searchParams.linkedCard.push(linkedItem.type);
      //         }
      //     });
      //     console.log(promptsVM.searchParams.linkedCard);
      // }
    }

    // function validatePin(cardNo, cardTypeCode, network) {
    //     if (cardTypeCode === 'D') {
    //         var requestData = {
    //             // cardNo: cardNo,
    //             // cardTypeCode: cardTypeCode
    //             "AccountNumber": localStorage.selectedAccount,
    //             "PinNo": cardNo,
    //             "NetworkCode": fleetCardVM.network.code,
    //             "CardTypeCode": cardTypeCode
    //         }
    //         cardManagementService.getPinValidation(requestData)
    //             .then(getPinValidationSuccess, getPinValidationError);
    //     }
    // }

    // function getPinValidationSuccess(apiResponse) {
    //     if (apiResponse.dataModel && apiResponse.dataModel.isLinked) {
    //         toastr.warning("Pin is already linked to a card, please choose another.");
    //     }
    // }

    // function getPinValidationError() {

    // }

    /** VOYAGER CARD + DRIVER , VOYAGER 1-8 CARD + DRIVER*/
    function validatePin(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          fleetCardVM.cardDetails[0].LinkedPin = null;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          fleetCardVM.cardDetails[0].LinkedPin = null;
        } else {
          // if (cardNo.length === 4) {
          if (parseInt(cardNo.slice(0, 2)) === 0) {
            toastr.error(toastrMessage.common.voyagerPinValidation);
            fleetCardVM.cardDetails[0].LinkedPin = null;
            return;
          }
          // }
          // else if (cardNo.length > 4) {
          //     if (parseInt(cardNo.slice(0, 1)) === 0) {
          //         toastr.error(toastrMessage.common.voyagerPinValidation);
          //         fleetCardVM.cardDetails[0].LinkedPin = null;
          //         return;
          //     }
          // }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: fleetCardVM.network.code,
          };
          cardManagementService
            .getPinValidation(requestData)
            .then(getPinValidationSuccess, getPinValidationError);
        }
      }
    }

    function getPinValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isExists) {
        fleetCardVM.cardDetails[0].LinkedPin = null;
        toastr.warning("Pin already exist.");
      }
    }

    function getPinValidationError() {}

    /**  VOYAGER CARD + VEHICLE,
     * VOYAGER CARD UNTIED , VOYAGER 1-8 CARD + VEHICLE , VOYAGER 1-8 CARD UNTIED , VOYAGER SECURE , VOYAGER PLUS
     */
    function validateDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          fleetCardVM.cardDetails[0].LinkedPin = null;
          fleetCardVM.invalidPin = true;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          fleetCardVM.cardDetails[0].LinkedPin = null;
          fleetCardVM.invalidPin = true;
        } else {
          // if (cardNo.length === 4) {
          if (parseInt(cardNo.slice(0, 2)) === 0) {
            toastr.error(toastrMessage.common.voyagerPinValidation);
            fleetCardVM.cardDetails[0].LinkedPin = null;
            fleetCardVM.invalidPin = true;
            return;
          }
          // }
          // else if (cardNo.length > 4) {
          //     if (parseInt(cardNo.slice(0, 1)) === 0) {
          //         toastr.error(toastrMessage.common.voyagerPinValidation);
          //         fleetCardVM.cardDetails[0].LinkedPin = null;
          //         return;
          //     }
          // }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: fleetCardVM.network.code,
          };
          fleetCardVM.invalidPin = false;
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    /** CFN - SINGLE , */
    function validateCFNDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.cfnPinValidation);
          fleetCardVM.cardDetails[0].LinkedPin = null;
          fleetCardVM.invalidPin = true;
          return;
        }
        if (cardNo.length < 5) {
          toastr.error(toastrMessage.common.cfnPinValidation);
          fleetCardVM.cardDetails[0].LinkedPin = null;
          fleetCardVM.invalidPin = true;
        } else {
          if (parseInt(cardNo.slice(0, 1)) === 0) {
            toastr.error(toastrMessage.common.cfnPinValidation);
            fleetCardVM.cardDetails[0].LinkedPin = null;
            fleetCardVM.invalidPin = true;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: fleetCardVM.network.code,
          };
          fleetCardVM.invalidPin = false;
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function validatePacprideDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.pacpridePinValidation);
          fleetCardVM.cardDetails[0].LinkedPin = null;
          fleetCardVM.invalidPin = true;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.pacpridePinValidation);
          fleetCardVM.cardDetails[0].LinkedPin = null;
          fleetCardVM.invalidPin = true;
        } else {
          if (parseInt(cardNo.slice(0, 3)) === 0) {
            toastr.error(toastrMessage.common.pacpridePinValidation);
            fleetCardVM.cardDetails[0].LinkedPin = null;
            fleetCardVM.invalidPin = true;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: fleetCardVM.network.code,
          };
          fleetCardVM.invalidPin = false;
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function validateCFSStdDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          errorMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
          toastr.error(errorMsg);
          fleetCardVM.cardDetails[0].LinkedPin = null;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error("Pin must be 4 digit");
          fleetCardVM.cardDetails[0].LinkedPin = null;
        } else {
          if (parseInt(cardNo.slice(0, 1)) === 0) {
            errorMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
            toastr.error(errorMsg);
            fleetCardVM.cardDetails[0].LinkedPin = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: fleetCardVM.network.code,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function validateNFDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          errorMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
          toastr.error(errorMsg);
          fleetCardVM.cardDetails[0].LinkedPin = null;
          return;
        }
        if (cardNo.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
          fleetCardVM.cardDetails[0].LinkedPin = null;
        } else {
          if (cardNo.length === 4) {
            if (parseInt(cardNo.slice(0, 2)) === 0) {
              errorMsg =
                toastrMessage.cardControlErrorMessage.invalidPinDriverId;
              toastr.error(errorMsg);
              fleetCardVM.cardDetails[0].LinkedPin = null;
              return;
            }
          } else if (cardNo.length > 4) {
            if (parseInt(cardNo.slice(0, 1)) === 0) {
              errorMsg =
                toastrMessage.cardControlErrorMessage.invalidPinDriverId;
              toastr.error(errorMsg);
              fleetCardVM.cardDetails[0].LinkedPin = null;
              return;
            }
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: fleetCardVM.network.code,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function getDriverValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isLinked) {
        fleetCardVM.cardDetails[0].LinkedPin = null;
        toastr.warning("Driver already linked.");
      }
    }

    function getDriverValidationError() {}

    /** VOYAGER CARD + DRIVER , VOYAGER 1-8 CARD + DRIVER*/
    function validateVoyagerCardDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          errorMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
          toastr.error(errorMsg);
          fleetCardVM.cardDetails[0].Driver = null;
          return;
        }
        if (cardNo.length < 4) {
          errorMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(errorMsg);
          fleetCardVM.cardDetails[0].Driver = null;
        } else {
          // if (cardNo.length === 4) {
          if (parseInt(cardNo.slice(0, 2)) === 0) {
            errorMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
            toastr.error(errorMsg);
            fleetCardVM.cardDetails[0].Driver = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: fleetCardVM.network.code,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getCardDriverValidationSuccess, getCardDriverValidationError);
        }
      }
    }

    function getCardDriverValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isLinked) {
        fleetCardVM.cardDetails[0].Driver = null;
        toastr.warning("Driver already linked.");
      }
    }

    function getCardDriverValidationError() {}

    /** NF - SingleCard , CFN - SINGLE , CFN FLEETWIDE VEHICLE , PPSI DIRECT ID , PPSI POOLED VEHICLE , VOYAGER CARD + VEHICLE,
     * VOYAGER 1-8 CARD + VEHICLE , VOYAGER SECURE , VOYAGER PLUS
     */
    function validateVehicle(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          errorMsg = toastrMessage.cardControlErrorMessage.invalidVhclId;
          toastr.error(errorMsg);
          fleetCardVM.cardDetails[0].VehicleID = null;
          return;
        }
        var requestData = {
          AccountNumber: localStorage.selectedAccount,
          PinNo: cardNo,
          NetworkCode: fleetCardVM.network.code,
        };
        cardManagementService
          .getVehicleValidation(requestData)
          .then(getVehicleValidationSuccess, getVehicleValidationError);
      }
    }

    function getVehicleValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isLinked) {
        fleetCardVM.cardDetails[0].VehicleID = null;
        toastr.warning("Vehicle already linked.");
      }
    }

    function getVehicleValidationError() {}

    function changeEmail(index) {
      fleetCardVM.restrictionDetails[index].NotifyLimitEmail = "";
    }

    function changeDepartment(item) {
      if (item === "Add New") {
        fleetCardVM.showNewField = true;
      } else {
        fleetCardVM.newDepartment = "";
        fleetCardVM.showNewField = false;
      }
    }

    function changeEmailReceipt(item, index) {
      if (item === "Y") {
        fleetCardVM.cardDetails[index].EmailReceiptChecked = "Y";
      } else if (item === "N") {
        fleetCardVM.cardDetails[index].ReceiptEmailID = "";
        fleetCardVM.cardDetails[index].EmailReceiptChecked = "N";
      }
    }
    /* Function  to append zeros to driver pin*/
    function AppendZeroes(item, index) {
      if (item) {
        if (item.length === 4) {
          fleetCardVM.cardDetails[index].LinkedPin = "00" + item;
        } else if (item.length === 5) {
          fleetCardVM.cardDetails[index].LinkedPin = "0" + item;
        }
      }
    }

    function addZeroes(item, index) {
      if (item) {
        if (item.length === 4) {
          fleetCardVM.cardDetails[index].Driver = "00" + item;
        } else if (item.length === 5) {
          fleetCardVM.cardDetails[index].Driver = "0" + item;
        }
      }
    }

    function onResetChange(item) {
      fleetCardVM.restrictionDetails[0].ResetTypeValue = "";
      fleetCardVM.disableReset = false;
      if (item && item === "Daily") {
        fleetCardVM.disableReset = true;
      } else if (item && item === "End Of Month") {
        fleetCardVM.disableReset = true;
      }
    }

    function emailChange() {
      fleetCardVM.restrictionDetails[index].emailChecked;
    }

    function gallonChange(item) {
      if (!item) {
        fleetCardVM.restrictionDetails[0].NotifyGallonLimitCheck = undefined;
        if (fleetCardVM.restrictionDetails[0].notiffyGallonLimitCheck) {
          fleetCardVM.restrictionDetails[0].notiffyGallonLimitCheck = null;
        }
      }
    }
    gallonWeekendChange;
    function gallonWeekendChange(item) {
      if (!item) {
        fleetCardVM.restrictionDetails[0].NotifyGallonLimitWeekendCheck =
          undefined;
        if (fleetCardVM.restrictionDetails[0].notiffyGallonLimitWeekendCheck) {
          fleetCardVM.restrictionDetails[0].notiffyGallonLimitWeekendCheck =
            undefined;
        }
      }
    }

    /*when account changed*/
    $scope.$on("accountChanged", function () {
      var hasPermission = false;
      if (localStorage.accountPermission) {
        angular.forEach(
          JSON.parse(localStorage.accountPermission),
          function (item, key) {
            if (item.option.toLowerCase().indexOf("card management") !== -1) {
              if (item.displayName === "R" || item.displayName === "W") {
                hasPermission = true;
                init();
              }
            }
            var arrayLength =
              JSON.parse(localStorage.accountPermission).length - 1;
            if (key === arrayLength && !hasPermission) {
              $state.go("root.dashboard");
            }
          }
        );
      }
    });

    function changeAddr(defaultOrAlt, index) {
      if (defaultOrAlt == "default") {
        fleetCardVM.IsDefaultAddress = "true";
      } else {
        fleetCardVM.IsDefaultAddress = "false";
      }
    }

    function changeShipping(shippingCarrier, index) {
      if (shippingCarrier == "usps") {
        fleetCardVM.shippingCarrier = "usps";
      } else if (shippingCarrier == "fedex") {
        fleetCardVM.shippingCarrier = "fedex";
      }
    }

    function checkAutoGenPin() {
      fleetCardVM.disablePin = !fleetCardVM.disablePin;
      fleetCardVM.autoGeneratePin = fleetCardVM.disablePin;

      if (fleetCardVM.disablePin == true) {
        fleetCardVM.invalidPin = false;
        fleetCardVM.cardDetails[0].LinkedPin = null;
        // alert('This request has been submitted for processing.');
      }
    }
  }
})();
