/*jshint browser: true*/
/*global angular: true*/

(function() {
    'use strict';

    angular.module('toastrMessages', ['toastr'])
        .constant('toastrMessage', toastrMessageConstants());

    //--------------------------------------
    //function declarations

    function toastrMessageConstants() {
        return {
            common: {
                systemNetworkFailure: 'System or network error. Please try again later.',
                mandatoryValidation: 'Please complete all the required fields.',
                mandatoryValidations: 'Please complete all the required fields',
                emailValidation: 'Please provide a valid Email Address.',
                phoneNumberValidation: 'Please provide a valid phone number.',
                voyagerPinValidation: 'A four to six digit pin must be entered, only one preceding zeros allowed',
                pacpridePinValidation: 'A four to five digit pin must be entered, only two preceding zeros allowed',
                cfnPinValidation: 'A five digit pin must be entered, No preceding zeros allowed'
            },
            login: {
                loginValidation: 'Please enter valid credentials.',
                invalidUrl: 'Invalid URL.',
                linkExpired: 'Link is expired or invalid. Please return to SC Fuels Portal to request Password Reset.',
                passwordValidation: 'Password must be between 8-20 characters and include uppercase, lowercase, numeric, and special characters.',
                newPasswordValidation: 'New and Confirm passwords must match.',
                emailEmpty: 'Please provide an email address.',
                passwordReset: 'Your password reset link has been sent to your registered email address.',
                passwordResetSuccess: 'Your password has been changed successfully. Please try to login.'
            },
            userManagement: {
                searchCondition: 'Please enter at  least 3 characters for search option.',
                allFieldEmpty: 'Please complete all the fields.',
                singleUserPermission: 'Please assign at least one account to this new User.',
                multiUserPermission: 'Please assign at least one account to this new User(s).',
                singleNewUserSuccess: ' was added successfully.',
                multiNewUserSuccess: 'Users were added successfully.',
                userDelete: ' was deleted successfully.',
                deletePrompt: 'Please confirm. Do you want to delete ',
                emailExists: ' already exists.',
                deactivatedUser: ' is already exists but deactivated.Do you want to activate?',
                editUserPrompt: 'Current changes will be lost.Do you want to continue?',
                UpdateUser: ' information was updated successfully.'

            },
            audit: {
                scfidFieldRequired: 'Please enter SCFid for search.',
                auditSearchFieldRequired: 'Please enter search criteria.'
            },
            tootipMessage: {
                bulkWholesale: 'Delivered fuel products greater than 4K gallons',
                onSiteFuels: 'Delivered fuel products less than 4K gallons',
                lubricants: 'All lubricants, chemicals and DEF',
                fleetCards: 'Fleet fueling cards from Cardlock'
            },
            fleetCardsTooltip: {
                strictControl: 'This feature will either allow or disallow a card to access multiple pumps at the same time. If set to YES the driver will have to wait approximately 15 minutes between transactions. If set to NO the driver will be allowed to access multiple pumps at the same time.',
                miscEntry: 'This is an additional prompt that can be used for reporting ( i.e. vehicle #, job #, etc.)',
                prideAdvantage: {
                    zipcodeLockout: 'Zip code Lockout: You can lock out cards from fueling at PrideNet/Retail sites within specific zip codes. This does not apply to Pacific Pride branded locations.'
                },
                fleetWide: {
                    // pos_cfnNetSite: 'This feature allows additional Extended (Fuelman) sites to be used',
                    pos_cfnNetSite: 'This feature allows purchases at CFNnet locations – Non Cardlock Sites.',
                    // cfnNetSites_info: 'This feature allows additional Extended (Fuelman) sites to be used.',
                    cfnNetSites_info: 'This feature allows purchases at CFNnet locations – Non Cardlock Sites.',
                    zipcodeLockout: 'Zip code Lockout: You can lock out cards from fueling at extended access (Fuelman) sites within specific zip codes. This does not apply to CFN branded locations.',
                    stateRestriction: 'Purchases will not be allowed within the selected states and territories.',
                    gallonLimit: 'The amount of gallons a card is allowed to purchase per period.',
                    notifyLimit: 'Use the notify limit to be alerted when the card is about to reach the gallon limit for the period. For instance, a card with a 200 gallon limit can arrange to have an alert sent when it reaches 150 gallons.',
                    gallonLimitWeekend: 'The amount of gallons a card is allowed to purchase per weekend.',
                    notifyLimitWeekend: 'Use the notify limit to be alerted when the card is about to reach the gallon limit for the weekend. For instance, a card with a 200 gallon limit can arrange to have an alert sent when it reaches 150 gallons.',
                    pinMessage: '“By Selecting “No” the Driver Pin, all active/newly added Driver IDs in Driver Management can be used with this card.” “By selecting yes to the Driver Pin, only the assigned Driver Pin can be used with this card.”',
                    // statesAllowed:'This feature locks out FleetWide cards from purchasing fuel at Extended (Fuelman) sites by state.',
                    statesAllowed:'This feature opens up the card to fuel at extended(Fuelman) sites by state.',
                    resetOptions:"If resetting on Day of Month,enter the day you want the usage to reset, such as, 15 for the 15th of the month. If resetting on Day of Week, enter 1 – 7, where 1=Sunday 2=Monday, 3=Tuesday, 4=Wednesday, 5=Thursday, 6=Friday, 7=Saturday.",
                    zipCode: 'This feature locks out cards from purchasing fuel at Extended (Fuelman) sites by a specific zip code.',
                    driverPin: 'The Driver Pin text box and auto generate pin checkbox will only appear if the default "No" is changed to "Yes".'
                },
                voyager: {
                    hardOrSoft: 'Soft setting will allow driver to call customer service and receive one additional transaction (dollars used must be under limit for the cycle). Hard setting requires a Fleet Manager to call for approval.'
                }
            },
            cardControlErrorMessage:{
                someError: "Some error occurred",
                cannotEdit: "Not able to edit",
                enterZip: "Please enter Zip Code",
                zipExists: "Zip Code already exists",
                validCardNo: "Enter valid Card Number",
                validVehicleId: "Enter a valid Vehicle ID",
                validDriverPin: "Please enter a valid Driver Pin",
                validStartTime: "Please enter valid Start Time",
                validEndTime: "Please enter valid End Time",
                invalidPinDriverId: "Invalid Pins/Driver IDs",
                invalidVhclId: "Invalid Vehicle IDs",
                minDriverId: "Driver ID Must be minimum 4 Digits",
                endTimeGr8StartTime: "End Time should be greater than Start Time",
                clearInvalidTime: "Please clear invalid time",
                validPinMax4Digits: "Please enter a valid pin, pin cannot exceed 4 digits",
                transPerDay199: "Transaction Per Day must be between 1 and 99",
                dollarPerCycle: "Dollars Per Cycle must be between $100 and $999,999,999",
                dollarPerDay: "Dollars Per Day must be between $100 and $999,999,999",
                dollarPerWeek: "Dollars Per Week must be between $100 and $999,999,999",
                dollarPerMon: "Dollars Per Month must be between $100 and $999,999,999",                
                transPerWeek: "Transaction Per Week must be between 1 and 999",
                transPerMon: "Transaction Per Month must be between 1 and 9999",
                transPerCycle: "Transaction Per Cycle must be between 1 and 9999",                
                fourDigits: "Pin Must be minimum 4 Digits",
                pin4Digits: "Pin must be 4 digit",
                perGallonLimit: "Per Fill Gallon Limit must be between 1 and 9999",
                notifyEnterValue: "Notify When Value Must be Entered",
                notifyWeekend: "Notify Weekend When Value Must be Entered",
                validEmail: "Enter valid Email id",
                notifyEmail: "Notify Email ID Must be Entered",
                notifyLessGLimit: "Notify When Value Must be less than GallonLimit",
                notifyLessGLWeekend: "Notify When Value Must be less than GallonLimit Weekend",
                resetValue17: "Reset Value Must be b/w 1-7",
                resetValue131: "Reset Value Must be b/w 1-31",
                zip5Digits: "Zip Code Must be 5 Digits",
                allDriver: "Please select All Driver or All Vehicle",
                noSpecialCharCard: "Special symbols/characters are not allowed in Card Description",
                noSpecialCharDept: "Special symbols/characters are not allowed in New Department",
                noSpecialCharDeptDesc: "Special symbols/characters are not allowed in Department Description",
                noSpecialCharVin: "Special symbols/characters are not allowed in VIN",
                noSpecialCharAuth: "Special symbols/characters are not allowed in Auth Code",
                noSpecialCharVclDesc: "Special symbols/characters are not allowed in Vehicle Description",
                noSpecialCharName: "Special symbols/characters are not allowed in Driver Name",
                requiredField: 'Please enter all the required fields',
                validDescription: "Enter valid description",
                enterDriverPin: "Please Enter Driver PIN",
            },
            successMessage: {
                requestSubmitted: "Your request has been submitted. Please review User Activity to review additional details related to your request."
            }

        };
    }

})();
