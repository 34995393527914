/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.invoicePayments')
        .controller('openInvoiceController', openInvoiceController);


    openInvoiceController.$inject = ['$scope', '$state', 'toastr', '$window', 'invoicePaymentsService', 'toastrMessage', 'utils', '$rootScope', '$filter', '$confirm'];

    function openInvoiceController($scope, $state, toastr, $window, invoicePaymentsService, toastrMessage, utils, $rootScope, $filter, $confirm) {
        var openVM = this;
        openVM.amountDueType = '';
        openVM.amountDueDateType = '';
        openVM.invoiceCategoryType = '';
        openVM.firstDueAmount = '';
        openVM.secondDueAmount = '';
        openVM.startNumber = '';
        openVM.endNumber = '';
        openVM.maxPage = 2;
        openVM.searchInvoiceNo = '';
        openVM.serviceTypeId = 1;
        openVM.showInvoiceSearchButton = true;
        openVM.showAdvanceSearch = false;
        openVM.showEnquiry = false;
        openVM.removeGrouping = false;
        openVM.showColumnPopup = false;
        openVM.showInquiryPopup = [];
        openVM.customInquiry = '';
        openVM.showDownloadPopup = [];
        openVM.loggedInUser = localStorage.userType;
        openVM.startYear = utils.calenderStartYear;
        openVM.endYear = utils.calenderEndYear;
        openVM.datePicker = { startDate: null, endDate: null };
        openVM.init = init;
        openVM.toggleSearch = toggleSearch;
        openVM.getAllInvoices = getAllInvoices;
        openVM.searchOpenInvoices = searchOpenInvoices;
        openVM.invoiceSearch = invoiceSearch;
        openVM.toggleHeader = toggleHeader;
        openVM.arrangeTableData = arrangeTableData;
        openVM.openInquiryReasons = openInquiryReasons;
        openVM.paginationClick = paginationClick;
        openVM.saveInquiry = saveInquiry;
        openVM.checkInquiry = checkInquiry;
        openVM.submitInquiry = submitInquiry;
        openVM.download = download;
        openVM.downloadAttachment = downloadAttachment;
        openVM.orderByColumn = orderByColumn;
        openVM.groupingRemoval = groupingRemoval;
        openVM.clearCalender = clearCalender;
        openVM.selectServiceType = selectServiceType;
        openVM.unSavedChange = false;
        openVM.invoiceListLength = 0;
        openVM.popupOpen = false;
        openVM.selectAll = false;
        openVM.checkAllInvoices = checkAllInvoices;
        openVM.checkedInvoicesToExport = checkedInvoicesToExport;
        openVM.memoPopupInvoice = memoPopupInvoice;
       // openVM.isPopUp = [];
        openVM.invoicesNumber = [];
        openVM.indexMemo = 0 ;
        openVM.cancelMemo = cancelMemo;
       // openVM.isMemo = [];
        openVM.memoLength = 2000;
        openVM.saveMemo = saveMemo;
        openVM.customMemo = [];
        openVM.customMemoRemoval = "";
        openVM.isSubmit = false;
        openVM.saveIndex = 0;
        openVM.saveParentIndex = 0;
        init();
        $scope.inquiryDetailsLength =86;

        /*  function declarations */

        /*initial loading*/
        function init() {
            openVM.currentPage = 1;
            openVM.firstPageItem = 1;
            openVM.secondPageItem = 2;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item) {
                    if (item.option.toLowerCase().indexOf('invoice') !== -1) {
                        openVM.userPermission = item.displayName;
                    }
                });
            }
            getSearchAreaDetails();
            fetchInquiryReasons();
        }
        /*when account changed*/
        $scope.$on('accountChanged', function() {
            var hasPermission = false;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item, key) {
                    if (item.option.toLowerCase().indexOf('invoice') !== -1) {
                        if (item.displayName === 'R' || item.displayName === 'W') {
                            hasPermission = true;
                            init();
                        }
                    }
                    if ((key === 3) && !hasPermission) {
                        $state.go('root.dashboard');
                    }
                });
            }
        });
        /*fetches the search area details*/
        function getSearchAreaDetails() {
            $rootScope.dataLoading = true;
            invoicePaymentsService.getSearchCriteria()
                .then(getSearchAreaDetailsSuccess, getSearchAreaDetailsError);
        }
        /*success callback for search area details*/
        function getSearchAreaDetailsSuccess(apiResponse) {
            openVM.searchArea = apiResponse;
            angular.forEach(openVM.searchArea.serviceTypes, function(item) {
                item.isChecked = true;
                if (item.id === 2) {
                    item.tooltip = toastrMessage.tootipMessage.bulkWholesale;
                } else if (item.id === 3) {
                    item.tooltip = toastrMessage.tootipMessage.onSiteFuels;
                } else if (item.id === 4) {
                    item.tooltip = toastrMessage.tootipMessage.lubricants;
                } else if (item.id === 5) {
                    item.tooltip = toastrMessage.tootipMessage.fleetCards;
                } else {
                    //Sonar Issue Fix
                }
            });
            openVM.amountDueType = openVM.searchArea.amountDueTypes[0];
            openVM.amountDueDateType = openVM.searchArea.amountDueDateTypes[0];
            openVM.invoiceCategoryType = openVM.searchArea.invoiceCategoryTypes[0];
            getColumnHeaders();
            getColumnPaymentHeaders();
        }
        /*error callback for search area details*/
        function getSearchAreaDetailsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches the column headers for table*/
        function getColumnHeaders() {
            $rootScope.dataLoading = true;
            invoicePaymentsService.getInvoiceColumnHeaders()
                .then(getColumnHeadersSuccess, getColumnHeadersError);
        }
        /*success callback for column headers*/
        function getColumnHeadersSuccess(apiResponse) {

            openVM.columnHeaders = [];
            if (localStorage.invoiceColumns && JSON.parse(localStorage.invoiceColumns).length) {
                openVM.columnHeaders = JSON.parse(localStorage.invoiceColumns);
            } else {
                angular.forEach(apiResponse.columnHeaders, function(item) {
                    if (item.strHeader === 'Due Date') {
                        openVM.columnHeaders.push({ columnID: item.id, columnName: item.strHeader, showIcon: 'ic-filter', showColumn: item.showColumn });
                    } else {
                        openVM.columnHeaders.push({ columnID: item.id, columnName: item.strHeader, showIcon: 'ic-filterNot', showColumn: item.showColumn });
                    }
                });
            }
            if ((openVM.userPermission !== 'W' || openVM.loggedInUser == 'SuperAdmin')) {
                openVM.columnHeaders[6].showColumn = '';
            }
            openVM.columnHeaderPopup = angular.copy(openVM.columnHeaders);
            localStorage.invoiceColumns = JSON.stringify(openVM.columnHeaders);
            checkUnsavedChange(function(result) {
                if (result) {
                    getOpenInvoiceSearchDetails('AdvanceSearch');
                }
            });
        }
        /*error callback for column headers*/
        function getColumnHeadersError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

    function getColumnPaymentHeaders() {
            $rootScope.dataLoading = true;
            invoicePaymentsService.getColumnHeaders()
                .then(getPaymentColumnHeadersSuccess, getPaymentColumnHeadersError);
        }

        function getPaymentColumnHeadersSuccess(apiResponse) {
            openVM.columnPaymentHeaders = [];
            if (localStorage.paymentColumns && JSON.parse(localStorage.paymentColumns).length) {
                openVM.columnPaymentHeaders = JSON.parse(localStorage.paymentColumns);
            } else if (!localStorage.paymentColumns || !JSON.parse(localStorage.paymentColumns).length)  {
                angular.forEach(apiResponse.columnHeaders, function(item) {
                    if (item.strHeader === 'Invoice Date') {
                        openVM.columnPaymentHeaders.push({columnID: item.id, columnName: item.strHeader, showIcon: 'ic-filter', showColumn: item.showColumn });
                    } else {
                        openVM.columnPaymentHeaders.push({ columnID: item.id, columnName: item.strHeader, showIcon: 'ic-filterNot', showColumn: item.showColumn});
                    }
                });
            }
             localStorage.paymentColumns = JSON.stringify(openVM.columnPaymentHeaders);
        }
        /*error callback for column header*/
        function getPaymentColumnHeadersError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        /*toggle advance search*/
        function toggleSearch() {
            openVM.showAdvanceSearch = !openVM.showAdvanceSearch;
            openVM.searchInvoiceNo = '';
            if (openVM.showAdvanceSearch) {
                openVM.showInvoiceSearchButton = false;
            } else {
                openVM.showInvoiceSearchButton = true;
            }
        }
        /*fetches all invoices for smart search*/
        function getAllInvoices(searchKey) {
            $rootScope.dataLoading = true;
            return invoicePaymentsService.getInvoices(searchKey)
                .then(getInvoicesSuccess, getInvoicesError);
        }
        /*success callback for invoice smart search*/
        function getInvoicesSuccess(apiResponse) {
            var results = [];
            angular.forEach(apiResponse.invoiceNumbers, function(item) {
                var invoices = item;
                results.push({ name: invoices });
            });
            $rootScope.dataLoading = false;
            openVM.invoiceListLength = results.length;
            return results;
        }
        /*error callback for invoice smart search*/
        function getInvoicesError(error) {
            $rootScope.dataLoading = false;
            if (error === 404) {
                //Sonar Issue Fix
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*fetches all inquiry reasons to show in pop up*/
        function fetchInquiryReasons() {
            invoicePaymentsService.fetchInquiryReasons()
                .then(fetchInquiryReasonsSuccess, fetchInquiryReasonsError);
        }
        /*success callback for inquiry resons*/
        function fetchInquiryReasonsSuccess(apiResponse) {
            openVM.inquiryReasons = apiResponse.inquiryReasons;
        }
        /*error callback for inquiry resons*/
        function fetchInquiryReasonsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*clicks on search button in advance search*/
        function searchOpenInvoices() {
            openVM.currentPage = 1;
            openVM.firstPageItem = 1;
            openVM.secondPageItem = 2;
            openVM.serviceTypes = [];
            angular.forEach(openVM.searchArea.serviceTypes, function(item) {
                if (item.isChecked) {
                    openVM.serviceTypes.push(item.id);
                }
            });
            if (openVM.serviceTypes.length === 0 || openVM.datePicker.startDate === null || openVM.datePicker.endDate === null) {
                toastr.error('Please enter all the required fields.');
            } else if ((openVM.firstDueAmount && isNaN(openVM.firstDueAmount)) || (openVM.secondDueAmount && isNaN(openVM.secondDueAmount))) {
                toastr.error('Enter a valid ' + openVM.amountDueType.type + '.');
            } else if (parseFloat(openVM.firstDueAmount) > parseFloat(openVM.secondDueAmount)) {
                toastr.error(openVM.amountDueType.type + ' first amount should be less.');
            } else if ((openVM.startNumber && !utils.isValidAlphaNumeric(openVM.startNumber)) || (openVM.endNumber && !utils.isValidAlphaNumeric(openVM.endNumber))) {
                toastr.error('Enter a valid ' + openVM.invoiceCategoryType.type + '.');
            } else if (parseInt(openVM.startNumber, 10) > parseInt(openVM.endNumber, 10)) {
                toastr.error(openVM.invoiceCategoryType.type + ' starting number should be less.');
            } else if (openVM.searchInvoiceNo && (openVM.searchInvoiceNo.length < 3)) {
                toastr.warning(toastrMessage.userManagement.searchCondition);
            } else {
                //api call 
                checkUnsavedChange(function(result) {
                    if (result) {
                        getOpenInvoiceSearchDetails('AdvanceSearch');
                    }
                });
            }
        }
        /*loads the open invoice details*/
        function getOpenInvoiceSearchDetails(callFrom) {
            var sortByColumn = '';
            var orderByColumn = '';
            var serviceTypes = [];
            var payload = {};
            var startDate = openVM.datePicker.startDate === null ? '' : $filter('date')(new Date(openVM.datePicker.startDate.toString()), 'MM/dd/yyyy');
            var endDate = openVM.datePicker.endDate === null ? '' : $filter('date')(new Date(openVM.datePicker.endDate.toString()), 'MM/dd/yyyy');
            //To get sorting parameter and direction
            openVM.columnHeaders.forEach(function(item) {
                if (item.showIcon === 'ic-filter' || item.showIcon === 'ic-filterDesc') {
                    sortByColumn = item.columnName;
                    orderByColumn = (item.showIcon === 'ic-filter') ? 'asc' : 'desc';
                }
            });
            if (openVM.searchArea !== undefined) {
                angular.forEach(openVM.searchArea.serviceTypes, function(item) {
                    if (item.isChecked) {
                        serviceTypes.push(item.id);
                    }
                });
                if (serviceTypes.length === 0) {
                    serviceTypes.push(openVM.serviceTypeId);
                }
            } else {
                serviceTypes.push(openVM.serviceTypeId);
            }

            if (callFrom === 'AdvanceSearch') {
                payload.serviceTypeId = serviceTypes;
                payload.dueDateTypeId = openVM.amountDueDateType.id === undefined ? '' : openVM.amountDueDateType.id;
                payload.startDate = startDate;
                payload.endDate = endDate;
                payload.dueAmountTypeId = openVM.amountDueType.id === undefined ? '' : openVM.amountDueType.id;
                payload.fromRange = openVM.firstDueAmount;
                payload.toRange = openVM.secondDueAmount;
                payload.invoiceCategoryId = openVM.invoiceCategoryType.id === undefined ? '' : openVM.invoiceCategoryType.id;
                payload.invoiceNumberFrom = openVM.startNumber;
                payload.invoiceNumberTo = openVM.endNumber;
                payload.isInquiry = openVM.showEnquiry;
                //payload.invoiceNumber = openVM.invoicesNumber;
                payload.invoiceNumber = openVM.searchInvoiceNo.name === undefined ? openVM.searchInvoiceNo : openVM.searchInvoiceNo.name;
                payload.pageNumber = openVM.currentPage;
                payload.sortingParameter = sortByColumn;
                payload.sortingDirection = orderByColumn;
                payload.removeGroup = openVM.removeGrouping;
            } else if (callFrom === 'InvoiceSearch') {
                openVM.showEnquiry = '';
                payload.serviceTypeId = serviceTypes;
                payload.dueDateTypeId = '';
                payload.startDate = '';
                payload.endDate = '';
                payload.dueAmountTypeId = '';
                payload.fromRange = '';
                payload.toRange = '';
                payload.invoiceCategoryId = '';
                payload.invoiceNumberFrom = '';
                payload.invoiceNumberTo = '';
                payload.isInquiry = '';
                payload.invoiceNumber = openVM.searchInvoiceNo.name === undefined ? openVM.searchInvoiceNo : openVM.searchInvoiceNo.name;
                payload.pageNumber = openVM.currentPage;
                payload.sortingParameter = sortByColumn;
                payload.sortingDirection = orderByColumn;
                payload.removeGroup = openVM.removeGrouping;
            } else {
                //Sonar Issue Fix
            }

            payload.pageRows = 10;
            payload.accountId = localStorage.selectedAccount;
            payload.operation = 'btnOpenInvoiceSearch';
            localStorage.invoiceSearchCriteria = JSON.stringify(payload);
            $rootScope.dataLoading = true;
            invoicePaymentsService.getOpenInvoiceList(payload)
                .then(getOpenInvoiceListSuccess, getOpenInvoiceListError);
        }
        /*success callback for load open invoices*/
        function getOpenInvoiceListSuccess(apiResponse) {
            openVM.invoiceList = apiResponse;
            if (openVM.invoiceList.accountDetails) {
                if (openVM.removeGrouping) {
                    // openVM.invoiceList.accountDetails.push({
                    //     accountNumber: "22222", 
                    //     accountName: "Test CO. INC.**", 
                    //     attachments: [], 
                    //     financialCharges: "-555.38",
                    //     accountName:"Test CO. INC.**",
                    //     accountNumber:"22222",
                    //     additionalInfo:null,
                    //     attachments:[],
                    //     deliveryDate:"2015-12-01T00:00:00",
                    //     dueDate:"2015-12-11T00:00:00",
                    //     financialCharges:"-555.38",
                    //     inquiryReason:null,
                    //     invoiceDate:"2015-12-15T00:00:00",
                    //     invoices:[{
                    //         number: "2045882",
                    //         amount: -20521.88,
                    //         type: "",
                    //         accountNumber: "22222",
                    //         memo: "frgertgs"
                    //     }],
                    //     isInquiry:false,
                    //     locationIDs:["5"],
                    //     procurementOrderNumber:"SW1638-0526",
                    //     totalPaymentDue:"-555.38"
                    // },
                    //    {
                    //     accountNumber: "22222", 
                    //     accountName: "Test CO. INC.**", 
                    //     attachments: [], 
                    //     financialCharges: "-444.38",
                    //     accountName:"Test CO. INC.**",
                    //     accountNumber:"22222",
                    //     additionalInfo:null,
                    //     attachments:[],
                    //     deliveryDate:"2015-12-01T00:00:00",
                    //     dueDate:"2015-12-11T00:00:00",
                    //     financialCharges:"-444.38",
                    //     inquiryReason:null,
                    //     invoiceDate:"2015-12-15T00:00:00",
                    //     invoices:[{
                    //         number: "2977882",
                    //         amount: -20521.88,
                    //         type: "",
                    //         accountNumber: "22222",
                    //         memo: null
                    //     }],
                    //     isInquiry:false,
                    //     locationIDs:["5"],
                    //     procurementOrderNumber:"SW1638-0526",
                    //     totalPaymentDue:"-444.38"
                    // },
                    // {
                    //     accountNumber: "22222", 
                    //     accountName: "Test CO. INC.**", 
                    //     attachments: [], 
                    //     financialCharges: "-222.38",
                    //     accountName:"Test CO. INC.**",
                    //     accountNumber:"22222",
                    //     additionalInfo:null,
                    //     attachments:[],
                    //     deliveryDate:"2015-12-01T00:00:00",
                    //     dueDate:"2015-12-11T00:00:00",
                    //     financialCharges:"-222.38",
                    //     inquiryReason:null,
                    //     invoiceDate:"2015-12-15T00:00:00",
                    //     invoices:[{
                    //         number: "2946682",
                    //         amount: -20521.88,
                    //         type: "",
                    //         accountNumber: "22222",
                    //         memo: null
                    //     }],
                    //     isInquiry:false,
                    //     locationIDs:["5"],
                    //     procurementOrderNumber:"SW1638-0526",
                    //     totalPaymentDue:"-222.38",
                    // });
                    // openVM.customMemo = [];
                     angular.forEach(openVM.invoiceList.accountDetails,function(accItem){
                        openVM.customMemo[openVM.invoiceList.accountDetails.indexOf(accItem)] = accItem.invoices[0].memo;
                       
                        if(accItem.invoices[0].memo != null && accItem.invoices[0].memo != ""){
                            accItem.isMemo = true;
                        }else{
                            accItem.isMemo = false;
                        }
             
                    });
                    openVM.invoiceList.accountDetails.forEach(function(item) {
                        var a = new Date();
                        a.setHours(0);
                        a.setMinutes(0);
                        a.setSeconds(0);
                        var dateDiff = (new Date(a).getTime() - new Date(item.dueDate).getTime()) / (1000 * 60 * 60 * 24);
                        item.pastDue = parseInt(dateDiff, 10);
                        if (item.inquiryReason) {
                            var reasons = [];
                            reasons = item.inquiryReason.split(',');
                            reasons.forEach(function(rItem, index) {
                                if (rItem.trim() === 'Other') {
                                    item.otherReason = rItem;
                                    reasons.splice(index, 1);
                                }
                            });
                            item.inquiryReason = reasons.join();
                        }
                    });
                } else {
            //         openVM.invoiceList.accountDetails.push({ 
            //     accountNumber:"22222",
            //     accountName:"Test CO. INC.**",
            //     accountData:[{
            //         attachments:[],
            //         financialCharges:"-155.38",
            //         totalPaymentDue:"-155.38",
            //         isInquiry:false,
            //         inquiryReason:null,
            //         deliveryDate:"2015-12-01T00:00:00",
            //         procurementOrderNumber:"SW1638-0526",
            //         additionalInfo:null,"locationIDs":["5"],
            //         dueDate:"2015-12-11T00:00:00",
            //         invoiceDate:"2015-12-15T00:00:00",
            //         invoices:[{
            //             number:"8888882",
            //             amount:-20521.88,
            //             type:"",
            //             accountNumber:"18917",
            //             memo:null
            //         }]
            //     },
            //     {
            //         attachments:[],
            //         financialCharges:"-20691.15",
            //         totalPaymentDue:"-20691.15",
            //         isInquiry:false,
            //         inquiryReason:null,
            //         deliveryDate:"2015-12-15T00:00:00",
            //         procurementOrderNumber:"SW2660-0419",
            //         additionalInfo:null,
            //         locationIDs:["25"],
            //         dueDate:"2015-12-25T00:00:00",
            //         invoiceDate:"2016-01-28T00:00:00",
            //         invoices:[{
            //             number:"7777712",
            //             amount:20691.15,
            //             type:"",
            //             accountNumber:"18917",
            //             memo:"test8899"
            //         }]
            //     }]
            // });
                    openVM.customMemo = [];
                    angular.forEach(openVM.invoiceList.accountDetails,function(accItem){
                        angular.forEach(accItem.accountData,function(item){
                         var parentIndex = openVM.invoiceList.accountDetails.indexOf(accItem); 
                         var index = accItem.accountData.indexOf(item);
                         if (openVM.customMemo[parentIndex]) {
                             openVM.customMemo[parentIndex].push(item.invoices[0].memo);
                        } else {
                        openVM.customMemo.push([item.invoices[0].memo]);
                        }
                       if(item.invoices[0].memo != null && item.invoices[0].memo != ""){
                        item.isMemo = true;
                       }else{
                         item.isMemo = false;
                       }
                      }); 
                    });
                    openVM.invoiceList.accountDetails.forEach(function(item) {
                        item.accountData.forEach(function(dataItem) {
                            var a = new Date();
                            a.setHours(0);
                            a.setMinutes(0);
                            a.setSeconds(0);
                            var dateDiff = (new Date(a).getTime() - new Date(dataItem.dueDate).getTime()) / (1000 * 60 * 60 * 24);
                            dataItem.pastDue = parseInt(dateDiff, 10);
                            if (dataItem.inquiryReason) {
                                var reasons = [];
                                reasons = dataItem.inquiryReason.split(',');
                                reasons.forEach(function(item, index) {
                                    if (item.trim() === 'Other') {
                                        dataItem.otherReason = item;
                                        reasons.splice(index, 1);
                                    }
                                });
                                dataItem.inquiryReason = reasons.join();
                            }
                        });
                    });
                }
            
            
        }
            // var temp = openVM.invoiceList.accountDetails[0].accountData ;
            // for (var i=0; i < openVM.invoiceList.accountDetails[0].accountData.length; i++) {
            //             let tempObject = temp.filter((element) =>{
            //               return element.isInquiry == true;
            //             });
            //             if (tempObject && tempObject.length > 0) {
            //                openVM.isSubmit = true;
            //             }else{
            //                 openVM.isSubmit = false;
            //             }
            //         }
            openVM.searchCount = openVM.invoiceList.totalCount;
            openVM.maxPage = Math.ceil(openVM.invoiceList.totalCount / 10);
            $rootScope.dataLoading = false;
        }
        /*error callback for load open invoices*/
        function getOpenInvoiceListError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*click on invoice search button*/
        function invoiceSearch() {
            if (openVM.searchInvoiceNo && (openVM.searchInvoiceNo.length < 3)) {
                toastr.warning(toastrMessage.userManagement.searchCondition);
            } else {
                openVM.currentPage = 1;
                openVM.firstPageItem = 1;
                openVM.secondPageItem = 2;
                clearSearchParameters();
                checkUnsavedChange(function(result) {
                    if (result) {
                        getOpenInvoiceSearchDetails('InvoiceSearch');
                    }
                });
            }
        }
        /*pagination*/
        function paginationClick(button) {
            openVM.invoicesNumber = [];
            openVM.selectAll = false;
            checkUnsavedChange(function(result) {
                if (result) {
                    var searchRequired = false;
                    $window.scrollTo(0, 0);
                    switch (button) {
                        case 'first':
                            if (openVM.firstPageItem > 1) {
                                openVM.firstPageItem = 1;
                                openVM.secondPageItem = 2;
                                openVM.currentPage = 1;
                                searchRequired = true;
                            }
                            break;
                        case 'previous':
                            if (openVM.firstPageItem > 1) {
                                openVM.firstPageItem--;
                                openVM.secondPageItem--;
                                openVM.currentPage--;
                                searchRequired = true;
                            } else if (openVM.firstPageItem < openVM.currentPage) {
                                openVM.currentPage--;
                                searchRequired = true;
                            } else {
                                //Sonar Issue Fix
                            }
                            break;
                        case 'next':
                            if (openVM.secondPageItem < openVM.maxPage) {
                                openVM.firstPageItem++;
                                openVM.secondPageItem++;
                                openVM.currentPage++;
                                searchRequired = true;
                            } else if (openVM.currentPage < openVM.secondPageItem) {
                                openVM.currentPage++;
                                searchRequired = true;
                            } else {
                                //Sonar Issue Fix
                            }
                            break;
                        case 'last':
                            if (openVM.secondPageItem < openVM.maxPage) {
                                openVM.firstPageItem = openVM.maxPage - 1;
                                openVM.secondPageItem = openVM.maxPage;
                                openVM.currentPage = openVM.maxPage;
                                searchRequired = true;
                            }
                            break;
                        default:
                            openVM.currentPage = button;
                            searchRequired = true;
                            break;
                    }
                      angular.forEach(openVM.invoiceList.accountDetails,function(accData){
                        angular.forEach(accData.accountData,function(item){
                            item.isPopUp = false;
                            item.isMemo = false;
                        });
                    }); 
                    

                    if (searchRequired) {
                        getOpenInvoiceSearchDetails('AdvanceSearch');
                    }
                }
            });
        }
        /*toggle column header pop up*/
        function toggleHeader() {
            openVM.showColumnPopup = true;
        }
        /*save button click in column header pop up*/
        function arrangeTableData(popupData) {
            var selected = false;
            angular.forEach(popupData, function(item) {
                if (item.showColumn) {
                    selected = true;
                }
            });
            if (selected) {
                angular.forEach(popupData, function(item, key) {
                    if (item.showColumn) {
                        openVM.columnHeaders[key].showColumn = true;
                    } else {
                        openVM.columnHeaders[key].showColumn = false;
                    }
                });
                openVM.showColumnPopup = false;
                localStorage.invoiceColumns = JSON.stringify(openVM.columnHeaders);

                 var columnInvoices = [];
                 openVM.columnHeaders.forEach(function(item){
                 var listObj = {};
                 listObj.id = item.columnID;
                 listObj.strHeader = item.columnName;
                 listObj.showColumn = item.showColumn;
                 columnInvoices.push(listObj);
                });

                 var Payload = {
                "pageType":"OpenInvoice",
                "columnHeaders":columnInvoices
                };
                $rootScope.dataLoading = true;
                invoicePaymentsService.saveColumnHeaders(Payload)
                .then(saveColumnHeadersSuccess, saveColumnHeadersError);

            } else {
                toastr.error('Please select at least one column.');
            }
        }

        function saveColumnHeadersSuccess(apiResponse){
             $rootScope.dataLoading = false;
            toastr.success('Saved Successfully.');

        }

        function saveColumnHeadersError(){
            $rootScope.dataLoading = false;
            toastr.error('Failed to save');
        }

        /*clicks on inquiry pop up*/
        function openInquiryReasons(isChecked, reasons, parent, index, event, additionalInfo, otherReason) {
            openVM.popupOpen = true;
            if (openVM.removeGrouping) {
                openVM.showInquiryPopup = [];
                openVM.showOtherInquiry = false;
                angular.forEach(openVM.invoiceList.accountDetails, function(acItem, acKey) {
                    if (index === acKey) {
                        acItem.isInquiry = !isChecked ? true : false;
                    }
                });
                openVM.showInquiryPopup[index] = true;
            } else {
                openVM.showInquiryPopup = [
                    []
                ];
                openVM.showInquiryPopup[parent] = [];
                openVM.showOtherInquiry = false;
                angular.forEach(openVM.invoiceList.accountDetails, function(item, key) {
                    if (parent === key) {
                        angular.forEach(item.accountData, function(acItem, acKey) {
                            if (index === acKey) {
                                acItem.isInquiry = !isChecked ? true : false;
                            }
                        });
                    }
                });
                openVM.showInquiryPopup[parent][index] = true;
            }
            openVM.inquiry = angular.copy(openVM.inquiryReasons);
            openVM.customInquiry = additionalInfo;
            angular.forEach(openVM.inquiry, function(item) {
                item.parent = parent;
                item.index = index;
                if (otherReason && item.name === 'Other') {
                    item.isChecked = true;
                    openVM.showOtherInquiry = true;
                } else if (reasons && reasons.toLowerCase().indexOf(item.name.toLowerCase()) >= 0) {
                    item.isChecked = true;
                    openVM.showOtherInquiry = true;
                } else {
                    item.isChecked = false;
                }
            });
        }
        /*save button click on inquiry pop up*/
        function saveInquiry(inquiries, customInquiry) {
            // openVM.showInquiryPopup = [];
            var validEntries = true;
            if (openVM.removeGrouping) {
                angular.forEach(openVM.invoiceList.accountDetails, function(acItem, acKey) {
                    if (inquiries[0].index === acKey) {
                        var reasonArray = [];
                        acItem.otherReason = '';
                        inquiries.forEach(function(inquiryItem) {
                            if (inquiryItem.isChecked) {
                                if (inquiryItem.name === 'Other') {
                                    if (customInquiry) {
                                        acItem.otherReason = 'Other';
                                    } else {
                                        validEntries = false;
                                        toastr.error('Provide information for "Other" inquiry.');
                                    }
                                } else {
                                    reasonArray.push(inquiryItem.name);
                                }
                            }
                        });
                        acItem.additionalInfo = customInquiry;
                        acItem.isInquiry = (reasonArray.length > 0 || acItem.otherReason) ? true : false;
                        acItem.inquiryReason = reasonArray.toString();
                        openVM.unSavedChange = true;
                    }
                });
            } else {
                angular.forEach(openVM.invoiceList.accountDetails, function(item, key) {
                    if (inquiries[0].parent === key) {
                        angular.forEach(item.accountData, function(acItem, acKey) {
                            if (inquiries[0].index === acKey) {
                                var reasonArray = [];
                                acItem.otherReason = '';
                                inquiries.forEach(function(inquiryItem) {
                                    if (inquiryItem.isChecked) {
                                        if (inquiryItem.name === 'Other') {
                                            if (customInquiry) {
                                                acItem.otherReason = 'Other';
                                            } else {
                                                validEntries = false;
                                                toastr.error('Provide information for "Other" inquiry.');
                                            }
                                        } else {
                                            reasonArray.push(inquiryItem.name);
                                        }
                                    }
                                });

                                acItem.additionalInfo = customInquiry;
                                acItem.isInquiry = (reasonArray.length > 0 || acItem.otherReason) ? true : false;
                                acItem.inquiryReason = reasonArray.toString();
                                openVM.unSavedChange = true;
                            }
                        });
                    }
                });
            }
            if (validEntries) {
                openVM.showInquiryPopup = [];
                openVM.popupOpen = false;
            }
        }
        /*clicks on submit button*/
        function submitInquiry() {
            var inquiryList = [];
            if (openVM.removeGrouping) {
                angular.forEach(openVM.invoiceList.accountDetails, function(acItem) {
                    var reasonString = acItem.inquiryReason;
                    var inquiryObj = {};
                    inquiryObj.invoiceNumber = acItem.invoices[0].number;
                    inquiryObj.accountNumber = acItem.accountNumber;
                    inquiryObj.inquiryReasons = [];
                    inquiryObj.additionalInfo = '';
                    angular.forEach(openVM.inquiryReasons, function(item) {
                        var tempObj = {};
                        if (item.name === 'Other' && acItem.otherReason) {
                            tempObj.inquiryReasonID = item.id;
                            tempObj.inquiryReason = acItem.otherReason;
                            inquiryObj.inquiryReasons.push(tempObj);
                        } else if (reasonString && reasonString.toLowerCase().indexOf(item.name.toLowerCase()) >= 0) {
                            tempObj.inquiryReasonID = item.id;
                            tempObj.inquiryReason = item.name;
                            inquiryObj.inquiryReasons.push(tempObj);
                        } else {
                            //Sonar Issue Fix
                        }
                    });

                    inquiryObj.additionalInfo = acItem.additionalInfo;
                    inquiryList.push(inquiryObj);
                });

            } else {
                angular.forEach(openVM.invoiceList.accountDetails, function(accountItem) {
                    angular.forEach(accountItem.accountData, function(acItem) {
                        var reasonString = acItem.inquiryReason;
                        var inquiryObj = {};
                        inquiryObj.invoiceNumber = acItem.invoices[0].number;
                        inquiryObj.accountNumber = accountItem.accountNumber;
                        inquiryObj.inquiryReasons = [];
                        inquiryObj.additionalInfo = '';
                        angular.forEach(openVM.inquiryReasons, function(item) {
                            var tempObj = {};
                            if (item.name === 'Other' && acItem.otherReason) {
                                tempObj.inquiryReasonID = item.id;
                                tempObj.inquiryReason = acItem.otherReason;
                                inquiryObj.inquiryReasons.push(tempObj);
                            } else if (reasonString && reasonString.toLowerCase().indexOf(item.name.toLowerCase()) >= 0) {
                                tempObj.inquiryReasonID = item.id;
                                tempObj.inquiryReason = item.name;
                                inquiryObj.inquiryReasons.push(tempObj);
                            } else {
                                //Sonar Issue Fix
                            }
                        });

                        inquiryObj.additionalInfo = acItem.additionalInfo;
                        inquiryList.push(inquiryObj);
                    });
                });
            }
            var payload = {
                'invoiceList': inquiryList,
                'Operation': 'btnInvoiceInquirySubmission'
            };
            if (inquiryList.length === 0) {
                toastr.error('Please select at least one inquiry.');
            } else {
                $rootScope.dataLoading = true;
                invoicePaymentsService.submitInquiryList(payload)
                    .then(submitInquiryListSuccess, submitInquiryListError);
            }
        }
        /*success callback for submit inquiry*/
        function submitInquiryListSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                toastr.success('Inquiry(s) submitted successfully.');
                openVM.unSavedChange = false;
            }
        }
        /*error callback for submit inquiry*/
        function submitInquiryListError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*clicks on inquiry checkbox in inquiry pop up*/
        function checkInquiry(inquiryName) {
            openVM.showOtherInquiry = false;
            angular.forEach(openVM.inquiry, function(item) {
                if (item.isChecked) {
                    openVM.showOtherInquiry = true;
                }
            });
            if (!openVM.showOtherInquiry) {
                openVM.customInquiry = '';
            }
        }
        /*click on attachments button*/
        function download(attachments, index) {
            openVM.showDownloadPopup = [];
            openVM.showDownloadPopup[index] = true;
            openVM.attachments = attachments;
        }
        /*success callback for download attachment*/
        function downloadAttachment(filePath) {
            openVM.showDownloadPopup = [];
            $window.open(filePath);
        }
        /*function to order the data by column header*/
        function orderByColumn(columnHeader, iconType) {
            openVM.serviceTypes = [];
            checkUnsavedChange(function(result) {
                if (result) {
                    angular.forEach(openVM.columnHeaders, function(item) {
                        if (item.columnName === columnHeader) {
                            if (iconType === 'ic-filterNot' || iconType === 'ic-filterDesc') {
                                item.showIcon = 'ic-filter';
                            } else {
                                item.showIcon = 'ic-filterDesc';
                            }
                        } else {
                            item.showIcon = 'ic-filterNot';
                        }
                    });
                    localStorage.invoiceColumns = JSON.stringify(openVM.columnHeaders);
                    getOpenInvoiceSearchDetails('AdvanceSearch');
                }
            });
        }
        /*click on remove grouping checkbox*/
        function groupingRemoval(isChecked) {
            openVM.selectAll = false ; 
            angular.forEach(openVM.invoiceList.accountDetails,function(accData){
                    angular.forEach(accData.accountData,function(item){
                        item.isPopUp = false;
                    });
                });
            openVM.serviceTypes = [];
            checkUnsavedChange(function(result) {
                if (result) {
                    angular.forEach(openVM.columnHeaders, function(item) {
                        if (item.columnName === 'Due Date') {
                            item.showIcon = 'ic-filter';
                        } else {
                            item.showIcon = 'ic-filterNot';
                        }
                    });
                    openVM.removeGrouping = isChecked;
                    getOpenInvoiceSearchDetails('AdvanceSearch');
                } else {
                    openVM.removeGrouping = isChecked ? false : true;
                }
            });
        }
        /*clear the date values from calender*/
        function clearCalender() {
            openVM.datePicker = { startDate: null, endDate: null };
            $('#daterange').data('daterangepicker').setStartDate(new Date());
            $('#daterange').data('daterangepicker').setEndDate(new Date());
        }
        /*clears the search parameters in advance search*/
        function clearSearchParameters() {
            angular.forEach(openVM.searchArea.serviceTypes, function(item) {
                item.isChecked = true;
            });
            openVM.serviceTypeId = 1;
            openVM.amountDueType = openVM.searchArea.amountDueTypes[0];
            openVM.amountDueDateType = openVM.searchArea.amountDueDateTypes[0];
            openVM.invoiceCategoryType = openVM.searchArea.invoiceCategoryTypes[0];
            openVM.firstDueAmount = '';
            openVM.secondDueAmount = '';
            openVM.startNumber = '';
            openVM.endNumber = '';
            openVM.datePicker = { startDate: null, endDate: null };
        }

        $rootScope.$on('hidePopup', function() {
            openVM.showDownloadPopup = [];
            openVM.showInquiryPopup = [];
            openVM.popupOpen = false;
            openVM.showColumnPopup = false;
            $scope.$apply();
        });
        /*confirmation for save the changes*/
        function checkUnsavedChange(callback) {
            if (openVM.unSavedChange) {
                // event.preventDefault();
                $confirm({
                    title: 'Confirm',
                    text: 'You have not submitted the saved changes. Do you want to proceed without submitting it?',
                    ok: 'Yes',
                    cancel: 'No'
                }, {
                    size: 'sm',
                    backdrop: true
                }).then(function() {
                    openVM.unSavedChange = false;
                    callback(true);
                }).catch(function() {
                    callback(false);
                });
            } else {
                callback(true);
            }
        }

        $scope.$on('$stateChangeStart', onStateChangeStart);
        /*state change handler to save changes*/
        function onStateChangeStart(event, toState, toParams, fromState) {
            if (openVM.unSavedChange && !$rootScope.unauthorized) {
                event.preventDefault();
                $confirm({
                    title: 'Confirm',
                    text: 'You have not submitted the saved changes. Do you want to proceed without submitting it?',
                    ok: 'Yes',
                    cancel: 'No'
                }, {
                    size: 'sm',
                    backdrop: true
                }).then(function() {
                    openVM.unSavedChange = false;
                    $state.go(toState.name);
                }).catch(function() {
                    $rootScope.activeTab = true;
                });
            }
        }
        /*Service Type Selection*/
        function selectServiceType(serviceType, isChecked) {
            var selectAll = true;
            if (serviceType === 1) {
                angular.forEach(openVM.searchArea.serviceTypes, function(item) {
                    item.isChecked = isChecked ? true : false;
                });
            } else {
                angular.forEach(openVM.searchArea.serviceTypes, function(item) {
                    if (serviceType === item.id) {
                        if (isChecked) {
                            item.isChecked = true;
                        }
                    }
                });
            }
            angular.forEach(openVM.searchArea.serviceTypes, function(item) {
                if (item.id > 1 && !item.isChecked) {
                    selectAll = false;
                    openVM.searchArea.serviceTypes[0].isChecked = false;
                }
            });
            openVM.searchArea.serviceTypes[0].isChecked = selectAll ? true : false;
        }
         /*clicks on the checkbox in the column header*/
        function checkAllInvoices(isChecked) {
             openVM.invoicesNumber = [];
             invoicePaymentsService.setSelectAllInvoices(isChecked); 
            if(isChecked){
                openVM.selectAll = true;
                angular.forEach(openVM.invoiceList.accountDetails,function(item){
                    angular.forEach(item.accountData, function(accItem) {
                openVM.invoicesNumber.push(accItem.invoices[0].number)
                 });
                });
                invoicePaymentsService.setInvoiceNumber(openVM.invoicesNumber); 
            }else{
                openVM.selectAll = false;  
            } 
            if (openVM.removeGrouping) {
                if (isChecked) {
                    angular.forEach(openVM.invoiceList.accountDetails, function(item) {
                        item.isChecked = true;
                    });
                } else {
                    angular.forEach(openVM.invoiceList.accountDetails, function(item) {
                        item.isChecked = false;
                    });
                }
            } else {
                if (isChecked) {
                    angular.forEach(openVM.invoiceList.accountDetails, function(accItem) {
                        angular.forEach(accItem.accountData, function(item) {
                            item.isChecked = true;
                        });
                    });
                } else {
                    angular.forEach(openVM.invoiceList.accountDetails, function(accItem) {
                        angular.forEach(accItem.accountData, function(item) {
                            item.isChecked = false;
                        });
                    });
                }
            }
        }

        function checkedInvoicesToExport(accountData ,isChecked){
               if(isChecked){
                    openVM.invoicesNumber.push(accountData.invoices[0].number);
                    }else{
                openVM.selectAll = false;
                openVM.invoicesNumber.splice(openVM.invoicesNumber.indexOf(accountData.invoices[0].number),1)
                }
                 var detailsLength = 0;
                angular.forEach(openVM.invoiceList.accountDetails,function(accData){
                    detailsLength += accData.accountData.length;
                   });
                if(detailsLength == openVM.invoicesNumber.length){
                    openVM.selectAll = true ;     
                }else{
                    openVM.selectAll = false ; 
                }
           // console.log(openVM.invoiceList.accountDetails[0].accountData.length);
           invoicePaymentsService.setSelectAllInvoicesHistory(false);
            invoicePaymentsService.setSelectAllInvoices(openVM.selectAll);
            invoicePaymentsService.setInvoiceNumber(openVM.invoicesNumber);  
        }
    

    function memoPopupInvoice(accountData,index){
    if (openVM.removeGrouping) {
         openVM.indexMemo = index;
         angular.forEach(openVM.invoiceList.accountDetails,function(accData){
                if(accountData.invoices[0].number == accData.invoices[0].number){
                    accData.isPopUp = true;
                }else{
                    accData.isPopUp = false;
                }
         });
     }else{
        openVM.indexMemo = index;
         angular.forEach(openVM.invoiceList.accountDetails,function(accData){
             angular.forEach(accData.accountData,function(item){
                console.log(item);
                if(accountData.invoices[0].number == item.invoices[0].number){
                    item.isPopUp = true;
                }else{
                    item.isPopUp = false;
                }
                
            });
         });
     }   
    }
        
         function saveMemo(accData,memo,parent,index){
            openVM.saveIndex = index;
            openVM.saveParentIndex = parent;
            if(openVM.removeGrouping){
                var memoObj = {
                    "invoiceNum" : accData.invoices[0].number,
                    "accountNum" : accData.invoices[0].accountNumber,
                    "memo" : openVM.customMemo[index]
                 };
            }else{
               var memoObj = {
                "invoiceNum" : accData.invoices[0].number,
                "accountNum" : accData.invoices[0].accountNumber,
                "memo" : openVM.customMemo[parent][index]
             }; 
            }
             
             $rootScope.dataLoading = true;
                invoicePaymentsService.saveMemoInvoices(memoObj)
                    .then(saveMemoSuccess, saveMemoError);
 
         }

         function saveMemoSuccess(apiResponse){
              $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                if(openVM.removeGrouping){
                    if(openVM.customMemo[openVM.saveIndex] != "" && openVM.customMemo[openVM.saveIndex] != null){
                        openVM.invoiceList.accountDetails[openVM.saveIndex].isMemo = true;
                    }else{
                         openVM.invoiceList.accountDetails[openVM.saveIndex].isMemo = false;
                    }
                      openVM.invoiceList.accountDetails[openVM.saveIndex].invoices[0].memo = openVM.customMemo[openVM.saveIndex];
                    angular.forEach(openVM.invoiceList.accountDetails,function(accData){
                        accData.isPopUp = false;
                    });

                }else{
                    if(openVM.customMemo[openVM.saveParentIndex][openVM.saveIndex] != "" && openVM.customMemo[openVM.saveParentIndex][openVM.saveIndex] != null){
                   openVM.invoiceList.accountDetails[openVM.saveParentIndex].accountData[openVM.saveIndex].isMemo = true;
                   
                }else{
                    openVM.invoiceList.accountDetails[openVM.saveParentIndex].accountData[openVM.saveIndex].isMemo = false;
                }
                 openVM.invoiceList.accountDetails[openVM.saveParentIndex].accountData[openVM.saveIndex].invoices[0].memo = openVM.customMemo[openVM.saveParentIndex][openVM.saveIndex];
                angular.forEach(openVM.invoiceList.accountDetails,function(accData){
                    angular.forEach(accData.accountData,function(item){
                        item.isPopUp = false;
                    });
                });
                }
                
            }
        }
        /*error callback for save memo*/
        function saveMemoError() {
            openVM.invoiceList.accountDetails[openVM.saveParentIndex].accountData[openVM.saveIndex].isMemo = false;
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        function cancelMemo(accData,parent,index){
            if(openVM.removeGrouping){
                 openVM.customMemo[index] = openVM.invoiceList.accountDetails[index].invoices[0].memo ;
                angular.forEach(openVM.invoiceList.accountDetails, function(accItem) {
                 accItem.isPopUp = false;
                });
            }else{
                 angular.forEach(openVM.invoiceList.accountDetails, function(accItem) {
                if(openVM.invoiceList.accountDetails.indexOf(accItem) == parent){
                    openVM.customMemo[parent][openVM.indexMemo] = accItem.accountData[openVM.indexMemo].invoices[0].memo ;
                }
                });
              angular.forEach(openVM.invoiceList.accountDetails,function(accData){
                angular.forEach(accData.accountData,function(item){
                item.isPopUp = false;
            });
         }); 
        }
        }

        $rootScope.$on('exportEvent', function(){
            openVM.selectAll = false;
            openVM.invoicesNumber = [];
            if(openVM.removeGrouping){
                angular.forEach(openVM.invoiceList.accountDetails, function(accItem) {
                    accItem.isChecked = false;
                });
            }else{
                angular.forEach(openVM.invoiceList.accountDetails, function(accItem) {
                    angular.forEach(accItem.accountData, function(item) {
                    item.isChecked = false;
                    });
                });
            }
            
        })
 }
})();