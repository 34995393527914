
/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';

    angular
        .module('scFuelsApp.userManagement')
        .controller('userManagementController', userManagementController);

    userManagementController.$inject = ['$scope', '$state', 'toastr', '$confirm','userManagementService','$rootScope','$window','toastrMessage'];

    function userManagementController($scope, $state, toastr, $confirm,userManagementService, $rootScope,$window,toastrMessage){
        var userManagementVM = this;
        userManagementVM.init = init;
        userManagementVM.expandCheck = false;
        userManagementVM.viewContactsToggle = viewContactsToggle;
        userManagementVM.newUser = newUser;
        userManagementVM.expandAll = expandAll;
        userManagementVM.editUser = editUser;
	    userManagementVM.paginationClick = paginationClick;
        userManagementVM.searchKey = '';
        userManagementVM.searchFlag = 'user';
        userManagementVM.maxPage = 2;
        userManagementVM.onSearchClick = onSearchClick;
        userManagementVM.loggedInUser = localStorage.userType;
        init();


        /*  function declarations */

        /*initial loading*/
        function init(){
            $window.scrollTo(0, 0);
            userManagementVM.currentPage = 1;
            userManagementVM.firstPageItem = 1;
            userManagementVM.secondPageItem = 2;
            if(localStorage.accountPermission){
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item){
                    if(item.option.indexOf('Account Maintenance')!== -1){
                        userManagementVM.userPermission = item.displayName;
                    }
                })
            }
        }
        /*clicks on the search button*/
        function onSearchClick() {
            if(userManagementVM.searchKey && (userManagementVM.searchKey.length < 3)){
                toastr.warning(toastrMessage.userManagement.searchCondition);
            }
            else{
                init();
                getUserAccountDetails();
            }            
        }
        /*watches the changes in the pagination current page*/
        $scope.$watch('userManagementVM.currentPage',function(){
            getUserAccountDetails();
        });
        /*when the accounts has changed*/
        $scope.$on('accountChanged', function(){
            var hasPermission = false;
            if(localStorage.accountPermission){
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item, key){
                    if(item.option.toLowerCase().indexOf('account')!== -1){
                        if(item.displayName == 'R' || item.displayName == 'W'){
                            hasPermission = true;
                            init();
                            getUserAccountDetails();
                        }
                    }
                    if((key == 3) && !hasPermission){
                        $state.go('root.dashboard');
                    }
                })
            }
        });
        // $scope.$watch(function () { return localStorage.accountPermission; },function(){
        //     init();
        //     getUserAccountDetails();
        // });
        /*clicks on the view contacts on the table*/
        function viewContactsToggle(index){
          angular.forEach(userManagementVM.accountDetails,function(userItem,userKey){
            if(index === userKey){
              angular.forEach(userItem.accounts,function(accItem,accKey){
                if(accKey>=3){
                    accItem.showAccount = !accItem.showAccount;
                    if(accItem.showAccount){
                        userItem.showAllAccount = true;
                    }
                    else{
                        userItem.showAllAccount = false;
                    }
                }
             });
            }
         });
        }
        /*clicks on the expand all checkbox*/
        function expandAll(isChecked){
          angular.forEach(userManagementVM.accountDetails,function(userItem){
            angular.forEach(userItem.accounts,function(accItem,accKey){
                if(accKey>=3){
                  if(isChecked){
                    accItem.showAccount = true;
                    userItem.showAllAccount = true;
                  }else{
                    accItem.showAccount = false;
                    userItem.showAllAccount = false;
                }
              }
            });
         });
        }
        /*clicks on the new user button*/
        function newUser(){
          $state.go("root.newUser");
        }
        /*fetches the user account details*/
        function getUserAccountDetails(){
            $rootScope.dataLoading = true;
              var payload = {
                "pageNumber": userManagementVM.currentPage,
                "orgId": localStorage.orgID,
                "searchKey": userManagementVM.searchKey,
                "searchFlag": userManagementVM.searchFlag
              };
          userManagementService.getUserAccountDetails(payload)
                    .then(getUserAccountDetailsSuccess, getUserAccountDetailsError);
        }
        /*success callback for user account details*/
        function getUserAccountDetailsSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                userManagementVM.accountDetails = apiResponse.dataModel.userList;
                if (userManagementVM.currentPage == 1) {
                    userManagementVM.totalUsers = apiResponse.dataModel.totalUsers;
                    userManagementVM.searchCount = apiResponse.dataModel.searchCount;
                }
                userManagementVM.maxPage = Math.ceil(userManagementVM.searchCount / 10);
                angular.forEach(userManagementVM.accountDetails, function (userItem) {
                    angular.forEach(userItem.accounts, function (accItem, accKey) {
                        if (accKey < 3) {
                            accItem.showAccount = true;
                        } else {
                            accItem.showAccount = false;
                        }
                    });
                });
            }
            else {
                userManagementVM.accountDetails = [];
                userManagementVM.searchCount = null;
            }
        }
       /*error callback for user account details*/
       function getUserAccountDetailsError () {
        $rootScope.dataLoading = false;
           toastr.error(toastrMessage.common.systemNetworkFailure);
       }
       /*clicks on the edit button*/
       function editUser(userId){
        if(userManagementVM.userPermission =='W' && userManagementVM.loggedInUser !== 'SuperAdmin'){
           $state.go('root.editUser',{'userID':userId});
        }
       }
       /*pagination*/
    function paginationClick(button) {
            $window.scrollTo(0, 0);
            switch (button){
                case 'first':
                    if(userManagementVM.firstPageItem>1){
                        userManagementVM.firstPageItem = 1;
                        userManagementVM.secondPageItem = 2;
                        userManagementVM.currentPage = 1;
                    }
                    break;
                case 'previous':
                    if(userManagementVM.firstPageItem>1){
                        userManagementVM.firstPageItem--;
                        userManagementVM.secondPageItem--;
                        userManagementVM.currentPage--;
                    }
                    else if(userManagementVM.firstPageItem < userManagementVM.currentPage){
                        userManagementVM.currentPage--;
                    }
                    break;
                case 'next':
                    if(userManagementVM.secondPageItem < userManagementVM.maxPage){
                        userManagementVM.firstPageItem++;
                        userManagementVM.secondPageItem++;
                        userManagementVM.currentPage++;
                    }
                    else if(userManagementVM.currentPage < userManagementVM.secondPageItem){
                        userManagementVM.currentPage++;
                    }
                    break;
                case 'last':
                    if(userManagementVM.secondPageItem < userManagementVM.maxPage || userManagementVM.currentPage < userManagementVM.secondPageItem){
                        userManagementVM.firstPageItem = userManagementVM.maxPage-1;
                        userManagementVM.secondPageItem = userManagementVM.maxPage;
                        userManagementVM.currentPage = userManagementVM.maxPage;
                    }
                    break;
                default:
                    userManagementVM.currentPage = button;
                    break;
            }
       }
    }
})();
