/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.invoicePayments', ['scFuelsApp.login'])
        .directive('formSubmitter', formSubmitterDirective)
        .config(routerConfiguration);

    formSubmitterDirective.$inject = ['$timeout'];
    routerConfiguration.$inject = ['$stateProvider'];

    function routerConfiguration($stateProvider) {
        $stateProvider
            .state('root.invoices', {
                url: '/invoices',
                views: {
                    '': {
                        templateUrl: 'app/modules/invoicePayments/invoices/invoiceTemplate.html',
                        controller: 'invoiceController',
                        controllerAs: 'invoiceVM'
                    }
                    /*,
                    'paymentHistory@root.invoices': {
                        templateUrl: 'app/modules/invoicePayments/invoices/paymentHistory/paymentHistoryTemplate.html',
                        controller: 'paymentHistoryController',
                        controllerAs: 'historyVM'
                    },
                    'openInvoices@root.invoices': {
                        templateUrl: 'app/modules/invoicePayments/invoices/openInvoices/openInvoicesTemplate.html',
                        controller: 'openInvoiceController',
                        controllerAs: 'openVM'
                    }*/
                }
            })
            .state('root.invoices.paymentHistory', {
                url: '/history',
                templateUrl: 'app/modules/invoicePayments/invoices/paymentHistory/paymentHistoryTemplate.html',
                controller: 'paymentHistoryController',
                controllerAs: 'historyVM'
            })
            .state('root.invoices.openInvoices', {
                url: '/openInvoices',
                templateUrl: 'app/modules/invoicePayments/invoices/openInvoices/openInvoicesTemplate.html',
                controller: 'openInvoiceController',
                controllerAs: 'openVM'
            })
            .state('root.payments', {
                url: '/payments?session',
                templateUrl: 'app/modules/invoicePayments/payments/paymentTemplate.html',
                controller: 'paymentController',
                controllerAs: 'paymentVM'
            })
            .state('root.makePayments', {
                url: '/makePayments',
                templateUrl: 'app/modules/invoicePayments/payments/makePayments/makePaymentsTemplate.html',
                controller: 'makePaymentsController',
                controllerAs: 'makePaymentsVM'
            });
    }

    function formSubmitterDirective($timeout) {
        return {
            restrict: 'E',
            replace: true,
            template: 
                '<form action="{{ formData.url }}" method="{{ formData.method }}">' +
                '   <div ng-repeat="(key,val) in formData.params">' +
                '       <input type="hidden" name="{{ key }}" value="{{ val }}" />' +
                '   </div>' +
                '</form>',
            link: function($scope, $element) {
                $scope.$on('form.submit', function(event, data) {
                    $scope.formData = data;
                    $timeout(function() {
                        $element.submit();
                    })
                })
            }
        }
    }
})();
