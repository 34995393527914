/*jshint browser: true*/
/*global angular: true*/

(function () {
    'use strict';
    angular.module('scFuelsApp.cardManagement')
        .factory('cardManagementService', cardManagementService);

    cardManagementService.$inject = ['$q', '$http', 'apiURLService'];

    function cardManagementService($q, $http, apiURLService) {
        var cardObject = {};
        cardObject.getCardSmartSearchList = getCardSmartSearchList;
        cardObject.getLastTransactionsList = getLastTransactionsList;
        cardObject.getTransactionDetails = getTransactionDetails;
        cardObject.getTransactionParameters = getTransactionParameters;
        cardObject.getTransactionColumns = getTransactionColumns;
        cardObject.exportTransactions = exportTransactions;
        cardObject.exportCardManagement = exportCardManagement;
        cardObject.printTransactions = printTransactions;
        cardObject.saveColumnHeaders = saveColumnHeaders;
        cardObject.printCardDetails = printCardDetails;

        cardObject.getFleetCardList = getFleetCardList;
        cardObject.getFleetCardDetails = getFleetCardDetails;
        cardObject.getFleetCardDropdownList = getFleetCardDropdownList;
        cardObject.getCardManagementDropdownList = getCardManagementDropdownList;
        cardObject.getCardRestrictionDropdownList = getCardRestrictionDropdownList;
        cardObject.saveFleetCardDetails = saveFleetCardDetails;
        cardObject.editFleetCardDetails = editFleetCardDetails;
        cardObject.editAndSaveFleetCardDetails = editAndSaveFleetCardDetails;
        cardObject.getFleetSmartSearchList = getFleetSmartSearchList;
        cardObject.getPinValidation = getPinValidation;

        cardObject.getPromptCardList = getPromptCardList;
        cardObject.getPromptCardDetails = getPromptCardDetails;
        cardObject.getPromptDropdownList = getPromptDropdownList;
        cardObject.getPromptSmartSearchList = getPromptSmartSearchList;
        cardObject.getPromptRestrictionDropdownList = getPromptRestrictionDropdownList;
        cardObject.saveDriverPinDetails = saveDriverPinDetails;
        cardObject.editDriverPinDetails = editDriverPinDetails;
        cardObject.getDriverValidation = getDriverValidation;
        cardObject.getVehicleValidation = getVehicleValidation;
        cardObject.getPromptLinkedCardList = getPromptLinkedCardList;
        cardObject.exportPrompts = exportPrompts;
        cardObject.printPrompts = printPrompts;

        var modals = []; // array of modals on the page
        cardObject.Add = Add;
        cardObject.Remove = Remove;
        cardObject.Open = Open;
        cardObject.Close = Close;

        return cardObject;

        function getCardSmartSearchList(requestData) {
            var url = apiURLService.getCardSmartSearchListURL();
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getLastTransactionsList(requestData) {
            var url = apiURLService.getLastTransactionsListURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getTransactionDetails(requestData, index) {
            var url = apiURLService.getTransactionDetailsURL();
            return $http.get(url).then(function (apiResponse) {
                apiResponse.data.index = index;
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getTransactionParameters() {
            var url = apiURLService.getTransactionParametersURL();
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getTransactionColumns() {
            var url = apiURLService.getTransactionColumnsURL();
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getFleetCardList(payload) {
            var url = apiURLService.getFleetCardListURL();
            return $http.post(url, payload).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getFleetCardDetails(payLoad, index) {
            var url = apiURLService.getFleetCardDetailsURL();
            // return $http.post(url, payLoad).then(function(apiResponse) {
            return $http.get(url).then(function (apiResponse) {
                apiResponse.data.index = index;
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getFleetCardDropdownList() {
            var url = apiURLService.getFleetCardDropdownListURL();
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getCardManagementDropdownList(acntNo) {
            var url = apiURLService.getCardManagementDropdownListURL() + '/' + acntNo;
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getCardRestrictionDropdownList(netwrok) {
            var url = apiURLService.getCardRestrictionDropdownListURL() + '?Network=' + netwrok;
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function saveFleetCardDetails(payload) {
            var url = apiURLService.saveFleetCardDetailsURL();
            return $http.post(url, payload).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function editFleetCardDetails(payload) {
            var url = apiURLService.editFleetCardDetailsURL();
            return $http.post(url, payload).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function editAndSaveFleetCardDetails(editPayload, savePayload) {
            var url = apiURLService.editAndSaveFleetCardDetailsURL();
            return $http.post(url, {EditActivityDataModel:editPayload, CreateActivityDataModel:savePayload}).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getFleetSmartSearchList(requestData) {
            var url = apiURLService.getFleetSmartSearchListURL();
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getPromptCardList(requestData) {
            var url = apiURLService.getPromptCardListURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getPromptCardDetails(index, requestData) {
            var url = apiURLService.getPromptCardDetailsURL();
            return $http.get(url).then(function (apiResponse) {
                apiResponse.data.index = index;
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getPromptDropdownList(accountNo) {
            var url = apiURLService.getPromptDropdownListURL() + '/' + accountNo;
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getPromptSmartSearchList(requestData) {
            var url = apiURLService.getPromptSmartSearchListURL();
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function exportTransactions(requestData) {
            var url = apiURLService.exportTransactionURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function printTransactions(requestData) {
            var url = apiURLService.printTransactionURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function saveColumnHeaders(requestData) {
            var url = apiURLService.saveTransColumnHeadersURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getPinValidation(requestData) {
            var url = apiURLService.getPinValidationURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getDriverValidation(requestData) {
            var url = apiURLService.getDriverValidationURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getVehicleValidation(requestData) {
            var url = apiURLService.getVehicleValidationURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function saveDriverPinDetails(payload) {
            var url = apiURLService.saveDriverPinDetailsURL();
            return $http.post(url, payload).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function editDriverPinDetails(payload) {
            var url = apiURLService.editDriverPinDetailsURL();
            return $http.post(url, payload).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function printCardDetails(requestData) {
            var url = apiURLService.printCardDetailsURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function exportCardManagement(requestData) {
            var url = apiURLService.exportCardManagementURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getPromptRestrictionDropdownList(netwrok) {
            var url = apiURLService.getPromptRestrictionDropdownListURL() + '?Network=' + netwrok;
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getPromptLinkedCardList(netwrok) {
            var url = apiURLService.getPromptLinkedCardListURL() + '/' + localStorage.selectedAccount + '/' + netwrok;
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }


        function exportPrompts(requestData) {
            var url = apiURLService.getExportPromptsListURL()
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function printPrompts(requestData) {
            var url = apiURLService.getPrintPromptsListURL()
            return $http.post(url, requestData).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }


        // popup modal funs
        function Add(modal) {
            // add modal to array of active modals
            modals.push(modal);
        }

        function Remove(id) {
            // remove modal from array of active modals
            var modalToRemove = _.findWhere(modals, { id: id });
            modals = _.without(modals, modalToRemove);
        }

        function Open(id) {
            // open modal specified by id
            var modal = _.findWhere(modals, { id: id });
            modal.open();
        }

        function Close(id) {
            // close modal specified by id
            var modal = _.findWhere(modals, { id: id });
            modal.close();
        }


    }
})();
