/*jshint browser: true*/
/*global angular: true*/

(function () {
    'use strict';
    angular.module('scFuelsApp.userActivity')
        .factory('userActivityService', userActivityService);

    userActivityService.$inject = ['$q', '$http', 'apiURLService'];

    function userActivityService($q, $http, apiURLService) {
    var userActivity={};
        userActivity.getUserActivities = getUserActivities;
        userActivity.getOtherUserActivities = getOtherUserActivities;
        return userActivity;

        function getUserActivities(gridFilter) {
            var url = apiURLService.getUserActivitiesURL();
            return $http.post(url, gridFilter).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getOtherUserActivities(gridFilter) {
            var url = apiURLService.getOtherUserActivitiesURL();
            return $http.post(url, gridFilter).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }
    }
})();
