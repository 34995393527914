/*jshint browser: true*/
/*global angular: true*/

(function() {
    'use strict';
    angular.module('scFuelsApp.invoicePayments')
        .factory('invoicePaymentsService', invoicePaymentsService);

    invoicePaymentsService.$inject = ['$q', '$http', 'apiURLService', '$sce', '$rootScope'];

    function invoicePaymentsService($q, $http, apiURLService, $sce, $rootScope) {
        var invoicePaymentsObject = {};
        invoicePaymentsObject.getSearchCriteria = getSearchCriteria;
        invoicePaymentsObject.getInvoices = getInvoices;
        invoicePaymentsObject.getColumnHeaders = getColumnHeaders;
        invoicePaymentsObject.getPaymentHistorySearch = getPaymentHistorySearch;

        invoicePaymentsObject.getInvoiceColumnHeaders = getInvoiceColumnHeaders;
        invoicePaymentsObject.fetchInquiryReasons = fetchInquiryReasons;
        invoicePaymentsObject.getOpenInvoiceList = getOpenInvoiceList;
        invoicePaymentsObject.exportInvoicePayment = exportInvoicePayment;
        invoicePaymentsObject.saveMemoInvoices = saveMemoInvoices;

        invoicePaymentsObject.getPaymentList = getPaymentList;
        invoicePaymentsObject.submitInquiryList = submitInquiryList;
        invoicePaymentsObject.makePayment = makePayment;
        invoicePaymentsObject.getPaymentColumnHeaders = getPaymentColumnHeaders;
        invoicePaymentsObject.exportPayments = exportPayments;
        invoicePaymentsObject.makePaymentInvoiceList = makePaymentInvoiceList;
        invoicePaymentsObject.finishPayment = finishPayment;
        invoicePaymentsObject.saveColumnHeaders = saveColumnHeaders;
        invoicePaymentsObject.setInvoiceNumber = setInvoiceNumber;
        invoicePaymentsObject.getInvoiceNumber = getInvoiceNumber;
        invoicePaymentsObject.setSelectAllInvoices = setSelectAllInvoices;
        invoicePaymentsObject.getSelectAllInvoices = getSelectAllInvoices;
        invoicePaymentsObject.setInvoiceNumberHistory = setInvoiceNumberHistory;
        invoicePaymentsObject.getInvoiceNumberHistory = getInvoiceNumberHistory;
        invoicePaymentsObject.setSelectAllInvoicesHistory = setSelectAllInvoicesHistory;
        invoicePaymentsObject.getAllInvoicesHistory = getAllInvoicesHistory;
       // invoicePaymentsObject.setIsAllInvoice = setIsAllInvoice;
       // invoicePaymentsObject.getIsAllInvoice = getIsAllInvoice;
       //  //invoicePaymentsObject.selectAll = false;
        return invoicePaymentsObject;

        function getSearchCriteria() {
            var url = apiURLService.getSearchCriteriaURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getInvoices(requestData) {
            var url = apiURLService.getInvoicesURL() + requestData;
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.status);
            });
        }

        function getColumnHeaders() {
            var url = apiURLService.getColumnHeadersURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getPaymentHistorySearch(requestData) {
            var url = apiURLService.getPaymentHistorySearchURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getInvoiceColumnHeaders() {
            var url = apiURLService.getInvoiceColumnHeadersURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function fetchInquiryReasons() {
            var url = apiURLService.fetchInquiryReasonsURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getOpenInvoiceList(requestData) {
            var url = apiURLService.getOpenInvoiceListURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getPaymentList(requestData) {
            var url = apiURLService.getPaymentListURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function submitInquiryList(requestData) {
            var url = apiURLService.submitInquiryListURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function makePayment(url, method, params) {
            url = $sce.trustAsResourceUrl(url);

            $rootScope.$broadcast('form.submit', {
                url: url,
                method: method,
                params: params
            });
        }

        function exportInvoicePayment(requestData) {
            var url = apiURLService.exportInvoicePaymentURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getPaymentColumnHeaders() {
            var url = apiURLService.getPaymentColumnURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function exportPayments(requestData) {
            var url = apiURLService.exportPaymentsURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function makePaymentInvoiceList(requestData) {
            var url = apiURLService.makePaymentInvoiceListURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

         function finishPayment(requestData) {
            var url = apiURLService.finishPaymentURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function saveColumnHeaders(requestData) {
            var url = apiURLService.saveColumnHeadersURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function setInvoiceNumber(invoiceNumber){
            invoicePaymentsObject.invoiceNumber = invoiceNumber;
        }

        function getInvoiceNumber(){
            return invoicePaymentsObject.invoiceNumber;
        }

        function setSelectAllInvoices(selectAll){
            invoicePaymentsObject.selectAll = selectAll
        }

        function getSelectAllInvoices(){
            return invoicePaymentsObject.selectAll;
        }

        function setInvoiceNumberHistory(invoiceNumberHistory){
            invoicePaymentsObject.invoiceNumberHistory = invoiceNumberHistory;
        }

        function getInvoiceNumberHistory(){
            return invoicePaymentsObject.invoiceNumberHistory;
        }

        function setSelectAllInvoicesHistory(selectAll){
            invoicePaymentsObject.selectAll = selectAll
        }

        function getAllInvoicesHistory(){
            return invoicePaymentsObject.selectAll;
        }

         function saveMemoInvoices(requestData) {
            var url = apiURLService.saveMemoInvoicesURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        // function  setIsAllInvoice(isAllSelected) {
        //     invoicePaymentsObject.selectAllPayment = isAllSelected;
        // }
        //  function  getIsAllInvoice() {
        //     return invoicePaymentsObject.isAllSelected;
        // }
    }
})();