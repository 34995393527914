/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';
    angular.module('scFuelsApp.userManagement')
        .factory('userManagementService', userManagementService);

    userManagementService.$inject = ['$q', '$http', 'apiURLService'];

    function userManagementService ($q, $http, apiURLService) {
        var userManagementObject = {};
        userManagementObject.getUserAccountDetails = getUserAccountDetails;
        userManagementObject.saveNewUserDetails = saveNewUserDetails;
        userManagementObject.validateEmail = validateEmail;
        userManagementObject.getAccountList = getAccountList;
        userManagementObject.getUser = getUser;
        userManagementObject.getDefaultPermissions = getDefaultPermissions;
        userManagementObject.updateUser = updateUser;
        return userManagementObject;

        function getUserAccountDetails(requestData){
            var url = apiURLService.getUserAccountDetailsURL()+requestData.orgId+"&&pageNumber="+requestData.pageNumber+"&&searchKey="+ requestData.searchKey +"&&searchFlag="+requestData.searchFlag +"";
            return $http.get(url).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function saveNewUserDetails(requestData){
            var url = apiURLService.saveNewUserURL();
            return $http.post(url,requestData).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function validateEmail(requestData){
          var url = apiURLService.validateEmailURL()+requestData;
          return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
          }).catch(function (apiResponse) {
              return $q.reject(apiResponse.data);
          });
        }

        function getAccountList(requestData){
          var url = apiURLService.getAccountListURL();
          return $http.post(url,requestData).then(function (apiResponse) {
                return apiResponse.data;
          }).catch(function (apiResponse) {
              return $q.reject(apiResponse.data);
          });
        }

        function getUser(requestData){
          var url = apiURLService.getUserURL()+requestData.userID+"&&orgId="+requestData.orgID;
          return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
          }).catch(function (apiResponse) {
              return $q.reject(apiResponse.data);
          });
        }

        function getDefaultPermissions(){
          var url = apiURLService.getDefaultPermissionURL();
          return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
          }).catch(function (apiResponse) {
              return $q.reject(apiResponse.data);
          });
        }

        function updateUser(requestData){
          var url = apiURLService.updateUserURL();
          return $http.post(url,requestData).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }
    }
})();
