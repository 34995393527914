/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.bannerManagement')
        .controller('BannerManagementController', BannerManagementController);


    BannerManagementController.$inject = ['$rootScope', '$scope', '$state', 'bannerManagementService', 'toastr', '$confirm', 'toastrMessage'];

    function BannerManagementController($rootScope, $scope, $state, bannerManagementService, toastr, $confirm, toastrMessage) {
        var bannerManagementVM = this;
        bannerManagementVM.addBanner = addBanner;
        bannerManagementVM.deleteItem = deleteItem;
        bannerManagementVM.saveBanner = saveBanner;
        bannerManagementVM.bannerList = [];
        bannerManagementVM.isChanged = false;

        init();

        /*function declarations*/

        /*function to get current banners in the Dashboard.*/
        function init() {
            $rootScope.dataLoading = true;
            bannerManagementService.getCurrentBanners()
                .then(getCurrentBannersSuccess, getCurrentBannersFailure);
        }
        /*success callback for current banner*/
        function getCurrentBannersSuccess(apiResponse) {
            if (apiResponse.statusCode === 200) {
                bannerManagementVM.bannerList = apiResponse.bannerData;
                $rootScope.dataLoading = false;
            }
        }
        /*error callback for current banner*/
        function getCurrentBannersFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        /*deletes a banner*/
        function deleteItem(index) {
            $confirm({
                title: 'Delete',
                text: 'Changes will be lost. Would you like to proceed?',
                ok: 'Yes',
                cancel: 'No'
            }, {
                size: 'sm',
                backdrop: true
            }).then(function() {
                bannerManagementVM.bannerList.splice(index, 1);
                bannerManagementVM.isChanged = true;
            });
        }
        /*add a banner*/
        function addBanner() {
            if (bannerManagementVM.newBanner) {
                bannerManagementVM.bannerList.push(bannerManagementVM.newBanner);
                bannerManagementVM.newBanner = null;
                angular.element('#banner-input').val('');
                bannerManagementVM.isChanged = true;
            }
        }
        /*save banner*/
        function saveBanner() {
            var params = prepareBannerParams();
            if (params) {
                $rootScope.dataLoading = true;
                bannerManagementService.saveBanner(params)
                    .then(saveBannerSuccess, saveBannerFailure);
            }
        }
        /*creates request for save banner*/
        function prepareBannerParams() {
            var bannerRec = {
                'bannerData': []
            };
            var tempObj = {};
            angular.forEach(bannerManagementVM.bannerList, function(item) {
                if (item.data) {
                    tempObj = {
                        'imageData': item.data.split(',')[1],
                        'imageName': item.name,
                        'imageType': item.type
                    }
                    bannerRec.bannerData.push(tempObj);
                } else if (item.bannerId) {
                    tempObj = {
                        'bannerId': item.bannerId
                    }
                    bannerRec.bannerData.push(tempObj);
                } else {
                    //sonar issue fix
                }
            });
            return bannerRec;
        }
        /*success callback for save banner*/
        function saveBannerSuccess(apiResponse) {
            if (apiResponse.statusCode === 200) {
                $rootScope.dataLoading = false;
                toastr.success('Banner updated successfully.');
            }
        }
        /*error callback for save banner*/
        function saveBannerFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        $scope.$watch('bannerManagementVM.newBanner', function() {
            if (bannerManagementVM.newBanner) {
                bannerManagementVM.bannerList.splice(0, 0, bannerManagementVM.newBanner);
                bannerManagementVM.newBanner = null;
                bannerManagementVM.isChanged = true;
            }
        });
    }
})();
