/*jshint browser: true*/
/*global angular: true*/

(function() {
    'use strict';
    angular.module('scFuelsApp.changeAccountInfo')
        .factory('accountInfoService', accountInfoService);

    accountInfoService.$inject = ['$q', '$http', 'apiURLService'];

    function accountInfoService($q, $http, apiURLService) {
        var accountInfoObject = {};
        accountInfoObject.getAccountList = getAccountList;
        accountInfoObject.fetchAccountInformation = fetchAccountInformation;
        accountInfoObject.emailValidation = emailValidation;
        accountInfoObject.saveBillToAddressInfo = saveBillToAddressInfo;
        accountInfoObject.saveContactInformation = saveContactInformation;
        accountInfoObject.savePaperlessInformation = savePaperlessInformation;
        accountInfoObject.deleteContactInfo = deleteContactInfo;
        accountInfoObject.deletePaperlessInfo = deletePaperlessInfo;
        accountInfoObject.getStateList = getStateList;
        return accountInfoObject;

        function getAccountList(requestData) {
            var url = apiURLService.getAccountInfoListURL()+requestData.pageNo+'&&pageRows=10';
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function fetchAccountInformation(requestData) {
            var url = apiURLService.fetchAccountInformationURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function emailValidation(requestData,index) {
            var url = apiURLService.emailValidationURL() + requestData;
            return $http.get(url).then(function(apiResponse) {
                apiResponse.data.index = index;
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function saveBillToAddressInfo(requestData) {
            var url = apiURLService.saveBillToAddressInfoURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function savePaperlessInformation(requestData) {
            var url = apiURLService.savePaperlessInformationURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function deleteContactInfo(requestData) {
            var url = apiURLService.deleteContactInfoURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function deletePaperlessInfo(requestData) {
            var url = apiURLService.deletePaperlessInfoURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function saveContactInformation(requestData) {
            var url = apiURLService.saveContactInformationURL();
            return $http.post(url, requestData).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getStateList(){
            var url = apiURLService.getStateListURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

    }
})();
