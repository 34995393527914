
/*jshint browser: true*/
/*global angular: true*/


(function(){
    'use strict';

    angular
        .module('scFuelsApp.userActivity', [])
        .config(routerConfiguration);

    routerConfiguration.$inject = ['$stateProvider'];
    function routerConfiguration ($stateProvider) {
        $stateProvider
            .state('root.userActivity', {
            url: '/activity',
            templateUrl: 'app/modules/userActivity/userActivityTemplate.html',
            controller: 'userActivityController',
            controllerAs : 'userActivityVM'
        });
    }
})();
