/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.invoicePayments')
        .controller('paymentHistoryController', paymentHistoryController);


    paymentHistoryController.$inject = ['$scope', '$state', 'toastr', '$window', 'invoicePaymentsService', 'toastrMessage', 'utils', '$rootScope', '$filter'];

    function paymentHistoryController($scope, $state, toastr, $window, invoicePaymentsService, toastrMessage, utils, $rootScope, $filter) {
        var historyVM = this;
        historyVM.amountDueType = '';
        historyVM.amountDueDateType = '';
        historyVM.invoiceCategoryType = '';
        historyVM.firstDueAmount = '';
        historyVM.secondDueAmount = '';
        historyVM.startNumber = '';
        historyVM.endNumber = '';
        historyVM.maxPage = 2;
        historyVM.serviceTypeId = 1;
        historyVM.startYear = utils.calenderStartYear;
        historyVM.endYear = utils.calenderEndYear;
        historyVM.datePicker = { startDate: null, endDate: null };
        historyVM.showInvoiceSearchButton = true;
        historyVM.showAdvanceSearch = false;
        historyVM.searchInvoiceNo = '';
        historyVM.showDownloadPopup = [];
        historyVM.showColumnPopup = false;
        historyVM.searchPaymentHistory = searchPaymentHistory;
        historyVM.getAllInvoices = getAllInvoices;
        historyVM.toggleSearch = toggleSearch;
        historyVM.paginationClick = paginationClick;
        historyVM.invoiceSearch = invoiceSearch;
        historyVM.orderByColumn = orderByColumn;
        historyVM.arrangeTableData = arrangeTableData;
        historyVM.download = download;
        historyVM.downloadAttachment = downloadAttachment;
        historyVM.toggleHeader = toggleHeader;
        historyVM.clearCalender = clearCalender;
        historyVM.selectServiceType = selectServiceType;
        historyVM.selectPaymentMode = selectPaymentMode;
        historyVM.invoiceListLength = 0;
        historyVM.selectAll = false;
        historyVM.checkAllInvoices = checkAllInvoices;
        historyVM.invoicesNumber = [];
        historyVM.checkedInvoicesToExport = checkedInvoicesToExport;
        historyVM.memoPopupInvoice = memoPopupInvoice;
       // historyVM.isPopUp = [];
        historyVM.indexMemo = 0 ;
        historyVM.cancelMemo = cancelMemo;
      //  historyVM.isMemo = [];
        // historyVM.selectAll = false;
        // historyVM.checkAllInvoices = checkAllInvoices;
        init();

        /*function declaration*/

        /*initial loading*/
        function init() {
            historyVM.currentPage = 1;
            historyVM.firstPageItem = 1;
            historyVM.secondPageItem = 2;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item) {
                    if (item.option.toLowerCase().indexOf('invoice') !== -1) {
                        historyVM.userPermission = item.displayName;
                    }
                });
            }
            // getColumnHeaders();
            getSearchAreaDetails();
        }
        /*when account changed*/
        $scope.$on('accountChanged', function() {
            var hasPermission = false;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item, key) {
                    if (item.option.toLowerCase().indexOf('invoice') !== -1) {
                        if (item.displayName === 'R' || item.displayName === 'W') {
                            hasPermission = true;
                            init();
                        }
                    }
                    if ((key === 3) && !hasPermission) {
                        $state.go('root.dashboard');
                    }
                });
            }
        });
        /*fetches the search area details*/
        function getSearchAreaDetails() {
            $rootScope.dataLoading = true;
            invoicePaymentsService.getSearchCriteria()
                .then(getSearchAreaDetailsSuccess, getSearchAreaDetailsError);
        }
        /*success callback for search area details*/
        function getSearchAreaDetailsSuccess(apiResponse) {
            historyVM.searchArea = apiResponse;
            angular.forEach(historyVM.searchArea.serviceTypes, function(item) {
                item.isChecked = true;
                if (item.id === 2) {
                    item.tooltip = toastrMessage.tootipMessage.bulkWholesale;
                } else if (item.id === 3) {
                    item.tooltip = toastrMessage.tootipMessage.onSiteFuels;
                } else if (item.id === 4) {
                    item.tooltip = toastrMessage.tootipMessage.lubricants;
                } else if (item.id === 5) {
                    item.tooltip = toastrMessage.tootipMessage.fleetCards;
                } else {
                    //Sonar Issue Fix
                }
            });
            angular.forEach(historyVM.searchArea.paymentModes, function(item) {
                item.isChecked = false;
            });
            historyVM.amountDueType = historyVM.searchArea.amountDueTypes[0];
            historyVM.amountDueDateType = historyVM.searchArea.amountDueDateTypes[0];
            historyVM.invoiceCategoryType = historyVM.searchArea.invoiceCategoryTypes[0];
            getColumnHeaders();
        }
        /*error callback for search area details*/
        function getSearchAreaDetailsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches all invoices for smart search*/
        function getAllInvoices(searchKey) {
            $rootScope.dataLoading = true;
            return invoicePaymentsService.getInvoices(searchKey)
                .then(getInvoicesSuccess, getInvoicesError);
        }
        /*success callback for invoice smart search*/
        function getInvoicesSuccess(apiResponse) {
            var results = [];
            angular.forEach(apiResponse.invoiceNumbers, function(item) {
                var invoices = item;
                results.push({ name: invoices });
            });
            $rootScope.dataLoading = false;
            historyVM.invoiceListLength = results.length;
            return results;
        }
        /*error callback for invoice smart search*/
        function getInvoicesError(error) {
            $rootScope.dataLoading = false;
            if (error === 404) {
                //Sonar Issue Fix
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*toggles the advance search*/
        function toggleSearch() {
            historyVM.showAdvanceSearch = !historyVM.showAdvanceSearch;
            historyVM.searchInvoiceNo = '';
            if (historyVM.showAdvanceSearch) {
                historyVM.showInvoiceSearchButton = false;
            } else {
                historyVM.showInvoiceSearchButton = true;
            }
        }
        /*clicks on invoice search button*/
        function invoiceSearch() {
            if (historyVM.searchInvoiceNo && (historyVM.searchInvoiceNo.length < 3)) {
                toastr.warning(toastrMessage.userManagement.searchCondition);
            } else {
                historyVM.currentPage = 1;
                historyVM.firstPageItem = 1;
                historyVM.secondPageItem = 2;
                clearSearchParameters();
                getPaymentHistorySearchDetails('InvoiceSearch');
            }
        }
        /*fetches the column headers for table*/
        function getColumnHeaders() {
            $rootScope.dataLoading = true;
            invoicePaymentsService.getColumnHeaders()
                .then(getColumnHeadersSuccess, getColumnHeadersError);
        }
        /*success callback for column header*/
        function getColumnHeadersSuccess(apiResponse) {
            historyVM.columnHeaders = [];
            if (localStorage.paymentColumns && JSON.parse(localStorage.paymentColumns).length) {
                historyVM.columnHeaders = JSON.parse(localStorage.paymentColumns);
            } else {
                angular.forEach(apiResponse.columnHeaders, function(item) {
                    if (item.strHeader === 'Invoice Date') {
                        historyVM.columnHeaders.push({ columnID: item.id, columnName: item.strHeader, showIcon: 'ic-filter', showColumn: item.showColumn });
                    } else {
                        historyVM.columnHeaders.push({ columnID: item.id, columnName: item.strHeader, showIcon: 'ic-filterNot', showColumn: item.showColumn});
                    }
                });
            }
            historyVM.columnHeaderPopup = angular.copy(historyVM.columnHeaders);
            localStorage.paymentColumns = JSON.stringify(historyVM.columnHeaders);
            getPaymentHistorySearchDetails('AdvanceSearch');
        }
        /*error callback for column header*/
        function getColumnHeadersError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*clicks on search button in the advance search*/
        function searchPaymentHistory() {
            historyVM.currentPage = 1;
            historyVM.firstPageItem = 1;
            historyVM.secondPageItem = 2;
            historyVM.serviceTypes = [];
            historyVM.paymentMode = [];
            angular.forEach(historyVM.searchArea.serviceTypes, function(item) {
                if (item.isChecked) {
                    historyVM.serviceTypes.push(item.id);
                }
            });
            angular.forEach(historyVM.searchArea.paymentModes, function(item) {
                if (item.isChecked) {
                    historyVM.paymentMode.push(item.id);
                }
            });

            if (historyVM.serviceTypes.length === 0 || historyVM.datePicker.startDate === null || historyVM.datePicker.endDate === null) {
                toastr.error('Please enter all the required fields.');
            } else if ((historyVM.firstDueAmount && isNaN(historyVM.firstDueAmount)) || (historyVM.secondDueAmount && isNaN(historyVM.secondDueAmount))) {
                toastr.error('Enter a valid ' + historyVM.amountDueType.type + '.');
            } else if (parseFloat(historyVM.firstDueAmount) > parseFloat(historyVM.secondDueAmount)) {
                toastr.error(historyVM.amountDueType.type + ' first amount should be less.');
            } else if ((historyVM.startNumber && !utils.isValidAlphaNumeric(historyVM.startNumber)) || (historyVM.endNumber && !utils.isValidAlphaNumeric(historyVM.endNumber))) {
                toastr.error('Enter a valid ' + historyVM.invoiceCategoryType.type + '.');
            } else if (parseInt(historyVM.startNumber, 10) > parseInt(historyVM.endNumber, 10)) {
                toastr.error(historyVM.invoiceCategoryType.type + ' starting number should be less.');
            } else if (historyVM.searchInvoiceNo && (historyVM.searchInvoiceNo.length < 3)) {
                toastr.warning(toastrMessage.userManagement.searchCondition);
            } else {
                //api call 
                getPaymentHistorySearchDetails('AdvanceSearch');
            }

        }
        /*loads the payment history data*/
        function getPaymentHistorySearchDetails(callFrom) {
            var sortByColumn = '';
            var orderByColumn = '';
            var serviceTypes = [];
            var payload = {};
            var startDate = historyVM.datePicker.startDate === null ? '' : $filter('date')(new Date(historyVM.datePicker.startDate.toString()), 'MM/dd/yyyy');
            var endDate = historyVM.datePicker.endDate === null ? '' : $filter('date')(new Date(historyVM.datePicker.endDate.toString()), 'MM/dd/yyyy');
            //To get sorting parameter and direction
            historyVM.columnHeaders.forEach(function(item) {
                if (item.showIcon === 'ic-filter' || item.showIcon === 'ic-filterDesc') {
                    sortByColumn = item.columnName;
                    orderByColumn = (item.showIcon === 'ic-filter') ? 'asc' : 'desc';
                }
            });
            if (historyVM.searchArea !== undefined) {
                angular.forEach(historyVM.searchArea.serviceTypes, function(item) {
                    if (item.isChecked) {
                        serviceTypes.push(item.id);
                    }
                });
                if (serviceTypes.length === 0) {
                    serviceTypes.push(historyVM.serviceTypeId);
                }
            } else {
                serviceTypes.push(historyVM.serviceTypeId);
            }

            if (callFrom === 'AdvanceSearch') {
                payload.serviceTypeId = serviceTypes;
                payload.dueDateTypeId = historyVM.amountDueDateType.id === undefined ? '' : historyVM.amountDueDateType.id;
                payload.startDate = startDate;
                payload.endDate = endDate;
                payload.dueAmountTypeId = historyVM.amountDueType.id === undefined ? '' : historyVM.amountDueType.id;
                payload.fromRange = historyVM.firstDueAmount;
                payload.toRange = historyVM.secondDueAmount;
                payload.invoiceCategoryId = historyVM.invoiceCategoryType.id === undefined ? '' : historyVM.invoiceCategoryType.id;
                payload.invoiceNumberFrom = historyVM.startNumber;
                payload.invoiceNumberTo = historyVM.endNumber;
                payload.paymentModeId = historyVM.paymentMode === undefined ? '' : historyVM.paymentMode;
                payload.invoiceNumber = historyVM.searchInvoiceNo.name === undefined ? historyVM.searchInvoiceNo : historyVM.searchInvoiceNo.name;
                payload.pageNumber = historyVM.currentPage;
                payload.sortingParameter = sortByColumn;
                payload.sortingDirection = orderByColumn;
            } else if (callFrom === 'InvoiceSearch') {
                payload.serviceTypeId = serviceTypes;
                payload.dueDateTypeId = '';
                payload.startDate = '';
                payload.endDate = '';
                payload.dueAmountTypeId = '';
                payload.fromRange = '';
                payload.toRange = '';
                payload.invoiceCategoryId = '';
                payload.invoiceNumberFrom = '';
                payload.invoiceNumberTo = '';
                payload.paymentModeId = '';
                payload.invoiceNumber = historyVM.searchInvoiceNo.name === undefined ? historyVM.searchInvoiceNo : historyVM.searchInvoiceNo.name;
                payload.pageNumber = 1;
                payload.sortingParameter = sortByColumn;
                payload.sortingDirection = orderByColumn;
            } else {
                //Sonar Issue Fix
            }

            payload.pageRows = 10;
            payload.accountId = localStorage.selectedAccount;
            payload.operation = 'btnInvoiceSearch';
            $rootScope.dataLoading = true;
            localStorage.paymentSearchCriteria = JSON.stringify(payload);
            invoicePaymentsService.getPaymentHistorySearch(payload)
                .then(getPaymentHistorySearchSuccess, getPaymentHistorySearchError);
        }
        /*success callback for load payment history*/
        function getPaymentHistorySearchSuccess(apiResponse) {
            historyVM.paymentHistroyList = apiResponse;
            historyVM.searchCount = historyVM.paymentHistroyList.totalCount;
            historyVM.maxPage = Math.ceil(historyVM.paymentHistroyList.totalCount / 10);
            $rootScope.dataLoading = false;
             angular.forEach(historyVM.paymentHistroyList.accountDetails,function(accItem){
                angular.forEach(accItem.accountData,function(item){
               if(item.invoices[0].memo != null && item.invoices[0].memo != ""){
                item.isMemo = true;
               }else{
                 item.isMemo = false;
               }
              }); 
            });
        }
        /*error callback for load payment history*/
        function getPaymentHistorySearchError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*pagination*/
        function paginationClick(button) {
            historyVM.selectAll = false ; 
            var searchRequired = false;
            $window.scrollTo(0, 0);
            switch (button) {
                case 'first':
                    if (historyVM.firstPageItem > 1) {
                        historyVM.firstPageItem = 1;
                        historyVM.secondPageItem = 2;
                        historyVM.currentPage = 1;
                        searchRequired = true;
                    }
                    break;
                case 'previous':
                    if (historyVM.firstPageItem > 1) {
                        historyVM.firstPageItem--;
                        historyVM.secondPageItem--;
                        historyVM.currentPage--;
                        searchRequired = true;
                    } else if (historyVM.firstPageItem < historyVM.currentPage) {
                        historyVM.currentPage--;
                        searchRequired = true;
                    } else {
                        //Sonar Issue fix
                    }
                    break;
                case 'next':
                    if (historyVM.secondPageItem < historyVM.maxPage) {
                        historyVM.firstPageItem++;
                        historyVM.secondPageItem++;
                        historyVM.currentPage++;
                        searchRequired = true;
                    } else if (historyVM.currentPage < historyVM.secondPageItem) {
                        historyVM.currentPage++;
                        searchRequired = true;
                    } else {
                        //Sonar Issue fix
                    }
                    break;
                case 'last':
                    if (historyVM.secondPageItem < historyVM.maxPage) {
                        historyVM.firstPageItem = historyVM.maxPage - 1;
                        historyVM.secondPageItem = historyVM.maxPage;
                        historyVM.currentPage = historyVM.maxPage;
                        searchRequired = true;
                    }
                    break;
                default:
                    historyVM.currentPage = button;
                    searchRequired = true;
                    break;
            }
             angular.forEach(historyVM.paymentHistroyList.accountDetails,function(accData){
                angular.forEach(accData.accountData,function(item){
                item.isPopUp = false;
                item.isMemo = false;
            });
         }); 
            // historyVM.isPopUp = [];  
            // historyVM.isMemo = [];
            if (searchRequired) {
                getPaymentHistorySearchDetails('AdvanceSearch');
            }
        }
        /*toggles the column header pop up*/
        function toggleHeader() {
            historyVM.showColumnPopup = true;
        }
        /*clicks on save button on column header pop up*/
        function arrangeTableData(popupData) {
            var selected = false;
            angular.forEach(popupData, function(item) {
                if (item.showColumn) {
                    selected = true;
                }
            });
            if (selected) {
                angular.forEach(popupData, function(item, key) {
                    if (item.showColumn) {
                        historyVM.columnHeaders[key].showColumn = true;
                    } else {
                        historyVM.columnHeaders[key].showColumn = false;
                    }
                });
                historyVM.showColumnPopup = false;
                localStorage.paymentColumns = JSON.stringify(historyVM.columnHeaders);

                 var columnPaymentHistory = [];
                 historyVM.columnHeaders.forEach(function(item){
                 var listObj = {};
                 listObj.id = item.columnID;
                 listObj.strHeader = item.columnName;
                 listObj.showColumn = item.showColumn;
                 columnPaymentHistory.push(listObj);
                });

                 var Payload = {
                "pageType":"PaymentHistory",
                "columnHeaders":columnPaymentHistory
                };
                $rootScope.dataLoading = true;
                invoicePaymentsService.saveColumnHeaders(Payload)
                .then(saveColumnHeadersSuccess, saveColumnHeadersError);
            } else {
                toastr.error('Please select at least one column.');
            }
        }

        function saveColumnHeadersSuccess(apiResponse){
             $rootScope.dataLoading = false;
           toastr.success('Saved Successfully.');

        }

        function saveColumnHeadersError(){
            $rootScope.dataLoading = false;
            toastr.error('Failed to save');
        }
        
        /*clicks on the attachments button*/
        function download(attachments, index) {
            historyVM.showDownloadPopup = [];
            historyVM.showDownloadPopup[index] = true;
            historyVM.attachments = attachments;
        }
        /*opens the attachments*/
        function downloadAttachment(filePath) {
            historyVM.showDownloadPopup = [];
            $window.open(filePath);
        }
        /*function to order the data by column header */
        function orderByColumn(columnHeader, iconType) {
            angular.forEach(historyVM.columnHeaders, function(item) {
                if (item.columnName === columnHeader) {
                    if (iconType === 'ic-filterNot' || iconType === 'ic-filterDesc') {
                        item.showIcon = 'ic-filter';
                    } else {
                        item.showIcon = 'ic-filterDesc';
                    }
                } else {
                    item.showIcon = 'ic-filterNot';
                }
            });
            localStorage.paymentColumns = JSON.stringify(historyVM.columnHeaders);
            getPaymentHistorySearchDetails('AdvanceSearch');
        }
        /*clears the date values from tha calender*/
        function clearCalender() {
            historyVM.datePicker = { startDate: null, endDate: null };
            $('#daterange').data('daterangepicker').setStartDate(new Date());
            $('#daterange').data('daterangepicker').setEndDate(new Date());
        }
        /*clears the search parameters from advance search*/
        function clearSearchParameters() {
            angular.forEach(historyVM.searchArea.serviceTypes, function(item) {
                item.isChecked = true;
            });
            angular.forEach(historyVM.searchArea.paymentModes, function(item) {
                item.isChecked = false;
            });
            historyVM.serviceTypeId = 1;
            historyVM.amountDueType = historyVM.searchArea.amountDueTypes[0];
            historyVM.amountDueDateType = historyVM.searchArea.amountDueDateTypes[0];
            historyVM.invoiceCategoryType = historyVM.searchArea.invoiceCategoryTypes[0];
            historyVM.firstDueAmount = '';
            historyVM.secondDueAmount = '';
            historyVM.startNumber = '';
            historyVM.endNumber = '';
            historyVM.datePicker = { startDate: null, endDate: null };
        }
        /*Service Type Selection*/
        function selectServiceType(serviceType, isChecked) {
            var selectAll = true;
            if (serviceType === 1) {
                angular.forEach(historyVM.searchArea.serviceTypes, function(item) {
                    item.isChecked = isChecked ? true : false;
                });
            } else {
                angular.forEach(historyVM.searchArea.serviceTypes, function(item) {
                    if (serviceType === item.id && isChecked) {
                        item.isChecked = true;
                    }
                });
            }
            angular.forEach(historyVM.searchArea.serviceTypes, function(item) {
                if (item.id > 1 && !item.isChecked) {
                    selectAll = false;
                    historyVM.searchArea.serviceTypes[0].isChecked = false;
                }
            });
            historyVM.searchArea.serviceTypes[0].isChecked = selectAll ? true : false;
        }

        function selectPaymentMode(paymentMode, isChecked) {
            var selectAll = true;
            if (paymentMode === 14) {
                angular.forEach(historyVM.searchArea.paymentModes, function(item) {
                    item.isChecked = isChecked ? true : false;
                });
            } else {
                angular.forEach(historyVM.searchArea.paymentModes, function(item) {
                    if (paymentMode === item.id && isChecked) {
                        item.isChecked = true;
                    }
                });
            }
            angular.forEach(historyVM.searchArea.paymentModes, function(item) {
                if (item.id > 14 && !item.isChecked) {
                    selectAll = false;
                    historyVM.searchArea.paymentModes[0].isChecked = false;
                }
            });
            historyVM.searchArea.paymentModes[0].isChecked = selectAll ? true : false;
        }

        $rootScope.$on('hidePopup', function() {
            historyVM.showDownloadPopup = [];
            historyVM.showColumnPopup = false;
            $scope.$apply();
        });

          function checkAllInvoices(isChecked) {
           historyVM.selectAll = false;  
            // } 
             historyVM.invoicesNumber = [];
             invoicePaymentsService.setSelectAllInvoicesHistory(isChecked); 
            if(isChecked){
                historyVM.selectAll = true;
                angular.forEach(historyVM.paymentHistroyList.accountDetails,function(item){
                    angular.forEach(item.accountData, function(accItem) {
                        historyVM.invoicesNumber.push(accItem.invoices[0].number);
                    });
                });
             invoicePaymentsService.setInvoiceNumberHistory(historyVM.invoicesNumber); 
             }else{
                historyVM.selectAll = false;  
            } 
            if (isChecked) {
                   angular.forEach(historyVM.paymentHistroyList.accountDetails,function(item){
                    angular.forEach(item.accountData, function(accItem) {
                        accItem.isChecked = true;
                    });
                });
            } else {
                   angular.forEach(historyVM.paymentHistroyList.accountDetails,function(item){
                    angular.forEach(item.accountData, function(accItem) {
                        accItem.isChecked = false;
                    });
                });
            }
            
                
        }
         function checkedInvoicesToExport(accountData ,isChecked){
               if(isChecked){
                    historyVM.invoicesNumber.push(accountData.invoices[0].number);
                    }else{
                historyVM.selectAll = false;
                historyVM.invoicesNumber.splice(historyVM.invoicesNumber.indexOf(accountData.invoices[0].number),1)
                }
                var detailsLength = 0;
                angular.forEach(historyVM.paymentHistroyList.accountDetails,function(accData){
                    detailsLength += accData.accountData.length;
                   });
                if(detailsLength == historyVM.invoicesNumber.length){
                    historyVM.selectAll = true ;     
                }else{
                    historyVM.selectAll = false ; 
                }
           // console.log(openVM.invoiceList.accountDetails[0].accountData.length);
            invoicePaymentsService.setSelectAllInvoicesHistory(historyVM.selectAll);
            invoicePaymentsService.setInvoiceNumberHistory(historyVM.invoicesNumber);  
            console.log(historyVM.invoicesNumber);
        }

         function memoPopupInvoice(accountData,parent,index){
             historyVM.indexMemo = index;
         angular.forEach(historyVM.paymentHistroyList.accountDetails,function(accData){
            angular.forEach(accData.accountData,function(item){
                if(accountData.invoices[0].number == item.invoices[0].number){
                    item.isPopUp = true;
                }else{
                    item.isPopUp = false;
                }
                
            });
         });
    }

     function cancelMemo(accData,parent,index){
              angular.forEach(historyVM.paymentHistroyList.accountDetails,function(accData){
                angular.forEach(accData.accountData,function(item){
                item.isPopUp = false;
            });
         }); 
        }


        $rootScope.$on('exportEvent', function(){
            historyVM.selectAll = false;
            historyVM.invoicesNumber = [];
            angular.forEach(historyVM.paymentHistroyList.accountDetails, function(accItem) {
                angular.forEach(accItem.accountData, function(item) {
                    item.isChecked = false;
                });
            });
        })

    }
})();
