/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';
    angular.module('scFuelsApp.contactus')
        .factory('contactUsService', contactUsService);

    contactUsService.$inject = ['$q', '$http', 'apiURLService'];

    function contactUsService ($q, $http, apiURLService) {
        var contactUsObject = {};
        contactUsObject.getContactDetails = getContactDetails;
        contactUsObject.getContactDropdownData = getContactDropdownData;
        contactUsObject.submitMessage = submitMessage;

        return contactUsObject;


        function getContactDetails(){
            var url = apiURLService.getContactDetailsURL();
            return $http.get(url).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getContactDropdownData(){
            var url = apiURLService.getContactDropdownDataURL();
            return $http.get(url).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }        

        function submitMessage(payload){
            var url = apiURLService.getsubmitMessageURL();
            return $http.post(url, payload).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }
    }
})();
