/*jshint browser: true*/
/*global angular: true*/

(function () {
    'use strict';

    angular.module('scFuelsApp.cardManagement')
        .controller('cardPrintController', cardPrintController);

    cardPrintController.$inject = ['$scope', '$state', '$rootScope', 'cardManagementService', 'utils', 'toastr', 'toastrMessage', '$filter', '$window'];

    function cardPrintController($scope, $state, $rootScope, cardManagementService, utils, toastr, toastrMessage, $filter, $window) {
        var fleetCardPrintVM = this;
        fleetCardPrintVM.searchCardNo = "";
        fleetCardPrintVM.cardNumberFrom = "";
        fleetCardPrintVM.cardNumberTo = "";
        fleetCardPrintVM.idNumberFrom = "";
        fleetCardPrintVM.idNumberTo = "";
        fleetCardPrintVM.amountFrom = "";
        fleetCardPrintVM.amountTo = "";
        fleetCardPrintVM.description = "";
        fleetCardPrintVM.department = "";
        fleetCardPrintVM.exceptions = false;
        fleetCardPrintVM.showCardSearchButton = true;
        fleetCardPrintVM.datePicker = {
            startDate: null,
            endDate: null
        };
        fleetCardPrintVM.startYear = utils.calenderStartYear;
        fleetCardPrintVM.endYear = utils.calenderEndYear;
        fleetCardPrintVM.showProductPopup = false;
        fleetCardPrintVM.showColumnPopup = false;
        fleetCardPrintVM.showAdvanceSearch = false;
        fleetCardPrintVM.showAccordianView = [];
        fleetCardPrintVM.cardDetails = [];
        fleetCardPrintVM.transactionData = [];
        fleetCardPrintVM.siteDetails = [];
        fleetCardPrintVM.amountDetails = [];

        // fleetCardPrintVM.getAllCards = getAllCards;
        // fleetCardPrintVM.toggleSearch = toggleSearch;
        // fleetCardPrintVM.transactionDetails = transactionDetails;
        // fleetCardPrintVM.selectProducts = selectProducts;
        // fleetCardPrintVM.searchTransactions = searchTransactions;
        // fleetCardPrintVM.cardSearch = cardSearch;
        // fleetCardPrintVM.arrangeTableData = arrangeTableData;
        // fleetCardPrintVM.toggleHeader = toggleHeader;
        // fleetCardPrintVM.paginationClick = paginationClick;
        // fleetCardPrintVM.orderByColumn = orderByColumn;
        // fleetCardPrintVM.clearCalender = clearCalender;
        // fleetCardPrintVM.applyProducts = applyProducts;
        fleetCardPrintVM.printTable = printTable;
        fleetCardPrintVM.backToTransaction = backToTransaction;
        init();

        //function declarations

        function init() {
            fleetCardPrintVM.currentPage = 1;
            fleetCardPrintVM.firstPageItem = 1;
            fleetCardPrintVM.secondPageItem = 2;
            // getTransactionParameters();
            getColumnHeaders();
        }

        $scope.$watch('fleetCardPrintVM.currentPage', function () {
            getTransactionSearchDetails([], fleetCardPrintVM.searchCardNo, 'Trans. Date');
        });

        // function getAllCards(searchKey) {
        //     return cardManagementService.getCardSmartSearchList(searchKey)
        //         .then(getCardSmartSearchListSuccess, getCardSmartSearchListError);
        // }

        // function getCardSmartSearchListSuccess(apiResponse) {
        //     var results = [];
        //     angular.forEach(apiResponse.invoiceNumbers, function(item) {
        //         var invoices = item;
        //         results.push({ name: invoices });
        //     });
        //     $rootScope.dataLoading = false;
        //     return results;
        // }

        // function getCardSmartSearchListError() {
        //     $rootScope.dataLoading = false;
        //     toastr.error(toastrMessage.common.systemNetworkFailure);
        // }

        // function toggleSearch() {
        //     fleetCardPrintVM.showAdvanceSearch = !fleetCardPrintVM.showAdvanceSearch;
        //     fleetCardPrintVM.searchCardNo = '';
        //     if (fleetCardPrintVM.showAdvanceSearch) {
        //         fleetCardPrintVM.showCardSearchButton = false;
        //     } else {
        //         fleetCardPrintVM.showCardSearchButton = true;
        //     }
        // }

        // function transactionDetails(CardNumber, iconType, index) {
        //     fleetCardPrintVM.showAccordianView[index] = 'app/modules/cardManagement/transactions/customView/transactionAccordianTemplate.html';
        //     angular.forEach(fleetCardPrintVM.cardList.dataModel, function(item, key) {
        //         if (key === index) {
        //             if (item.showIcon === 'ic-expand_arrow-down') {
        //                 item.showIcon = 'ic-expand_arrow-up';
        //                 return cardManagementService.getTransactionDetails(CardNumber, index)
        //                     .then(getTransactionDetailsSuccess, getTransactionDetailsError);
        //             } else {
        //                 item.showIcon = 'ic-expand_arrow-down';
        //             }
        //         }
        //     });
        // }

        // function getTransactionDetailsSuccess(apiResponse) {
        //     var index = apiResponse.index;
        //     fleetCardPrintVM.transactionDetail = apiResponse;
        //     fleetCardPrintVM.cardDetails[index] = fleetCardPrintVM.transactionDetail.cardDetails;
        //     fleetCardPrintVM.transactionData[index] = fleetCardPrintVM.transactionDetail.transactionDetails;
        //     fleetCardPrintVM.siteDetails[index] = fleetCardPrintVM.transactionDetail.siteDetails;
        //     fleetCardPrintVM.amountDetails[index] = fleetCardPrintVM.transactionDetail.amountDetails;
        // }

        // function getTransactionDetailsError() {

        // }

        // function getTransactionParameters() {
        //     return cardManagementService.getTransactionParameters()
        //         .then(getTransactionParametersSuccess, getTransactionParametersError);
        // }

        // function getTransactionParametersSuccess(apiResponse) {
        //     fleetCardPrintVM.productValues = apiResponse.products;
        //     fleetCardPrintVM.productValues.forEach(function(item) {
        //         item.isChecked = false;
        //     });
        // }

        // function getTransactionParametersError() {

        // }

        // function selectProducts() {
        //     fleetCardPrintVM.showProductPopup = !fleetCardPrintVM.showProductPopup;
        // }

        // $rootScope.$on('hidePopup', function() {
        //     fleetCardPrintVM.showProductPopup = false;
        //     fleetCardPrintVM.showColumnPopup = false;
        //     $scope.$apply();
        // });

        // function searchTransactions() {
        //     fleetCardPrintVM.productIds = [];
        //     angular.forEach(fleetCardPrintVM.productValues, function(item) {
        //         if (item.isChecked) {
        //             fleetCardPrintVM.productIds.push(item.id);
        //         }
        //     });

        //     if ((fleetCardPrintVM.cardNumberFrom && !utils.isValidNumber(fleetCardPrintVM.cardNumberFrom)) || (fleetCardPrintVM.cardNumberTo && !utils.isValidNumber(fleetCardPrintVM.cardNumberTo))) {
        //         toastr.error("Enter valid card Numbers");
        //     } else if (parseInt(fleetCardPrintVM.cardNumberFrom, 10) > parseInt(fleetCardPrintVM.cardNumberTo, 10)) {
        //         toastr.error("Starting card number should be less");
        //     } else if ((fleetCardPrintVM.idNumberFrom && !utils.isValidNumber(fleetCardPrintVM.idNumberFrom)) || (fleetCardPrintVM.idNumberTo && !utils.isValidNumber(fleetCardPrintVM.idNumberTo))) {
        //         toastr.error("Enter valid id Numbers");
        //     } else if (parseInt(fleetCardPrintVM.idNumberFrom, 10) > parseInt(fleetCardPrintVM.idNumberTo, 10)) {
        //         toastr.error("Starting Id number should be less");
        //     } else if ((fleetCardPrintVM.amountFrom && isNaN(fleetCardPrintVM.amountFrom)) || (fleetCardPrintVM.amountTo && isNaN(fleetCardPrintVM.amountTo))) {
        //         toastr.error("Enter valid amount Numbers");
        //     } else if (parseFloat(fleetCardPrintVM.amountFrom) > parseFloat(fleetCardPrintVM.amountTo)) {
        //         toastr.error("Starting amount number should be less");
        //     } else if (fleetCardPrintVM.description && !utils.isAlphaNumeric(fleetCardPrintVM.description)) {
        //         toastr.error("Special symbols are not alloewd in description");
        //     } else {
        //         getTransactionSearchDetails(fleetCardPrintVM.productIds, fleetCardPrintVM.searchCardNo, 'Trans. Date');
        //     }
        // }

        function getTransactionSearchDetails(products, CardNumber, sortBy, orderBy) {
            $rootScope.dataLoading = true;
            // var startDate = fleetCardPrintVM.datePicker.startDate === null ? "" : $filter('date')(new Date(fleetCardPrintVM.datePicker.startDate.toString()), 'MM/dd/yyyy');
            // var endDate = fleetCardPrintVM.datePicker.endDate === null ? "" : $filter('date')(new Date(fleetCardPrintVM.datePicker.endDate.toString()), 'MM/dd/yyyy');

            // var payload = {
            //     "cardNumFrom": fleetCardPrintVM.cardNumberFrom,
            //     "cardNumTo": fleetCardPrintVM.cardNumberTo,
            //     "CardDescription": fleetCardPrintVM.description,
            //     "product": products,
            //     "idFrom": fleetCardPrintVM.idNumberFrom,
            //     "idTo": fleetCardPrintVM.idNumberTo,
            //     "department": fleetCardPrintVM.department,
            //     "transactionDateFrom": startDate,
            //     "transactionDateTo": endDate,
            //     "amountFrom": fleetCardPrintVM.amountFrom,
            //     "amountTo": fleetCardPrintVM.amountTo,
            //     "transactionwithExpceptions": fleetCardPrintVM.exceptions,
            //     "transactionTimeTo": "",
            //     "transactionTimeFrom": "",
            //     "searchString": CardNumber.name === undefined ? CardNumber : CardNumber.name,
            //     "accntNum": "All Accounts",
            //     "pageNumber": fleetCardPrintVM.currentPage,
            //     "sortingParameter": sortBy,
            //     "sortingDirection": orderBy === undefined ? 'asc' : orderBy,
            //     "pageRows": 20
            // };

            // var payload = {
            //     "Filter": {
            //         "PageNo": 1,
            //         "PageSize": 10,
            //         "SortColumn": sortBy,
            //         "SortOrder": orderBy === undefined ? 'asc' : orderBy
            //     },
            //     "Search": {
            //         "CardId": fleetCardPrintVM.searchCardNo,
            //         "CardDesc": fleetCardPrintVM.description,
            //         "DateFrom": startDate,
            //         "DateTo": endDate
            //     }
            // };
            var payload = JSON.parse(localStorage.cardManageMentPaylod);
            payload.Filter.PageNo = 0;
            payload.Filter.PageSize = 0;
            payload.Search.AccountNo = localStorage.selectedAccount;
            return cardManagementService.printCardDetails(payload)
                .then(getLastTransactionsSuccess, getLastTransactionsError);
        }

        function getLastTransactionsSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            fleetCardPrintVM.cardList = apiResponse;
            fleetCardPrintVM.searchCount = apiResponse.totalRecords;
            fleetCardPrintVM.maxPage = Math.ceil(fleetCardPrintVM.searchCount / 10);
            angular.forEach(fleetCardPrintVM.cardList.dataModel, function (item) {
                item.showIcon = 'ic-expand_arrow-down';
            });
            $rootScope.dataLoading = false;
        }

        function getLastTransactionsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
            $rootScope.dataLoading = false;
        }

        // function cardSearch() {
        //     if (!fleetCardPrintVM.searchCardNo) {
        //         toastr.error("Enter card #");
        //     } else if (fleetCardPrintVM.searchCardNo && (fleetCardPrintVM.searchCardNo.length < 3)) {
        //         toastr.warning(toastrMessage.userManagement.searchCondition);
        //     } else {
        //         init();
        //         getTransactionSearchDetails([], fleetCardPrintVM.searchCardNo, 'Trans. Date');
        //     }
        // }

        function getColumnHeaders() {
            return cardManagementService.getTransactionColumns()
                .then(getTransactionColumnsSuccess, getTransactionColumnsError);
        }

        function getTransactionColumnsSuccess(apiResponse) {
            fleetCardPrintVM.columnHeaders = apiResponse.dataModel;
            angular.forEach(fleetCardPrintVM.columnHeaders, function (item) {
                // item.showColumn = true;
                if (item.strHeader === 'Trans. Date') {
                    item.showIcon = 'ic-filter';
                } else {
                    item.showIcon = 'ic-filterNot';
                }
            });
            fleetCardPrintVM.columnHeaderPopup = angular.copy(fleetCardPrintVM.columnHeaders);
        }

        function getTransactionColumnsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        // function arrangeTableData(popupData) {
        //     $rootScope.dataLoading = true;
        //     angular.forEach(popupData, function(item, key) {
        //         if (item.showColumn) {
        //             fleetCardPrintVM.columnHeaders[key].showColumn = true;
        //         } else {
        //             fleetCardPrintVM.columnHeaders[key].showColumn = false;
        //         }
        //     });
        //     $rootScope.dataLoading = false;
        //     fleetCardPrintVM.showColumnPopup = false;
        // }

        // function toggleHeader() {
        //     fleetCardPrintVM.showColumnPopup = !fleetCardPrintVM.showColumnPopup;
        // }

        // function paginationClick(button) {
        //     $window.scrollTo(0, 0);
        //     switch (button) {
        //         case 'first':
        //             if (fleetCardPrintVM.firstPageItem > 1) {
        //                 fleetCardPrintVM.firstPageItem = 1;
        //                 fleetCardPrintVM.secondPageItem = 2;
        //                 fleetCardPrintVM.currentPage = 1;
        //             }
        //             break;
        //         case 'previous':
        //             if (fleetCardPrintVM.firstPageItem > 1) {
        //                 fleetCardPrintVM.firstPageItem--;
        //                 fleetCardPrintVM.secondPageItem--;
        //                 fleetCardPrintVM.currentPage--;
        //             } else if (fleetCardPrintVM.firstPageItem < fleetCardPrintVM.currentPage) {
        //                 fleetCardPrintVM.currentPage--;
        //             } else {
        //                 //sonar issue fix
        //             }
        //             break;
        //         case 'next':
        //             if (fleetCardPrintVM.secondPageItem < fleetCardPrintVM.maxPage) {
        //                 fleetCardPrintVM.firstPageItem++;
        //                 fleetCardPrintVM.secondPageItem++;
        //                 fleetCardPrintVM.currentPage++;
        //             } else if (fleetCardPrintVM.currentPage < fleetCardPrintVM.secondPageItem) {
        //                 fleetCardPrintVM.currentPage++;
        //             } else {
        //                 //sonar issue fix
        //             }
        //             break;
        //         case 'last':
        //             if (fleetCardPrintVM.secondPageItem < fleetCardPrintVM.maxPage) {
        //                 fleetCardPrintVM.firstPageItem = fleetCardPrintVM.maxPage - 1;
        //                 fleetCardPrintVM.secondPageItem = fleetCardPrintVM.maxPage;
        //                 fleetCardPrintVM.currentPage = fleetCardPrintVM.maxPage;
        //             }
        //             break;
        //         default:
        //             fleetCardPrintVM.currentPage = button;
        //             break;
        //     }
        // }

        // function orderByColumn(columnHeader, iconType) {
        //     var orderBy = '';
        //     fleetCardPrintVM.productIds = [];
        //     angular.forEach(fleetCardPrintVM.columnHeaders, function(item) {
        //         if (item.strHeader === columnHeader) {
        //             if (iconType === 'ic-filterNot' || iconType === 'ic-filterDesc') {
        //                 item.showIcon = 'ic-filter';
        //                 orderBy = 'asc';
        //             } else {
        //                 item.showIcon = 'ic-filterDesc';
        //                 orderBy = 'desc';
        //             }
        //         } else {
        //             item.showIcon = 'ic-filterNot';
        //         }
        //     });
        //     angular.forEach(fleetCardPrintVM.productValues, function(item) {
        //         if (item.isChecked) {
        //             fleetCardPrintVM.productIds.push(item.id);
        //         }
        //     });
        //     getTransactionSearchDetails(fleetCardPrintVM.productIds, fleetCardPrintVM.searchCardNo, columnHeader, orderBy);
        // }

        /*clears the date values from tha calender*/
        // function clearCalender() {
        //     fleetCardPrintVM.datePicker = { startDate: null, endDate: null };
        //     $('#daterange').data('daterangepicker').setStartDate(new Date());
        //     $('#daterange').data('daterangepicker').setEndDate(new Date());
        // }

        // function applyProducts() {
        //     fleetCardPrintVM.showProductPopup = false;
        // }

        function printTable() {
            // window.print();

            var printContents = document.getElementById('printDiv').innerHTML;
            var popupWin = window.open('', '_blank', 'width=800,height=800,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no,top=50');
            popupWin.window.focus();
            popupWin.document.open();
            popupWin.document.write('<!DOCTYPE html><html><head><title>Card Management</title>' +
                '<link rel="stylesheet" type="text/css" href="resources/stylesheets/print.css" />' +
                '</head><body onload="window.print(); window.close();"><div>' +
                printContents + '</div></html>');
            popupWin.document.close();

            // var printContents = document.getElementById('printDiv').innerHTML;
            // var originalContents = document.body.innerHTML;        
            // document.body.innerHTML = printContents;
            // window.print();
            // document.body.innerHTML = originalContents;
        }

        function backToTransaction() {
            $state.go('root.fleetCard');
        }

        /*when account changed*/
        $scope.$on('accountChanged', function () {
            var hasPermission = false;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function (item, key) {
                    if (item.option.toLowerCase().indexOf('card management') !== -1) {
                        if (item.displayName === 'R' || item.displayName === 'W') {
                            hasPermission = true;
                            init();
                            getTransactionSearchDetails([], fleetCardPrintVM.searchCardNo, 'Trans. Date');
                        }
                    }
                    if ((key === 3) && !hasPermission) {
                        $state.go('root.dashboard');
                    }
                });
            }
        });
    }
})();