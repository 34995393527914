
/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';

    angular.module('scFuelsApp.login')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$state','toastr','loginService','$rootScope','toastrMessage','$stateParams','$window','$timeout'];

    function LoginController ($state,toastr,loginService, $rootScope,toastrMessage,$stateParams,$window,$timeout) {

        var loginVM = this;
        $rootScope.dataLoading = false;
        loginVM.onLoginSubmit = onLoginSubmit;
        loginVM.username = '';
        loginVM.password = '';
        localStorage.clear();
        if($stateParams.refresh){
          $timeout(function() {
            $window.location.reload();
          }, 500);                     
        }


        /*function declaration*/

        /*clicks on login button*/
        function onLoginSubmit () {
            if(loginVM.username && loginVM.password){
              $rootScope.dataLoading = true;
              var loginData = loginVM.username+":"+loginVM.password;
              localStorage.loginDetails = btoa(unescape(encodeURIComponent(loginData)));
              loginService.authenticateLogin()
                        .then(loginSuccess, loginError);
          }else{
            toastr.error(toastrMessage.login.loginValidation);
          }
        }
        /*success callback for login*/
        function loginSuccess (apiResponse) {
            $rootScope.dataLoading = false;
           if(apiResponse.statusCode === 200){
             $rootScope.unauthorized = false;
             localStorage.loggedInToken = apiResponse.dataModel.token;
             localStorage.userID = parseInt(apiResponse.dataModel.userId,10);
             localStorage.loggedInUser = apiResponse.dataModel.name;
             localStorage.loggedInUserOrg = apiResponse.dataModel.orgName;
             localStorage.userType = apiResponse.dataModel.userType;
             localStorage.userEmail = apiResponse.dataModel.email;
             localStorage.permissions = JSON.stringify(apiResponse.dataModel.permissions);
             localStorage.orgID = apiResponse.dataModel.orgId? parseInt(apiResponse.dataModel.orgId,10) : 0;
             // localStorage.accountsInfo = JSON.stringify(apiResponse.dataModel.permissions);
             
             // this flag will control whether UI displayed is for employee/customer
             // of scfuels.
             if(apiResponse.dataModel.userType!== undefined && (apiResponse.dataModel.userType === 'ItSupport' || apiResponse.dataModel.userType === 'Marketting' || apiResponse.dataModel.userType === 'CreditService')){
                 localStorage.hasEntertpisePermission = true;
             }else{
                 localStorage.hasEntertpisePermission = false;
             }
             if(JSON.parse(localStorage.hasEntertpisePermission)){
                if(localStorage.userType == 'Marketting'){
                  $state.go('root.bannerManagement');
                }
                else if(localStorage.userType == 'ItSupport'){
                  $state.go('root.techInsight');
                }
                else{
                  $state.go('root.dashboard');
                }
             }else{
               
                // Send the custom dimension value with a pageview hit.
                ga('send', 'pageview',{
                   'dimension1':  localStorage.orgID
                });
                
                $state.go('root.dashboard');
             }
           }else{
             toastr.error(apiResponse.statusMessage);
           }

       }
        /*error callback for login*/
       function loginError () {
        $rootScope.dataLoading = false;
           toastr.error(toastrMessage.common.systemNetworkFailure);
       }
    }
})();
