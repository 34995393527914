/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';

    angular.module('scFuelsApp.login')
        .controller('faqController', faqController);

    faqController.$inject = ['$scope','$state', '$rootScope', 'headermenuService'];

    function faqController ($scope, $state, $rootScope, headermenuService) {
        var faqVM = this;
        init();

        /*function declarations*/

        /*initial page load*/
        function init() {
         	$rootScope.dataLoading = true;
        	headermenuService.getFAQData()
        	.then(getFAQDataSuccess, getFAQDataFailure);
        }
        /*success callback for FAQ data*/
        function getFAQDataSuccess(apiResponse) {
        	$rootScope.dataLoading = false;
        	if(apiResponse.statusCode === 200){
        		faqVM.faqList = apiResponse.faqData;
        	}
        }
         /*error callback for FAQ data*/
        function getFAQDataFailure() {
			$rootScope.dataLoading = false;
        }
    }
})();
