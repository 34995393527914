/*jshint browser: true*/
/*global angular: true*/


(function () {
    'use strict';

    angular
        .module('scFuelsApp.userActivity')
        .controller('userActivityController', userActivityController);


    userActivityController.$inject = ['$scope', '$state', 'contactUsService', 'toastr', '$rootScope', 'toastrMessage', 'userActivityService', '$window', 'utils'];

    function userActivityController($scope, $state, contactUsService, toastr, $rootScope, toastrMessage, userActivityService, $window, utils) {
        var userActivityVM = this;
        userActivityVM.MyActivity = true;
        userActivityVM.UserType = localStorage.userType;
        userActivityVM.Category = "";
        userActivityVM.UserActivities = [];
        userActivityVM.currentPage = 1;
        userActivityVM.firstPageItem = 1;
        userActivityVM.secondPageItem = 2;
        userActivityVM.pageSize = 20;
        userActivityVM.searchCount = 0;
        userActivityVM.sortColumn = "default";
        userActivityVM.sortOrder = "desc";
        userActivityVM.maxPage = 0;
        userActivityVM.datePicker = {
            startDate: null,
            endDate: null
        };
        userActivityVM.UserActivityTableHeaders = [
            {
                Column: "Date",
                icon: "ic-filterDesc"
            },
            {
                Column: "Time",
                icon: "ic-filterNot"
            },
            {
                Column: "Category",
                icon: "ic-filterNot"
            },
            {
                Column: "Activity",
                icon: "ic-filterNot"
            },
            {
                Column: "Network",
                icon: "ic-filterNot"
            },
            {
                Column: "Type",
                icon: "ic-filterNot"
            },
            {
                Column: "Note",
                icon: "ic-filterNot"
            }
        ];

        userActivityVM.orderByColumn = orderByColumn;
        userActivityVM.searchUserActivities = searchUserActivities;
        userActivityVM.paginationClick = paginationClick;
        userActivityVM.clearCalender = clearCalender;


        userActivityVM.OtherUserCategory = "";
        userActivityVM.OtherUserUserName = "";
        userActivityVM.otherUserUserId = "";
        userActivityVM.otherUserActivities = [];
        userActivityVM.otherUsercurrentPage = 1;
        userActivityVM.otherUserfirstPageItem = 1;
        userActivityVM.otherUsersecondPageItem = 2;
        userActivityVM.otherUserpageSize = 20;
        userActivityVM.otherUsersearchCount = 0;
        userActivityVM.otherUsersortColumn = "default";
        userActivityVM.otherUsersortOrder = "desc";
        userActivityVM.otherUsermaxPage = 0;
        userActivityVM.otherUserdatePicker = {
            startDate: null,
            endDate: null
        };
        userActivityVM.otherUserActivityTableHeaders = [
            {
                Column: "Date",
                icon: "ic-filterDesc"
            },
            {
                Column: "Time",
                icon: "ic-filterNot"
            },
            {
                Column: "Category",
                icon: "ic-filterNot"
            },
            {
                Column: "User",
                icon: "ic-filterNot"
            },
            {
                Column: "Activity",
                icon: "ic-filterNot"
            },
            {
                Column: "Network",
                icon: "ic-filterNot"
            },
            {
                Column: "Type",
                icon: "ic-filterNot"
            },
            {
                Column: "Note",
                icon: "ic-filterNot"
            }
        ];


        userActivityVM.startYear = utils.calenderStartYear;
        userActivityVM.endYear = utils.calenderEndYear;



        function orderByColumn(sortColumn, icon, myActivity) {
            if (myActivity) {
                userActivityVM.sortColumn = sortColumn;
                angular.forEach(userActivityVM.UserActivityTableHeaders, function (header) {
                    header.icon = "ic-filterNot"
                    if (header.Column == sortColumn) {
                        if (icon == "ic-filterNot") {
                            header.icon = "ic-filter";
                            userActivityVM.sortOrder = "Asc";
                        }
                        else if (icon == "ic-filter") {
                            header.icon = "ic-filterDesc";
                            userActivityVM.sortOrder = "Desc";
                        }
                        else if (icon == "ic-filterDesc") {
                            header.icon = "ic-filter";
                            userActivityVM.sortOrder = "Asc";
                        }
                    }
                });
                getUserActivities();
            }
            else {
                userActivityVM.otherUsersortColumn = sortColumn;
                angular.forEach(userActivityVM.otherUserActivityTableHeaders, function (header) {
                    header.icon = "ic-filterNot"
                    if (header.Column == sortColumn) {
                        if (icon == "ic-filterNot") {
                            header.icon = "ic-filter";
                            userActivityVM.otherUsersortOrder = "Asc";
                        }
                        else if (icon == "ic-filter") {
                            header.icon = "ic-filterDesc";
                            userActivityVM.otherUsersortOrder = "Desc";
                        }
                        else if (icon == "ic-filterDesc") {
                            header.icon = "ic-filter";
                            userActivityVM.otherUsersortOrder = "Asc";
                        }
                    }
                });
                getOtherUserActivities();
            }

        }

        function searchUserActivities(myActivity) {
            if (myActivity) {
                userActivityVM.currentPage = 1;
                getUserActivities();
            }
            else {
                userActivityVM.otherUsercurrentPage = 1;
                getOtherUserActivities();
            }
        }


        function paginationClick(button, myActivity) {
            if (myActivity) {
                switch (button) {
                    case 'first':
                        if (userActivityVM.firstPageItem > 1) {
                            $window.scrollTo(0, 0);
                            userActivityVM.firstPageItem = 1;
                            userActivityVM.secondPageItem = 2;
                            userActivityVM.currentPage = 1;
                        }
                        break;
                    case 'previous':
                        if (userActivityVM.firstPageItem > 1) {
                            $window.scrollTo(0, 0);
                            userActivityVM.firstPageItem--;
                            userActivityVM.secondPageItem--;
                            userActivityVM.currentPage--;
                        } else if (userActivityVM.firstPageItem < userActivityVM.currentPage) {
                            userActivityVM.currentPage--;
                        } else {
                            //sonar issue fix
                        }
                        break;
                    case 'next':
                        if (userActivityVM.secondPageItem < userActivityVM.maxPage) {
                            $window.scrollTo(0, 0);
                            userActivityVM.firstPageItem++;
                            userActivityVM.secondPageItem++;
                            userActivityVM.currentPage++;
                        } else if (userActivityVM.currentPage < userActivityVM.secondPageItem) {
                            userActivityVM.currentPage++;
                        } else {
                            //sonar issue fix
                        }
                        break;
                    case 'last':
                        if (userActivityVM.secondPageItem < userActivityVM.maxPage) {
                            $window.scrollTo(0, 0);
                            userActivityVM.firstPageItem = userActivityVM.maxPage - 1;
                            userActivityVM.secondPageItem = userActivityVM.maxPage;
                            userActivityVM.currentPage = userActivityVM.maxPage;
                        }
                        break;
                    default:
                        userActivityVM.currentPage = button;
                        break;
                }
                getUserActivities();
            }
            else {
                switch (button) {
                    case 'first':
                        if (userActivityVM.otherUserfirstPageItem > 1) {
                            $window.scrollTo(0, 0);
                            userActivityVM.otherUserfirstPageItem = 1;
                            userActivityVM.otherUsersecondPageItem = 2;
                            userActivityVM.otherUsercurrentPage = 1;
                        }
                        break;
                    case 'previous':
                        if (userActivityVM.otherUserfirstPageItem > 1) {
                            $window.scrollTo(0, 0);
                            userActivityVM.otherUserfirstPageItem--;
                            userActivityVM.otherUsersecondPageItem--;
                            userActivityVM.otherUsercurrentPage--;
                        } else if (userActivityVM.otherUserfirstPageItem < userActivityVM.otherUsercurrentPage) {
                            userActivityVM.otherUsercurrentPage--;
                        } else {
                            //sonar issue fix
                        }
                        break;
                    case 'next':
                        if (userActivityVM.otherUsersecondPageItem < userActivityVM.otherUsermaxPage) {
                            $window.scrollTo(0, 0);
                            userActivityVM.otherUserfirstPageItem++;
                            userActivityVM.otherUsersecondPageItem++;
                            userActivityVM.otherUsercurrentPage++;
                        } else if (userActivityVM.otherUsercurrentPage < userActivityVM.otherUsersecondPageItem) {
                            userActivityVM.otherUsercurrentPage++;
                        } else {
                            //sonar issue fix
                        }
                        break;
                    case 'last':
                        if (userActivityVM.otherUsersecondPageItem < userActivityVM.otherUsermaxPage) {
                            $window.scrollTo(0, 0);
                            userActivityVM.otherUserfirstPageItem = userActivityVM.otherUsermaxPage - 1;
                            userActivityVM.otherUsersecondPageItem = userActivityVM.otherUsermaxPage;
                            userActivityVM.otherUsercurrentPage = userActivityVM.otherUsermaxPage;
                        }
                        break;
                    default:
                        userActivityVM.otherUsercurrentPage = button;
                        break;
                }
                getOtherUserActivities();
            }

        }

        function clearCalender(myActivity) {
            if (myActivity) {
                userActivityVM.datePicker = {
                    startDate: null,
                    endDate: null
                };
                $('#daterange').data('daterangepicker').setStartDate(new Date());
                $('#daterange').data('daterangepicker').setEndDate(new Date());
            }
            else {
                userActivityVM.otherUserdatePicker = {
                    startDate: null,
                    endDate: null
                };
                $('#otherUserdaterange').data('daterangepicker').setStartDate(new Date());
                $('#otherUserdaterange').data('daterangepicker').setEndDate(new Date());
            }
        }


        init();

        /*initial page load*/
        function init() {
            getUserActivities();
            if (userActivityVM.UserType == 'SuperAdmin' || userActivityVM.UserType == 'MasterAdmin') {
                getOtherUserActivities();
            }
        }

        function getUserActivities() {
            $rootScope.dataLoading = true;
            var gridFilter = {
                "Filter": {
                    "PageNo": userActivityVM.currentPage,
                    "PageSize": userActivityVM.pageSize,
                    "SortColumn": userActivityVM.sortColumn,
                    "SortOrder": userActivityVM.sortOrder
                },
                "Search": {
                    "AccountNo": localStorage.selectedAccount,
                    "Category": userActivityVM.Category,
                    "DateFrom": (userActivityVM.datePicker.startDate) ? formatDate(new Date(userActivityVM.datePicker.startDate)) : null,
                    "DateTo": (userActivityVM.datePicker.endDate) ? formatDate(new Date(userActivityVM.datePicker.endDate.toDate())) : null
                }
            };
            return userActivityService.getUserActivities(gridFilter).then(function (response) {
                userActivityVM.UserActivities = response.dataModel.userActivities;
                userActivityVM.searchCount = (userActivityVM.UserActivities.length > 0) ? userActivityVM.UserActivities[0].totalCount : 0;
                userActivityVM.maxPage = Math.ceil(userActivityVM.searchCount / userActivityVM.pageSize);
                $rootScope.dataLoading = false;
            }, function () {
                toastr.error(toastrMessage.common.systemNetworkFailure);
                $rootScope.dataLoading = false;
            })
        }

        function getOtherUserActivities() {
            $rootScope.dataLoading = true;
            var gridFilter = {
                "Filter": {
                    "PageNo": userActivityVM.otherUsercurrentPage,
                    "PageSize": userActivityVM.otherUserpageSize,
                    "SortColumn": userActivityVM.otherUsersortColumn,
                    "SortOrder": userActivityVM.otherUsersortOrder
                },
                "Search": {
                    "AccountNo": localStorage.selectedAccount,
                    "Category": userActivityVM.otherUserCategory,
                    "DateFrom": (userActivityVM.otherUserdatePicker.startDate) ? formatDate(new Date(userActivityVM.otherUserdatePicker.startDate)) : null,
                    "DateTo": (userActivityVM.otherUserdatePicker.endDate) ? formatDate(new Date(userActivityVM.otherUserdatePicker.endDate.toDate())) : null,
                    "UserName":userActivityVM.OtherUserUserName
                }
            };
            return userActivityService.getOtherUserActivities(gridFilter).then(function (response) {
                userActivityVM.otherUserActivities = response.dataModel.otherUserActivities;
                userActivityVM.otherUsersearchCount = (userActivityVM.otherUserActivities.length > 0) ? userActivityVM.otherUserActivities[0].totalCount : 0;
                userActivityVM.otherUsermaxPage = Math.ceil(userActivityVM.otherUsersearchCount / userActivityVM.otherUserpageSize);
                $rootScope.dataLoading = false;
            }, function () {
                toastr.error(toastrMessage.common.systemNetworkFailure);
                $rootScope.dataLoading = false;
            })
        }

        function formatDate(date) {
            return ((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear()).toString();
        }

        $scope.$on('accountChanged', function () {
            init();
        });
    }
})();
