/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.changeAccountInfo')
        .controller('accountInfoController', accountInfoController);


    accountInfoController.$inject = ['$rootScope', '$scope', '$state', 'toastr', '$confirm', 'toastrMessage', 'accountInfoService', 'utils', '$timeout', '$window'];

    function accountInfoController($rootScope, $scope, $state, toastr, $confirm, toastrMessage, accountInfoService, utils, $timeout, $window) {
        var accountVM = this;
        accountVM.showPopup = false;
        accountVM.showSavecontact = false;
        accountVM.showSaveBill = false;
        accountVM.showSavePaperless = false;
        accountVM.optionSelected = 0;
        accountVM.applyAccounts = applyAccounts;
        accountVM.addNewAccount = addNewAccount;
        accountVM.editAccountInfo = editAccountInfo;
        accountVM.deleteAccountInfo = deleteAccountInfo;
        accountVM.saveAccountInfo = saveAccountInfo;
        accountVM.chooseAccounts = chooseAccounts;
        accountVM.selectOption = selectOption;
        accountVM.editBillToAddress = editBillToAddress;
        accountVM.saveBillToAddress = saveBillToAddress;
        accountVM.addNewEmail = addNewEmail;
        accountVM.deleteEmail = deleteEmail;
        accountVM.cancel = cancel;
        accountVM.savePaperless = savePaperless;
        accountVM.validateEmail = validateEmail;
        accountVM.selectAllAccounts = selectAllAccounts;
        accountVM.loggedInUser = localStorage.userType;
        accountVM.duplicateMailCheck = duplicateMailCheck;
        accountVM.accountListPage = 1;
        accountVM.accounts = [{ "accountName": "All", "accountNumber": 0, "id": "" }];
        accountVM.totalAccountList = 0;
        accountVM.accountListPagination = accountListPagination;
        accountVM.paginationClick = paginationClick;
        accountVM.unSavedChange = false;
        accountVM.optionSelectedRadio = 0;
        init();
        /*function declarations*/

        /*initial load*/
        function init() {
            accountVM.currentPage = 1;
            accountVM.firstPageItem = 1;
            accountVM.secondPageItem = 2;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item) {
                    if (item.option.toLowerCase().indexOf('account') !== -1) {
                        accountVM.userPermission = item.displayName;
                    }
                })
            }
            getStateList();
            getAccountList(accountVM.accountListPage);
        }
        /*when account changed*/
        $scope.$on('accountChanged', function() {
            var hasPermission = false;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item, key) {
                    if (item.option.toLowerCase().indexOf('account') !== -1) {
                        if (item.displayName === 'R' || item.displayName === 'W') {
                            hasPermission = true;
                            init();
                        }
                    }
                    if ((key === 3) && !hasPermission) {
                        $state.go('root.dashboard');
                    }
                })
            }
        });

        /*to fetch account list with write access*/
        function getAccountList(page) {
            $rootScope.dataLoading = true;
            var payload = {
                pageNo: page
            }
            accountInfoService.getAccountList(payload)
                .then(getAccountListSuccess, getAccountListFailure);
        }
        /*success callback for account list*/
        function getAccountListSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            accountVM.totalAccountList = (apiResponse.totalCount > 0) ? apiResponse.totalCount : accountVM.totalAccountList;
            if (apiResponse.accountInf) {
                apiResponse.accountInf.forEach(function(item, index) {
                    accountVM.accounts.push(item);
                });
                accountVM.accounts.forEach(function(item, index) {
                    item.isChecked = (index === 1) ? true : false;
                });
                applyAccounts(accountVM.accounts, accountVM.optionSelected);
            }
        }
        /*error callback for account list*/
        function getAccountListFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*cancel button click in Bill-to address,contact information,manage paperless*/
        function cancel() {           
            $confirm({
                title: 'Cancel',
                text: 'Changes will be lost. Would you like to proceed?',
                ok: 'Yes',
                cancel: 'No'
            }, {
                size: 'sm',
                backdrop: true
            }).then(function() {
                accountVM.unSavedChange = false;
                selectOption(accountVM.optionSelected, accountVM.currentPage);
            });
        }
        /*selects accounts from pop up*/
        function chooseAccounts(event) {
            event.preventDefault();
            accountVM.showPopup = !accountVM.showPopup;
        }

        $rootScope.$on('hidePopup', function() {
            accountVM.showPopup = false;
            $scope.$apply();
        });

        /*Selects All option in Account list*/
        function selectAllAccounts(selection, isSelected) {
            if (selection === 'All') {
                accountVM.accounts.forEach(function(item) {
                    item.isChecked = isSelected ? true : false;
                });
            } else {
                var allSelected = true;
                accountVM.accounts.forEach(function(item) {
                    if (item.accountName !== 'All' && !item.isChecked) {
                        allSelected = false;
                        accountVM.accounts[0].isChecked = false;
                    }
                });
                accountVM.accounts[0].isChecked = allSelected ? true : false;
            }
        }

        /*Apply button click on pop up*/
        function applyAccounts(accounts, optionSelected) {
            accountVM.showPopup = false;
            var selectedAccounts = [];
            accounts.forEach(function(item) {
                if (item.isChecked) {
                    var accountLabel = item.accountNumber + '| ' + item.accountName;
                    if (item.accountName !== 'All') {
                        selectedAccounts.push(accountLabel);
                    }
                }
            });
            if (selectedAccounts.length === 0) {
                accountVM.selectAccounts = '';
                toastr.error('Select at least one account.');
            } else {
                accountVM.selectAccounts = selectedAccounts.toString();
                selectOption(optionSelected, 1);
            }
        }

        /*Validate email address*/
        function validateEmail(emailId, index) {
            $rootScope.dataLoading = true;
            accountInfoService.emailValidation(emailId, index)
                .then(emailValidationSuccess, emailValidationFailure);
        }
        /*success callback for email validation*/
        function emailValidationSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                accountVM.accountsInfo.forEach(function(item) {
                    item.accountData.forEach(function(info, key) {
                        if ((info.email === apiResponse.emailInfo.email) && (key === apiResponse.index) && !info.disabled) {
                            info.name = apiResponse.emailInfo.name;
                            info.phone = apiResponse.emailInfo.phone;
                            info.type = apiResponse.emailInfo.type ? apiResponse.emailInfo.type : 'Select';
                            info.email = apiResponse.emailInfo.email;
                        }
                    });
                });
            } else if (apiResponse.statusCode === 0) {
                toastr.error("Please enter Registered Email Address.");
                accountVM.accountsInfo.forEach(function(item) {
                    item.accountData.forEach(function(info, key) {
                        if ((key === apiResponse.index) && !info.disabled) {
                            info.name = '';
                            info.phone = '';
                            info.type = 'Select';
                        }
                    });
                });
            } else {
                //Sonar Issue Fix
            }
        }
        /*error callback for email validation*/
        function emailValidationFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        /*Add new Contact Information*/
        function addNewAccount(index) {
            accountVM.unSavedChange = true;
            accountVM.showSavecontact = true;
            accountVM.accountsInfo.forEach(function(item, key) {
                if (index === key) {
                    var newAccount = {};
                    newAccount.name = '';
                    newAccount.phone = '';
                    newAccount.type = 'Select';
                    newAccount.email = '';
                    newAccount.disabled = false;
                    newAccount.emailDisable = false;
                    newAccount.editable = false;
                    item.accountData.push(newAccount);
                }
            });
        }

        /*Edit Contact Information*/
        function editAccountInfo(parent, index) {
            $rootScope.dataLoading = true;
            accountVM.unSavedChange = true;
            accountVM.showSavecontact = true;
            accountVM.accountsInfo.forEach(function(item, key) {
                if (parent === key) {
                    item.accountData.forEach(function(info, infoKey) {
                        if (index === infoKey) {
                            info.disabled = false;
                        }
                    });
                }
            });
            $timeout(function() {
                $rootScope.dataLoading = false;
            }, 1000);
        }

        /*Delete Contact Information*/
        function deleteAccountInfo(parent, index) {
            $confirm({
                title: 'Delete',
                text: 'Please confirm. Do you want to delete?',
                ok: 'Yes',
                cancel: 'No'
            }, {
                size: 'sm',
                backdrop: true
            }).then(function() {
                accountVM.accountsInfo.forEach(function(item, key) {
                    if (parent === key) {
                        item.accountData.forEach(function(info, infoKey) {
                            if (index === infoKey) {
                                var accountInfo = [];
                                var deleteData = {
                                    'accountNumber': item.accountNumber,
                                    'accountName': item.accountName,
                                    'name': info.name,
                                    'phone': info.phone,
                                    'type': info.type,
                                    'email': info.email
                                }
                                accountInfo.push(deleteData);
                                var payload = {
                                    'accountInfo': accountInfo
                                };

                                $rootScope.dataLoading = true;
                                accountInfoService.deleteContactInfo(payload)
                                    .then(deleteContactInfoSuccess, deleteContactInfoFailure);
                            }
                        });
                    }
                });
            });
        }
        /*success callback for delete contact information*/
        function deleteContactInfoSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                toastr.success("Contact Information deleted successfully.");
                accountVM.unSavedChange = false;
                selectOption(accountVM.optionSelected, accountVM.currentPage);
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for delete contact information*/
        function deleteContactInfoFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        /*Save Contact Information*/
        function saveAccountInfo() {
            var ismandatory = true;
            var isValidPhone = true;
            var isValidEmail = true;
            var isValidType = true;
            var isExists = true;
            var accountName = '';
            var type = '';
            var accountEmail = '';
            var accountInfo = [];

            accountVM.accountsInfo.forEach(function(item) {
                if (ismandatory && isValidPhone && isValidEmail && isValidType && isExists) {
                    var accountInfos = {};
                    accountInfos.accountNumber = item.accountNumber;
                    accountInfos.accountName = item.accountName;
                    var accountsData = [];
                    if(item.accountData.length){
                    item.accountData.forEach(function(info) {
                        if (info.name !== '' || info.phone !== '' || info.email !== '' || info.type !== 'Select') {
                            if (info.name && info.phone && info.email && (info.type !== 'Select')) {
                                if (!utils.isValidPhoneNumber(info.phone)) {
                                    isValidPhone = false;
                                    accountName = item.accountName;
                                } else if (!utils.isValidEmail(info.email)) {
                                    isValidEmail = false;
                                    accountName = item.accountName;
                                } else if (item.accountData.length > 1) {
                                    if (item.accountData[0].type === item.accountData[1].type && (item.accountData[0].name !== item.accountData[1].name || item.accountData[0].email !== item.accountData[1].email ||
                                            item.accountData[0].phone !== item.accountData[1].phone)) {
                                        isValidType = false;
                                        type = item.accountData[0].type;
                                        accountName = item.accountName;
                                    } else if (item.accountData[0].name === item.accountData[1].name && item.accountData[0].email === item.accountData[1].email && item.accountData[0].phone === item.accountData[1].phone && item.accountData[0].type === item.accountData[1].type) {
                                        isExists = false;
                                        type = item.accountData[0].type;
                                        accountName = item.accountData[0].name;
                                        accountEmail = item.accountData[0].email;
                                    } else {
                                        var dataObj = {};
                                        dataObj.name = info.name;
                                        dataObj.phone = info.phone;
                                        dataObj.type = info.type;
                                        dataObj.email = info.email;
                                        accountsData.push(dataObj);
                                    }
                                } else {
                                    var dataObj = {};
                                    dataObj.name = info.name;
                                    dataObj.phone = info.phone;
                                    dataObj.type = info.type;
                                    dataObj.email = info.email;
                                    accountsData.push(dataObj);
                                }
                            } else {
                                ismandatory = false;
                                accountName = item.accountName;
                            }
                        } else {
                                ismandatory = false;
                                accountName = item.accountName;
                            }
                        accountInfos.accountData = accountsData;
                    });
                }else{
                    accountInfos.accountData = [];
                }

                }
                accountInfo.push(accountInfos);
            });

            if (!ismandatory) {
                toastr.error(toastrMessage.common.mandatoryValidations + ' for ' + accountName + '.');
            } else if (!isValidType) {
                toastr.error('Cannot add more than one ' + type + ' manager.');
            } else if (!isExists) {
                toastr.error(accountEmail + ' already exists.');
            } else if (!isValidPhone) {
                toastr.error('Enter valid Phone for ' + accountName + '.');
            } else if (!isValidEmail) {
                toastr.error('Enter valid Email Address for ' + accountName + '.');
            } else {
                // getEditedAccountInformation()
                saveAccountInformation(accountInfo);
            }
        }

        function getEditedAccountInformation() {
            var accountName = '';
            var accountInfo = [];
            accountVM.accountsInfo.forEach(function(item, itemKey) {
                var accountInfos = {};
                accountInfos.accountNumber = item.accountNumber;
                accountInfos.accountName = item.accountName;
                var accountsData = [];
                item.accountData.forEach(function(info, infoKey) {
                    if (accountVM.accountsInfoCopy[itemKey].accountData[infoKey]) {
                        if (info.name !== accountVM.accountsInfoCopy[itemKey].accountData[infoKey].name ||
                            info.phone !== accountVM.accountsInfoCopy[itemKey].accountData[infoKey].phone ||
                            info.type !== accountVM.accountsInfoCopy[itemKey].accountData[infoKey].type ||
                            info.email !== accountVM.accountsInfoCopy[itemKey].accountData[infoKey].email) {
                            var dataObj = {};
                            dataObj.name = info.name;
                            dataObj.phone = info.phone;
                            dataObj.type = info.type;
                            dataObj.email = info.email;
                            accountsData.push(dataObj);
                            accountInfos.accountData = accountsData;
                        }
                    } else {
                        var dataObj = {};
                        dataObj.name = info.name;
                        dataObj.phone = info.phone;
                        dataObj.type = info.type;
                        dataObj.email = info.email;
                        accountsData.push(dataObj);
                        accountInfos.accountData = accountsData;
                    }
                });
                if (accountInfos.accountData) {
                    accountInfo.push(accountInfos);
                }
            });
            saveAccountInformation(accountInfo);
        }

        /*contact information save api call*/
        function saveAccountInformation(requestPayload) {
            var payload = {
                'accountInfo': requestPayload
            };
            $rootScope.dataLoading = true;
            accountVM.unSavedChange = false;
            accountInfoService.saveContactInformation(payload)
                .then(saveContactInformationSuccess, saveContactInformationFailure);
        }
        /*success callback for save contact information*/
        function saveContactInformationSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                // toastr.success("Contact information saved successfully.");
                toastr.success('Request ' + apiResponse.requestNo + ' has been submitted.');
                selectOption(accountVM.optionSelected, 1);
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for save contact information*/
        function saveContactInformationFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        // Fetches Account Information of selected radio option
        function selectOption(selected, pageNo) {
            // accountVM.optionSelected = selected;
            accountVM.currentPage = pageNo;
            if (pageNo === 1) {
                accountVM.currentPage = 1;
                accountVM.firstPageItem = 1;
                accountVM.secondPageItem = 2;
            }
            var accountList = [];
            accountVM.accounts.forEach(function(item) {
                if (item.isChecked) {
                    var accObj = {};
                    accObj.accountNumber = item.accountNumber;
                    accObj.accountName = item.accountName;
                    accountList.push(accObj);
                }
            });

            var payload = {
                'accounts': accountList,
                "selectedOption": selected,
                "pageNumber": pageNo,
                "pageRows": 3
            }
            checkUnsavedChange(function(result) {
                if (result) {
                    accountVM.optionSelectedRadio = selected;
                    $rootScope.dataLoading = true;
                    accountInfoService.fetchAccountInformation(payload)
                        .then(fetchAccountInformationSuccess, fetchAccountInformationFailure);
                } else {
                    accountVM.optionSelected = accountVM.optionSelectedRadio;
                }
            });
        }
        /*success callback for fetch account information*/
        function fetchAccountInformationSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            accountVM.searchCount = apiResponse.totalCount;
            accountVM.maxPage = Math.ceil(accountVM.searchCount / 3);
            if (accountVM.optionSelected === 0) { //Bill to Address
                accountVM.showSaveBill = false;
                accountVM.completePending = true;
                accountVM.billToAddress = apiResponse.accountInfo;
                accountVM.billToAddress.forEach(function(item) {
                    item.accountData.forEach(function(info) {
                        info.disabled = true;
                        info.streetName = info.streetName.toUpperCase();
                        info.city = info.city.toUpperCase();
                        info.state = info.state.toUpperCase();
                        if (info.status !== "pending") {
                            accountVM.completePending = false;
                        }
                    });
                });
                accountVM.billToAddressCopy = angular.copy(accountVM.billToAddress);

            } else if (accountVM.optionSelected === 1) { //Contact Information
                accountVM.showSavecontact = false;
                accountVM.accountsInfo = apiResponse.accountInfo;
                accountVM.accountsInfo.forEach(function(item) {
                    item.accountData.forEach(function(info) {
                        info.disabled = true;
                        info.emailDisable = true;
                        info.editable = true;
                    });
                });
                accountVM.accountsInfoCopy = angular.copy(accountVM.accountsInfo);
            } else { // Manage Paperless                             
                accountVM.showSavePaperless = false;
                accountVM.paperLess = apiResponse.accountInfo;
                accountVM.paperLess.forEach(function(item) {
                    if(item.accountData.invoice){
                        item.accountData.invoice.forEach(function(info) {
                            info.disabled = true;
                            info.showDelete = true;
                        }); 
                    }else{
                        item.accountData.invoice = [];
                    }
                    if(item.accountData.deliveryTickets){
                        item.accountData.deliveryTickets.forEach(function(info) {
                            info.disabled = true;
                            info.showDelete = true;
                        }); 
                    }else{
                       item.accountData.deliveryTickets = []; 
                    }
                    
                });
                accountVM.paperLessCopy = angular.copy(accountVM.paperLess);
            }
        }
        /*error callback for fetch account information*/
        function fetchAccountInformationFailure() {
            $rootScope.dataLoading = false;
        }

        /*edit bill-to address information*/
        function editBillToAddress() {
            $rootScope.dataLoading = true;
            accountVM.unSavedChange = true;
            accountVM.showSaveBill = true;
            accountVM.billToAddress.forEach(function(item) {
                item.accountData.forEach(function(info) {
                    if (info.status !== 'pending') {
                        info.disabled = false;
                    } else {
                        info.disabled = true;
                    }
                });
            });
            $timeout(function() {
                $rootScope.dataLoading = false;
            }, 1000);
        }
        /*save bill-to address information*/
        function saveBillToAddress() {
            var ismandatory = true;
            var isValidZipCode = true;
            var accountName = '';
            var accountInfo = [];
            accountVM.billToAddress.forEach(function(item, itemKey) {
                var accountInfos = {};
                accountInfos.accountNumber = item.accountNumber;
                accountInfos.accountName = item.accountName;
                var accountsData = [];
                item.accountData.forEach(function(info, infoKey) {
                    if (info.streetName !== accountVM.billToAddressCopy[itemKey].accountData[infoKey].streetName ||
                        info.city !== accountVM.billToAddressCopy[itemKey].accountData[infoKey].city ||
                        info.zipcode !== accountVM.billToAddressCopy[itemKey].accountData[infoKey].zipcode ||
                        info.state !== accountVM.billToAddressCopy[itemKey].accountData[infoKey].state) {
                        var dataObj = {};
                        dataObj.streetName = info.streetName;
                        dataObj.city = info.city;
                        dataObj.zipcode = info.zipcode;
                        dataObj.state = info.state;
                        accountsData.push(dataObj);
                        accountInfos.accountData = accountsData;
                    }
                });
                if (accountInfos.accountData) {
                    accountInfo.push(accountInfos);
                }
            });

            if (accountInfo) {
                accountInfo.forEach(function(item) {
                    if (ismandatory && isValidZipCode) {
                        accountName = item.accountName;
                        item.accountData.forEach(function(info) {
                            if (info.streetName && info.city && info.zipcode && info.state) {
                                if (!utils.isValidZipCode(info.zipcode)) {
                                    isValidZipCode = false;
                                }
                            } else {
                                ismandatory = false;
                            }
                        });
                    }
                });
                if (!ismandatory) {
                    toastr.error(toastrMessage.common.mandatoryValidations + ' for ' + accountName + '.');
                } else if (!isValidZipCode) {
                    toastr.error('Enter a valid Zip code for ' + accountName + '.');
                } else {
                    saveBillToAddressInformation(accountInfo);
                }
            }
        }
        /*bill-to address information api call*/
        function saveBillToAddressInformation(requestPayload) {
            // $rootScope.dataLoading = true;
            accountVM.unSavedChange = false;
            var payload = {
                'accountInfo': requestPayload
            }
            accountInfoService.saveBillToAddressInfo(payload)
                .then(saveBillToAddressInfoSuccess, saveBillToAddressInfoFailure);
        }
        /*success callback for save bill-to address information*/
        function saveBillToAddressInfoSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                toastr.success('Request ' + apiResponse.requestNo + ' has been submitted.');
                selectOption(accountVM.optionSelected, 1);
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for save bill-to address information*/
        function saveBillToAddressInfoFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*add new invoice/delivery email in manage paperless information*/
        function addNewEmail(index, type) {
            accountVM.showSavePaperless = true;
            accountVM.unSavedChange = true;
            accountVM.paperLess.forEach(function(item, key) {
                if (index === key) {
                    if (type === 'invoice') {
                        var newEmail = {}
                        newEmail.email = '';
                        newEmail.disabled = false;
                        newEmail.showDelete = false;
                        item.accountData.invoice.push(newEmail);
                    } else {
                        var newEmail = {}
                        newEmail.email = '';
                        newEmail.disabled = false;
                        newEmail.showDelete = false;
                        item.accountData.deliveryTickets.push(newEmail);
                    }
                }
            });
        }
        /*deletes invoice/delivery email from manage paperless information*/
        function deleteEmail(parent, index, type) {
            $confirm({
                title: 'Delete',
                text: 'Please confirm. Do you want to delete?',
                ok: 'Yes',
                cancel: 'No'
            }, {
                size: 'sm',
                backdrop: true
            }).then(function() {
                var acInfo = [];
                var payload = {};
                accountVM.paperLess.forEach(function(item, key) {
                    if (parent === key) {
                        if (type === 'invoice') {
                            item.accountData.invoice.forEach(function(info, infoKey) {
                                if (index === infoKey) {
                                    var deleteData = {
                                        'accountNumber': item.accountNumber,
                                        'accountName': item.accountName,
                                        'accountData': {
                                            'invoice': [{
                                                'email': info.email
                                            }]
                                        }
                                    }
                                    acInfo.push(deleteData);
                                    payload.accountInfo = acInfo;
                                }
                            });
                        } else {
                            item.accountData.deliveryTickets.forEach(function(info, infoKey) {
                                if (index === infoKey) {
                                    var deleteData = {
                                        'accountNumber': item.accountNumber,
                                        'accountName': item.accountName,
                                        'accountData': {
                                            'deliveryTickets': [{
                                                'email': info.email
                                            }]
                                        }
                                    }
                                    acInfo.push(deleteData);
                                    payload.accountInfo = acInfo;
                                }
                            });
                        }
                        $rootScope.dataLoading = true;
                        accountInfoService.deletePaperlessInfo(payload)
                            .then(deletePaperlessInfoSuccess, deletePaperlessInfoFailure);
                    }
                });
            });
        }
        /*success callback for delete paperless information*/
        function deletePaperlessInfoSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                toastr.success("Email Address deleted successfully.");
                accountVM.unSavedChange = false;
                selectOption(accountVM.optionSelected, accountVM.currentPage);
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for delete paperless information*/
        function deletePaperlessInfoFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*save paperless information*/
        function savePaperless() {
            var isValidInvoiceEmail = true;
            var isValidDeliveryEmail = true;
            var isMandatoryInvoice = true;
            var isMandatorydelivery = true;
            var accountName = '';
            accountVM.paperLess.forEach(function(item) {
                if (isValidInvoiceEmail && isValidDeliveryEmail) {
                    if (item.accountData.invoice) {
                        item.accountData.invoice.forEach(function(info) {
                            /*if (info.email && !utils.isValidEmail(info.email)) {
                                isValidInvoiceEmail = false;
                                accountName = item.accountName;
                            }*/
                            if(info.email){
                                if(!utils.isValidEmail(info.email)){
                                    isValidInvoiceEmail = false;
                                    accountName = item.accountName;
                                }
                            }else{
                                isMandatoryInvoice = false;
                                accountName = item.accountName;
                            }
                        });
                    }
                    if (item.accountData.deliveryTickets) {
                        item.accountData.deliveryTickets.forEach(function(info) {
                            /*if (info.email && !utils.isValidEmail(info.email)) {
                                isValidDeliveryEmail = false;
                                accountName = item.accountName;
                            }*/
                            if(info.email){
                                if(!utils.isValidEmail(info.email)){
                                    isValidDeliveryEmail = false;
                                    accountName = item.accountName;
                                }
                            }else{
                                isMandatorydelivery = false;
                                accountName = item.accountName;
                            }
                        });
                    }
                }
            });

            if (!isValidInvoiceEmail) {
                toastr.error('Enter valid invoice Email Address for ' + accountName + '.');
            } else if (!isValidDeliveryEmail) {
                toastr.error('Enter valid delivery ticket Email Address for ' + accountName + '.');
            } else if (!isMandatoryInvoice || !isMandatorydelivery) {
                toastr.error('Please provide Email Address.');
            }else {
                var accInfo = [];
                accountVM.paperLess.forEach(function(item) {
                    var accObj = {};
                    accObj.accountName = item.accountName;
                    accObj.accountNumber = item.accountNumber;
                    accObj.accountData = {};
                    if (item.accountData.invoice) {
                        var invoice = [];
                        item.accountData.invoice.forEach(function(info) {
                            if(info.email){
                                var mail = {};
                                mail.email = info.email;
                                invoice.push(mail);
                            }                            
                        });
                        accObj.accountData.invoice = invoice;
                    } else {
                        accObj.accountData.invoice = [];
                    }
                    if (item.accountData.deliveryTickets) {
                        var delivery = [];
                        item.accountData.deliveryTickets.forEach(function(info) {
                            if(info.email){
                                var mail = {};
                                mail.email = info.email;
                                delivery.push(mail);  
                            }                            
                        });
                        accObj.accountData.deliveryTickets = delivery;
                    } else {
                        accObj.accountData.deliveryTickets = [];
                    }
                    accInfo.push(accObj);
                });
                // getEditedManagePaperless();
                savePaperlessInformation(accInfo);
            }
        }

        function getEditedManagePaperless() {
            var accInfo = [];
            accountVM.paperLess.forEach(function(item, itemKey) {
                var accObj = {};
                accObj.accountName = item.accountName;
                accObj.accountNumber = item.accountNumber;
                accObj.accountData = {};
                if (item.accountData.invoice) {
                    var invoice = [];
                    item.accountData.invoice.forEach(function(info, infoKey) {
                        if (!accountVM.paperLessCopy[itemKey].accountData.invoice[infoKey]) {
                            var mail = {};
                            if (info.email) {
                                mail.email = info.email;
                                invoice.push(mail);
                            }
                        }
                    });
                    if (invoice.length) {
                        accObj.accountData.invoice = invoice;
                    }
                }
                if (item.accountData.deliveryTickets) {
                    var delivery = [];
                    item.accountData.deliveryTickets.forEach(function(info, infoKey) {
                        if (!accountVM.paperLessCopy[itemKey].accountData.deliveryTickets[infoKey]) {
                            var mail = {};
                            if (info.email) {
                                mail.email = info.email;
                                delivery.push(mail);
                            }
                        }
                    });
                    if (delivery.length) {
                        accObj.accountData.deliveryTickets = delivery;
                    }
                }
                if (Object.keys(accObj.accountData).length !== 0) {
                    accInfo.push(accObj);
                }
            });
            savePaperlessInformation(accInfo);
        }

        /*paperless information save api call*/
        function savePaperlessInformation(requestPayload) {
            var payload = {
                'accountInfo': requestPayload
            };
            $rootScope.dataLoading = true;
            accountVM.unSavedChange = false;
            accountInfoService.savePaperlessInformation(payload)
                .then(savePaperlessInformationSuccess, savePaperlessInformationFailure);
        }
        /*success callback for save paperless information*/
        function savePaperlessInformationSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                // toastr.success("Email Address saved successfully.");
                toastr.success('Request ' + apiResponse.requestNo + ' has been submitted.');
                selectOption(accountVM.optionSelected, 1);
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for save paperless information*/
        function savePaperlessInformationFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*Checks for duplicate Email Address entry*/
        function duplicateMailCheck(parent, index, mailId, type) {
            var isExists = false;
            accountVM.paperLess.forEach(function(item, key) {
                if (parent === key) {
                    if (type === 'invoice') {
                        item.accountData.invoice.forEach(function(invoice, invoiceKey) {
                            if (mailId === invoice.email && index !== invoiceKey) {
                                isExists = true;
                            }
                        });
                    } else {
                        item.accountData.deliveryTickets.forEach(function(delivery, deliveryKey) {
                            if (mailId === delivery.email && index !== deliveryKey) {
                                isExists = true;
                            }
                        });
                    }
                }
            });
            if (isExists) {
                toastr.error("This Email Address has already been entered. Please choose a different Email Address.");
            }
        }

        function getStateList() {
            accountInfoService.getStateList()
                .then(getStateListSuccess, getStateListFailure);
        }

        function getStateListSuccess(apiResponse) {
            accountVM.US_STATES = apiResponse.states;
        }

        function getStateListFailure() {

        }

        /*Scroll pagination call from the account list UI*/
        function accountListPagination() {
            if (accountVM.totalAccountList > 10) {
                accountVM.accountListPage++;
                getAccountList(accountVM.accountListPage);
            }
        }

        /*pagination*/
        function paginationClick(button) {
            checkUnsavedChange(function(result) {
                if (result) {
                    var enablePageClick = false;
                    $window.scrollTo(0, 0);
                    switch (button) {
                        case 'first':
                            if (accountVM.firstPageItem > 1) {
                                accountVM.firstPageItem = 1;
                                accountVM.secondPageItem = 2;
                                accountVM.currentPage = 1;
                                enablePageClick = true;
                            }
                            break;
                        case 'previous':
                            if (accountVM.firstPageItem > 1) {
                                accountVM.firstPageItem--;
                                accountVM.secondPageItem--;
                                accountVM.currentPage--;
                                enablePageClick = true;
                            } else if (accountVM.firstPageItem < accountVM.currentPage) {
                                accountVM.currentPage--;
                                enablePageClick = true;
                            }
                            break;
                        case 'next':
                            if (accountVM.secondPageItem < accountVM.maxPage) {
                                accountVM.firstPageItem++;
                                accountVM.secondPageItem++;
                                accountVM.currentPage++;
                                enablePageClick = true;
                            } else if (accountVM.currentPage < accountVM.secondPageItem) {
                                accountVM.currentPage++;
                                enablePageClick = true;
                            }
                            break;
                        case 'last':
                            if (accountVM.secondPageItem < accountVM.maxPage) {
                                accountVM.firstPageItem = accountVM.maxPage - 1;
                                accountVM.secondPageItem = accountVM.maxPage;
                                accountVM.currentPage = accountVM.maxPage;
                                enablePageClick = true;
                            }
                            break;
                        default:
                            accountVM.currentPage = button;
                            enablePageClick = true;
                            break;
                    }
                    if (enablePageClick) {
                        selectOption(accountVM.optionSelected, accountVM.currentPage);
                    }
                }
            });
        }

        /*confirmation for save the changes*/
        function checkUnsavedChange(callback) {
            if (accountVM.unSavedChange) {
                $confirm({
                    title: 'Confirm',
                    text: 'You have not submitted the saved changes. Do you want to proceed without submitting it?',
                    ok: 'Yes',
                    cancel: 'No'
                }, {
                    size: 'sm',
                    backdrop: true
                }).then(function() {
                    accountVM.unSavedChange = false;
                    callback(true);
                }).catch(function() {
                    callback(false);
                });
            } else {
                callback(true);
            }
        }

        $scope.$on('$stateChangeStart', onStateChangeStart);
        /*state change handler to save changes*/
        function onStateChangeStart(event, toState, toParams, fromState) {
            if (accountVM.unSavedChange && !$rootScope.unauthorized) {
                event.preventDefault();
                $confirm({
                    title: 'Confirm',
                    text: 'You have not submitted the saved changes. Do you want to proceed without submitting it?',
                    ok: 'Yes',
                    cancel: 'No'
                }, {
                    size: 'sm',
                    backdrop: true
                }).then(function() {
                    accountVM.unSavedChange = false;
                    $state.go(toState.name);
                }).catch(function() {
                    $rootScope.activeTab = true;
                });
            }
        }
    }
})();