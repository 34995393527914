/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.userManagement')
        .controller('newUserController', newUserController);


    newUserController.$inject = ['$scope', '$state', 'toastr', '$confirm', 'userManagementService', 'utils', '$timeout', '$rootScope', 'toastrMessage', '$filter','$window'];

    function newUserController($scope, $state, toastr, $confirm, userManagementService, utils, $timeout, $rootScope, toastrMessage, $filter,$window) {
        var newUserVM = this;
        newUserVM.tooltip = 'Able to select Read or Write and update all accounts at once.';
        newUserVM.submitted = false;
        newUserVM.firstName = '';
        newUserVM.lastName = '';
        newUserVM.email = '';
        newUserVM.workPhone = '';
        newUserVM.mobilePhone = '';
        newUserVM.transaction = [];
        newUserVM.invoice = [];
        newUserVM.payment = [];
        newUserVM.card = [];
        newUserVM.accMnt = [];
        newUserVM.newUsers = [];
        newUserVM.accPermissions = [];
        newUserVM.accountCheck = [];
        newUserVM.isValidID = false;
        newUserVM.activateUser = [];
        newUserVM.newUserAdded = true;
        newUserVM.buttonLabel = 'New User';
        newUserVM.userId = '';
        newUserVM.init = init();
        newUserVM.submitNewUser = submitNewUser;
        newUserVM.addNewUser = addNewUser;
        newUserVM.moduleAccess = moduleAccess;
        newUserVM.checkAccounts = checkAccounts;
        newUserVM.borderSelection = borderSelection;
        newUserVM.onChecked = onChecked;
        newUserVM.buttonClass = buttonClass;
        newUserVM.checkEmail = checkEmail;
        newUserVM.editOrDelete = editOrDelete;
        newUserVM.paginationClick = paginationClick;


        /*function declarations*/

        /*initial loading*/
        function init() {
            newUserVM.currentPage = 1;
            newUserVM.firstPageItem = 1;
            newUserVM.secondPageItem = 2;
            getUserAccountDetails();
            getDefaultPermissions();
        }
        /*when the account has been changed*/
        $scope.$on('accountChanged', function() {
            var hasPermission = false;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item, key) {
                    if (item.option.toLowerCase().indexOf('account') !== -1) {
                        if (item.displayName == 'W') {
                            init();
                        }
                    }
                    if ((key == 3) && !hasPermission) {
                        $state.go('root.dashboard');
                    }
                })
            }
        });

        function paginationClick(button) {
            var searchRequired = false;
            switch (button) {
                case 'first':
                    if (newUserVM.firstPageItem > 1) {
                        $window.scrollTo(0, 0);
                        newUserVM.firstPageItem = 1;
                        newUserVM.secondPageItem = 2;
                        newUserVM.currentPage = 1;
                        searchRequired = true;
                    }
                    break;
                case 'previous':
                    if (newUserVM.firstPageItem > 1) {
                        $window.scrollTo(0, 0);
                        newUserVM.firstPageItem--;
                        newUserVM.secondPageItem--;
                        newUserVM.currentPage--;
                        searchRequired = true;
                    } else if (newUserVM.firstPageItem < newUserVM.currentPage) {
                        $window.scrollTo(0, 0);
                        newUserVM.currentPage--;
                        searchRequired = true;
                    } else {
                        //sonar issue fix
                    }
                    break;
                case 'next':
                    if (newUserVM.secondPageItem < newUserVM.maxPage) {
                        $window.scrollTo(0, 0);
                        newUserVM.firstPageItem++;
                        newUserVM.secondPageItem++;
                        newUserVM.currentPage++;
                        searchRequired = true;
                    } else if (newUserVM.currentPage < newUserVM.secondPageItem) {
                        $window.scrollTo(0, 0);
                        newUserVM.currentPage++;
                        searchRequired = true;
                    } else {
                        //sonar issue fix
                    }
                    break;
                case 'last':
                    if (newUserVM.secondPageItem < newUserVM.maxPage || newUserVM.currentPage < newUserVM.secondPageItem) {
                        $window.scrollTo(0, 0);
                        newUserVM.firstPageItem = newUserVM.maxPage - 1;
                        newUserVM.secondPageItem = newUserVM.maxPage;
                        newUserVM.currentPage = newUserVM.maxPage;
                        searchRequired = true;
                    }
                    break;
                default:
                    $window.scrollTo(0, 0);
                    newUserVM.currentPage = button;
                    searchRequired = true;
                    break;
            }
            if (searchRequired) {
                getUserAccountDetails();
            }

        }
        /*fetches the account list*/
        function getUserAccountDetails() {
            $rootScope.dataLoading = true;
            var payload = {
                'orgId': localStorage.orgID,
                'Filter': {
                    "PageNo":newUserVM.currentPage,
                    "PageSize": 25
                }
            }
            userManagementService.getAccountList(payload)
                .then(getAccountListSuccess, getAccountListError);
        }
        /*success callback for account list*/
        function getAccountListSuccess(apiResponse) {
            newUserVM.accountList = apiResponse.dataModel;
            newUserVM.searchCount = apiResponse.dataModel.totalCount;
            newUserVM.maxPage = Math.ceil(newUserVM.searchCount / 25);
            $rootScope.dataLoading = false;
        }
        /*error callback for account list*/
        function getAccountListError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches the default permissions*/
        function getDefaultPermissions() {
            userManagementService.getDefaultPermissions()
                .then(getDefaultPermissionsSuccess, getDefaultPermissionsError);
        }
        /*success callback for default permission*/
        function getDefaultPermissionsSuccess(apiResponse) {
            newUserVM.defaultPermissions = apiResponse.dataModel.defaultPermissions;
        }
        /*error callback for default permission*/
        function getDefaultPermissionsError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*checks for the email validation*/
        function checkEmail(mailID) {
            if (mailID && utils.isValidEmail(mailID)) {
                $rootScope.dataLoading = true;
                userManagementService.validateEmail(mailID)
                    .then(validateEmailSuccess, validateEmailError);
            }
        }
        /*success callback for email validation*/
        function validateEmailSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                newUserVM.isValidID = false;
            } else if (apiResponse.statusCode === 0) {
                toastr.error(newUserVM.email + toastrMessage.userManagement.emailExists);
                newUserVM.isValidID = true;
            } else if (apiResponse.statusCode === 100) {
                $confirm({
                    title: "Activate",
                    text: newUserVM.email + toastrMessage.userManagement.deactivatedUser,
                    ok: "Yes",
                    cancel: "No"
                }, {
                    size: 'sm'
                }).then(function() {
                    newUserVM.isValidID = false;
                    newUserVM.firstName = $filter('capitalize')(apiResponse.dataModel.firstName);
                    newUserVM.lastName = $filter('capitalize')(apiResponse.dataModel.lastName);
                    newUserVM.email = apiResponse.dataModel.emailId;
                    newUserVM.workPhone = utils.getPhoneNumberFormat(apiResponse.dataModel.workPhone);
                    newUserVM.mobilePhone = utils.getPhoneNumberFormat(apiResponse.dataModel.mobilePhone);
                    newUserVM.userId = apiResponse.dataModel.userId;
                }).catch(function() {
                    newUserVM.email = '';
                });
            }
        }
        /*error callback for email validation*/
        function validateEmailError(errorResponse) {
            $rootScope.dataLoading = false;
            toastr.error(errorResponse);
        }
        /*clicks on the submit button*/
        function submitNewUser(isvalidForm) {
            newUserVM.submitted = false;
            $scope.newUserForm.$setPristine();
            $scope.newUserForm.$setUntouched();
            newUserVM.accPermissions = [];
            angular.forEach(newUserVM.accountList.accounts, function(accItem, accKey) {
                if (newUserVM.accountCheck[accKey]) {
                    var accountPermissionObj = {};
                    accountPermissionObj.Id = accItem.number;
                    accountPermissionObj.Permissions = [];
                    angular.forEach(newUserVM.defaultPermissions, function(permissionItem) {
                        if (newUserVM.invoice[accKey] === permissionItem.name) {
                            accountPermissionObj.Permissions.push({ "Name": permissionItem.name, "OptionId": permissionItem.optionId });
                        }
                        if (newUserVM.payment[accKey] === permissionItem.name) {
                            accountPermissionObj.Permissions.push({ "Name": permissionItem.name, "OptionId": permissionItem.optionId });
                        }
                        if (newUserVM.card[accKey] === permissionItem.name) {
                            accountPermissionObj.Permissions.push({ "Name": permissionItem.name, "OptionId": permissionItem.optionId });
                        }
                        if (newUserVM.accMnt[accKey] === permissionItem.name) {
                            accountPermissionObj.Permissions.push({ "Name": permissionItem.name, "OptionId": permissionItem.optionId });
                        }
                        if (newUserVM.transaction[accKey] === permissionItem.name) {
                            accountPermissionObj.Permissions.push({ "Name": permissionItem.name, "OptionId": permissionItem.optionId });
                        }
                    });
                    newUserVM.accPermissions.push(accountPermissionObj);
                }
            });
            if (newUserVM.firstName || newUserVM.lastName || newUserVM.email || newUserVM.workPhone) {
                newUserVM.submitted = true;
                if (isvalidForm) {
                    if (!utils.isValidEmail(newUserVM.email)) {
                        toastr.error(toastrMessage.common.emailValidation);
                    } else if (newUserVM.mobilePhone && !utils.isValidPhoneNumber(newUserVM.mobilePhone)) {
                        toastr.error(toastrMessage.common.phoneNumberValidation);
                    } else if (!utils.isValidPhoneNumber(newUserVM.workPhone)) {
                        toastr.error(toastrMessage.common.phoneNumberValidation);
                    } else if (newUserVM.isValidID === true) {
                        toastr.error(newUserVM.email + toastrMessage.userManagement.emailExists);
                    } else {
                        setNewUserDetails();
                    }
                } else {
                    toastr.error(toastrMessage.common.mandatoryValidation);
                }
            } else {
                setNewUserDetails();
            }
        }
        /*sets the new user details*/
        function setNewUserDetails() {
            newUserVM.userDetails = [];
            if (newUserVM.firstName && newUserVM.lastName && newUserVM.email && newUserVM.workPhone) {
                var userObj = {
                    "emailId": newUserVM.email,
                    "firstName": $filter('capitalize')(newUserVM.firstName),
                    "lastName": $filter('capitalize')(newUserVM.lastName),
                    "orgId": localStorage.orgID,
                    "workPhone": newUserVM.workPhone,
                    "mobilePhone": newUserVM.mobilePhone,
                    "active": true,
                    "userId": (newUserVM.userId === '') ? '' : newUserVM.userId
                };
                newUserVM.userDetails.push(userObj);
            }
            angular.forEach(newUserVM.newUsers, function(userItem) {
                newUserVM.userDetails.push(userItem);
            });
            if (newUserVM.userDetails.length === 0) {
                newUserVM.submitted = true;
                toastr.error(toastrMessage.userManagement.allFieldEmpty);
            } else if (newUserVM.accPermissions.length === 0 && newUserVM.userDetails.length > 1) {
                toastr.error(toastrMessage.userManagement.multiUserPermission);
            } else if (newUserVM.accPermissions.length === 0 && newUserVM.userDetails.length === 1) {
                toastr.error(toastrMessage.userManagement.singleUserPermission);
            } else {
                saveNewUserDetails(newUserVM.userDetails, newUserVM.accPermissions);
            }
        }
        /*saves new user details*/
        function saveNewUserDetails(users, permissions) {
            $rootScope.dataLoading = true;
            var payload = {
                "Users": users,
                "AccountPermissions": permissions
            };
            userManagementService.saveNewUserDetails(payload)
                .then(saveNewUserDetailsSuccess, saveNewUserDetailsError);
        }
        /*success callback for save new user*/
        function saveNewUserDetailsSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                if (newUserVM.userDetails.length === 1) {
                    toastr.success($filter('capitalize')(newUserVM.userDetails[0].firstName) + ' ' + $filter('capitalize')(newUserVM.userDetails[0].lastName) + toastrMessage.userManagement.singleNewUserSuccess);
                } else {
                    toastr.success(toastrMessage.userManagement.multiNewUserSuccess);
                }

                $state.go('root.userManagement');
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*error callback for save new user*/
        function saveNewUserDetailsError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*add new user*/
        function addNewUser(isvalidForm) {
            newUserVM.submitted = true;
            localStorage.editUserData = JSON.stringify(null);
            if (isvalidForm) {
                if (!utils.isValidEmail(newUserVM.email)) {
                    toastr.error(toastrMessage.common.emailValidation);
                } else if (newUserVM.mobilePhone && !utils.isValidPhoneNumber(newUserVM.mobilePhone)) {
                    toastr.error(toastrMessage.common.phoneNumberValidation);
                } else if (!utils.isValidPhoneNumber(newUserVM.workPhone)) {
                    toastr.error(toastrMessage.common.phoneNumberValidation);
                } else if (newUserVM.isValidID === true) {
                    toastr.error(newUserVM.email + toastrMessage.userManagement.emailExists);
                } else {
                    var userObj = {
                        "emailId": newUserVM.email,
                        "firstName": $filter('capitalize')(newUserVM.firstName),
                        "lastName": $filter('capitalize')(newUserVM.lastName),
                        "orgId": localStorage.orgID,
                        "workPhone": newUserVM.workPhone,
                        "mobilePhone": newUserVM.mobilePhone,
                        "active": true,
                        "userId": (newUserVM.userId === '') ? '' : newUserVM.userId
                    };
                    newUserVM.newUserAdded = true;
                    newUserVM.newUsers.push(userObj);
                    newUserVM.firstName = '';
                    newUserVM.lastName = '';
                    newUserVM.email = '';
                    newUserVM.workPhone = '';
                    newUserVM.mobilePhone = '';
                    newUserVM.userId = '';
                    newUserVM.buttonLabel = 'New User';
                    newUserVM.submitted = false;
                    $scope.newUserForm.$setPristine();
                    $scope.newUserForm.$setUntouched();
                }
            } else {
                toastr.error(toastrMessage.common.mandatoryValidation);
            }
        }
        /*selects the module permission*/
        function moduleAccess(modules, accessToken, index) {
            switch (modules) {
                case 'invoice':
                    newUserVM.invoice[index] = accessToken;
                    break;
                case 'payment':
                    newUserVM.payment[index] = accessToken;
                    break;
                case 'card':
                    newUserVM.card[index] = accessToken;
                    break;
                case 'accMnt':
                    newUserVM.accMnt[index] = accessToken;
                    break;
                case 'transaction':
                    newUserVM.transaction[index] = accessToken;
                    break;
                default:
            }
        }
        /*selects the accounts for the new user*/
        function checkAccounts(index, isChecked, permission) {
            angular.forEach(newUserVM.accountList.accounts, function(accItem, accKey) {
                if (index === accKey) {
                    if (isChecked) {
                        if (permission === 'read') {
                            newUserVM.invoice[index] = 'IR';
                            newUserVM.payment[index] = 'PR';
                            newUserVM.card[index] = 'CR';
                            newUserVM.accMnt[index] = 'AR';
                            newUserVM.transaction[index] = 'TR';
                        } else if (permission === 'write') {
                            newUserVM.invoice[index] = 'IW';
                            newUserVM.payment[index] = 'PW';
                            newUserVM.card[index] = 'CW';
                            newUserVM.accMnt[index] = 'AW';
                            newUserVM.transaction[index] = 'TW';
                        } else {
                            newUserVM.invoice[index] = newUserVM.invoicePermission || 'IN';
                            newUserVM.payment[index] = newUserVM.paymentPermission || 'PN';
                            newUserVM.card[index] = newUserVM.cardPermission || 'CN';
                            newUserVM.accMnt[index] = newUserVM.accountPermission || 'AN';
                            newUserVM.transaction[index] = newUserVM.transactionPermission || 'TN';
                        }

                    } else {
                        newUserVM.invoice[accKey] = '';
                        newUserVM.payment[accKey] = '';
                        newUserVM.card[accKey] = '';
                        newUserVM.accMnt[accKey] = '';
                        newUserVM.transaction[index] = '';
                    }
                }
            });
        }
        /*selects the account permission for the new user*/
        function checkModules(module, isChecked, permission) {
            angular.forEach(newUserVM.accountList.accounts, function(accItem, accKey) {
                if (module === 'invoice') {
                    if (isChecked) {
                        if (permission === 'read') {
                            newUserVM.invoice[accKey] = 'IR';
                        } else if (permission === 'write') {
                            newUserVM.invoice[accKey] = 'IW';
                        } else {
                            newUserVM.invoice[accKey] = 'IN';
                        }

                    } else {
                        newUserVM.invoice[accKey] = 'IN';
                    }
                    newUserVM.invoicePermission = newUserVM.invoice[accKey];
                } else if (module === 'payment') {
                    if (isChecked) {
                        if (permission === 'read') {
                            newUserVM.payment[accKey] = 'PR';
                        } else if (permission === 'write') {
                            newUserVM.payment[accKey] = 'PW';
                        } else {
                            newUserVM.payment[accKey] = 'PN';
                        }

                    } else {
                        newUserVM.payment[accKey] = 'PN';
                    }
                    newUserVM.paymentPermission = newUserVM.payment[accKey];
                } else if (module === 'card') {
                    if (isChecked) {
                        if (permission === 'read') {
                            newUserVM.card[accKey] = 'CR';
                        } else if (permission === 'write') {
                            newUserVM.card[accKey] = 'CW';
                        } else {
                            newUserVM.card[accKey] = 'CN';
                        }

                    } else {
                        newUserVM.card[accKey] = 'CN';
                    }
                    newUserVM.cardPermission = newUserVM.card[accKey];
                } else if (module === 'account') {
                    if (isChecked) {
                        if (permission === 'read') {
                            newUserVM.accMnt[accKey] = 'AR';
                        } else if (permission === 'write') {
                            newUserVM.accMnt[accKey] = 'AW';
                        } else {
                            newUserVM.accMnt[accKey] = 'AN';
                        }

                    } else {
                        newUserVM.accMnt[accKey] = 'AN';
                    }
                    newUserVM.accountPermission = newUserVM.accMnt[accKey];
                } else if (module === 'transaction') {
                    if (isChecked) {
                        if (permission === 'read') {
                            newUserVM.transaction[accKey] = 'TR';
                        } else if (permission === 'write') {
                            newUserVM.transaction[accKey] = 'TW';
                        } else {
                            newUserVM.transaction[accKey] = 'TN';
                        }
                    } else {
                        newUserVM.transaction[accKey] = 'TN';
                    }
                    newUserVM.transactionPermission = newUserVM.transaction[accKey];
                }
            });
        }
        /*selects the border for the accounts according to permission*/
        function borderSelection(selected, index) {
            if (newUserVM.accountCheck[index]) {
                if (selected === 'IW' || selected === 'PW' || selected === 'CW' || selected === 'AW' || selected === 'TW') {
                    return 'wrn_write wrn_default_width';
                } else if (selected === 'IR' || selected === 'PR' || selected === 'CR' || selected === 'AR' || selected === 'TR') {
                    return 'wrn_status1 wrn_read wrn_default_width';
                } else {
                    return 'wrn_status2 wrn_noacces wrn_default_width';
                }
            } else {
                return 'wrn_status2 wrn_noacces wrn_default_width';
            }
        }
        /*selects the read or write permission from pop up*/
        function onChecked(item, checked, permission) {
            switch (item) {
                case 'all':
                    newUserVM.allAccountCheck = checked;
                    angular.forEach(newUserVM.accountList.accounts, function(item, key) {
                        newUserVM.accountCheck[key] = checked;
                        checkAccounts(key, checked, permission);
                    });
                    if (checked) {
                        newUserVM.invoicePermission = (permission === 'write') ? 'IW' : 'IR';
                        newUserVM.paymentPermission = (permission === 'write') ? 'PW' : 'PR';
                        newUserVM.cardPermission = (permission === 'write') ? 'CW' : 'CR';
                        newUserVM.accountPermission = (permission === 'write') ? 'AW' : 'AR';
                        newUserVM.transactionPermission = (permission === 'write') ? 'TW' : 'TR';
                    } else {
                        newUserVM.invoicePermission = 'IN';
                        newUserVM.paymentPermission = 'PN';
                        newUserVM.cardPermission = 'CN';
                        newUserVM.accountPermission = 'AN';
                        newUserVM.transactionPermission = 'TN';
                    }
                    break;
                case 'invoice':
                    if (permission === 'read' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Read access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            newUserVM.invoiceCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else if (permission === 'write' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Write access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            newUserVM.invoiceCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else {
                        newUserVM.invoiceCheck = checked;
                        checkModules(item, checked, permission);
                    }
                    break;
                case 'payment':
                    if (permission === 'read' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Read access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            newUserVM.paymentCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else if (permission === 'write' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Write access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            newUserVM.paymentCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else {
                        newUserVM.paymentCheck = checked;
                        checkModules(item, checked, permission);
                    }
                    break;
                case 'card':
                    if (permission === 'read' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Read access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            newUserVM.cardCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else if (permission === 'write' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Write access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            newUserVM.cardCheck = checked;
                            checkModules(item, checked, permission);
                        });

                    } else {
                        newUserVM.cardCheck = checked;
                        checkModules(item, checked, permission);
                    }

                    break;
                case 'account':
                    if (permission === 'read' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Read access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            newUserVM.accMaintainCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else if (permission === 'write' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Write access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            newUserVM.accMaintainCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else {
                        newUserVM.accMaintainCheck = checked;
                        checkModules(item, checked, permission);
                    }

                    break;
                case 'transaction':
                    if (permission === 'read' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Read access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            newUserVM.transactionCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else if (permission === 'write' && checked) {
                        $confirm({
                            title: "Confirm",
                            text: 'All Accounts will be changed to Write access ONLY. Please confirm.',
                            ok: "Yes",
                            cancel: "No"
                        }, {
                            size: 'sm',
                            backdrop: true
                        }).then(function() {
                            newUserVM.transactionCheck = checked;
                            checkModules(item, checked, permission);
                        });
                    } else {
                        newUserVM.transactionCheck = checked;
                        checkModules(item, checked, permission);
                    }
                    break;
            }
        }
        /*class selection for the account permission*/
        function buttonClass(item, value, permission, index) {
            var styleClass;
            if (newUserVM.accountCheck[index]) {
                if (permission === 'W') {
                    if (item === value) {
                        styleClass = 'write-only active';
                    } else {
                        styleClass = 'write-only';
                    }
                } else if (permission === 'R') {
                    if (item === value) {
                        styleClass = 'read-only active';
                    } else {
                        styleClass = 'read-only';
                    }
                } else {
                    if (item === value) {
                        styleClass = 'no-acces-only active';
                    } else {
                        styleClass = 'no-acces-only';
                    }
                }
            } else {
                styleClass = 'disable-button';
            }
            return styleClass;
        }
        /*edit or delete button click on the added user*/
        function editOrDelete(actionToken, index, firstName, lastName) {
            if (actionToken === 'delete') {
                $confirm({
                    title: "Delete",
                    text: toastrMessage.userManagement.deletePrompt + $filter('capitalize')(firstName) + " " + $filter('capitalize')(lastName) + "?",
                    ok: "Yes",
                    cancel: "No"
                }, {
                    size: 'sm',
                    backdrop: true
                }).then(function() {
                    newUserVM.newUsers.splice(index, 1);
                    toastr.success($filter('capitalize')(firstName) + ' ' + $filter('capitalize')(lastName) + toastrMessage.userManagement.userDelete);
                });
            } else {
                if (newUserVM.firstName || newUserVM.lastName || newUserVM.email || newUserVM.workPhone || newUserVM.mobilePhone) {
                    $confirm({
                        title: "Confirm",
                        text: toastrMessage.userManagement.editUserPrompt,
                        ok: "Yes",
                        cancel: "No"
                    }, {
                        size: 'sm'
                    }).then(function() {
                        angular.forEach(newUserVM.newUsers, function(userItem, userKey) {
                            if (index === userKey) {
                                if (JSON.parse(localStorage.editUserData) !== null) {
                                    newUserVM.newUsers.push(JSON.parse(localStorage.editUserData));
                                }
                                newUserVM.firstName = $filter('capitalize')(userItem.firstName);
                                newUserVM.lastName = $filter('capitalize')(userItem.lastName);
                                newUserVM.email = userItem.emailId;
                                newUserVM.workPhone = utils.getPhoneNumberFormat(userItem.workPhone);
                                newUserVM.mobilePhone = utils.getPhoneNumberFormat(userItem.mobilePhone);
                                localStorage.editUserData = JSON.stringify(userItem);
                                newUserVM.newUsers.splice(index, 1);
                                newUserVM.buttonLabel = 'Save';
                            }
                        });
                    });
                } else {
                    angular.forEach(newUserVM.newUsers, function(userItem, userKey) {
                        if (index === userKey) {
                            if (JSON.parse(localStorage.editUserData) !== null) {
                                newUserVM.newUsers.push(JSON.parse(localStorage.editUserData));
                            }
                            newUserVM.firstName = $filter('capitalize')(userItem.firstName);
                            newUserVM.lastName = $filter('capitalize')(userItem.lastName);
                            newUserVM.email = userItem.emailId;
                            newUserVM.workPhone = utils.getPhoneNumberFormat(userItem.workPhone);
                            newUserVM.mobilePhone = utils.getPhoneNumberFormat(userItem.mobilePhone);
                            localStorage.editUserData = JSON.stringify(userItem);
                            newUserVM.newUsers.splice(index, 1);
                            newUserVM.buttonLabel = 'Save';
                        }
                    });
                }

            }
        }
    }
})();
