/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.invoicePayments')
        .controller('invoiceController', invoiceController);


    invoiceController.$inject = ['$scope', '$state', 'toastr', '$confirm', '$rootScope', 'invoicePaymentsService', '$window', 'toastrMessage'];

    function invoiceController($scope, $state, toastr, $confirm, $rootScope, invoicePaymentsService, $window, toastrMessage) {
        var invoiceVM = this;
        invoiceVM.tabContent = true;
        invoiceVM.exportPopup = false;
        invoiceVM.paymentExport = false;
        invoiceVM.invoiceExport = false;
        invoiceVM.loggedInUser = localStorage.userType;
        invoiceVM.init = init;
        invoiceVM.onTabBarClick = onTabBarClick;
        invoiceVM.exportTo = exportTo;
        invoiceVM.toggleExport = toggleExport;
        invoiceVM.invoiceNumber = [];
        invoiceVM.invoiceNumberHistory = [];
        invoiceVM.selectAll = false;
        invoiceVM.selectAllHistory = false;
       // invoiceVM.isExported = false;
        $rootScope.activeTab = true;
        init();

        /*  function declarations */

        /*initial loading*/
        function init() {
            // $state.go('root.invoices.openInvoices');
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item) {
                    if (item.option.toLowerCase().indexOf('invoice') !== -1) {
                        invoiceVM.userPermission = item.displayName;
                    }
                });
            }
            if($state.current.url === "/history"){
                $rootScope.activeTab = false;
            }
        }
        /*when account changed*/
        $scope.$on('accountChanged', function() {
            var hasPermission = false;
            if (localStorage.accountPermission) {
                angular.forEach(JSON.parse(localStorage.accountPermission), function(item, key) {
                    if (item.option.toLowerCase().indexOf('invoice') !== -1) {
                        if (item.displayName === 'R' || item.displayName === 'W') {
                            hasPermission = true;
                            init();
                        }
                    }
                    if ((key === 3) && !hasPermission) {
                        $state.go('root.dashboard');
                    }
                });
            }
        });

        /*selects the tab*/
        function onTabBarClick(tabIndex) {
            invoiceVM.exportPopup = false;
            if (tabIndex === 0) {
                invoiceVM.tabContent = false;
                $rootScope.activeTab = false;
                $state.go('root.invoices.paymentHistory');
            } else {
                invoiceVM.tabContent = true;
                $rootScope.activeTab = true;
                $state.go('root.invoices.openInvoices');
            }
        }
        /*toggle export pop up*/
        function toggleExport() {
            invoiceVM.exportPopup = !invoiceVM.exportPopup;
            invoiceVM.paymentExport = false;
            invoiceVM.invoiceExport = false;
        }
        /*click on export button in pop up*/
        function exportTo(payment, invoice) {
      
            invoiceVM.invoiceNumber =   invoicePaymentsService.getInvoiceNumber();
            invoiceVM.invoiceNumberHistory =   invoicePaymentsService.getInvoiceNumberHistory();
            var exportPayload = [];
            if (!payment && !invoice) {
                toastr.error('Select export option');
            } else {
                invoiceVM.exportPopup = false;
                if (payment) {
                    if (localStorage.paymentSearchCriteria === undefined || localStorage.paymentColumns === undefined) {
                        if(localStorage.paymentColumns && JSON.parse(localStorage.paymentColumns).length){
                            var paymentColumns = JSON.parse(localStorage.paymentColumns);
                            var pHeaders = [];
                            paymentColumns.forEach(function(item) {
                                if (item.showColumn) {
                                    pHeaders.push(item.columnName);
                                }
                            });
                        } else {
                          pHeaders = ["Location ID","Invoice Date","Invoice #","Invoice Amount","Payment Date","Payment Mode","Attachments","Delivery Date","Purchase Order #","BOL #","Due Date","Balance Due"];  
                        }
                        var paymentObj = {
                            "exportOption": 'Payment',
                            "serviceTypeId": [1, 2, 3, 4, 5],
                            "dueDateTypeId": 6,
                            "startDate": "",
                            "endDate": "",
                            "dueAmountTypeId": 9,
                            "fromRange": "",
                            "toRange": "",
                            "invoiceCategoryId": 11,
                            "invoiceNumberFrom": "",
                            "invoiceNumberTo": "",
                            "invoiceNumber": "",
                            "listInvoiceNumber" : [],
                            "paymentModeId": "",
                            "isInquiry": false,
                            "columnHeaders": pHeaders,
                            "pageNumber": 1,
                            "pageRows": 10,
                            "sortingParameter": "Invoice Date",
                            "sortingDirection": "asc",
                            "removeGroup": false,
                            "operation": 'btnInvoiceSearch'
                        };
                    } else{
                        var paymentSearch = JSON.parse(localStorage.paymentSearchCriteria);
                        var paymentColumns = JSON.parse(localStorage.paymentColumns);
                        var pHeaders = [];
                        paymentColumns.forEach(function(item) {
                            if (item.showColumn) {
                                pHeaders.push(item.columnName);
                            }
                        });
                        var paymentObj = {
                            'exportOption': 'Payment',
                            "serviceTypeId": paymentSearch.serviceTypeId,
                            "dueDateTypeId": paymentSearch.dueDateTypeId,
                            "startDate": paymentSearch.startDate,
                            "endDate": paymentSearch.endDate,
                            "dueAmountTypeId": paymentSearch.dueAmountTypeId,
                            "fromRange": paymentSearch.fromRange,
                            "toRange": paymentSearch.toRange,
                            "invoiceCategoryId": paymentSearch.invoiceCategoryId,
                            "invoiceNumberFrom": paymentSearch.invoiceNumberFrom,
                            "invoiceNumberTo": paymentSearch.invoiceNumberTo,
                            "invoiceNumber":paymentSearch.invoiceNumber,
                            "listInvoiceNumber": invoiceVM.invoiceNumberHistory,
                            "paymentModeId": paymentSearch.paymentModeId,
                            "isInquiry": false,
                            "columnHeaders": pHeaders,
                            "pageNumber": paymentSearch.pageNumber,
                            "pageRows": 10,
                            "sortingParameter": paymentSearch.sortingParameter,
                            "sortingDirection": paymentSearch.sortingDirection,
                            "operation": 'btnInvoiceExport' 
                        };
                    }

                }
                if (invoice) {
                    if (localStorage.invoiceSearchCriteria === undefined || localStorage.invoiceColumns === undefined) {
                        iHeaders = ["Location ID","Due Date","Invoice Date","Invoice #","Invoice Amount","Balance Due","Inquiry","Inquiry Reason"];
                        var invoiceObj = {
                            "exportOption": 'Invoice',
                            "serviceTypeId": [1, 2, 3, 4, 5],
                            "dueDateTypeId": 6,
                            "startDate": "",
                            "endDate": "",
                            "dueAmountTypeId": 9,
                            "fromRange": "",
                            "toRange": "",
                            "invoiceCategoryId": 11,
                            "invoiceNumberFrom": "",
                            "invoiceNumberTo": "",
                            "invoiceNumber": "",
                            "listInvoiceNumber" : [],
                            "paymentModeType": [],
                            "isInquiry": false,
                            "columnHeaders": iHeaders,
                            "pageNumber": 1,
                            "pageRows": 10,
                            "sortingParameter": "Due Date",
                            "sortingDirection": "asc",
                            "removeGroup": false,
                            "operation": 'btnInvoiceExport'
                        };
                    } else {
                        var invoiceSearch = JSON.parse(localStorage.invoiceSearchCriteria);
                        var invoiceColumns = JSON.parse(localStorage.invoiceColumns);
                        var iHeaders = [];
                        invoiceColumns.forEach(function(item) {
                            if (item.showColumn) {
                                iHeaders.push(item.columnName);
                            }
                        });
                        var invoiceObj = {
                            "exportOption": 'Invoice',
                            "serviceTypeId": invoiceSearch.serviceTypeId,
                            "dueDateTypeId": invoiceSearch.dueDateTypeId,
                            "startDate": invoiceSearch.startDate,
                            "endDate": invoiceSearch.endDate,
                            "dueAmountTypeId": invoiceSearch.dueAmountTypeId,
                            "fromRange": invoiceSearch.fromRange,
                            "toRange": invoiceSearch.toRange,
                            "invoiceCategoryId": invoiceSearch.invoiceCategoryId,
                            "invoiceNumberFrom": invoiceSearch.invoiceNumberFrom,
                            "invoiceNumberTo": invoiceSearch.invoiceNumberTo,
                            "invoiceNumber":invoiceSearch.invoiceNumber,
                            "listInvoiceNumber": invoiceVM.invoiceNumber,
                            "paymentModeType": [],
                            "isInquiry": invoiceSearch.isInquiry,
                            "columnHeaders": iHeaders,
                            "pageNumber": invoiceSearch.pageNumber,
                            "pageRows": 10,
                            "sortingParameter": invoiceSearch.sortingParameter,
                            "sortingDirection": invoiceSearch.sortingDirection,
                            "removeGroup": invoiceSearch.removeGroup,
                            "operation": 'btnInvoiceExport'
                        };
                    }

                }

                if (payment) {
                    exportPayload.push(paymentObj);
                }
                if (invoice) {
                    exportPayload.push(invoiceObj);
                }
                var payload = {
                    "exportData": exportPayload
                };
                $rootScope.$emit('exportEvent');
                $rootScope.dataLoading = true;
                invoicePaymentsService.exportInvoicePayment(payload)
                    .then(exportInvoicePaymentSuccess, exportInvoicePaymentError);
            }

        }
        /*success callback for export*/
        function exportInvoicePaymentSuccess(apiResponse) {
            invoiceVM.invoiceNumber = [];
            invoiceVM.invoiceNumberHistory = [];
            invoicePaymentsService.setInvoiceNumber(invoiceVM.invoiceNumber);
            invoicePaymentsService.setInvoiceNumberHistory(invoiceVM.invoiceNumberHistory);
           // invoiceVM.isExported = true;
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                $window.open(apiResponse.filePath);
            }
        }
        /*error callback for export*/
        function exportInvoicePaymentError() {
            invoiceVM.invoiceNumber = [];
            invoiceVM.invoiceNumberHistory = [];
            invoicePaymentsService.setInvoiceNumber(invoiceVM.invoiceNumber);
            invoicePaymentsService.setInvoiceNumberHistory(invoiceVM.invoiceNumberHistory);
           // invoiceVM.isExported = true;
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        $rootScope.$on('hidePopup', function() {
            invoiceVM.exportPopup = false;
            $scope.$apply();
        });
    }
})();
