/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';
    angular.module('scFuelsApp.login')
        .factory('headermenuService', headermenuService);

    headermenuService.$inject = ['$q', '$http', 'apiURLService'];

    function headermenuService ($q, $http, apiURLService) {
        var headermenuObject = {};
        headermenuObject.settingsPassword = settingsPassword;
        headermenuObject.settingsQuestion = settingsQuestion;
        headermenuObject.getHeaderNotifications = getHeaderNotifications;
        headermenuObject.getHeaderMessages = getHeaderMessages;
        headermenuObject.getFAQData = getFAQData;
        headermenuObject.updateMessagesReadCount = updateMessagesReadCount;
        headermenuObject.updateNotificationReadCount = updateNotificationReadCount;
        headermenuObject.getAccountList = getAccountList;
        headermenuObject.getAccountPermission = getAccountPermission;
        return headermenuObject;

        function settingsPassword(requestData) {
            var url = apiURLService.settingsPasswordURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function settingsQuestion(requestData){
             var url = apiURLService.settingsQuestionURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getHeaderNotifications(){
             var url = apiURLService.getHeaderNotificationsURL();
            return $http.get(url).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getHeaderMessages(){
             var url = apiURLService.getHeaderMessagesURL();
            return $http.get(url).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getFAQData(){
             var url = apiURLService.getFAQURL();
            return $http.get(url).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function updateMessagesReadCount(requestData){
            var url = apiURLService.updateMessagesReadCountURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function updateNotificationReadCount(requestData){
           var url = apiURLService.updateNotificationReadCountURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            }); 
        }

        function getAccountList(requestData){
            var url = apiURLService.getHeaderAccountListURL();
            return $http.post(url, requestData).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            }); 
        //    var url = apiURLService.getHeaderAccountListURL()+requestData.pageNo+'&orgId='+requestData.orgID;
        //     return $http.get(url).then(function (apiResponse) {
        //           return apiResponse.data;
        //     }).catch(function (apiResponse) {
        //         return $q.reject(apiResponse.data);
        //     }); 
        }

        function getAccountPermission(requestData){
            var url = apiURLService.getAccountPermissionURL()+requestData.accountID+'&orgId='+requestData.orgID;
            return $http.get(url).then(function (apiResponse) {
                  return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }
        
    }
})();
