
/*jshint browser: true*/
/*global angular: true*/
(function () {
    'use strict';
    angular.module('scFuelsApp.login')
        .factory('loginService', loginService);

    loginService.$inject = ['$q', '$http', 'apiURLService'];

    function loginService($q, $http, apiURLService) {
        var loginObject = {};
        loginObject.authenticateLogin = authenticateLogin;
        loginObject.getSecurityQuestionList = getSecurityQuestionList;
        loginObject.registerUser = registerUser;
        loginObject.sendResetLink = sendResetLink;
        loginObject.resetPassword = resetPassword;
        loginObject.validateToken = validateToken;
        loginObject.expireToken = expireToken;
        return loginObject;

        function authenticateLogin() {
            var url = apiURLService.getLoginURL();
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getSecurityQuestionList(Id) {
            var url = apiURLService.getSecurityQuestionListURL() + '?token=' + Id;
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function registerUser(params) {
            var url = apiURLService.getUserRegistrationURL();
            return $http.post(url, params).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function sendResetLink(emailId) {
            var url = apiURLService.resetPwdLinkURL() + emailId;
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function resetPassword(params) {
            var url = apiURLService.resetPasswordURL();
            return $http.post(url, params).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function validateToken(token) {
            var url = apiURLService.validateTokenURL() + token;
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function expireToken() {
            var url = apiURLService.expireTokenURL();
            return $http.get(url).then(function (apiResponse) {
                return apiResponse.data;
            }).catch(function (apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }
    }
})();
