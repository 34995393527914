/*jshint browser: true*/
/*global angular: true*/

(function () {
  "use strict";

  angular
    .module("scFuelsApp.cardManagement")
    .controller("fleetCardController", fleetCardController);

  fleetCardController.$inject = [
    "$scope",
    "$state",
    "$rootScope",
    "toastrMessage",
    "cardManagementService",
    "utils",
    "toastr",
    "$window",
    "$confirm",
    "$filter",
    "modalService",
    "$ngConfirm",
  ];

  function fleetCardController(
    $scope,
    $state,
    $rootScope,
    toastrMessage,
    cardManagementService,
    utils,
    toastr,
    $window,
    $confirm,
    $filter,
    modalService,
    $ngConfirm
  ) {
    var fleetCardVM = this;
    fleetCardVM.tooltips = toastrMessage.fleetCardsTooltip;
    fleetCardVM.showAccordianView = [];
    fleetCardVM.cardDetails = [];
    fleetCardVM.restrictionDetails = [];
    fleetCardVM.showManageDriver = [];
    fleetCardVM.addNewDriver = [];
    fleetCardVM.editDriver = [];
    fleetCardVM.searchParams = {};
    fleetCardVM.isAddNewDriver = [];
    fleetCardVM.showCardSearchButton = true;
    fleetCardVM.cardDetailsForm = [];
    fleetCardVM.restrictionsForm = [];
    fleetCardVM.disablePin = false;
    //fleetCardVM.disableLinkedPin = true;
    fleetCardVM.autoGeneratePin = false; //Jinran modified on 4/4/23
    fleetCardVM.disableCard = true;
    fleetCardVM.disableVehicleId = true;
    fleetCardVM.disableAuthCode = true;
    fleetCardVM.disableDriverId = true;
    fleetCardVM.showTimeProfile = [];
    fleetCardVM.timeProfileDetails = [];
    fleetCardVM.isAddNewProfile = [];
    fleetCardVM.stateRestriction = [];
    fleetCardVM.showStatePopup = [];
    fleetCardVM.networkCode = "";
    fleetCardVM.profileTemplate =
      "app/modules/cardManagement/fleetCardManagement/cardDetails/timeProfileTemplate.html";
    fleetCardVM.showNewField = false;
    fleetCardVM.submitClicked = false;
    fleetCardVM.disableReset = false;
    fleetCardVM.currentIndex = null;
    fleetCardVM.currentLinkedPin;
    fleetCardVM.currentVehicleID = null;
    fleetCardVM.InitialLoad = true;
    fleetCardVM.hstep = 1;
    fleetCardVM.mstep = 1;
    fleetCardVM.ismeridian = true;
    fleetCardVM.select = null;

    fleetCardVM.advanceSearch = advanceSearch;
    fleetCardVM.manageTimeProfile = manageTimeProfile;
    fleetCardVM.managePinClick = managePinClick;
    fleetCardVM.showCardDetails = showCardDetails;
    fleetCardVM.editDriverDetails = editDriverDetails;
    fleetCardVM.addNewDriver = addNewDriver;
    fleetCardVM.cancelAddDriver = cancelAddDriver;
    fleetCardVM.saveDriverDetails = saveDriverDetails;
    fleetCardVM.deleteDriverDetails = deleteDriverDetails;
    fleetCardVM.applyDropdown = applyDropdown;
    fleetCardVM.toggleSearch = toggleSearch;
    fleetCardVM.getAllCards = getAllCards;
    fleetCardVM.cardNumberSearch = cardNumberSearch;
    fleetCardVM.orderByColumn = orderByColumn;
    fleetCardVM.paginationClick = paginationClick;
    fleetCardVM.editCardDetails = editCardDetails;
    fleetCardVM.editProfileDetails = editProfileDetails;
    fleetCardVM.addNewProfile = addNewProfile;
    fleetCardVM.saveNewProfile = saveNewProfile;
    fleetCardVM.cancelAddProfile = cancelAddProfile;
    fleetCardVM.deleteProfileDetails = deleteProfileDetails;
    fleetCardVM.changeStatus = changeStatus;
    fleetCardVM.openModal = openModal;
    fleetCardVM.changePIN = changePIN;
    fleetCardVM.applyStateRestriction = applyStateRestriction;
    fleetCardVM.selectStateRestrictions = selectStateRestrictions;
    fleetCardVM.addNewZipcode = addNewZipcode;
    fleetCardVM.checkDuplicate = checkDuplicate;
    fleetCardVM.deleteZipcode = deleteZipcode;
    fleetCardVM.onNetworkChange = onNetworkChange;
    fleetCardVM.printTable = printTable;
    fleetCardVM.validatePin = validatePin;
    fleetCardVM.changeEmail = changeEmail;
    fleetCardVM.changeDriverPIN = changeDriverPIN;
    fleetCardVM.changeOdometer = changeOdometer;
    fleetCardVM.closeClick = closeClick;
    fleetCardVM.changeDepartment = changeDepartment;
    fleetCardVM.exportTable = exportTable;
    fleetCardVM.AppendZeroes = AppendZeroes;
    fleetCardVM.addZeroes = addZeroes;
    fleetCardVM.onResetChange = onResetChange;
    fleetCardVM.validateDriver = validateDriver;
    fleetCardVM.validateVehicle = validateVehicle;
    fleetCardVM.validateVoyagerCardDriver = validateVoyagerCardDriver;
    fleetCardVM.changeEmailReceipt = changeEmailReceipt;
    fleetCardVM.selectNetwork = selectNetwork;
    fleetCardVM.validateCFNDriver = validateCFNDriver;
    fleetCardVM.validatePacprideDriver = validatePacprideDriver;
    fleetCardVM.validateCFSStdDriver = validateCFSStdDriver;
    fleetCardVM.validateNFDriver = validateNFDriver;
    fleetCardVM.gallonChange = gallonChange;
    fleetCardVM.gallonWeekendChange = gallonWeekendChange;
    fleetCardVM.selectAllStates = selectAllStates;

    // JC added
    fleetCardVM.changePinCheckbox = changePinCheckbox;
    fleetCardVM.showShipping = false;
    fleetCardVM.cancelReissue = cancelReissue;
    fleetCardVM.oldPin = "";    
    var toasterMsg = "";

    init();

    function init() {
      $rootScope.dataLoading = true;
      if (localStorage.accountPermission) {
        angular.forEach(
          JSON.parse(localStorage.accountPermission),
          function (item) {
            if (item.option.toLowerCase().indexOf("card management") !== -1) {
              fleetCardVM.userPermission = item.displayName;
            }
          }
        );
      }
      fleetCardVM.submitClicked = false;
      fleetCardVM.columnHeaders = [
        {
          name: "Card #",
          icon: "ic-filter",
          sortParam: "CardNumber",
        },
        {
          name: "Network",
          icon: "ic-filterNot",
          sortParam: "Network",
        },
        {
          name: "Card Type",
          icon: "ic-filterNot",
          sortParam: "CardType",
        },
        {
          name: "Vehicle ID",
          icon: "ic-filterNot",
          sortParam: "VehicleID",
        },
        {
          name: "Card Description",
          icon: "ic-filterNot",
          sortParam: "CardDescription",
        },
        {
          name: "Status",
          icon: "ic-filterNot",
          sortParam: "CardStatus",
        },
        {
          name: "Date Last Used",
          icon: "ic-filterNot",
          sortParam: "DateLastUsed",
        },
        {
          name: "Department",
          icon: "ic-filterNot",
          sortParam: "Department",
        },
        {
          name: "Linked PIN",
          icon: "ic-filterNot",
          sortParam: "LinkedPin",
        },
      ];
      fleetCardVM.currentPage = 1;
      fleetCardVM.firstPageItem = 1;
      fleetCardVM.secondPageItem = 2;      
      getFleetCardDropdownList();
      getFleetCardList();
      
      fleetCardVM.autoGeneratePin = false; // auto pin checked persists bug. JC
      fleetCardVM.currentLinkedPin = ''; // init LinkedPin

      let currentPage = sessionStorage.getItem("CurrentPageNo");
      let maxPage = sessionStorage.getItem("MyMaxPage");
      if (currentPage) {
        sessionStorage.removeItem("CurrentPageNo");
        let intPage = parseInt(currentPage);
        let intMaxPage = parseInt(maxPage);
        fleetCardVM.currentPage = intPage;
        let searchRequired = true;
        if (intPage > 1) {
          if (intPage == intMaxPage) {
            fleetCardVM.firstPageItem = intPage - 1;
            fleetCardVM.secondPageItem = intPage;
          } else {
            fleetCardVM.firstPageItem = intPage - 1;
            fleetCardVM.secondPageItem = intPage + 1;
          }
        } else {
          fleetCardVM.firstPageItem = 1;
          fleetCardVM.secondPageItem = 2;
        }
        if (searchRequired) {
          getFleetCardList();
        }
      }

      let CRResults = sessionStorage.getItem("CancelReissueResults");
      if(CRResults!=null && CRResults!=''){
        if(CRResults.startsWith("Your request has been submitted"))
        {
          toastr.success(CRResults)
        }else{
          toastr.error(CRResults);
        }        
        sessionStorage.removeItem("CancelReissueResults");
      }
    }

    function getFleetCardDropdownList() {
      cardManagementService
        .getCardManagementDropdownList(localStorage.selectedAccount)
        .then(getFleetCardDropdownListSuccess, getFleetCardDropdownListError);
    }

    function getFleetCardDropdownListSuccess(apiResponse) {
      // debugger;
      fleetCardVM.dropDownData = apiResponse.dataModel;
      if (fleetCardVM.dropDownData.network) {
        fleetCardVM.dropDownData.network.unshift({
          code: "Select",
          description: "Select",
        });
      }
      fleetCardVM.dropDownData.advanceSearchDept = JSON.parse(
        JSON.stringify(apiResponse.dataModel.department)
      );
      fleetCardVM.dropDownData.advanceSearchDept.unshift({
        code: "Select",
        description: "Select",
      });
      fleetCardVM.dropDownData.department = apiResponse.dataModel.department;
      fleetCardVM.dropDownData.department.push({
        code: "Add New",
        description: "Add New",
      });
      fleetCardVM.dropDownData.reset = [
        { code: "D", Description: "Daily" },
        { code: "W", Description: "Weekly" },
        { code: "M", Description: "Monthly" },
      ];

      fleetCardVM.dropDownData.siteRestriction =
        apiResponse.dataModel.siteRestriction;
      fleetCardVM.dropDownData.purchaseDay = apiResponse.dataModel.purchaseDay;
      fleetCardVM.dropDownData.stateRestrictions =
        apiResponse.dataModel.stateRestriction;
      if (fleetCardVM.dropDownData.stateRestrictions) {
        angular.forEach(
          fleetCardVM.dropDownData.stateRestrictions,
          function (dropdownItem) {
            dropdownItem.isChecked = false;
          }
        );
      }
    }

    function getFleetCardDropdownListError() {}

    function getCardRestrictionDropdownList(networkType) {
      cardManagementService
        .getCardRestrictionDropdownList(networkType)
        .then(getCardRestrictionListSuccess, getCardRestrictionListError);
    }

    function getCardRestrictionListSuccess(apiResponse) {
      // debugger;
      fleetCardVM.dropDownData.resetDay = [
        "Daily",
        "Day of Week",
        "Day of Month",
        "End of Month",
      ];
      fleetCardVM.dropDownData.miscEntry = ["Test1", "Test2", "Test3", "Test4"];
      $rootScope.dataLoading = false;
      fleetCardVM.dropDownRestrictionData = apiResponse.dataModel;
      for (var i = 0; i < apiResponse.dataModel.length; i++) {
        if (apiResponse.dataModel[i].name === "PRODUCT AUTH CODE") {
          fleetCardVM.dropDownData.productAuthCode =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "MANUAL ENTRY") {
          fleetCardVM.dropDownRestrictionData.miscEntry =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "TIME PROFILE") {
          fleetCardVM.dropDownRestrictionData.timeProfile =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "PRODUCTS") {
          fleetCardVM.dropDownRestrictionData.products =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "LIMIT CODE") {
          fleetCardVM.dropDownRestrictionData.gallonsPerTransaction =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "PROD CODE") {
          fleetCardVM.dropDownRestrictionData.allowedProducts =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "AUTH CODE") {
          fleetCardVM.dropDownRestrictionData.restrictionCodes =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "SITES ALLOWED") {
          fleetCardVM.dropDownRestrictionData.sitesAllowed =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "LIMIT PROFILE") {
          fleetCardVM.dropDownRestrictionData.limitProfile =
            apiResponse.dataModel[i].restrictions;
        }
        if (apiResponse.dataModel[i].name === "PROMPTS") {
          fleetCardVM.dropDownRestrictionData.prompts =
            apiResponse.dataModel[i].restrictions;
        }
      }
      // 'REASON CODE'
    }

    function getCardRestrictionListError() {}

    function advanceSearch() {
      if (
        fleetCardVM.searchParams.searchCardNo &&
        !utils.isValidAlphaNumeric(fleetCardVM.searchParams.searchCardNo)
      ) {
        toastr.error(toastrMessage.cardControlErrorMessage.validCardNo);
      } else if (
        fleetCardVM.searchParams.CardDescription &&
        !utils.isValidAlphaNumeric(fleetCardVM.searchParams.CardDescription)
      ) {
        toastr.error(toastrMessage.cardControlErrorMessage.noSpecialCharCard);
      } else if (
        fleetCardVM.searchParams.VehicleID &&
        !utils.isValidNumber(fleetCardVM.searchParams.VehicleID)
      ) {
        toastr.error(toastrMessage.cardControlErrorMessage.validVehicleId);
      } else {
        fleetCardVM.currentPage = 1;
        fleetCardVM.firstPageItem = 1;
        fleetCardVM.secondPageItem = 2;
        getFleetCardList();
      }
    }

    function getFleetCardList() {
      if (
        fleetCardVM.searchParams.network &&
        fleetCardVM.searchParams.network === "Select"
      ) {
        fleetCardVM.networkCode = null;
      }
      if (
        fleetCardVM.searchParams.Department &&
        fleetCardVM.searchParams.Department === "Select"
      ) {
        fleetCardVM.searchParams.Department = null;
      }
      $rootScope.dataLoading = true;
      var payLoad = {
        Filter: {
          PageNo: fleetCardVM.currentPage,
          PageSize: 20,
          SortColumn: fleetCardVM.searchParams.sortingParameter
            ? fleetCardVM.searchParams.sortingParameter
            : "CardNumber",
          SortOrder: fleetCardVM.searchParams.sortingDirection
            ? fleetCardVM.searchParams.sortingDirection
            : "asc",
        },
        Search: {
          AccountNo: localStorage.selectedAccount,
          CardNumber: fleetCardVM.searchParams.searchCardNo,
          Network: fleetCardVM.networkCode ? fleetCardVM.networkCode : null,
          CardDescription: fleetCardVM.searchParams.CardDescription,
          CardStatus: fleetCardVM.searchParams.CardStatus
            ? fleetCardVM.searchParams.CardStatus
            : "A",
          DateLastUsed: fleetCardVM.searchParams.DateLastUsed,
          Department: fleetCardVM.searchParams.Department
            ? fleetCardVM.searchParams.Department
            : null,
          CardType:
            fleetCardVM.searchParams.cardTypeArray &&
            fleetCardVM.searchParams.cardTypeArray.length
              ? fleetCardVM.searchParams.cardTypeArray.toString()
              : null,
          LinkedPin: fleetCardVM.searchParams.LinkedPin
            ? fleetCardVM.searchParams.LinkedPin
            : null,
          VehicleID: fleetCardVM.searchParams.VehicleID
            ? fleetCardVM.searchParams.VehicleID
            : null,
        },
      };

      localStorage.cardManageMentPaylod = JSON.stringify(payLoad);
      cardManagementService
        .getFleetCardList(payLoad)
        .then(getFleetCardListSuccess, getFleetCardListError);
    }

    function getFleetCardListSuccess(apiResponse) {
      fleetCardVM.fleetCardList = apiResponse.DataModel;
      fleetCardVM.searchCount = apiResponse.TotalRecords;
      fleetCardVM.maxPage = Math.ceil(fleetCardVM.searchCount / 20);
      sessionStorage.setItem("MyMaxPage", fleetCardVM.maxPage);
      fleetCardVM.dataCopy = JSON.parse(JSON.stringify(apiResponse.DataModel));
      angular.forEach(fleetCardVM.fleetCardList, function (item) {
        item.showIcon = "icon-Expand";
        item.gridData = {
          CardTypeDesc: item.cardDetails.CardTypeDesc,
          VehicleID: item.cardDetails.VehicleID,
          CardDescription: item.cardDetails.CardDescription,
          CardStatus: item.cardDetails.CardStatus,
          DateLastUsed: item.cardDetails.DateLastUsed.substr(0, 10),
          Department: item.cardDetails.Department,
          LinkedPin: item.cardDetails.LinkedPin,
        };
      });
      $rootScope.dataLoading = false;
    }

    function getFleetCardListError() {
      fleetCardVM.dataCopy = {};
      $rootScope.dataLoading = false;
    }

    function managePinClick(index) {
      fleetCardVM.showManageDriver[index] =
        !fleetCardVM.showManageDriver[index];
      if (fleetCardVM.showManageDriver[index]) {
        fleetCardVM.cardDetails[index].driverDetails = [
          {
            driverId: "D001412",
            firstName: "Philip",
            lastName: "Hart",
            linkStatus: "Available",
          },
          {
            driverId: "D001246",
            firstName: "Gerald",
            lastName: "Harrison",
            linkStatus: "Available",
          },
          {
            driverId: "D001419",
            firstName: "Samuel",
            lastName: "Ryes",
            linkStatus: "Linked",
          },
        ];
      }
    }

    function editDriverDetails(parent, index) {
      fleetCardVM.editDriver = [];
      fleetCardVM.editDriver[parent] = [];
      if (fleetCardVM.isAddNewDriver[parent]) {
        fleetCardVM.isAddNewDriver[parent] = false;
        fleetCardVM.cardDetails[parent].driverDetails.splice(0, 1);
      }
      fleetCardVM.editDriver[parent][index] = true;
    }

    function addNewDriver(driverIndex) {
      if (!fleetCardVM.isAddNewDriver[driverIndex]) {
        var tempObj = {
          driverId: "",
          firstName: "",
          lastName: "",
          linkStatus: "",
        };
        fleetCardVM.cardDetails[driverIndex].driverDetails.splice(
          0,
          0,
          tempObj
        );
        fleetCardVM.isAddNewDriver[driverIndex] = true;
        fleetCardVM.editDriver = [];
        fleetCardVM.editDriver[driverIndex] = [];
        fleetCardVM.editDriver[driverIndex][0] = true;
      }
    }

    function saveDriverDetails(index) {}

    function cancelAddDriver(driverIndex, index) {
      fleetCardVM.editDriver = [];
      fleetCardVM.editDriver[driverIndex] = [];
      if (fleetCardVM.isAddNewDriver[driverIndex]) {
        fleetCardVM.isAddNewDriver[driverIndex] = false;
        fleetCardVM.cardDetails[driverIndex].driverDetails.splice(0, 1);
      }
      fleetCardVM.editDriver[driverIndex][index] = false;
    }

    function deleteDriverDetails(parent, index) {
      deleteDriverSuccess(parent, index);
    }

    function deleteDriverSuccess(parent, index) {
      fleetCardVM.cardDetails[parent].driverDetails.splice(index, 1);
    }

    function deleteDriverFailure() {}
    /*To get the Time profile list*/
    function manageTimeProfile(index) {
      fleetCardVM.showTimeProfile[index] = !fleetCardVM.showTimeProfile[index];
      if (fleetCardVM.showTimeProfile[index]) {
        fleetCardVM.timeProfileDetails[index] = [
          {
            profileName: "D001412",
            profileDesc: "Philip",
          },
          {
            profileName: "D001246",
            profileDesc: "Gerald",
          },
          {
            profileName: "D001419",
            profileDesc: "Samuel",
          },
        ];
      }
    }
    /*To edit time profile*/
    function editProfileDetails(parent, index) {
      fleetCardVM.editProfile = [];
      fleetCardVM.editProfile[parent] = [];
      if (fleetCardVM.isAddNewProfile[parent]) {
        fleetCardVM.isAddNewProfile[parent] = false;
        fleetCardVM.timeProfileDetails[parent].splice(0, 1);
      }
      fleetCardVM.editProfile[parent][index] = true;
    }
    /*To add new time profile*/
    function addNewProfile(profileIndex) {
      if (!fleetCardVM.isAddNewProfile[profileIndex]) {
        var profileObj = {
          profileName: "",
          profileDesc: "",
        };
        fleetCardVM.timeProfileDetails[profileIndex].splice(0, 0, profileObj);
        fleetCardVM.isAddNewProfile[profileIndex] = true;
        fleetCardVM.editProfile = [];
        fleetCardVM.editProfile[profileIndex] = [];
        fleetCardVM.editProfile[profileIndex][0] = true;
      }
    }
    /*Save new Time Profile*/
    function saveNewProfile(profileIndex) {
      //save api call
    }
    /*cancel new Time profile*/
    function cancelAddProfile(parent, index) {
      fleetCardVM.editProfile = [];
      fleetCardVM.editProfile[parent] = [];
      if (fleetCardVM.isAddNewProfile[parent]) {
        fleetCardVM.isAddNewProfile[parent] = false;
        fleetCardVM.timeProfileDetails[parent].splice(0, 1);
      }
      fleetCardVM.editProfile[parent][index] = false;
    }
    /*delete Time profile*/
    function deleteProfileDetails(parent, index) {
      //delete api call
    }

    /** EDIT : Row Click */
    function showCardDetails(cardDetails, index) {
      fleetCardVM.showNewField = false;
      fleetCardVM.submitClicked = false;
      fleetCardVM.disableReset = false;
      fleetCardVM.newDepartment = "";
      if (cardDetails.cardRestrictions.ResetType) {
        if (
          cardDetails.cardRestrictions.ResetType === "Daily" ||
          cardDetails.cardRestrictions.ResetType === "End of Month"
        ) {
          fleetCardVM.disableReset = true;
        }
      }
      onResetChange(cardDetails.cardRestrictions.ResetType);
      angular.forEach(fleetCardVM.fleetCardList, function (item, key) {
        if (key === index) {
          if (item.showIcon === "icon-Expand") {
            item.showIcon = "icon-Condense";
            // debugger;
            $rootScope.dataLoading = true;
            getCardRestrictionDropdownList(item.cardDetails.NetworkCode);

            fleetCardVM.currentIndex = index;
            fleetCardVM.cardDetails[index] = item.cardDetails;
            fleetCardVM.restrictionDetails[index] = item.cardRestrictions;
            fleetCardVM.restrictionDetails[index].IsDefaultAddress = "true";
            fleetCardVM.restrictionDetails[index].ShippingCarrier = "usps";

            /** NEW Timepicker */
            if (
              item.cardRestrictions.StartTimeA !== null &&
              item.cardRestrictions.StartTimeA !== ""
            ) {
              fleetCardVM.restrictionDetails[index].StartTimeA =
                utils.displayTime(item.cardRestrictions.StartTimeA);
            }
            if (
              item.cardRestrictions.EndTimeA !== null &&
              item.cardRestrictions.EndTimeA !== ""
            ) {
              fleetCardVM.restrictionDetails[index].EndTimeA =
                utils.displayTime(item.cardRestrictions.EndTimeA);
            }

            toggleFleetTemplate(cardDetails.cardDetails.CardTypeDesc, index); // diff templates

            fleetCardVM.restrictionDetails[index].emailChecked = false;
            if(fleetCardVM.cardDetails[index].LinkedPin!=null)
                fleetCardVM.currentLinkedPin = JSON.parse(JSON.stringify(fleetCardVM.cardDetails[index].LinkedPin));
            fleetCardVM.currentVehicleID = JSON.parse(
              JSON.stringify(fleetCardVM.cardDetails[index].VehicleID)
            );
            /** For State Restriction, code to description convertion */
            if (fleetCardVM.restrictionDetails[index].StateRestrictions) {
              fleetCardVM.restrictionDetails[index].StateRestrictions =
                fleetCardVM.restrictionDetails[index].StateRestrictions.replace(
                  /,\s*$/,
                  ""
                );
              var stateRestrictionsArray =
                fleetCardVM.restrictionDetails[index].StateRestrictions.split(
                  ","
                );
              var newStateRestrictionsArray = [];
              angular.forEach(stateRestrictionsArray, function (listItem) {
                var selectedStateRestriction = $filter("filter")(
                  fleetCardVM.dropDownData.stateRestrictions,
                  { code: listItem },
                  true
                );
                if (selectedStateRestriction.length > 0) {
                  newStateRestrictionsArray.push(
                    selectedStateRestriction[0].description
                  );
                }
              });
              fleetCardVM.restrictionDetails[index].StateRestrictions =
                newStateRestrictionsArray.join(",");
              fleetCardVM.stateRestriction[index] =
                fleetCardVM.restrictionDetails[index].StateRestrictions;
            }
            /** */
            /** For Table Row Collapse */
            $rootScope.dataLoading = false;
            $(".collapse").on("show.bs.collapse", function () {
              $(".collapse.in").each(function () {
                $(this).collapse("hide");
              });
            });
            /** */
          } else {
            item.showIcon = "icon-Expand";
          }
        } else {
          /** For Table Row Collapse */
          item.showIcon = "icon-Expand";
          /** */
        }
      });
    }

    function closeClick(item, index) {
      /** For Table Row Collapse */
      item.showIcon = "icon-Expand";
      /** */
    }

    function changeOdometer(resDet) {
      if (resDet.ValidateOdometerMileageYN !== "Yes") {
        resDet.ValidateOdometerMileage = 0;
        resDet.ValidateOdometerMileageLimitHS = "";
      }
    }

    function onNetworkChange() {
      fleetCardVM.searchParams.cardTypeArray = null;
      if (fleetCardVM.searchParams.networkItem) {
        angular.forEach(fleetCardVM.searchParams.networkItem, function (item) {
          if (item.isChecked) {
            item.isChecked = false;
          }
        });
      }
      angular.forEach(fleetCardVM.dropDownData.network, function (item) {
        if (item.description === fleetCardVM.searchParams.network) {
          fleetCardVM.searchParams.networkItem = item.cardType;
          fleetCardVM.networkCode = item.code;
          fleetCardVM.searchParams.cardTypeSelected = null;
        }
      });
    }

    function applyDropdown(item) {
      fleetCardVM.showNetworkPopup = false;
      fleetCardVM.showStatusPopup = false;
      fleetCardVM.showCardtypePopup = false;
      if (item === "status") {
        angular.forEach(fleetCardVM.dropDownData.cardStatus, function (item) {
          if (item.isChecked) {
            fleetCardVM.searchParams.CardStatus = item.name;
          }
        });
      }
      if (item === "cardType") {
        fleetCardVM.searchParams.cardTypeArray = [];
        fleetCardVM.searchParams.cardTypeSelected = null;
        angular.forEach(fleetCardVM.searchParams.networkItem, function (item) {
          if (item.isChecked) {
            fleetCardVM.searchParams.cardTypeSelected = item.description;
            fleetCardVM.searchParams.CardType = item.code;
            fleetCardVM.searchParams.cardTypeArray.push(item.code);
          }
        });
      }
    }

    function toggleFleetTemplate(cardType, index) {
      // debugger;
      var templateURL;
      switch (cardType) {
        case "PPSI DIRECT ID":
          fleetCardVM.dropDownData.reset = [
            { code: "D", Description: "Daily" },
            { code: "W", Description: "Weekly" },
            { code: "M", Description: "Monthly" },
          ];
          templateURL = "prideAdvantageDirectIDTemplate";
          break;
        case "PPSI POOLED VEHICLE":
          fleetCardVM.dropDownData.reset = [
            { code: "D", Description: "Daily" },
            { code: "W", Description: "Weekly" },
            { code: "M", Description: "Monthly" },
          ];
          templateURL = "prideAdvantagePooledVehicleCardTemplate";
          break;
        case "CFN FLEETWIDE VEHICLE":
          templateURL = "fleetWideVehicleCardTemplate";
          break;
        case "CFN SINGLE":
          fleetCardVM.dropDownData.resetDay = [
            "Daily",
            "Day of Week",
            "'Day of Month",
            "End of Month",
          ];
          templateURL = "fleetWideSingleCardTemplate";
          break;
        case "CF CARD + VEHICLE + PIN":
          templateURL = "CFTemplate";
          break;
        case "VOYAGER SECURE":
          templateURL = "voyagerSecureTemplate";
          break;
        case "VOYAGER PLUS":
          templateURL = "voyagerPlusTemplate";
          break;
        case "VOYAGER CARD UNTIED":
          templateURL = "classicUntiedCardTemplate";
          break;
        case "VOYAGER CARD + DRIVER":
          templateURL = "classicDriverTiedCardTemplate";
          break;
        case "VOYAGER 1-8 CARD + VEHICLE":
          templateURL = "1-8VehicleTiedCardTemplate";
          break;
        case "VOYAGER 1-8 CARD UNTIED":
          templateURL = "1-8UntiedCardTemplate";
          break;
        case "VOYAGER 1-8 CARD + DRIVER":
          templateURL = "1-8DriverTiedCardTemplate";
          break;
        case "VOYAGER CARD + VEHICLE":
          templateURL = "classicVehicleTiedCardTemplate";
          break;
        case "SINGLE CARD":
          fleetCardVM.dropDownData.miscEntry = [
            "Test1",
            "Test2",
            "Test3",
            "Test4",
          ];
          templateURL = "NFTemplate";
          break;
        default:
          templateURL = "";
          break;
      }
      fleetCardVM.showAccordianView[index] =
        "app/modules/cardManagement/fleetCardManagement/cardDetails/" +
        templateURL +
        ".html";
    }

    $rootScope.$on("hidePopup", function () {
      fleetCardVM.showNetworkPopup = false;
      fleetCardVM.showStatusPopup = false;
      fleetCardVM.showCardtypePopup = false;
      fleetCardVM.showStatePopup = [];
      $scope.$apply();
    });

    /*Advance search- toggle the search template*/
    function toggleSearch() {
      fleetCardVM.showAdvanceSearch = !fleetCardVM.showAdvanceSearch;
      // fleetCardVM.searchParams.searchCardNo = '';
      if (fleetCardVM.showAdvanceSearch) {
        fleetCardVM.showCardSearchButton = false;
      } else {
        fleetCardVM.searchParams.VehicleID = "";
        fleetCardVM.searchParams.network = "";
        fleetCardVM.searchParams.ViewDetailsOfLinkedDriversOnly = "";
        fleetCardVM.searchParams.Department = "";
        fleetCardVM.searchParams.networkItem = [];
        fleetCardVM.searchParams.cardTypeSelected = "";
        fleetCardVM.searchParams.LinkedPin = null;
        fleetCardVM.showCardSearchButton = true;
        fleetCardVM.networkCode = null;
        fleetCardVM.searchParams.cardTypeArray = null;
        if (fleetCardVM.dropDownData.network) {
          angular.forEach(
            fleetCardVM.dropDownData.network,
            function (dropdownItem) {
              angular.forEach(dropdownItem.cardType, function (item) {
                if (item.isChecked) {
                  item.isChecked = false;
                }
              });
            }
          );
        }
      }
    }
    /*To get all card suggestion list for smart search*/
    function getAllCards(searchKey) {
      return cardManagementService
        .getFleetSmartSearchList(searchKey)
        .then(getFleetSmartSearchListSuccess, getFleetSmartSearchListError);
    }
    /*success callback of getAllCards*/
    function getFleetSmartSearchListSuccess(apiResponse) {
      var results = [];
      angular.forEach(apiResponse.invoiceNumbers, function (item) {
        var invoices = item;
        results.push({
          name: invoices,
        });
      });
      $rootScope.dataLoading = false;
      return results;
    }
    /*error callback of getAllCards*/
    function getFleetSmartSearchListError() {}

    /*search using card number*/
    function cardNumberSearch() {
      if (
        fleetCardVM.searchParams.CardDescription &&
        !utils.isValidAlphaNumeric(fleetCardVM.searchParams.CardDescription)
      ) {
        toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
        toastr.error(toasterMsg);
      } else {
        clearSearchParams();
        fleetCardVM.currentPage = 1;
        getFleetCardList();
      }
    }
    /*To sort the card list*/
    function orderByColumn(column, icon) {
      fleetCardVM.searchParams.sortingParameter = column;
      angular.forEach(fleetCardVM.columnHeaders, function (headerItem) {
        if (headerItem.sortParam === column) {
          if (icon === "ic-filterNot" || icon === "ic-filterDesc") {
            headerItem.icon = "ic-filter";
            fleetCardVM.searchParams.sortingDirection = "asc";
          } else {
            headerItem.icon = "ic-filterDesc";
            fleetCardVM.searchParams.sortingDirection = "desc";
          }
        } else {
          headerItem.icon = "ic-filterNot";
        }
      });
      getFleetCardList();
    }
    /*pagination click*/
    function paginationClick(button) {
      var searchRequired = false;
      switch (button) {
        case "first":
          if (fleetCardVM.firstPageItem > 1) {
            $window.scrollTo(0, 0);
            fleetCardVM.firstPageItem = 1;
            fleetCardVM.secondPageItem = 2;
            fleetCardVM.currentPage = 1;
            searchRequired = true;
          }
          break;
        case "previous":
          if (fleetCardVM.firstPageItem > 1) {
            $window.scrollTo(0, 0);
            fleetCardVM.firstPageItem--;
            fleetCardVM.secondPageItem--;
            fleetCardVM.currentPage--;
            searchRequired = true;
          } else if (fleetCardVM.firstPageItem < fleetCardVM.currentPage) {
            $window.scrollTo(0, 0);
            fleetCardVM.currentPage--;
            searchRequired = true;
          } else {
            //sonar issue fix
          }
          break;
        case "next":
          if (fleetCardVM.secondPageItem < fleetCardVM.maxPage) {
            $window.scrollTo(0, 0);
            fleetCardVM.firstPageItem++;
            fleetCardVM.secondPageItem++;
            fleetCardVM.currentPage++;
            searchRequired = true;
          } else if (fleetCardVM.currentPage < fleetCardVM.secondPageItem) {
            $window.scrollTo(0, 0);
            fleetCardVM.currentPage++;
            searchRequired = true;
          } else {
            //sonar issue fix
          }
          break;
        case "last":
          if (
            fleetCardVM.secondPageItem < fleetCardVM.maxPage ||
            fleetCardVM.currentPage < fleetCardVM.secondPageItem
          ) {
            $window.scrollTo(0, 0);
            fleetCardVM.firstPageItem = fleetCardVM.maxPage - 1;
            fleetCardVM.secondPageItem = fleetCardVM.maxPage;
            fleetCardVM.currentPage = fleetCardVM.maxPage;
            searchRequired = true;
          }
          break;
        default:
          $window.scrollTo(0, 0);
          fleetCardVM.currentPage = button;
          searchRequired = true;
          break;
      }
      if (searchRequired) {
        getFleetCardList();
      }
    }
    /*To clear the search parameters in advance search*/
    function clearSearchParams() {
      fleetCardVM.searchParams.VehicleID = "";
      fleetCardVM.searchParams.cardNumFrom = "";
      fleetCardVM.searchParams.cardNumTo = "";
      fleetCardVM.searchParams.Department =
        fleetCardVM.dropDownData.department[0].name;
      fleetCardVM.searchParams.network = "";
      fleetCardVM.searchParams.ViewDetailsOfLinkedDriversOnly = false;
    }

    function editCardDetails(cardDetails, index) {
      // debugger;
      var cardType = cardDetails.cardDetails.CardTypeDesc;
      var cardData = fleetCardVM.cardDetails[index];
      var restrictData = fleetCardVM.restrictionDetails[index];
      //Jinran mod 5/23/23: force 'NA' value if ResetType is empty
      if (restrictData.ResetType == "") restrictData.ResetType = "NA";

      fleetCardVM.submitClicked = true;
      if (cardData.Department === "Add New" || fleetCardVM.newDepartment) {
        // if(fleetCardVM.newDepartment){
        cardData.Department = fleetCardVM.newDepartment;
      }
      // }
      switch (cardType) {
        case "PPSI DIRECT ID":
          validatePrideAdvantageDirectId(cardData, restrictData, index);
          break;
        case "PPSI POOLED VEHICLE":
          validatePrideAdvantagePooledVehicleCard(
            cardData,
            restrictData,
            index
          );
          break;
        case "CFN FLEETWIDE VEHICLE":
          validateFleetwideVehicleCard(cardData, restrictData, index);
          break;
        case "CFN SINGLE":
          validateFleetwideSingleCard(cardData, restrictData, index);
          break;
        case "CF CARD + VEHICLE + PIN":
          validateCF(cardData, restrictData, index);
          break;
        case "VOYAGER SECURE":
          validateVoyagerSecure(cardData, restrictData, index);
          break;
        case "VOYAGER PLUS":
          validateVoyagerPlus(cardData, restrictData, index);
          break;
        case "VOYAGER CARD UNTIED":
          validateClassicUnitedCard(cardData, restrictData, index);
          break;
        case "VOYAGER CARD + DRIVER":
          validateClassicDriverTiedCard(cardData, restrictData, index);
          break;
        case "VOYAGER 1-8 CARD + VEHICLE":
          validateVehicleTiedToCard(cardData, restrictData, index);
          break;
        case "VOYAGER 1-8 CARD UNTIED":
          validateUnitedCard(cardData, restrictData, index);
          break;
        case "VOYAGER 1-8 CARD + DRIVER":
          validateDriverTiedToCard(cardData, restrictData, index);
          break;
        case "VOYAGER CARD + VEHICLE":
          validateClassicVehicleTiedCard(cardData, restrictData, index);
          break;
        case "SINGLE CARD":
          validateNfSingleCard(cardData, restrictData, index);
          break;
        default:
          toasterMsg = toastrMessage.cardControlErrorMessage.cannotEdit;
          toastr.error(toasterMsg);          
          break;
      }
    }

    function validatePrideAdvantageDirectId(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        cardData.MiscEntry &&
        cardData.PinType &&
        restrictData.TimeProfile &&
        restrictData.GallonLimit &&
        restrictData.ProductAuthCode &&
        restrictData.PerFillLimit &&
        restrictData.ResetType
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validDriverPin;
          toastr.error(toasterMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut1 &&
          restrictData.ZipCodeLockOut1.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut2 &&
          restrictData.ZipCodeLockOut2.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut3 &&
          restrictData.ZipCodeLockOut3.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut4 &&
          restrictData.ZipCodeLockOut4.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut5 &&
          restrictData.ZipCodeLockOut5.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else {
          //api call
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          if (restrictData.ResetType) {
            var selectedResetType = $filter("filter")(
              fleetCardVM.dropDownData.reset,
              { code: restrictData.ResetType },
              true
            );
            if (selectedResetType.length > 0) {
              newRestrictData.ResetType = selectedResetType[0].Description;
            }
          }
          if (restrictData.ProductAuthCode) {
            var selectedProducts = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.products,
              { listValue: restrictData.ProductAuthCode },
              true
            );
            if (selectedProducts) {
              if (selectedProducts.length > 0) {
                newRestrictData.ProductAuthDesc =
                  selectedProducts[0].listDescription;
                newRestrictData.Products = selectedProducts[0].listDescription;
              }
            }
          }
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validatePrideAdvantagePooledVehicleCard(
      cardData,
      restrictData,
      index
    ) {
      if (
        fleetCardVM.cardDetailsForm[index].$valid &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut1 &&
          restrictData.ZipCodeLockOut1.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut2 &&
          restrictData.ZipCodeLockOut2.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut3 &&
          restrictData.ZipCodeLockOut3.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut4 &&
          restrictData.ZipCodeLockOut4.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut5 &&
          restrictData.ZipCodeLockOut5.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else {
          //api call
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          if (restrictData.ResetType) {
            var selectedResetType = $filter("filter")(
              fleetCardVM.dropDownData.reset,
              { code: restrictData.ResetType },
              true
            );
            if (selectedResetType.length > 0) {
              newRestrictData.ResetType = selectedResetType[0].Description;
            }
          }
          if (restrictData.ProductAuthCode) {
            var selectedProducts = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.products,
              { listValue: restrictData.ProductAuthCode },
              true
            );
            if (selectedProducts) {
              if (selectedProducts.length > 0) {
                newRestrictData.ProductAuthDesc =
                  selectedProducts[0].listDescription;
                newRestrictData.Products = selectedProducts[0].listDescription;
              }
            }
          }
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateFleetwideVehicleCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.PerFillLimit &&
        restrictData.GallonLimit &&
        restrictData.ProductAuthDesc &&
        restrictData.RestrictMultiFueling &&
        restrictData.ResetType
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          restrictData.PerFillLimit < 1 &&
          restrictData.PerFillLimit > 9999
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.perGallonLimit;
          toastr.error(toasterMsg);
        } else if (
          restrictData.notiffyGallonLimitCheck &&
          !restrictData.NotifyLimit
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyEnterValue;
          toastr.error(toasterMsg);
        } else if (
          restrictData.notiffyGallonLimitWeekendCheck &&
          !restrictData.notifyWeekendGallonLimit
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyWeekend;
          toastr.error(toasterMsg);
        } else if (
          restrictData.emailChecked &&
          restrictData.NotifyLimitEmail &&
          !utils.isValidEmail(restrictData.NotifyLimitEmail)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validEmail;
          toastr.error(toasterMsg);
        } else if (
          restrictData.emailChecked &&
          !restrictData.NotifyLimitEmail
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validEmail;
          toastr.error(toasterMsg);
        } else if (
          (restrictData.NotifyLimit && parseInt(restrictData.NotifyLimit)) >
          (restrictData.GallonLimit && parseInt(restrictData.GallonLimit))
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyLessGLimit;
          toastr.error(toasterMsg);
        } else if (
          (restrictData.notifyWeekendGallonLimit &&
            parseInt(restrictData.notifyWeekendGallonLimit)) >
          (restrictData.GallonLimitWeekEnd &&
            parseInt(restrictData.GallonLimitWeekEnd))
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyLessGLWeekend;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ResetType === "Day of Week" &&
          (!restrictData.ResetTypeValue ||
            restrictData.ResetTypeValue < 1 ||
            restrictData.ResetTypeValue > 7)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.resetValue17;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ResetType === "Day of Month" &&
          (!restrictData.ResetTypeValue ||
            restrictData.ResetTypeValue < 1 ||
            restrictData.ResetTypeValue > 31)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.resetValue131;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut1 &&
          restrictData.ZipCodeLockOut1.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut2 &&
          restrictData.ZipCodeLockOut2.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut3 &&
          restrictData.ZipCodeLockOut3.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut4 &&
          restrictData.ZipCodeLockOut4.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut5 &&
          restrictData.ZipCodeLockOut5.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else {
          //api call
          if (restrictData.ProductAuthCode) {
            delete restrictData.ProductAuthCode;
          }
          if (restrictData.emailChecked === false) {
            if (
              restrictData.notiffyGallonLimitCheck ||
              restrictData.notiffyGallonLimitWeekendCheck
            ) {
              restrictData.NotifyLimitEmail = localStorage.userEmail;
            } else {
              restrictData.emailChecked = null;
            }
          }

          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            restrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          saveCardDetails(index, cardData, restrictData);
        }
      } else {
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateFleetwideSingleCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        cardData.CardStatus &&
        restrictData.PerFillLimit &&
        restrictData.GallonLimit &&
        cardData.LinkedPin &&
        restrictData.TimeProfile &&
        restrictData.RestrictMultiFueling &&
        restrictData.ProductAuthDesc &&
        restrictData.ResetType
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (cardData.LinkedPin.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          restrictData.PerFillLimit < 1 &&
          restrictData.PerFillLimit > 9999
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.perGallonLimit;
          toastr.error(toasterMsg);
        } else if (
          restrictData.NotifyGallonLimitCheck &&
          !restrictData.NotifyLimit
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyEnterValue;
          toastr.error(toasterMsg);
        } else if (
          restrictData.NotifyGallonLimitWeekendCheck &&
          !restrictData.NotifyLimitWeekEnd
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyWeekend;
          toastr.error(toasterMsg);
        } else if (
          restrictData.emailChecked &&
          restrictData.NotifyLimitEmail &&
          !utils.isValidEmail(restrictData.NotifyLimitEmail)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validEmail;
          toastr.error(toasterMsg);
        } else if (
          restrictData.emailChecked &&
          !restrictData.NotifyLimitEmail
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validEmail;
          toastr.error(toasterMsg);
        } else if (
          (restrictData.NotifyLimit && parseInt(restrictData.NotifyLimit)) >
          (restrictData.GallonLimit && parseInt(restrictData.GallonLimit))
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyLessGLimit;
          toastr.error(toasterMsg);
        } else if (
          (restrictData.NotifyLimitWeekEnd &&
            parseInt(restrictData.NotifyLimitWeekEnd)) >
          (restrictData.GallonLimitWeekEnd &&
            parseInt(restrictData.GallonLimitWeekEnd))
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyLessGLWeekend;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ResetType === "Day of Week" &&
          (!restrictData.ResetTypeValue ||
            restrictData.ResetTypeValue < 1 ||
            restrictData.ResetTypeValue > 7)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.resetValue17;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ResetType === "Day of Month" &&
          (!restrictData.ResetTypeValue ||
            restrictData.ResetTypeValue < 1 ||
            restrictData.ResetTypeValue > 31)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.resetValue131;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut1 &&
          restrictData.ZipCodeLockOut1.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut2 &&
          restrictData.ZipCodeLockOut2.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut3 &&
          restrictData.ZipCodeLockOut3.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut4 &&
          restrictData.ZipCodeLockOut4.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut5 &&
          restrictData.ZipCodeLockOut5.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else {
          //api call
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          if (restrictData.ProductAuthCode) {
            delete restrictData.ProductAuthCode;
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (!restrictData.emailChecked) {
            if (
              restrictData.NotifyGallonLimitCheck ||
              restrictData.NotifyGallonLimitWeekendCheck
            ) {
              newRestrictData.NotifyLimitEmail = localStorage.userEmail;
            } else {
              newRestrictData.emailChecked = null;
            }
          }
          saveCardDetails(index, cardData, newRestrictData);
        }
      } else {
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateCF(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.LimitCode &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validPinMax4Digits;
          toastr.error(toasterMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(toasterMsg);
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.validStartTime
              );
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error(toastrMessage.cardControlErrorMessage.validEndTime);
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.endTimeGr8StartTime
              );
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          if (restrictData.LimitCode) {
            var selectedLimitCode = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
              { listValue: restrictData.LimitCode },
              true
            );
            if (selectedLimitCode.length > 0) {
              newRestrictData.LimitCode = selectedLimitCode[0].listDescription;
            }
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (restrictData.ProdCode) {
            var selectedProduct = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.allowedProducts,
              { listValue: restrictData.ProdCode },
              true
            );
            if (selectedProduct) {
              if (selectedProduct.length > 0) {
                newRestrictData.Products = selectedProduct[0].listDescription;
              }
            }
          }
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(toasterMsg);
            return;
          }
        }
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateVoyagerSecure(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.TransactionsPerDay &&
        restrictData.LimitCode &&
        cardData.PinType &&
        restrictData.DollarsPerCycle &&
        restrictData.ProdCode &&
        restrictData.RestrictionCode &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerDay < 1 ||
          restrictData.TransactionsPerDay > 99
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerDay199;
          toastr.error(toasterMsg);
        } else if (restrictData.DollarsPerCycle < 100) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerCycle;
          toastr.error(toasterMsg);
        } else if (cardData.VIN && !utils.isValidAlphaNumeric(cardData.VIN)) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharVin;
          toastr.error(toasterMsg);
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.validStartTime
              );
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error(toastrMessage.cardControlErrorMessage.validEndTime);
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.endTimeGr8StartTime
              );
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          if (restrictData.LimitCode) {
            var selectedGallonsPerTransaction = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
              { listValue: restrictData.LimitCode },
              true
            );
            if (selectedGallonsPerTransaction.length > 0) {
              newRestrictData.LimitCode =
                selectedGallonsPerTransaction[0].listDescription;
            }
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (restrictData.RestrictionCode) {
            var selectedRestrictionCode = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.restrictionCodes,
              { listValue: restrictData.RestrictionCode },
              true
            );
            if (selectedRestrictionCode.length > 0) {
              newRestrictData.RestrictionCode =
                selectedRestrictionCode[0].listDescription;
            }
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          if (restrictData.ProdCode) {
            var selectedProduct = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.allowedProducts,
              { listValue: restrictData.ProdCode },
              true
            );
            if (selectedProduct) {
              if (selectedProduct.length > 0) {
                newRestrictData.Products = selectedProduct[0].listDescription;
              }
            }
          }

          if (cardData.LicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.LicenseState },
              true
            );
            if (selectedLicenseState) {
              if (selectedLicenseState.length > 0) {
                newCardData.LicenseState = selectedLicenseState[0].description;
              }
            }
          }
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(toasterMsg);
            return;
          }
        }
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateVoyagerPlus(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.TransactionsPerDay &&
        restrictData.LimitCode &&
        restrictData.DollarsPerDay &&
        cardData.PinType &&
        restrictData.DollarsPerCycle &&
        restrictData.RestrictionCode &&
        restrictData.ProdCode &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerDay199;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerCycle;
          toastr.error(toasterMsg);
        } else if (cardData.VIN && !utils.isValidAlphaNumeric(cardData.VIN)) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharVin;
          toastr.error(toasterMsg);
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validDriverPin;
          toastr.error(toasterMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(toasterMsg);
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.validStartTime
              );
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error(toastrMessage.cardControlErrorMessage.validEndTime);
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.endTimeGr8StartTime
              );
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (restrictData.LimitCode) {
            var selectedGallonsPerTransaction = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
              { listValue: restrictData.LimitCode },
              true
            );
            if (selectedGallonsPerTransaction.length > 0) {
              newRestrictData.LimitCode =
                selectedGallonsPerTransaction[0].listDescription;
            }
          }
          if (restrictData.RestrictionCode) {
            var selectedRestrictionCode = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.restrictionCodes,
              { listValue: restrictData.RestrictionCode },
              true
            );
            if (selectedRestrictionCode.length > 0) {
              newRestrictData.RestrictionCode =
                selectedRestrictionCode[0].listDescription;
            }
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (restrictData.ProdCode) {
            var selectedProduct = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.allowedProducts,
              { listValue: restrictData.ProdCode },
              true
            );
            if (selectedProduct) {
              if (selectedProduct.length > 0) {
                newRestrictData.Products = selectedProduct[0].listDescription;
              }
            }
          }
          if (cardData.LicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.LicenseState },
              true
            );
            if (selectedLicenseState) {
              if (selectedLicenseState.length > 0) {
                newCardData.LicenseState = selectedLicenseState[0].description;
              }
            }
          }
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(toasterMsg);
            return;
          }
        }
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateClassicUnitedCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        restrictData.DollarsPerCycle &&
        restrictData.RestrictionCode &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          // toastr.error(
          //   "Special symbols/characters are not allowed in New Department"
          // );
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerDay199;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerCycle;
          toastr.error(toasterMsg);
        } else if (
          restrictData.authCode &&
          !utils.isValidAlphaNumeric(restrictData.authCode)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharAuth;
          toastr.error(toasterMsg);
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validDriverPin;
          toastr.error(toasterMsg);
        } else if (cardData.PinStatus === "N" && !cardData.PinType) {
          toasterMsg = toastrMessage.cardControlErrorMessage.allDriver;
          toastr.error(toasterMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(toasterMsg);
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.validStartTime
              );
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error(toastrMessage.cardControlErrorMessage.validEndTime);
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.endTimeGr8StartTime
              );
              return;
            }
          }
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode.length > 0) {
            newRestrictData.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(toasterMsg);
            return;
          }
        }
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateClassicDriverTiedCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        restrictData.DollarsPerCycle &&
        restrictData.RestrictionCode &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerDay199;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerCycle;
          toastr.error(toasterMsg);
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validDriverPin;
          toastr.error(toasterMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(toasterMsg);
        } else if (cardData.Driver && cardData.Driver.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.minDriverId;
          toastr.error(toasterMsg);          
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.validStartTime
              );
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error(toastrMessage.cardControlErrorMessage.validEndTime);
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.endTimeGr8StartTime
              );
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode.length > 0) {
            newRestrictData.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (cardData.LicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.LicenseState },
              true
            );
            if (selectedLicenseState.length > 0) {
              newCardData.LicenseState = selectedLicenseState[0].description;
            }
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(toasterMsg);
            return;
          }
        }
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateVehicleTiedToCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        cardData.PinType &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        restrictData.RestrictionCode &&
        restrictData.DollarsPerCycle &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          cardData.VehicleID &&
          !utils.isValidAlphaNumeric(cardData.VehicleID)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharVclDesc;
          toastr.error(toasterMsg);
        } else if (cardData.VIN && !utils.isValidAlphaNumeric(cardData.VIN)) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharVin;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerDay199;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerCycle;
          toastr.error(toasterMsg);
        } else if (cardData.PinType === "SINGLE" && !fleetCardVM.currentLinkedPin) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validDriverPin;
          toastr.error(toasterMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(toasterMsg);
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.validStartTime
              );
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error(toastrMessage.cardControlErrorMessage.validEndTime);
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.endTimeGr8StartTime
              );
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode.length > 0) {
            newRestrictData.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
          if (restrictData.StateRestrictions) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (cardData.LicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.LicenseState },
              true
            );
            if (selectedLicenseState.length > 0) {
              newCardData.LicenseState = selectedLicenseState[0].description;
            }
          }
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(toasterMsg);
            return;
          }
        }
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateUnitedCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        fleetCardVM.restrictionsForm[index].$valid &&
        restrictData.RestrictionCode &&
        restrictData.DollarsPerCycle
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerDay199;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerCycle;
          toastr.error(toasterMsg);
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validDriverPin;
          toastr.error(toasterMsg);
        } else if (cardData.PinStatus === "N" && !cardData.PinType) {
          toasterMsg = toastrMessage.cardControlErrorMessage.allDriver;
          toastr.error(toasterMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(toasterMsg);
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.validStartTime
              );
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error(toastrMessage.cardControlErrorMessage.validEndTime);
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.endTimeGr8StartTime
              );
              return;
            }
          }
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode.length > 0) {
            newRestrictData.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(toasterMsg);
            return;
          }
        }
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateDriverTiedToCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        restrictData.RestrictionCode &&
        restrictData.DollarsPerCycle &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.DriverName &&
          !utils.isValidAlphaNumeric(cardData.DriverName)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharName;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg =
            toastrMessage.cardControlErrorMessage.noSpecialCharDeptDesc;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerDay199;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerCycle;
          toastr.error(toasterMsg);
        } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validDriverPin;
          toastr.error(toasterMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(toasterMsg);
        } else if (cardData.Driver && cardData.Driver.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.minDriverId;
          toastr.error(toasterMsg);          
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.validStartTime
              );
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error(toastrMessage.cardControlErrorMessage.validEndTime);
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.endTimeGr8StartTime
              );
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode.length > 0) {
            newRestrictData.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (cardData.DriversLicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.DriversLicenseState },
              true
            );
            if (selectedLicenseState.length > 0) {
              newCardData.DriversLicenseState =
                selectedLicenseState[0].description;
            }
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(toasterMsg);
            return;
          }
        }
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateClassicVehicleTiedCard(cardData, restrictData, index) {
      if (
        cardData.CardDescription &&
        restrictData.RestrictionCode &&
        restrictData.TransactionsPerDay &&
        restrictData.DollarsPerDay &&
        restrictData.DollarsPerCycle &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerDay &&
          restrictData.TransactionsPerDay < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerDay199;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerWeek &&
          restrictData.TransactionsPerWeek < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerMonth &&
          restrictData.TransactionsPerMonth < 1
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.TransactionsPerCycle &&
          (restrictData.TransactionsPerCycle < 1 ||
            restrictData.TransactionsPerCycle > 9999)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerDay < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerWeek &&
          restrictData.DollarsPerWeek < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerMonth &&
          restrictData.DollarsPerMonth < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(toasterMsg);
        } else if (
          restrictData.DollarsPerCycle &&
          restrictData.DollarsPerCycle < 100
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerCycle;
          toastr.error(toasterMsg);
        } else if (cardData.PinType === "SINGLE" && !fleetCardVM.currentLinkedPin) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validDriverPin;
          toastr.error(toasterMsg);
        } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(toasterMsg);
        } else {
          //api call
          if (restrictData.StartTimeA || restrictData.EndTimeA) {
            if (restrictData.StartTimeA === null) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.validStartTime
              );
              return;
            } else if (restrictData.EndTimeA === null) {
              toastr.error(toastrMessage.cardControlErrorMessage.validEndTime);
              return;
            } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
              toastr.error(
                toastrMessage.cardControlErrorMessage.endTimeGr8StartTime
              );
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          var selectedRestrictionCode = $filter("filter")(
            fleetCardVM.dropDownRestrictionData.restrictionCodes,
            { listValue: restrictData.RestrictionCode },
            true
          );
          if (selectedRestrictionCode.length > 0) {
            newRestrictData.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          if (
            restrictData.StartTimeA &&
            typeof restrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.StartTimeA)
            );
          }
          if (
            restrictData.EndTimeA &&
            typeof restrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(restrictData.EndTimeA)
            );
          }
          if (restrictData.PurchaseDays) {
            var selectedPurchaseDays = $filter("filter")(
              fleetCardVM.dropDownData.purchaseDay,
              { code: restrictData.PurchaseDays },
              true
            );
            if (selectedPurchaseDays.length > 0) {
              newRestrictData.PurchaseDays =
                selectedPurchaseDays[0].description;
            }
          }
          if (cardData.LicenseState) {
            var selectedLicenseState = $filter("filter")(
              fleetCardVM.dropDownData.stateRestrictions,
              { code: cardData.LicenseState },
              true
            );
            if (selectedLicenseState.length > 0) {
              newCardData.LicenseState = selectedLicenseState[0].description;
            }
          }
          newCardData.LinkedPin = fleetCardVM.currentLinkedPin;
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        if (
          restrictData.StartTimeA === null ||
          restrictData.EndTimeA === null
        ) {
          if (
            fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
            toastr.error(toasterMsg);
            return;
          }
        }
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function validateNfSingleCard(cardData, restrictData, index) {
      if (cardData.CardDescription) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else {
          //api call
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          if (restrictData.ProductAuthCode) {
            var selectedProducts = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.products,
              { listValue: restrictData.ProductAuthCode },
              true
            );
            if (selectedProducts) {
              if (selectedProducts.length > 0) {
                newRestrictData.ProductAuthDesc =
                  selectedProducts[0].listDescription;
                newRestrictData.Products = selectedProducts[0].listDescription;
              }
            }
          }
          saveCardDetails(index, newCardData, newRestrictData);
        }
      } else {
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
    }

    function saveCardDetails(index, cardData, restrictData) {
      $rootScope.dataLoading = true;
      var oldData = fleetCardVM.dataCopy[index];
      if (oldData.cardRestrictions.RestrictionCode) {
        var selectedRestrictionCode = $filter("filter")(
          fleetCardVM.dropDownRestrictionData.restrictionCodes,
          { listValue: oldData.cardRestrictions.RestrictionCode },
          true
        );
        if (selectedRestrictionCode) {
          if (selectedRestrictionCode.length > 0) {
            oldData.cardRestrictions.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
        }
      }

      if (oldData.cardRestrictions.ResetType) {
        var selectedResetType = $filter("filter")(
          fleetCardVM.dropDownData.reset,
          { code: oldData.cardRestrictions.ResetType },
          true
        );
        if (selectedResetType) {
          if (selectedResetType.length > 0) {
            oldData.cardRestrictions.ResetType =
              selectedResetType[0].Description;
          }
        }
      }
      if (oldData.cardRestrictions.LimitCode) {
        var selectedGallonsPerTransaction = $filter("filter")(
          fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
          { listValue: oldData.cardRestrictions.LimitCode },
          true
        );
        if (selectedGallonsPerTransaction) {
          if (selectedGallonsPerTransaction.length > 0) {
            oldData.cardRestrictions.LimitCode =
              selectedGallonsPerTransaction[0].listDescription;
          }
        }
      }

      if (oldData.cardDetails.LimitCode) {
        var selectedGallonsPerTransaction = $filter("filter")(
          fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
          { listValue: oldData.cardDetails.LimitCode },
          true
        );
        if (selectedGallonsPerTransaction) {
          if (selectedGallonsPerTransaction.length > 0) {
            oldData.cardDetails.LimitCode =
              selectedGallonsPerTransaction[0].listDescription;
          }
        }
      }

      if (oldData.cardDetails.LicenseState) {
        var selectedLicenseState = $filter("filter")(
          fleetCardVM.dropDownData.stateRestrictions,
          { code: oldData.cardDetails.LicenseState },
          true
        );
        if (selectedLicenseState) {
          if (selectedLicenseState.length > 0) {
            oldData.cardDetails.LicenseState =
              selectedLicenseState[0].description;
          }
        }
      }

      if (oldData.cardRestrictions.PurchaseDays) {
        var selectedPurchaseDays = $filter("filter")(
          fleetCardVM.dropDownData.purchaseDay,
          { code: oldData.cardRestrictions.PurchaseDays },
          true
        );
        if (selectedPurchaseDays) {
          if (selectedPurchaseDays.length > 0) {
            oldData.cardRestrictions.PurchaseDays =
              selectedPurchaseDays[0].description;
          }
        }
      }

      var payLoad = {
        AccountNo: localStorage.selectedAccount,
        CardNo: cardData.CardNumber,
        OrgId: localStorage.orgID,
        CardTypeDescription:
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].CardTypeDesc,
        CardTypeCode:
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].CardType,
        Network: cardData.Network,
        NetworkCode: cardData.NetworkCode,
        Model: {
          CardDetails: cardData,
          RestrictionDetails: restrictData,
        },
        DeltaModel: {
          CardDetails: oldData.cardDetails,
          RestrictionDetails: oldData.cardRestrictions,
        },
      };
      cardManagementService
        .editFleetCardDetails(JSON.stringify(payLoad))
        .then(saveFleetCardDetailsSuccess, saveFleetCardDetailsError);      
    }

    function saveFleetCardDetailsSuccess(apiResponse) {
      $rootScope.dataLoading = false;
      if (apiResponse.statusCode === 200) {
        toasterMsg = toastrMessage.successMessage.requestSubmitted;
        toastr.success(toasterMsg);
        // sessionStorage.setItem("CancelReissueResults", toasterMsg);
        getFleetCardList();
      }
    }

    function saveFleetCardDetailsError() {
      $rootScope.dataLoading = false;
      toasterMsg = toastrMessage.cardControlErrorMessage.someError;
      toastr.error(toasterMsg);      
    }

    /*confirmation for locking */
    function changeStatus(status, index) {
      if (status === "A") {
        fleetCardVM.cardDetails[index].CardStatus = "A";
      } else if ((status = "L")) {
        fleetCardVM.cardDetails[index].CardStatus = "L";
      }
    }

    function openModal(id) {
      cardManagementService.Open(id);
    }

    /*confirmation for pin */
    function changePIN(status, index) {
      fleetCardVM.cardDetails[index].PinStatus = "";
      if (fleetCardVM.cardDetails[index].PinType) {
        fleetCardVM.cardDetails[index].PinType = "";
      }
      if (fleetCardVM.cardDetails[index].LinkedPin) {
        fleetCardVM.cardDetails[index].LinkedPin = null;
      }
      if (status === "SINGLE") {
        fleetCardVM.cardDetails[index].PinType = "SINGLE";
      } else if (status === "FLOAT DRIVER") {
        fleetCardVM.cardDetails[index].PinType = "FLOAT DRIVER";
      } else if (status === "FLOAT VEHICLE") {
        fleetCardVM.cardDetails[index].PinType = "FLOAT VEHICLE";
      } else if (status === "N") {
        fleetCardVM.cardDetails[index].PinStatus = "N";
      }
    }

    /*confirmation for driver pin */
    function changeDriverPIN(status, index) {
      fleetCardVM.cardDetails[index].PinType = status;
    }
    /* Function  to append zeros to driver pin*/
    function AppendZeroes(item, index) {
      if (item) {
        if (item.length === 4) {
          fleetCardVM.cardDetails[index].LinkedPin = "00" + item;
        } else if (item.length === 5) {
          fleetCardVM.cardDetails[index].LinkedPin = "0" + item;
        }
      }
    }

    function addZeroes(item, index) {
      if (item) {
        if (item.length === 4) {
          fleetCardVM.cardDetails[index].Driver = "00" + item;
        } else if (item.length === 5) {
          fleetCardVM.cardDetails[index].Driver = "0" + item;
        }
      }
    }
    /*Apply button click on State Restriction popup*/
    function applyStateRestriction(index) {
      if (fleetCardVM.cardDetails[index].Network !== "CFN") {
        toastr.warning(
          "State restrictions entered will not appear as SAVED in the Portal, but the changes will be effective in the Network"
        );
      }
      fleetCardVM.showStatePopup[index] = false;
      fleetCardVM.restrictionDetails[index].StateRestrictions = [];
      angular.forEach(
        fleetCardVM.dropDownData.stateRestrictions,
        function (item) {
          if (item.isChecked) {
            fleetCardVM.restrictionDetails[index].StateRestrictions.push(
              item.description
            );
          }
        }
      );
      fleetCardVM.stateRestriction[index] =
        fleetCardVM.restrictionDetails[index].StateRestrictions.toString();
    }

    function changeDepartment(item) {
      if (item === "Add New") {
        fleetCardVM.showNewField = true;
      } else {
        fleetCardVM.newDepartment = "";
        fleetCardVM.showNewField = false;
      }
    }

    function changeEmailReceipt(item, index) {
      if (item === "Y") {
        fleetCardVM.cardDetails[index].EmailReceiptChecked = "Y";
      } else if (item === "N") {
        fleetCardVM.cardDetails[index].ReceiptEmailID = "";
        fleetCardVM.cardDetails[index].EmailReceiptChecked = "N";
      }
    }
    /*Selects State Restriction from popup*/
    function selectStateRestrictions(index, states) {
      fleetCardVM.showStatePopup[index] = !fleetCardVM.showStatePopup[index];
      if (fleetCardVM.showStatePopup[index]) {
        if (fleetCardVM.dropDownData.stateRestrictions) {
          angular.forEach(
            fleetCardVM.dropDownData.stateRestrictions,
            function (dropdownItem) {
              dropdownItem.isChecked = false;
              fleetCardVM.select = false;
            }
          );
        }
        var stateList = [];
        if (states) {
          stateList = states.split(",");
          angular.forEach(stateList, function (listItem) {
            angular.forEach(
              fleetCardVM.dropDownData.stateRestrictions,
              function (item) {
                if (listItem.indexOf(item.description) === 0) {
                  item.isChecked = true;
                }
              }
            );
          });
        }
      }
    }

    function selectAllStates(item) {
      console.log("item", item);
      // fleetCardVM.select = !fleetCardVM.select;
      if (item) {
        angular.forEach(
          fleetCardVM.dropDownData.stateRestrictions,
          function (item) {
            item.isChecked = true;
          }
        );
      } else {
        angular.forEach(
          fleetCardVM.dropDownData.stateRestrictions,
          function (item) {
            item.isChecked = false;
          }
        );
      }
    }
    /*Adds new zipcode*/
    function addNewZipcode(index) {
      var isNull = false;
      angular.forEach(
        fleetCardVM.restrictionDetails[index].zipCodeLockout,
        function (item) {
          if (!item.name) {
            isNull = true;
          }
        }
      );
      if (isNull) {
        toasterMsg = toastrMessage.cardControlErrorMessage.enterZip;
        toastr.error(toasterMsg);
      } else {
        isNull = false;
        fleetCardVM.restrictionDetails[index].zipCodeLockout.push({
          name: "",
        });
      }
    }

    function printTable() {
      $state.go("root.printCard");
      // window.print();
    }
    /*Checks if there is duplicate zipcode entries*/
    function checkDuplicate(zipcode, parent, index) {
      var isExists = false;
      angular.forEach(
        fleetCardVM.restrictionDetails[parent].zipCodeLockout,
        function (item, key) {
          if (key !== index && zipcode === item.name) {
            isExists = true;
          }
        }
      );
      if (isExists) {
        isExists = false;
        toasterMsg = toastrMessage.cardControlErrorMessage.zipExists;
        toastr.error(toasterMsg);
      }
    }

    function deleteZipcode(zipcode, index) {
      $confirm(
        {
          title: "Delete",
          text: "Do you want to delete this Zipcode from list?",
          ok: "Yes",
          cancel: "No",
        },
        {
          size: "sm",
          backdrop: true,
        }
      ).then(function () {
        angular.forEach(
          fleetCardVM.restrictionDetails[index].zipCodeLockout,
          function (item, key) {
            if (zipcode === item.name) {
              fleetCardVM.restrictionDetails[index].zipCodeLockout.splice(
                key,
                1
              );
            }
          }
        );
      });
    }

    /** VOYAGER CARD + DRIVER , VOYAGER 1-8 CARD + DRIVER*/
    function validatePin(cardNo, network) {
      if (cardNo && cardNo !== fleetCardVM.currentLinkedPin) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
        } else {
          // if (cardNo.length === 4) {
          if (parseInt(cardNo.slice(0, 2)) === 0) {
            toastr.error(toastrMessage.common.voyagerPinValidation);
            fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: network,
          };
          cardManagementService
            .getPinValidation(requestData)
            .then(getPinValidationSuccess, getPinValidationError);
        }
      }
    }

    function getPinValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isExists) {
        fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
        toastr.warning("Pin already exist.");
      }
    }

    function getPinValidationError() {}

    /**  VOYAGER CARD + VEHICLE,
     * VOYAGER CARD UNTIED , VOYAGER 1-8 CARD + VEHICLE , VOYAGER 1-8 CARD UNTIED , VOYAGER SECURE , VOYAGER PLUS
     */
    function validateDriver(cardNo, network) {
      if (cardNo && cardNo !== fleetCardVM.currentLinkedPin) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
        } else {
          // if (cardNo.length === 4) {
          if (parseInt(cardNo.slice(0, 2)) === 0) {
            toastr.error(toastrMessage.common.voyagerPinValidation);
            fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: network,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    /** CFN - SINGLE , */
    function validateCFNDriver(cardNo, network) {
      if (cardNo && cardNo !== fleetCardVM.currentLinkedPin) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.cfnPinValidation);
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
          return;
        }
        if (cardNo.length < 5) {
          toastr.error(toastrMessage.common.cfnPinValidation);
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
        } else {
          if (parseInt(cardNo.slice(0, 1)) === 0) {
            toastr.error(toastrMessage.common.cfnPinValidation);
            fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: network,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function validatePacprideDriver(cardNo, network) {
      if (cardNo && cardNo !== fleetCardVM.currentLinkedPin) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.pacpridePinValidation);
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.pacpridePinValidation);
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
        } else {
          if (parseInt(cardNo.slice(0, 3)) === 0) {
            toastr.error(toastrMessage.common.pacpridePinValidation);
            fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: network,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function validateCFSStdDriver(cardNo, network) {
      if (cardNo && cardNo !== fleetCardVM.currentLinkedPin) {
        if (parseInt(cardNo) === 0) {          
          toasterMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
          toastr.error(toasterMsg);
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
          return;
        }
        if (cardNo.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.pin4Digits;
          toastr.error(toasterMsg);          
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
        } else {
          if (parseInt(cardNo.slice(0, 1)) === 0) {
            toasterMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
            toastr.error(toasterMsg);
            fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: network,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function validateNFDriver(cardNo, network) {      
    }

    function getDriverValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isLinked) {
        fleetCardVM.cardDetails[fleetCardVM.currentIndex].LinkedPin = null;
        toastr.warning("Driver already linked.");
      }
    }

    function getDriverValidationError() {}

    /** VOYAGER CARD + DRIVER , VOYAGER 1-8 CARD + DRIVER*/
    function validateVoyagerCardDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toasterMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
          toastr.error(toasterMsg);
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].Driver = null;
          return;
        }
        if (cardNo.length < 4) {
          toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
          toastr.error(toasterMsg);
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].Driver = null;
        } else {
          // if (cardNo.length === 4) {
          if (parseInt(cardNo.slice(0, 2)) === 0) {
            toasterMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
            toastr.error(toasterMsg);
            fleetCardVM.cardDetails[fleetCardVM.currentIndex].Driver = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: network,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getCardDriverValidationSuccess, getCardDriverValidationError);
        }
      }
    }

    function getCardDriverValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isLinked) {
        fleetCardVM.cardDetails[fleetCardVM.currentIndex].Driver = null;
        toastr.warning("Driver already linked.");
      }
    }

    function getCardDriverValidationError() {}

    /** NF - SingleCard , CFN - SINGLE , CFN FLEETWIDE VEHICLE , PPSI DIRECT ID , PPSI POOLED VEHICLE , VOYAGER CARD + VEHICLE,
     * VOYAGER 1-8 CARD + VEHICLE , VOYAGER SECURE , VOYAGER PLUS
     */
    function validateVehicle(cardNo, network) {
      if (cardNo && fleetCardVM.currentVehicleID !== cardNo) {
        if (parseInt(cardNo) === 0) {
          toasterMsg = toastrMessage.cardControlErrorMessage.invalidVhclId;
          toastr.error(toasterMsg);          
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].VehicleID = null;
          return;
        }
        var requestData = {
          AccountNumber: localStorage.selectedAccount,
          PinNo: cardNo,
          NetworkCode: network,
        };
        cardManagementService
          .getVehicleValidation(requestData)
          .then(getVehicleValidationSuccess, getVehicleValidationError);
      }
    }

    function getVehicleValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isLinked) {
        fleetCardVM.cardDetails[fleetCardVM.currentIndex].VehicleID = null;
        toastr.warning("Vehicle already linked.");
      }
    }

    function getVehicleValidationError() {}

    function changeEmail() {}
    function onResetChange(item) {
      // fleetCardVM.restrictionDetails[0].ResetTypeValue = ''
      fleetCardVM.disableReset = false;
      if (item && item === "Daily") {
        fleetCardVM.disableReset = true;
      } else if (item && item === "End of Month") {
        fleetCardVM.disableReset = true;
      }
    }

    function exportTable(fileType) {
      var payload = JSON.parse(localStorage.cardManageMentPaylod);
      payload.Filter.PageNo = 0;
      payload.Filter.PageSize = 0;
      // $rootScope.$emit('exportPaymentEvent');
      if (fileType === "xls") {
        $rootScope.dataLoading = true;
        cardManagementService
          .exportCardManagement(payload)
          .then(exportCardManagementSuccess, exportCardManagementError);
      }
    }
    /*success callback for export payment*/
    function exportCardManagementSuccess(apiResponse) {
      $rootScope.dataLoading = false;
      $window.open(apiResponse.filePath);
    }
    function exportCardManagementError() {
      $rootScope.dataLoading = false;
      toastr.error(toastrMessage.common.systemNetworkFailure);
    }

    /** Select/change network from dropdown */
    function selectNetwork() {
      if (fleetCardVM.searchParams.network) {
        fleetCardVM.showCardtypePopup = !fleetCardVM.showCardtypePopup;
      }
    }

    function gallonChange(item, index) {
      if (!item) {
        fleetCardVM.restrictionDetails[index].NotifyGallonLimitCheck =
          undefined;
        if (fleetCardVM.restrictionDetails[index].notiffyGallonLimitCheck) {
          fleetCardVM.restrictionDetails[index].notiffyGallonLimitCheck = null;
        }
      }
    }
    gallonWeekendChange;
    function gallonWeekendChange(item, index) {
      if (!item) {
        fleetCardVM.restrictionDetails[index].NotifyGallonLimitWeekendCheck =
          undefined;
      }
      if (
        fleetCardVM.restrictionDetails[index].notiffyGallonLimitWeekendCheck
      ) {
        fleetCardVM.restrictionDetails[index].notiffyGallonLimitWeekendCheck =
          undefined;
      }
    }

    /*when account changed*/
    $scope.$on("accountChanged", function () {
      fleetCardVM.searchParams.searchCardNo = null;
      fleetCardVM.searchParams.CardDescription = null;
      fleetCardVM.searchParams.CardStatus = "";
      fleetCardVM.searchParams.VehicleID = null;
      fleetCardVM.searchParams.network = null;
      fleetCardVM.searchParams.Department = null;
      fleetCardVM.searchParams.cardTypeSelected = null;
      fleetCardVM.searchParams.LinkedPin = null;
      fleetCardVM.searchParams.networkItem = [];
      fleetCardVM.searchParams.LinkedPin = null;
      fleetCardVM.searchParams.CardType = null;
      fleetCardVM.searchParams.Network = null;
      fleetCardVM.searchParams.cardTypeArray = null;
      fleetCardVM.searchParams.DateLastUsed = null;
      fleetCardVM.networkCode = null;
      var hasPermission = false;
      if (localStorage.accountPermission) {
        angular.forEach(
          JSON.parse(localStorage.accountPermission),
          function (item, key) {
            if (item.option.toLowerCase().indexOf("card management") !== -1) {
              if (item.displayName === "R" || item.displayName === "W") {
                hasPermission = true;
                fleetCardVM.searchParams.sortingParameter = "";
                fleetCardVM.searchParams.sortingDirection = "";
                init();
                // getFleetCardList();
              }
            }
            var arrayLength =
              JSON.parse(localStorage.accountPermission).length - 1;
            if (key === arrayLength && !hasPermission) {
              $state.go("root.dashboard");
            }
          }
        );
      }
    });

    fleetCardVM.openModal = openModal;
    fleetCardVM.closeModal = closeModal;

    function openModal(id) {
      modalService.Open(id);
    }

    function closeModal(id) {
      alert("WARNING: Your changes will not be saved or submitted.");
      modalService.Close(id);
      sessionStorage.setItem("CurrentPageNo", fleetCardVM.currentPage);
      document.location.reload();
      //history.go(-1);
    }

    function changePinCheckbox(item, index) {
      if (item === true) {
        fleetCardVM.autoGeneratePin = true;
        fleetCardVM.oldPin = fleetCardVM.cardDetails[index].LinkedPin;
        fleetCardVM.cardDetails[index].LinkedPin = "";
        // fleetCardVM.oldPin = fleetCardVM.currentLinkedPin;
        fleetCardVM.currentLinkedPin = "";
      } else {
        fleetCardVM.autoGeneratePin = false;
        fleetCardVM.cardDetails[index].LinkedPin = fleetCardVM.oldPin;
        fleetCardVM.currentLinkedPin = fleetCardVM.oldPin;
      }
    }

    // cancel/reissue func
    function cancelReissue(index, id) {
      var rslt = lockAndCreateNewCard(index);
      sessionStorage.setItem("CancelReissueResults", rslt);        
      if (rslt=='') console.info("cancel/reissue success.");      
      modalService.Close(id);
      sessionStorage.setItem("CurrentPageNo", fleetCardVM.currentPage);
      window.location.reload();
    }

    // window.onload = function(){
    //     var reloading = sessionStorage.getItem("reloading");
    //     if(reloading){
    //         sessionStorage.removeItem("reloading");
    //         fleetCardVM.currentPage = fleetCardVM.newPageIndex;
    //     }
    // }

    // window.addEventListener("load", (event) => {
    //   alert("here");
    // });

    // cancel/reissue function
    // step 1: lock the old card and making changes accordingly
    // step 2: create a new card
    function lockAndCreateNewCard(index) {
      var cardType = fleetCardVM.cardDetails[index].CardTypeDesc;
      var cardData = JSON.parse(JSON.stringify(fleetCardVM.cardDetails[index]));
      var restrictData = fleetCardVM.restrictionDetails[index];
      //Jinran mod 5/23/23: force 'NA' value if ResetType is empty
      if (restrictData.ResetType == "") restrictData.ResetType = "NA";
      var autoGenPin = false;
      autoGenPin = fleetCardVM.autoGeneratePin;
      var result = '';
      fleetCardVM.submitClicked = true;

      switch (cardType) {
        case "PPSI DIRECT ID":
          result = lockAndCreateNewPPSIDirectId(cardData, restrictData, index, autoGenPin);
          break;
        case "PPSI POOLED VEHICLE":
          result = lockAndCreateNewPPSIPooledVehicleCard(cardData, restrictData, index);
          break;
        case "CFN FLEETWIDE VEHICLE":
          result = lockAndCreateNewFleetwideVehicleCard(cardData, restrictData, index);
          break;
        case "CFN SINGLE":
          result = lockAndCreateNewFleetwideSingleCard(cardData, restrictData, index, autoGenPin);          
          break;
        case "CF CARD + VEHICLE + PIN":
          validateCF(cardData, restrictData, index);
          break;
        case "VOYAGER SECURE":
          validateVoyagerSecure(cardData, restrictData, index);
          break;
        case "VOYAGER PLUS":
          validateVoyagerPlus(cardData, restrictData, index);
          break;
        case "VOYAGER CARD UNTIED":
          validateClassicUnitedCard(cardData, restrictData, index);
          break;
        case "VOYAGER CARD + DRIVER":
          validateClassicDriverTiedCard(cardData, restrictData, index);
          break;
        case "VOYAGER 1-8 CARD + VEHICLE":
          validateVehicleTiedToCard(cardData, restrictData, index);
          break;
        case "VOYAGER 1-8 CARD UNTIED":
          validateUnitedCard(cardData, restrictData, index);
          break;
        case "VOYAGER 1-8 CARD + DRIVER":
          validateDriverTiedToCard(cardData, restrictData, index);
          break;
        case "VOYAGER CARD + VEHICLE":
          result = lockAndCreateNewVoyagerPlusVehicleCard(cardData, restrictData, index, autoGenPin)
          break;
        case "SINGLE CARD":
          result = lockAndCreateNewNfSingleCard(cardData, restrictData, index)
          break;
        default:
          toasterMsg = toastrMessage.cardControlErrorMessage.cannotEdit;
          toastr.error(toasterMsg);
          break;
      }
      return result;
    }

    // function saveNewCardDetails(cardData, restrictData) {
    //   console.info("in saveNewCardDetails func");
    //   $rootScope.dataLoading = true;
    //   var payLoad = {
    //     AccountNo: localStorage.selectedAccount,
    //     CardNo: "",
    //     OrgId: localStorage.orgID,
    //     CardTypeDescription: cardData.CardTypeDesc,
    //     CardTypeCode: cardData.CardType,
    //     Network: cardData.Network,
    //     NetworkCode: cardData.NetworkCode,
    //     Model: {
    //       CardDetails: cardData,
    //       RestrictionDetails: restrictData,
    //     },
    //     DeltaModel: null,
    //   };
    //   cardManagementService
    //     .saveFleetCardDetails(payLoad)
    //     .then(saveFleetCardDetailsSuccess, saveFleetCardDetailsError);
    // }

    // function cancelReissueCardDetails(index, cardData, restrictData) {
    //   $rootScope.dataLoading = true;
    //   var oldData = fleetCardVM.dataCopy[index];
    //   if (oldData.cardRestrictions.RestrictionCode) {
    //     var selectedRestrictionCode = $filter("filter")(
    //       fleetCardVM.dropDownRestrictionData.restrictionCodes,
    //       { listValue: oldData.cardRestrictions.RestrictionCode },
    //       true
    //     );
    //     if (selectedRestrictionCode) {
    //       if (selectedRestrictionCode.length > 0) {
    //         oldData.cardRestrictions.RestrictionCode =
    //           selectedRestrictionCode[0].listDescription;
    //       }
    //     }
    //   }

    //   if (oldData.cardRestrictions.ResetType) {
    //     var selectedResetType = $filter("filter")(
    //       fleetCardVM.dropDownData.reset,
    //       { code: oldData.cardRestrictions.ResetType },
    //       true
    //     );
    //     if (selectedResetType) {
    //       if (selectedResetType.length > 0) {
    //         oldData.cardRestrictions.ResetType =
    //           selectedResetType[0].Description;
    //       }
    //     }
    //   }
    //   if (oldData.cardRestrictions.LimitCode) {
    //     var selectedGallonsPerTransaction = $filter("filter")(
    //       fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
    //       { listValue: oldData.cardRestrictions.LimitCode },
    //       true
    //     );
    //     if (selectedGallonsPerTransaction) {
    //       if (selectedGallonsPerTransaction.length > 0) {
    //         oldData.cardRestrictions.LimitCode =
    //           selectedGallonsPerTransaction[0].listDescription;
    //       }
    //     }
    //   }

    //   if (oldData.cardDetails.LimitCode) {
    //     var selectedGallonsPerTransaction = $filter("filter")(
    //       fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
    //       { listValue: oldData.cardDetails.LimitCode },
    //       true
    //     );
    //     if (selectedGallonsPerTransaction) {
    //       if (selectedGallonsPerTransaction.length > 0) {
    //         oldData.cardDetails.LimitCode =
    //           selectedGallonsPerTransaction[0].listDescription;
    //       }
    //     }
    //   }

    //   if (oldData.cardDetails.LicenseState) {
    //     var selectedLicenseState = $filter("filter")(
    //       fleetCardVM.dropDownData.stateRestrictions,
    //       { code: oldData.cardDetails.LicenseState },
    //       true
    //     );
    //     if (selectedLicenseState) {
    //       if (selectedLicenseState.length > 0) {
    //         oldData.cardDetails.LicenseState =
    //           selectedLicenseState[0].description;
    //       }
    //     }
    //   }

    //   if (oldData.cardRestrictions.PurchaseDays) {
    //     var selectedPurchaseDays = $filter("filter")(
    //       fleetCardVM.dropDownData.purchaseDay,
    //       { code: oldData.cardRestrictions.PurchaseDays },
    //       true
    //     );
    //     if (selectedPurchaseDays) {
    //       if (selectedPurchaseDays.length > 0) {
    //         oldData.cardRestrictions.PurchaseDays =
    //           selectedPurchaseDays[0].description;
    //       }
    //     }
    //   }

    //   //only set lock bit, keep other fields as old
    //   cardData.CardStatus = "L";
    //   cardData.CardDescription = oldData.cardDetails.CardDescription;
    //   cardData.LinkedPin = oldData.cardDetails.LinkedPin;
    //   var payLoad = {
    //     AccountNo: localStorage.selectedAccount,
    //     CardNo: cardData.CardNumber,
    //     OrgId: localStorage.orgID,
    //     CardTypeDescription:
    //       fleetCardVM.cardDetails[fleetCardVM.currentIndex].CardTypeDesc,
    //     CardTypeCode:
    //       fleetCardVM.cardDetails[fleetCardVM.currentIndex].CardType,
    //     Network: cardData.Network,
    //     NetworkCode: cardData.NetworkCode,
    //     Model: {
    //       CardDetails: cardData,
    //       RestrictionDetails: restrictData,
    //     },
    //     DeltaModel: {
    //       CardDetails: oldData.cardDetails,
    //       RestrictionDetails: oldData.cardRestrictions,
    //     },
    //   };
    //   alert(
    //     "This card will be locked and a new card will be reissued. If an update was made, it will reflect on the new card."
    //   );
    //   cardManagementService
    //     .editFleetCardDetails(JSON.stringify(payLoad))
    //     .then(saveFleetCardDetailsSuccess, saveFleetCardDetailsError);
    // }

    function lockAndCreateNewFleetwideSingleCard(
      cardData,
      restrictData,
      index,
      autoGenPin
    ) {
      toasterMsg = "";
      if (autoGenPin == false) {
        if (
          cardData.CardDescription &&
          cardData.CardStatus &&
          restrictData.PerFillLimit &&
          restrictData.GallonLimit &&
          cardData.LinkedPin &&
          restrictData.TimeProfile &&
          restrictData.RestrictMultiFueling &&
          restrictData.ProductAuthDesc &&
          restrictData.ResetType
        ) {
          if (
            cardData.CardDescription &&
            !utils.isValidAlphaNumeric(cardData.CardDescription)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
            toastr.error(toasterMsg);
          } else if (cardData.LinkedPin.length < 4) {
            toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
            toastr.error(toasterMsg);
          } else if (
            cardData.Department &&
            !utils.isValidAlphaNumeric(cardData.Department)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
            toastr.error(toasterMsg);
          } else if (
            restrictData.PerFillLimit < 1 &&
            restrictData.PerFillLimit > 9999
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.perGallonLimit;
            toastr.error(toasterMsg);
          } else if (
            restrictData.NotifyGallonLimitCheck &&
            !restrictData.NotifyLimit
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.notifyEnterValue;
            toastr.error(toasterMsg);
          } else if (
            restrictData.NotifyGallonLimitWeekendCheck &&
            !restrictData.NotifyLimitWeekEnd
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.notifyWeekend;
            toastr.error(toasterMsg);
          } else if (
            restrictData.emailChecked &&
            restrictData.NotifyLimitEmail &&
            !utils.isValidEmail(restrictData.NotifyLimitEmail)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.validEmail;
            toastr.error(toasterMsg);
          } else if (
            restrictData.emailChecked &&
            !restrictData.NotifyLimitEmail
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.notifyEmail;
            toastr.error(toasterMsg);
          } else if (
            (restrictData.NotifyLimit && parseInt(restrictData.NotifyLimit)) >
            (restrictData.GallonLimit && parseInt(restrictData.GallonLimit))
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.notifyLessGLimit;
            toastr.error(toasterMsg);
          } else if (
            (restrictData.NotifyLimitWeekEnd &&
              parseInt(restrictData.NotifyLimitWeekEnd)) >
            (restrictData.GallonLimitWeekEnd &&
              parseInt(restrictData.GallonLimitWeekEnd))
          ) {
            toasterMsg =
              toastrMessage.cardControlErrorMessage.notifyLessGLWeekend;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ResetType === "Day of Week" &&
            (!restrictData.ResetTypeValue ||
              restrictData.ResetTypeValue < 1 ||
              restrictData.ResetTypeValue > 7)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.resetValue17;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ResetType === "Day of Month" &&
            (!restrictData.ResetTypeValue ||
              restrictData.ResetTypeValue < 1 ||
              restrictData.ResetTypeValue > 31)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.resetValue131;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut1 &&
            restrictData.ZipCodeLockOut1.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut2 &&
            restrictData.ZipCodeLockOut2.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut3 &&
            restrictData.ZipCodeLockOut3.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut4 &&
            restrictData.ZipCodeLockOut4.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut5 &&
            restrictData.ZipCodeLockOut5.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else {
            var newRestrictData = JSON.parse(JSON.stringify(restrictData));
            if (restrictData.ProductAuthCode) {
              delete restrictData.ProductAuthCode;
            }
            if (
              restrictData.StateRestrictions &&
              typeof restrictData.StateRestrictions !== "string"
            ) {
              newRestrictData.StateRestrictions =
                restrictData.StateRestrictions.join(",");
            }
            if (!restrictData.emailChecked) {
              if (
                restrictData.NotifyGallonLimitCheck ||
                restrictData.NotifyGallonLimitWeekendCheck
              ) {
                newRestrictData.NotifyLimitEmail = localStorage.userEmail;
              } else {
                newRestrictData.emailChecked = null;
              }
            }
            editAndCreateCardDetails(index, cardData, newRestrictData, autoGenPin);
          }
        } else {
          toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
          toastr.error(toasterMsg);
        }
      } else {
        if (
          cardData.CardDescription &&
          cardData.CardStatus &&
          restrictData.PerFillLimit &&
          restrictData.GallonLimit &&
          restrictData.TimeProfile &&
          restrictData.RestrictMultiFueling &&
          restrictData.ProductAuthDesc &&
          restrictData.ResetType
        ) {
          if (
            cardData.CardDescription &&
            !utils.isValidAlphaNumeric(cardData.CardDescription)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.specialChar;
            toastr.error(toasterMsg);
          } else if (
            cardData.Department &&
            !utils.isValidAlphaNumeric(cardData.Department)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
            toastr.error(toasterMsg);
          } else if (
            restrictData.PerFillLimit < 1 &&
            restrictData.PerFillLimit > 9999
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.perGallonLimit;
            toastr.error(toasterMsg);
          } else if (
            restrictData.NotifyGallonLimitCheck &&
            !restrictData.NotifyLimit
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.notifyEnterValue;
            toastr.error(toasterMsg);
          } else if (
            restrictData.NotifyGallonLimitWeekendCheck &&
            !restrictData.NotifyLimitWeekEnd
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.notifyWeekend;
            toastr.error(toasterMsg);
          } else if (
            restrictData.emailChecked &&
            restrictData.NotifyLimitEmail &&
            !utils.isValidEmail(restrictData.NotifyLimitEmail)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.validEmail;
            toastr.error(toasterMsg);
          } else if (
            restrictData.emailChecked &&
            !restrictData.NotifyLimitEmail
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.validEmail;
            toastr.error(toasterMsg);
          } else if (
            (restrictData.NotifyLimit && parseInt(restrictData.NotifyLimit)) >
            (restrictData.GallonLimit && parseInt(restrictData.GallonLimit))
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.notifyLessGLimit;
            toastr.error(toasterMsg);
          } else if (
            (restrictData.NotifyLimitWeekEnd &&
              parseInt(restrictData.NotifyLimitWeekEnd)) >
            (restrictData.GallonLimitWeekEnd &&
              parseInt(restrictData.GallonLimitWeekEnd))
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.notifyLessGLWeekend;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ResetType === "Day of Week" &&
            (!restrictData.ResetTypeValue ||
              restrictData.ResetTypeValue < 1 ||
              restrictData.ResetTypeValue > 7)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.resetValue17;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ResetType === "Day of Month" &&
            (!restrictData.ResetTypeValue ||
              restrictData.ResetTypeValue < 1 ||
              restrictData.ResetTypeValue > 31)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.resetValue131;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut1 &&
            restrictData.ZipCodeLockOut1.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut2 &&
            restrictData.ZipCodeLockOut2.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut3 &&
            restrictData.ZipCodeLockOut3.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut4 &&
            restrictData.ZipCodeLockOut4.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut5 &&
            restrictData.ZipCodeLockOut5.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else {
            var newRestrictData = JSON.parse(JSON.stringify(restrictData));
            if (restrictData.ProductAuthCode) {
              delete restrictData.ProductAuthCode;
            }
            if (
              restrictData.StateRestrictions &&
              typeof restrictData.StateRestrictions !== "string"
            ) {
              newRestrictData.StateRestrictions =
                restrictData.StateRestrictions.join(",");
            }
            if (!restrictData.emailChecked) {
              if (
                restrictData.NotifyGallonLimitCheck ||
                restrictData.NotifyGallonLimitWeekendCheck
              ) {
                newRestrictData.NotifyLimitEmail = localStorage.userEmail;
              } else {
                newRestrictData.emailChecked = null;
              }
            }
            editAndCreateCardDetails(index, cardData, newRestrictData, autoGenPin);
          }
        } else {
          toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
          toastr.error(toasterMsg);
        }
      }
      return toasterMsg;
    }

    function lockAndCreateNewFleetwideVehicleCard(
      cardData,
      restrictData,
      index
    ) {
      toasterMsg = '';
      if (
        cardData.CardDescription &&
        restrictData.PerFillLimit &&
        restrictData.GallonLimit &&
        restrictData.ProductAuthDesc &&
        restrictData.RestrictMultiFueling &&
        restrictData.ResetType
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          restrictData.PerFillLimit < 1 &&
          restrictData.PerFillLimit > 9999
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.perGallonLimit;
          toastr.error(toasterMsg);
        } else if (
          restrictData.notiffyGallonLimitCheck &&
          !restrictData.NotifyLimit
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyEnterValue;
          toastr.error(toasterMsg);
        } else if (
          restrictData.notiffyGallonLimitWeekendCheck &&
          !restrictData.notifyWeekendGallonLimit
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyWeekend;
          toastr.error(toasterMsg);
        } else if (
          restrictData.emailChecked &&
          restrictData.NotifyLimitEmail &&
          !utils.isValidEmail(restrictData.NotifyLimitEmail)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validEmail;
          toastr.error(toasterMsg);
        } else if (
          restrictData.emailChecked &&
          !restrictData.NotifyLimitEmail
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.validEmail;
          toastr.error(toasterMsg);
        } else if (
          (restrictData.NotifyLimit && parseInt(restrictData.NotifyLimit)) >
          (restrictData.GallonLimit && parseInt(restrictData.GallonLimit))
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyLessGLimit;
          toastr.error(toasterMsg);
        } else if (
          (restrictData.notifyWeekendGallonLimit &&
            parseInt(restrictData.notifyWeekendGallonLimit)) >
          (restrictData.GallonLimitWeekEnd &&
            parseInt(restrictData.GallonLimitWeekEnd))
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.notifyLessGLWeekend;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ResetType === "Day of Week" &&
          (!restrictData.ResetTypeValue ||
            restrictData.ResetTypeValue < 1 ||
            restrictData.ResetTypeValue > 7)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.resetValue17;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ResetType === "Day of Month" &&
          (!restrictData.ResetTypeValue ||
            restrictData.ResetTypeValue < 1 ||
            restrictData.ResetTypeValue > 31)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.resetValue131;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut1 &&
          restrictData.ZipCodeLockOut1.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut2 &&
          restrictData.ZipCodeLockOut2.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut3 &&
          restrictData.ZipCodeLockOut3.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut4 &&
          restrictData.ZipCodeLockOut4.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut5 &&
          restrictData.ZipCodeLockOut5.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else {
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          if (restrictData.ProductAuthCode) {
            delete restrictData.ProductAuthCode;
          }
          if (restrictData.emailChecked === false) {
            if (
              restrictData.notiffyGallonLimitCheck ||
              restrictData.notiffyGallonLimitWeekendCheck
            ) {
              newRestrictData.NotifyLimitEmail = localStorage.userEmail;
            } else {
              newRestrictData.emailChecked = null;
            }
          }

          if (
            restrictData.StateRestrictions &&
            typeof restrictData.StateRestrictions !== "string"
          ) {
            newRestrictData.StateRestrictions =
              restrictData.StateRestrictions.join(",");
          }
          editAndCreateCardDetails(index, cardData, restrictData, false);          
        }
      } else {
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
      return toasterMsg;
    }

    function lockAndCreateNewPPSIPooledVehicleCard(
      cardData,
      restrictData,
      index
    ) {
      toasterMsg = '';
      if (
        fleetCardVM.cardDetailsForm[index].$valid &&
        fleetCardVM.restrictionsForm[index].$valid
      ) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut1 &&
          restrictData.ZipCodeLockOut1.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut2 &&
          restrictData.ZipCodeLockOut2.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut3 &&
          restrictData.ZipCodeLockOut3.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut4 &&
          restrictData.ZipCodeLockOut4.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else if (
          restrictData.ZipCodeLockOut5 &&
          restrictData.ZipCodeLockOut5.length < 5
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
          toastr.error(toasterMsg);
        } else {          
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));

          if (restrictData.ResetType) {
            var selectedResetType = $filter("filter")(
              fleetCardVM.dropDownData.reset,
              { code: restrictData.ResetType },
              true
            );
            if (selectedResetType.length > 0) {
              newRestrictData.ResetType = selectedResetType[0].Description;
            }
          }
          if (restrictData.ProductAuthCode) {
            var selectedProducts = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.products,
              { listValue: restrictData.ProductAuthCode },
              true
            );
            if (selectedProducts) {
              if (selectedProducts.length > 0) {
                newRestrictData.ProductAuthDesc =
                  selectedProducts[0].listDescription;
                newRestrictData.Products = selectedProducts[0].listDescription;
              }
            }
          }
          editAndCreateCardDetails(index, cardData, restrictData, false);
        }
      } else {
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
      return toasterMsg;
    }

    function lockAndCreateNewPPSIDirectId(
      cardData,
      restrictData,
      index,
      autoGenPin
    ) {
      toasterMsg = '';
      if (autoGenPin == false) {
        if (
          cardData.CardDescription &&
          cardData.MiscEntry &&
          cardData.PinType &&
          restrictData.TimeProfile &&
          restrictData.GallonLimit &&
          restrictData.ProductAuthCode &&
          restrictData.PerFillLimit &&
          restrictData.ResetType &&
          cardData.LinkedPin
        ) {
          if (
            cardData.CardDescription &&
            !utils.isValidAlphaNumeric(cardData.CardDescription)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
            toastr.error(toasterMsg);
          } else if (
            cardData.Department &&
            !utils.isValidAlphaNumeric(cardData.Department)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
            toastr.error(toasterMsg);
          } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
            toasterMsg = toastrMessage.cardControlErrorMessage.validDriverPin;
            toastr.error(toasterMsg);
          } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
            toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut1 &&
            restrictData.ZipCodeLockOut1.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut2 &&
            restrictData.ZipCodeLockOut2.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut3 &&
            restrictData.ZipCodeLockOut3.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut4 &&
            restrictData.ZipCodeLockOut4.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut5 &&
            restrictData.ZipCodeLockOut5.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else {            
            var newRestrictData = JSON.parse(JSON.stringify(restrictData));
            if (restrictData.ResetType) {
              var selectedResetType = $filter("filter")(
                fleetCardVM.dropDownData.reset,
                { code: restrictData.ResetType },
                true
              );
              if (selectedResetType.length > 0) {
                newRestrictData.ResetType = selectedResetType[0].Description;
              }
            }
            if (restrictData.ProductAuthCode) {
              var selectedProducts = $filter("filter")(
                fleetCardVM.dropDownRestrictionData.products,
                { listValue: restrictData.ProductAuthCode },
                true
              );
              if (selectedProducts) {
                if (selectedProducts.length > 0) {
                  newRestrictData.ProductAuthDesc =
                    selectedProducts[0].listDescription;
                  newRestrictData.Products =
                    selectedProducts[0].listDescription;
                }
              }
            }
            editAndCreateCardDetails(index, cardData, restrictData, autoGenPin);
          }
        } else {
          toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
          toastr.error(toasterMsg);
        }
      } else {
        if (
          cardData.CardDescription &&
          cardData.MiscEntry &&
          cardData.PinType &&
          restrictData.TimeProfile &&
          restrictData.GallonLimit &&
          restrictData.ProductAuthCode &&
          restrictData.PerFillLimit &&
          restrictData.ResetType
        ) {
          if (
            cardData.CardDescription &&
            !utils.isValidAlphaNumeric(cardData.CardDescription)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
            toastr.error(toasterMsg);
          } else if (
            cardData.Department &&
            !utils.isValidAlphaNumeric(cardData.Department)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut1 &&
            restrictData.ZipCodeLockOut1.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut2 &&
            restrictData.ZipCodeLockOut2.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut3 &&
            restrictData.ZipCodeLockOut3.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut4 &&
            restrictData.ZipCodeLockOut4.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else if (
            restrictData.ZipCodeLockOut5 &&
            restrictData.ZipCodeLockOut5.length < 5
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.zip5Digits;
            toastr.error(toasterMsg);
          } else {
            var newRestrictData = JSON.parse(JSON.stringify(restrictData));
            if (restrictData.ResetType) {
              var selectedResetType = $filter("filter")(
                fleetCardVM.dropDownData.reset,
                { code: restrictData.ResetType },
                true
              );
              if (selectedResetType.length > 0) {
                newRestrictData.ResetType = selectedResetType[0].Description;
              }
            }
            if (restrictData.ProductAuthCode) {
              var selectedProducts = $filter("filter")(
                fleetCardVM.dropDownRestrictionData.products,
                { listValue: restrictData.ProductAuthCode },
                true
              );
              if (selectedProducts) {
                if (selectedProducts.length > 0) {
                  newRestrictData.ProductAuthDesc =
                    selectedProducts[0].listDescription;
                  newRestrictData.Products =
                    selectedProducts[0].listDescription;
                }
              }
            }
            editAndCreateCardDetails(index, cardData, restrictData, autoGenPin);
          }
        } else {
          toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
          toastr.error(toasterMsg);
        }
      }
      return toasterMsg;
    }

    function lockAndCreateNewVoyagerPlusVehicleCard(
      cardData,
      restrictData,
      index,
      autoGenPin
    ) {
      toasterMsg = '';
      if (
        fleetCardVM.cardDetails[index].PinType.toLowerCase() == "float driver"
      ) {
        //without LINKed Driver
        if (
          cardData.CardDescription &&
          restrictData.RestrictionCode &&
          restrictData.TransactionsPerDay &&
          restrictData.DollarsPerDay &&
          restrictData.DollarsPerCycle &&
          fleetCardVM.restrictionsForm[index].$valid
        ) {
          if (
            cardData.CardDescription &&
            !utils.isValidAlphaNumeric(cardData.CardDescription)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
            toastr.error(toasterMsg);
          } else if (
            cardData.Department &&
            !utils.isValidAlphaNumeric(cardData.Department)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharDept;
            toastr.error(toasterMsg);
          } else if (
            restrictData.TransactionsPerDay &&
            restrictData.TransactionsPerDay < 1
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.transPerDay199;
            toastr.error(toasterMsg);
          } else if (
            restrictData.TransactionsPerWeek &&
            restrictData.TransactionsPerWeek < 1
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.transPerWeek;
            toastr.error(toasterMsg);
          } else if (
            restrictData.TransactionsPerMonth &&
            restrictData.TransactionsPerMonth < 1
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.transPerMon;
            toastr.error(toasterMsg);
          } else if (
            restrictData.TransactionsPerCycle &&
            (restrictData.TransactionsPerCycle < 1 ||
              restrictData.TransactionsPerCycle > 9999)
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
            toastr.error(toasterMsg);
          } else if (
            restrictData.DollarsPerDay &&
            restrictData.DollarsPerDay < 100
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
            toastr.error(toasterMsg);
          } else if (
            restrictData.DollarsPerWeek &&
            restrictData.DollarsPerWeek < 100
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
            toastr.error(toasterMsg);
          } else if (
            restrictData.DollarsPerMonth &&
            restrictData.DollarsPerMonth < 100
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
            toastr.error(toasterMsg);
          } else if (
            restrictData.DollarsPerCycle &&
            restrictData.DollarsPerCycle < 100
          ) {
            toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerCycle;
            toastr.error(toasterMsg);
          } else {
            if (restrictData.StartTimeA || restrictData.EndTimeA) {
              if (restrictData.StartTimeA === null) {
                toasterMsg = toastrMessage.cardControlErrorMessage.validStartTime;
                toastr.error(toasterMsg);
                return toasterMsg;
              } else if (restrictData.EndTimeA === null) {
                toasterMsg = toastrMessage.cardControlErrorMessage.validEndTime;
                toastr.error(toasterMsg);
                return toasterMsg;
              } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
                toasterMsg = toastrMessage.cardControlErrorMessage.endTimeGr8StartTime;
                toastr.error(toasterMsg);
                return toasterMsg;
              }
            }
            var newCardData = JSON.parse(JSON.stringify(cardData));
            var newRestrictData = JSON.parse(JSON.stringify(restrictData));
            var selectedRestrictionCode = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.restrictionCodes,
              { listValue: restrictData.RestrictionCode },
              true
            );
            if (selectedRestrictionCode.length > 0) {
              newRestrictData.RestrictionCode =
                selectedRestrictionCode[0].listDescription;
            }
            if (
              restrictData.StateRestrictions &&
              typeof restrictData.StateRestrictions !== "string"
            ) {
              newRestrictData.StateRestrictions =
                restrictData.StateRestrictions.join(",");
            }
            if (
              restrictData.StartTimeA &&
              typeof restrictData.StartTimeA !== "string"
            ) {
              newRestrictData.StartTimeA = utils.getFromToTimeFormat(
                new Date(restrictData.StartTimeA)
              );
            }
            if (
              restrictData.EndTimeA &&
              typeof restrictData.EndTimeA !== "string"
            ) {
              newRestrictData.EndTimeA = utils.getFromToTimeFormat(
                new Date(restrictData.EndTimeA)
              );
            }
            if (restrictData.PurchaseDays) {
              var selectedPurchaseDays = $filter("filter")(
                fleetCardVM.dropDownData.purchaseDay,
                { code: restrictData.PurchaseDays },
                true
              );
              if (selectedPurchaseDays.length > 0) {
                newRestrictData.PurchaseDays =
                  selectedPurchaseDays[0].description;
              }
            }
            if (cardData.LicenseState) {
              var selectedLicenseState = $filter("filter")(
                fleetCardVM.dropDownData.stateRestrictions,
                { code: cardData.LicenseState },
                true
              );
              if (selectedLicenseState.length > 0) {
                newCardData.LicenseState = selectedLicenseState[0].description;
              }
            }
            editAndCreateCardDetails(index, cardData, newRestrictData, autoGenPin);
          }
        } else {
          if (
            restrictData.StartTimeA === null ||
            restrictData.EndTimeA === null
          ) {
            if (
              fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
              toastr.error(toasterMsg);              
            }
          }
          toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
          toastr.error(toasterMsg);
        }        
      } else {
        // with a LINKED Driver
        if (autoGenPin == false) {
          if (
            cardData.CardDescription &&
            restrictData.RestrictionCode &&
            restrictData.TransactionsPerDay &&
            restrictData.DollarsPerDay &&
            restrictData.DollarsPerCycle &&
            fleetCardVM.restrictionsForm[index].$valid
          ) {
            if (
              cardData.CardDescription &&
              !utils.isValidAlphaNumeric(cardData.CardDescription)
            ) {
              toasterMsg =
                toastrMessage.cardControlErrorMessage.noSpecialCharCard;
              toastr.error(toasterMsg);
            } else if (
              cardData.Department &&
              !utils.isValidAlphaNumeric(cardData.Department)
            ) {
              toasterMsg =
                toastrMessage.cardControlErrorMessage.noSpecialCharDept;
              toastr.error(toasterMsg);
            } else if (
              restrictData.TransactionsPerDay &&
              restrictData.TransactionsPerDay < 1
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.transPerDay199;
              toastr.error(toasterMsg);
            } else if (
              restrictData.TransactionsPerWeek &&
              restrictData.TransactionsPerWeek < 1
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.transPerWeek;
              toastr.error(toasterMsg);
            } else if (
              restrictData.TransactionsPerMonth &&
              restrictData.TransactionsPerMonth < 1
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.transPerMon;
              toastr.error(toasterMsg);
            } else if (
              restrictData.TransactionsPerCycle &&
              (restrictData.TransactionsPerCycle < 1 ||
                restrictData.TransactionsPerCycle > 9999)
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
              toastr.error(toasterMsg);
            } else if (
              restrictData.DollarsPerDay &&
              restrictData.DollarsPerDay < 100
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
              toastr.error(toasterMsg);
            } else if (
              restrictData.DollarsPerWeek &&
              restrictData.DollarsPerWeek < 100
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
              toastr.error(toasterMsg);
            } else if (
              restrictData.DollarsPerMonth &&
              restrictData.DollarsPerMonth < 100
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
              toastr.error(toasterMsg);
            } else if (
              restrictData.DollarsPerCycle &&
              restrictData.DollarsPerCycle < 100
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerCycle;
              toastr.error(toasterMsg);
            } else if (cardData.PinType === "SINGLE" && !cardData.LinkedPin) {
              toasterMsg = toastrMessage.cardControlErrorMessage.validDriverPin;
              toastr.error(toasterMsg);
            } else if (cardData.LinkedPin && cardData.LinkedPin.length < 4) {
              toasterMsg = toastrMessage.cardControlErrorMessage.fourDigits;
              toastr.error(toasterMsg);
            } else {
              if (restrictData.StartTimeA || restrictData.EndTimeA) {
                if (restrictData.StartTimeA === null) {
                  toasterMsg = toastrMessage.cardControlErrorMessage.validStartTime;
                  toastr.error(toasterMsg);
                  return toasterMsg;
                } else if (restrictData.EndTimeA === null) {
                  toasterMsg = toastrMessage.cardControlErrorMessage.validEndTime;
                  toastr.error(toasterMsg);
                  return toasterMsg;
                } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
                  toasterMsg = toastrMessage.cardControlErrorMessage.endTimeGr8StartTime;
                  toastr.error(toasterMsg);
                  return toasterMsg;
                }
              }
              var newCardData = JSON.parse(JSON.stringify(cardData));
              var newRestrictData = JSON.parse(JSON.stringify(restrictData));
              var selectedRestrictionCode = $filter("filter")(
                fleetCardVM.dropDownRestrictionData.restrictionCodes,
                { listValue: restrictData.RestrictionCode },
                true
              );
              if (selectedRestrictionCode.length > 0) {
                newRestrictData.RestrictionCode =
                  selectedRestrictionCode[0].listDescription;
              }
              if (
                restrictData.StateRestrictions &&
                typeof restrictData.StateRestrictions !== "string"
              ) {
                newRestrictData.StateRestrictions =
                  restrictData.StateRestrictions.join(",");
              }
              if (
                restrictData.StartTimeA &&
                typeof restrictData.StartTimeA !== "string"
              ) {
                newRestrictData.StartTimeA = utils.getFromToTimeFormat(
                  new Date(restrictData.StartTimeA)
                );
              }
              if (
                restrictData.EndTimeA &&
                typeof restrictData.EndTimeA !== "string"
              ) {
                newRestrictData.EndTimeA = utils.getFromToTimeFormat(
                  new Date(restrictData.EndTimeA)
                );
              }
              if (restrictData.PurchaseDays) {
                var selectedPurchaseDays = $filter("filter")(
                  fleetCardVM.dropDownData.purchaseDay,
                  { code: restrictData.PurchaseDays },
                  true
                );
                if (selectedPurchaseDays.length > 0) {
                  newRestrictData.PurchaseDays =
                    selectedPurchaseDays[0].description;
                }
              }
              if (cardData.LicenseState) {
                var selectedLicenseState = $filter("filter")(
                  fleetCardVM.dropDownData.stateRestrictions,
                  { code: cardData.LicenseState },
                  true
                );
                if (selectedLicenseState.length > 0) {
                  newCardData.LicenseState =
                    selectedLicenseState[0].description;
                }
              }
              editAndCreateCardDetails(index, cardData, newRestrictData, autoGenPin);
            }
          } else {
            if (
              restrictData.StartTimeA === null ||
              restrictData.EndTimeA === null
            ) {
              if (fleetCardVM.restrictionsForm[index].$error.hasOwnProperty("time")) {
                toasterMsg = toastrMessage.cardControlErrorMessage.clearInvalidTime;
                toastr.error(toasterMsg);
                return toasterMsg;
              }
            }
            toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
            toastr.error(toasterMsg);
          }
        } else {
          if (
            cardData.CardDescription &&
            restrictData.RestrictionCode &&
            restrictData.TransactionsPerDay &&
            restrictData.DollarsPerDay &&
            restrictData.DollarsPerCycle &&
            fleetCardVM.restrictionsForm[index].$valid
          ) {
            if (
              cardData.CardDescription &&
              !utils.isValidAlphaNumeric(cardData.CardDescription)
            ) {
              toasterMsg =
                toastrMessage.cardControlErrorMessage.noSpecialCharCard;
              toastr.error(toasterMsg);
            } else if (
              cardData.Department &&
              !utils.isValidAlphaNumeric(cardData.Department)
            ) {
              toasterMsg =
                toastrMessage.cardControlErrorMessage.noSpecialCharDept;
              toastr.error(toasterMsg);
            } else if (
              restrictData.TransactionsPerDay &&
              restrictData.TransactionsPerDay < 1
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.transPerDay199;
              toastr.error(toasterMsg);
            } else if (
              restrictData.TransactionsPerWeek &&
              restrictData.TransactionsPerWeek < 1
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.transPerWeek;
              toastr.error(toasterMsg);
            } else if (
              restrictData.TransactionsPerMonth &&
              restrictData.TransactionsPerMonth < 1
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.transPerMon;
              toastr.error(toasterMsg);
            } else if (
              restrictData.TransactionsPerCycle &&
              (restrictData.TransactionsPerCycle < 1 ||
                restrictData.TransactionsPerCycle > 9999)
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.transPerCycle;
              toastr.error(toasterMsg);
            } else if (
              restrictData.DollarsPerDay &&
              restrictData.DollarsPerDay < 100
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
              toastr.error(toasterMsg);
            } else if (
              restrictData.DollarsPerWeek &&
              restrictData.DollarsPerWeek < 100
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
              toastr.error(toasterMsg);
            } else if (
              restrictData.DollarsPerMonth &&
              restrictData.DollarsPerMonth < 100
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
              toastr.error(toasterMsg);
            } else if (
              restrictData.DollarsPerCycle &&
              restrictData.DollarsPerCycle < 100
            ) {
              toasterMsg = toastrMessage.cardControlErrorMessage.dollarPerCycle;
              toastr.error(toasterMsg);
            } else {
              if (restrictData.StartTimeA || restrictData.EndTimeA) {
                if (restrictData.StartTimeA === null) {
                  toasterMsg = toastrMessage.cardControlErrorMessage.validStartTime;
                  toastr.error(toasterMsg);
                  return toasterMsg;
                } else if (restrictData.EndTimeA === null) {
                  toasterMsg = toastrMessage.cardControlErrorMessage.validEndTime;
                  toastr.error(toasterMsg);
                  return;
                } else if (restrictData.StartTimeA > restrictData.EndTimeA) {
                  toasterMsg = toastrMessage.cardControlErrorMessage.endTimeGr8StartTime;
                  toastr.error(toasterMsg);
                  return toasterMsg;
                }
              }
              var newCardData = JSON.parse(JSON.stringify(cardData));
              var newRestrictData = JSON.parse(JSON.stringify(restrictData));
              var selectedRestrictionCode = $filter("filter")(
                fleetCardVM.dropDownRestrictionData.restrictionCodes,
                { listValue: restrictData.RestrictionCode },
                true
              );
              if (selectedRestrictionCode.length > 0) {
                newRestrictData.RestrictionCode =
                  selectedRestrictionCode[0].listDescription;
              }
              if (
                restrictData.StateRestrictions &&
                typeof restrictData.StateRestrictions !== "string"
              ) {
                newRestrictData.StateRestrictions =
                  restrictData.StateRestrictions.join(",");
              }
              if (
                restrictData.StartTimeA &&
                typeof restrictData.StartTimeA !== "string"
              ) {
                newRestrictData.StartTimeA = utils.getFromToTimeFormat(
                  new Date(restrictData.StartTimeA)
                );
              }
              if (
                restrictData.EndTimeA &&
                typeof restrictData.EndTimeA !== "string"
              ) {
                newRestrictData.EndTimeA = utils.getFromToTimeFormat(
                  new Date(restrictData.EndTimeA)
                );
              }
              if (restrictData.PurchaseDays) {
                var selectedPurchaseDays = $filter("filter")(
                  fleetCardVM.dropDownData.purchaseDay,
                  { code: restrictData.PurchaseDays },
                  true
                );
                if (selectedPurchaseDays.length > 0) {
                  newRestrictData.PurchaseDays =
                    selectedPurchaseDays[0].description;
                }
              }
              if (cardData.LicenseState) {
                var selectedLicenseState = $filter("filter")(
                  fleetCardVM.dropDownData.stateRestrictions,
                  { code: cardData.LicenseState },
                  true
                );
                if (selectedLicenseState.length > 0) {
                  newCardData.LicenseState =
                    selectedLicenseState[0].description;
                }
              }
              editAndCreateCardDetails(index, cardData, newRestrictData, autoGenPin);
            }
          } else {
            if (
              restrictData.StartTimeA === null ||
              restrictData.EndTimeA === null
            ) {
              if (
                fleetCardVM.restrictionsForm[index].$error.hasOwnProperty(
                  "time"
                )
              ) {
                toasterMsg =
                  toastrMessage.cardControlErrorMessage.clearInvalidTime;
                toastr.error(toasterMsg);
                return toasterMsg;
              }
            }
            toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
            toastr.error(toasterMsg);
          }
        }
      }
      return toasterMsg;
    }

    function lockAndCreateNewNfSingleCard(cardData, restrictData, index) {
      toasterMsg = '';
      if (cardData.CardDescription) {
        if (
          cardData.CardDescription &&
          !utils.isValidAlphaNumeric(cardData.CardDescription)
        ) {
          toasterMsg = toastrMessage.cardControlErrorMessage.noSpecialCharCard;
          toastr.error(toasterMsg);
        } else {          
          var newRestrictData = JSON.parse(JSON.stringify(restrictData));
          if (restrictData.ProductAuthCode) {
            var selectedProducts = $filter("filter")(
              fleetCardVM.dropDownRestrictionData.products,
              { listValue: restrictData.ProductAuthCode },
              true
            );
            if (selectedProducts) {
              if (selectedProducts.length > 0) {
                newRestrictData.ProductAuthDesc =
                  selectedProducts[0].listDescription;
                newRestrictData.Products = selectedProducts[0].listDescription;
              }
            }
          }          
          editAndCreateCardDetails(index, cardData, restrictData, false);
        }
      } else {
        toasterMsg = toastrMessage.cardControlErrorMessage.requiredField;
        toastr.error(toasterMsg);
      }
      return toasterMsg;
    }

    function editAndCreateCardDetails(
      index,
      cardData,
      restrictData,
      autoGenPin
    ) {
      $rootScope.dataLoading = true;
      var oldData = fleetCardVM.dataCopy[index];
      if (oldData.cardRestrictions.RestrictionCode) {
        var selectedRestrictionCode = $filter("filter")(
          fleetCardVM.dropDownRestrictionData.restrictionCodes,
          { listValue: oldData.cardRestrictions.RestrictionCode },
          true
        );
        if (selectedRestrictionCode) {
          if (selectedRestrictionCode.length > 0) {
            oldData.cardRestrictions.RestrictionCode =
              selectedRestrictionCode[0].listDescription;
          }
        }
      }

      if (oldData.cardRestrictions.ResetType) {
        var selectedResetType = $filter("filter")(
          fleetCardVM.dropDownData.reset,
          { code: oldData.cardRestrictions.ResetType },
          true
        );
        if (selectedResetType) {
          if (selectedResetType.length > 0) {
            oldData.cardRestrictions.ResetType =
              selectedResetType[0].Description;
          }
        }
      }
      if (oldData.cardRestrictions.LimitCode) {
        var selectedGallonsPerTransaction = $filter("filter")(
          fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
          { listValue: oldData.cardRestrictions.LimitCode },
          true
        );
        if (selectedGallonsPerTransaction) {
          if (selectedGallonsPerTransaction.length > 0) {
            oldData.cardRestrictions.LimitCode =
              selectedGallonsPerTransaction[0].listDescription;
          }
        }
      }

      if (oldData.cardDetails.LimitCode) {
        var selectedGallonsPerTransaction = $filter("filter")(
          fleetCardVM.dropDownRestrictionData.gallonsPerTransaction,
          { listValue: oldData.cardDetails.LimitCode },
          true
        );
        if (selectedGallonsPerTransaction) {
          if (selectedGallonsPerTransaction.length > 0) {
            oldData.cardDetails.LimitCode =
              selectedGallonsPerTransaction[0].listDescription;
          }
        }
      }

      if (oldData.cardDetails.LicenseState) {
        var selectedLicenseState = $filter("filter")(
          fleetCardVM.dropDownData.stateRestrictions,
          { code: oldData.cardDetails.LicenseState },
          true
        );
        if (selectedLicenseState) {
          if (selectedLicenseState.length > 0) {
            oldData.cardDetails.LicenseState =
              selectedLicenseState[0].description;
          }
        }
      }

      if (oldData.cardRestrictions.PurchaseDays) {
        var selectedPurchaseDays = $filter("filter")(
          fleetCardVM.dropDownData.purchaseDay,
          { code: oldData.cardRestrictions.PurchaseDays },
          true
        );
        if (selectedPurchaseDays) {
          if (selectedPurchaseDays.length > 0) {
            oldData.cardRestrictions.PurchaseDays =
              selectedPurchaseDays[0].description;
          }
        }
      }

      //only set lock bit, keep other fields as old
      var stageCardData = cardData;
      stageCardData.CardStatus = "L";
      stageCardData.CardDescription = oldData.cardDetails.CardDescription;
      stageCardData.LinkedPin = oldData.cardDetails.LinkedPin;
      var eidtPayLoad = {
        AccountNo: localStorage.selectedAccount,
        CardNo: stageCardData.CardNumber,
        OrgId: localStorage.orgID,
        CardTypeDescription:
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].CardTypeDesc,
        CardTypeCode:
          fleetCardVM.cardDetails[fleetCardVM.currentIndex].CardType,
        Network: stageCardData.Network,
        NetworkCode: stageCardData.NetworkCode,
        Model: {
          CardDetails: stageCardData,
          RestrictionDetails: restrictData,
        },
        DeltaModel: {
          CardDetails: oldData.cardDetails,
          RestrictionDetails: oldData.cardRestrictions,
        },
      };

      // add new card
      console.info("add new card...");
      var newCardData = fleetCardVM.cardDetails[index];
      if (newCardData.Department === "Add New" || fleetCardVM.newDepartment) {
        newCardData.Department = fleetCardVM.newDepartment;
      }
      newCardData.CardStatus = "A";
      if (autoGenPin) newCardData.LinkedPin = "AUTO";
      $rootScope.dataLoading = true;
      var savePayLoad = {
        AccountNo: localStorage.selectedAccount,
        CardNo: "",
        OrgId: localStorage.orgID,
        CardTypeDescription: newCardData.CardTypeDesc,
        CardTypeCode: newCardData.CardType,
        Network: newCardData.Network,
        NetworkCode: newCardData.NetworkCode,
        Model: {
          CardDetails: newCardData,
          RestrictionDetails: restrictData,
        },
        DeltaModel: null,
      };

      alert(
        "This card will be locked and a new card will be reissued. If an update was made, it will reflect on the new card."
      );
      cardManagementService
        .editAndSaveFleetCardDetails(eidtPayLoad, savePayLoad)
        .then(saveFleetCardDetailsSuccess, saveFleetCardDetailsError);
    }
  }
})();
