/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';
    
    angular.module('scFuelsApp.bannerManagement')
        .factory('bannerManagementService', bannerManagementService);

    bannerManagementService.$inject = ['$q', '$http', 'apiURLService'];

    function bannerManagementService ($q, $http, apiURLService) {
        var bannerManagementObject = {};
        bannerManagementObject.getCurrentBanners = getCurrentBanners;
        bannerManagementObject.saveBanner = saveBanner;
        return bannerManagementObject;

        function getCurrentBanners() {
        	var url = apiURLService.getCurrentBannersURL();
        	return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function saveBanner(params) {
        	var url = apiURLService.saveBannerURL();
        	return $http.post(url, params).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }
    }
})();
