
/*jshint browser: true*/
/*global angular: true*/


(function(){
    'use strict';

    angular
        .module('scFuelsApp.changeAccountInfo', [])
        .config(routerConfiguration);

    routerConfiguration.$inject = ['$stateProvider'];
    function routerConfiguration ($stateProvider) {
        $stateProvider
            .state('root.accountInfo', {
            url: '/changeAccountInfo',
            templateUrl: 'app/modules/changeAccountInfo/accountInfoTemplate.html',
            controller: 'accountInfoController',
            controllerAs : 'accountVM'
        });
    }
})();
