/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';

    angular.module('scFuelsApp.login', [])
        .config(routerConfiguration);

    routerConfiguration.$inject = ['$stateProvider'];
    function routerConfiguration ($stateProvider) {
        // $stateProvider
        //     .state('registration', {
        //       url: '/registration?Id',
        //       templateUrl: 'app/modules/login/registration/registrationTemplate.html',
        //       controller: 'registrationController',
        //       controllerAs : 'registrationVM'
        //     })
        //     .state('forgotPassword', {
        //       url: '/forgotPassword',
        //       templateUrl: 'app/modules/login/forgotPassword/forgotPasswordTemplate.html',
        //       controller: 'forgotPwdController',
        //       controllerAs : 'forgotPwdVM'
        //     })
        //     .state('resetPassword', {
        //       url: '/resetPassword?Id',
        //       templateUrl: 'app/modules/login/resetPassword/resetPasswordTemplate.html',
        //       controller: 'resetPasswordController',
        //       controllerAs : 'resetPasswordVM'
        //     });
    }
})();
