/*jshint browser: true*/
/*global angular: true*/

(function () {
  "use strict";

  angular
    .module("scFuelsApp.cardManagement")
    .controller("promptsController", promptsController);
  ("");
  promptsController.$inject = [
    "$scope",
    "$state",
    "$rootScope",
    "toastrMessage",
    "cardManagementService",
    "utils",
    "toastr",
    "$window",
    "$confirm",
    "$filter",
    "$timeout",
  ];

  function promptsController(
    $scope,
    $state,
    $rootScope,
    toastrMessage,
    cardManagementService,
    utils,
    toastr,
    $window,
    $confirm,
    $filter,
    $timeout
  ) {
    var promptsVM = this;
    promptsVM.tooltips = toastrMessage.fleetCardsTooltip;
    promptsVM.showAccordianView = [];
    promptsVM.cardDetails = [];
    promptsVM.promptDetails = [];
    promptsVM.promptRestrictions = [];
    promptsVM.restrictionsForm = [];
    promptsVM.cardDetailsForm = [];
    promptsVM.showTimeProfile = [];
    promptsVM.timeProfileDetails = [];
    promptsVM.isAddNewProfile = [];
    promptsVM.showCardSearchButton = true;
    promptsVM.disablePooledDriver = false;
    promptsVM.disablePin = false;
    promptsVM.disableDriverId = true;
    promptsVM.disableLinkedCard = true;
    promptsVM.searchParams = {};
    promptsVM.dataCopy = {};
    promptsVM.showNewField = false;
    promptsVM.currentIndex = null;
    promptsVM.submitClicked = false;
    promptsVM.alreadyLinked = false;
    promptsVM.TiedCardValueCopy = "";
    promptsVM.LinkedCardDescCopy = "";

    promptsVM.CardStatus = null;
    // promptsVM.linkedCardObj = {};
    promptsVM.hstep = 1;
    promptsVM.mstep = 1;
    promptsVM.ismeridian = true;
    promptsVM.profileTemplate =
      "app/modules/cardManagement/promptsManagement/cardDetails/timeProfileTemplate.html";

    promptsVM.showCardDetails = showCardDetails;
    promptsVM.toggleSearch = toggleSearch;
    promptsVM.applyPopupDropdown = applyPopupDropdown;
    promptsVM.getAllCards = getAllCards;
    promptsVM.advanceSearch = advanceSearch;
    promptsVM.cardNumberSearch = cardNumberSearch;
    promptsVM.orderByColumn = orderByColumn;
    promptsVM.paginationClick = paginationClick;
    promptsVM.editCardDetails = editCardDetails;
    promptsVM.manageTimeProfile = manageTimeProfile;
    promptsVM.editProfileDetails = editProfileDetails;
    promptsVM.addNewProfile = addNewProfile;
    promptsVM.saveNewProfile = saveNewProfile;
    promptsVM.cancelAddProfile = cancelAddProfile;
    promptsVM.deleteProfileDetails = deleteProfileDetails;
    promptsVM.changeStatus = changeStatus;
    promptsVM.changeStatusPin = changeStatusPin;
    promptsVM.onNetworkChange = onNetworkChange;
    promptsVM.closeClick = closeClick;
    promptsVM.changeDepartment = changeDepartment;
    promptsVM.getPromptRestrictionDropdownList =
      getPromptRestrictionDropdownList;
    promptsVM.validatePin = validatePin;
    promptsVM.changeLinkedCard = changeLinkedCard;
    promptsVM.validateDriver = validateDriver;
    promptsVM.validateVehicle = validateVehicle;
    promptsVM.getPromptLinkedCardList = getPromptLinkedCardList;
    promptsVM.printTable = printTable;
    promptsVM.exportTable = exportTable;
    promptsVM.selectNetwork = selectNetwork;
    promptsVM.validateCFNDriver = validateCFNDriver;
    promptsVM.validatePacprideDriver = validatePacprideDriver;
    promptsVM.validateCFSStdPin = validateCFSStdPin;

    var errorMsg = "";

    init();

    /*function declarations*/
    /*intial function call*/
    function init() {
      if (localStorage.accountPermission) {
        angular.forEach(
          JSON.parse(localStorage.accountPermission),
          function (item) {
            if (item.option.toLowerCase().indexOf("card management") !== -1) {
              promptsVM.userPermission = item.displayName;
            }
          }
        );
      }
      promptsVM.columnHeaders = [
        { name: "Network", icon: "ic-filterNot", sortParam: "Network" },
        { name: "ID", icon: "ic-filter", sortParam: "DriverID" },
        {
          name: "Prompt Description",
          icon: "ic-filterNot",
          sortParam: "PromptDescription",
        },
        { name: "Status", icon: "ic-filterNot", sortParam: "Status" },
        { name: "Linked Card", icon: "ic-filterNot", sortParam: "LinkedCard" },
        {
          name: "Card Description",
          icon: "ic-filterNot",
          sortParam: "CardDescription",
        },
        { name: "Department", icon: "ic-filterNot", sortParam: "Department" },
      ];
      promptsVM.currentPage = 1;
      promptsVM.firstPageItem = 1;
      promptsVM.secondPageItem = 2;
      getPromptDropdownList();
      getPromptCardList();
    }
    /*To get the dropdown values for the advance search*/
    function getPromptDropdownList() {
      cardManagementService
        .getPromptDropdownList(localStorage.selectedAccount)
        .then(getPromptDropdownListSuccess, getPromptDropdownListError);
    }
    /*success callback of getPromptDropdownList*/
    function getPromptDropdownListSuccess(apiResponse) {
      promptsVM.dropDownData = apiResponse.dataModel;
      if (promptsVM.dropDownData.network) {
        promptsVM.dropDownData.network.unshift({
          code: "Select",
          description: "Select",
        });
      }
      promptsVM.dropDownData.advSearchDept = JSON.parse(
        JSON.stringify(apiResponse.dataModel.department)
      );
      promptsVM.dropDownData.department.push({
        code: "Add New",
        description: "Add New",
      });
      // promptsVM.dropDownData.department = apiResponse.dataModel.department;
      promptsVM.dropDownData.advSearchDept.unshift({
        code: "Select",
        description: "Select",
      });
      // apiResponse.dataModel.network
      // promptsVM.searchParams.descType = promptsVM.dropDownData.descType[0];
      // promptsVM.searchParams.idType = promptsVM.dropDownData.idType[0];
      // if (promptsVM.dropDownData.network) {
      //     angular.forEach(promptsVM.dropDownData.network, function(networkItem) {
      //         networkItem.isChecked = false;
      //     });
      // }
      // if (promptsVM.dropDownData.Status) {
      //     angular.forEach(promptsVM.dropDownData.Status, function(statusItem) {
      //         statusItem.isChecked = false;
      //     });
      // }
      // if (promptsVM.dropDownData.linkedCard) {
      //     angular.forEach(promptsVM.dropDownData.linkedCard, function(linkedItem) {
      //         linkedItem.isChecked = false;
      //     });
      // }
    }

    /*error callback of getPromptDropdownList*/
    function getPromptDropdownListError() {}

    function changeDepartment(item) {
      if (item === "Add New") {
        promptsVM.showNewField = true;
      } else {
        promptsVM.showNewField = false;
      }
    }

    function getPromptRestrictionDropdownList(networkCode) {
      cardManagementService
        .getPromptRestrictionDropdownList(networkCode)
        .then(getPromptRestrictionListSuccess, getPromptRestrictionListError);
    }

    function getPromptRestrictionListSuccess(apiResponse) {
      $rootScope.dataLoading = false;
      promptsVM.dropDownRestrictionData = apiResponse.dataModel;
      // promptsVM.dropDownData.productAuthCode = promptsVM.dropDownRestrictionData[0].restrictions;
      for (var i = 0; i < apiResponse.dataModel.length; i++) {
        // if (apiResponse.dataModel[i].name === 'MANUAL ENTRY') {
        //     promptsVM.dropDownRestrictionData.miscEntry = apiResponse.dataModel[i].restrictions;
        // }
        if (apiResponse.dataModel[i].name === "TIME PROFILE") {
          promptsVM.dropDownRestrictionData.timeProfile =
            apiResponse.dataModel[i].restrictions;
        }
        // if (apiResponse.dataModel[i].name === 'PRODUCTS') {
        //     promptsVM.dropDownRestrictionData.products = apiResponse.dataModel[i].restrictions;
        // }
        // if (apiResponse.dataModel[i].name === 'LIMIT CODE') {
        //     promptsVM.dropDownRestrictionData.gallonsPerTransaction = apiResponse.dataModel[i].restrictions;
        // }
        // if (apiResponse.dataModel[i].name === 'PROD CODE') {
        //     promptsVM.dropDownRestrictionData.allowedProducts = apiResponse.dataModel[i].restrictions;
        // }
        // if (apiResponse.dataModel[i].name === 'AUTH CODE') {
        //     promptsVM.dropDownRestrictionData.restrictionCodes = apiResponse.dataModel[i].restrictions;
        // }
      }
    }

    function getPromptRestrictionListError() {}

    function getPromptLinkedCardList(networkCode) {
      $rootScope.dataLoading = true;
      cardManagementService
        .getPromptLinkedCardList(networkCode)
        .then(getPromptLinkedCardListSuccess, getPromptLinkedCardListError);
    }

    function getPromptLinkedCardListSuccess(apiResponse) {
      promptsVM.linkedCardList = apiResponse.dataModel;
      // promptsVM.linkedCardList.push(promptsVM.linkedCardObj);
      $rootScope.dataLoading = false;
    }

    function getPromptLinkedCardListError() {
      $rootScope.dataLoading = false;
    }

    /*search using advance search*/
    function advanceSearch() {
      if (
        promptsVM.searchParams.description &&
        !utils.isValidAlphaNumeric(promptsVM.searchParams.description)
      ) {
        toastr.error("Enter valid Driver Description");
      } else if (
        promptsVM.searchParams.cardDescription &&
        !utils.isValidAlphaNumeric(promptsVM.searchParams.cardDescription)
      ) {
        toastr.error("Enter valid Card Description");
      } else {
        promptsVM.currentPage = 1;
        promptsVM.firstPageItem = 1;
        promptsVM.secondPageItem = 2;
        getPromptCardList();
      }
    }
    /*search using card number*/
    function cardNumberSearch() {
      if (
        promptsVM.searchParams.searchCardNo &&
        promptsVM.searchParams.searchCardNo.length < 3
      ) {
        toastr.warning(toastrMessage.userManagement.searchCondition);
      } else if (
        promptsVM.searchParams.description &&
        !utils.isValidAlphaNumeric(promptsVM.searchParams.description)
      ) {
        toastr.error("Enter valid Driver Description");
      } else if (
        promptsVM.searchParams.cardDescription &&
        !utils.isValidAlphaNumeric(promptsVM.searchParams.cardDescription)
      ) {
        toastr.error("Enter valid Card Description");
      } else {
        clearSearchParams();
        promptsVM.currentPage = 1;
        promptsVM.firstPageItem = 1;
        promptsVM.secondPageItem = 2;
        getPromptCardList();
      }
    }
    /*To get the prompt card list from search*/
    function getPromptCardList() {
      if (
        promptsVM.searchParams.network &&
        promptsVM.searchParams.network === "Select"
      ) {
        promptsVM.searchParams.network = null;
      }
      if (
        promptsVM.searchParams.dept &&
        promptsVM.searchParams.dept === "Select"
      ) {
        promptsVM.searchParams.dept = null;
      }
      // var payload = {
      //     "descriptionType": promptsVM.searchParams.descType ? promptsVM.searchParams.descType.type : '',
      //     "description": promptsVM.searchParams.description ? promptsVM.searchParams.description : '',
      //     "idType": promptsVM.searchParams.idType ? promptsVM.searchParams.idType.type : '',
      //     "idFrom": promptsVM.searchParams.idNumFrom ? promptsVM.searchParams.idNumFrom : '',
      //     "idTo": promptsVM.searchParams.idNumTo ? promptsVM.searchParams.idNumTo : '',
      //     "network": promptsVM.searchParams.network ? promptsVM.searchParams.network : '',
      //     "Status": promptsVM.searchParams.Status ? promptsVM.searchParams.Status : '',
      //     "linkedCard": promptsVM.searchParams.linkedCard ? promptsVM.searchParams.linkedCard : '',
      //     "department": promptsVM.searchParams.dept ? promptsVM.searchParams.dept : '',
      //     "searchString": promptsVM.searchParams.searchCardNo ? (promptsVM.searchParams.searchCardNo.name ? promptsVM.searchParams.searchCardNo.name : promptsVM.searchParams.searchCardNo) : '',
      //     "accntNum": "All Accounts",
      //     "pageNumber": promptsVM.currentPage,
      //     "sortingParameter": promptsVM.searchParams.sortingParameter ? promptsVM.searchParams.sortingParameter : 'ID',
      //     "sortingDirection": promptsVM.searchParams.sortingDirection ? promptsVM.searchParams.sortingDirection : 'asc',
      //     "pageRows": 20
      // }
      $rootScope.dataLoading = true;
      if (
        promptsVM.searchParams.linkedCard &&
        promptsVM.searchParams.linkedCard.length === 0
      ) {
        promptsVM.searchParams.linkedCard = null;
      }
      var payload = {
        Filter: {
          PageNo: promptsVM.currentPage,
          PageSize: 20,
          SortColumn: promptsVM.searchParams.sortingParameter
            ? promptsVM.searchParams.sortingParameter
            : "DriverID",
          SortOrder: promptsVM.searchParams.sortingDirection
            ? promptsVM.searchParams.sortingDirection
            : "asc",
        },
        Search: {
          AccountNo: localStorage.selectedAccount,
          DriverID: promptsVM.searchParams.driverId
            ? promptsVM.searchParams.driverId
            : null,
          CardDescription: promptsVM.searchParams.linkedCard
            ? promptsVM.searchParams.linkedCard.toString()
            : null,
          Status: promptsVM.searchParams.Status
            ? promptsVM.searchParams.Status
            : "A",
          PromptDescription: promptsVM.searchParams.description
            ? promptsVM.searchParams.description
            : null,
          Network: promptsVM.searchParams.network
            ? promptsVM.searchParams.network
            : null,
          // "DriverIDFrom": promptsVM.searchParams.idNumFrom ? promptsVM.searchParams.idNumFrom : null,
          // "DriverIDTo": promptsVM.searchParams.idNumTo ? promptsVM.searchParams.idNumTo : null,
          Department: promptsVM.searchParams.dept
            ? promptsVM.searchParams.dept
            : null,
          LinkedCard: promptsVM.searchParams.cardDescription
            ? promptsVM.searchParams.cardDescription
            : null,
        },
      };
      localStorage.promptsPayload = JSON.stringify(payload);

      // var payload = {
      //     "Filter": {
      //         "PageNo": 1,
      //         "PageSize": 20,
      //         "SortColumn": "network",
      //         "SortOrder": "asc"
      //     },
      //     "Search": {
      //         "AccountNo": "0050687",
      //         "DriverID": "10111",
      //         "CardDescription": "DRIVER ID",
      //         "Status": "L",
      //         "PromptDescription": "ZACK MILLER",
      //         "Network": "C",
      //         "DriverIDFrom": "10111",
      //         "DriverIDTo":"10111",
      //         "Department":""
      //     }
      //  };

      cardManagementService
        .getPromptCardList(payload)
        .then(getPromptCardListSuccess, getPromptCardListError);
    }
    /*success callback of getPromptCardList*/
    function getPromptCardListSuccess(apiResponse) {
      promptsVM.promptCardList = apiResponse.DataModel;
      promptsVM.searchCount = apiResponse.TotalRecords;
      promptsVM.maxPage = Math.ceil(promptsVM.searchCount / 20);
      promptsVM.dataCopy = JSON.parse(JSON.stringify(apiResponse.DataModel));
      angular.forEach(promptsVM.promptCardList, function (item) {
        item.showIcon = "icon-Expand";
        item.gridData = {
          NetworkDescription: item.promptDetails.NetworkDescription,
          PinNo: item.promptDetails.PinNo,
          PromptDescription: item.promptDetails.PromptDescription,
          Status: item.promptDetails.Status,
          LinkedCardDesc: item.promptDetails.LinkedCardDesc,
          CardDescription: item.promptDetails.CardDescription,
          Department: item.promptDetails.Department,
          TiedCard: item.promptDetails.TieCard,
        };
      });
      $rootScope.dataLoading = false;
    }
    /*error callback of getPromptCardList*/
    function getPromptCardListError() {
      $rootScope.dataLoading = false;
      toastr.error(toastrMessage.common.systemNetworkFailure);
    }
    /*To show the card details of the selected row*/
    function showCardDetails(cardDetails, index) {
      promptsVM.TiedCardValueCopy = JSON.parse(
        JSON.stringify(cardDetails.gridData.TiedCard)
      );
      promptsVM.LinkedCardDescCopy = JSON.parse(
        JSON.stringify(cardDetails.gridData.LinkedCardDesc)
      );
      promptsVM.CardStatus = cardDetails.promptDetails.CardStatus;
      // var payload = {
      //     "network": cardDetails.Network,
      //     "cardType": cardDetails.CardTypeCode,
      //     "cardId": cardDetails.id
      // }
      promptsVM.showNewField = false;
      angular.forEach(promptsVM.promptCardList, function (item, key) {
        if (key === index) {
          if (item.showIcon === "icon-Expand") {
            $rootScope.dataLoading = true;
            // promptsVM.linkedCardObj = {
            //     code: item.promptDetails.TieCard,
            //     description: item.promptDetails.LinkedCardDesc
            // }
            item.showIcon = "icon-Condense";
            getPromptRestrictionDropdownList(item.promptDetails.Network);
            getPromptLinkedCardList(item.promptDetails.Network);
            promptsVM.currentIndex = index;
            promptsVM.promptDetails[index] = JSON.parse(
              JSON.stringify(item.promptDetails)
            );
            promptsVM.promptRestrictions[index] = JSON.parse(
              JSON.stringify(item.promptRestrictions)
            );

            /** NEW Timepicker */
            if (
              item.promptRestrictions.StartTimeA !== null &&
              item.promptRestrictions.StartTimeA !== ""
            ) {
              promptsVM.promptRestrictions[index].StartTimeA =
                utils.displayTime(item.promptRestrictions.StartTimeA);
            }
            if (
              item.promptRestrictions.EndTimeA !== null &&
              item.promptRestrictions.EndTimeA !== ""
            ) {
              promptsVM.promptRestrictions[index].EndTimeA = utils.displayTime(
                item.promptRestrictions.EndTimeA
              );
            }

            togglePromptTemplate(
              cardDetails.promptDetails.NetworkDescription,
              cardDetails.promptDetails.CardDescription,
              index
            );
            // cardManagementService.getPromptCardDetails(index, payload)
            //     .then(getPromptCardDetailsSuccess, getPromptCardDetailsError);

            /** For Table Row Collapse */
            $(".collapse").on("show.bs.collapse", function () {
              $(".collapse.in").each(function () {
                $(this).collapse("hide");
              });
            });
            /** */
            $rootScope.dataLoading = false;
          } else {
            item.showIcon = "icon-Expand";
          }
        } else {
          /** For Table Row Collapse */
          item.showIcon = "icon-Expand";
          /** */
        }
      });
    }

    function closeClick(item, index) {
      /** For Table Row Collapse */
      item.showIcon = "icon-Expand";
      /** */
    }

    /*success callback of showCardDetails*/
    // function getPromptCardDetailsSuccess(apiResponse) {
    //     var index = apiResponse.index;
    //     promptsVM.cardDetails[index] = apiResponse.cardDetails;
    //     promptsVM.promptRestrictions[index] = apiResponse.promptRestrictions;
    //     if(promptsVM.promptRestrictions[index].StartTimeA){
    //        promptsVM.promptRestrictions[index].StartTimeA = utils.stringToTime(promptsVM.promptRestrictions[index].StartTimeA);
    //     }
    //     if(promptsVM.promptRestrictions[index].EndTimeA){
    //         promptsVM.promptRestrictions[index].EndTimeA = utils.stringToTime(promptsVM.promptRestrictions[index].EndTimeA);
    //     }
    // }
    /*error callback of showCardDetails*/
    // function getPromptCardDetailsError() {
    // }
    /*To load the corresponding template of selected row*/
    function togglePromptTemplate(network, linkCard, index) {
      promptsVM.submitClicked = false;
      promptsVM.alreadyLinked = false;
      var cardType = network + "-" + linkCard;
      var templateURL;
      switch (cardType.toUpperCase()) {
        case "PACPRIDE-DRIVER ID":
          templateURL = "pacPridePooledDriverTemplate";
          break;
        case "VOYAGER-DRIVER ID":
          templateURL = "vfDriverIdTemplate";
          break;
        case "VOYAGER-VEHICLE ID":
          templateURL = "vfVehicleIdTemplate";
          break;
        case "CFS STD-PIN":
          templateURL = "cfPinTemplate";
          break;
        case "CFN-DRIVER ID":
          templateURL = "cfnFleetwideDriverTemplate";
          break;
        case "VOYAGER-PIN":
          templateURL = "vfPinTemplate";
          break;
        case "VOYAGER-LINKED DRIVER ID":
          templateURL = "vfPinTemplate";
          break;
        case "CFN-PIN":
          templateURL = "cfnPinTemplate";
          break;
        case "PACPRIDE-PIN":
          templateURL = "pacPridePinTemplate";
          break;

        default:
          templateURL = "";
          break;
      }
      promptsVM.showAccordianView[index] =
        "app/modules/cardManagement/promptsManagement/cardDetails/" +
        templateURL +
        ".html";
    }
    /*Advance search- toggle the search template*/
    function toggleSearch() {
      promptsVM.showAdvanceSearch = !promptsVM.showAdvanceSearch;
      promptsVM.searchParams.searchCardNo = "";
      if (promptsVM.showAdvanceSearch) {
        promptsVM.showCardSearchButton = false;
      } else {
        promptsVM.showCardSearchButton = true;
        promptsVM.searchParams.cardTypeSelected = "";
        angular.forEach(
          promptsVM.searchParams.networkItem,
          function (linkedItem) {
            if (linkedItem.isChecked) {
              linkedItem.isChecked = false;
            }
          }
        );
        promptsVM.searchParams.cardDescription = null;
        promptsVM.searchParams.dept = null;
        promptsVM.searchParams.network = null;
        promptsVM.searchParams.networkItem = null;
        promptsVM.searchParams.linkedCard = null;
      }
    }
    /*apply button click on the pop up network,Status,linked card*/
    function applyPopupDropdown(popupItem) {
      promptsVM.showNetworkPopup = false;
      promptsVM.showStatusPopup = false;
      promptsVM.showLinkedPopup = false;
      // if (popupItem === 'network') {
      //     promptsVM.searchParams.network = [];
      //     angular.forEach(promptsVM.dropDownData.network, function(networkItem) {
      //         if (networkItem.isChecked) {
      //             promptsVM.searchParams.network.push(networkItem.type);
      //         }
      //     });
      // }
      // if (popupItem === 'Status') {
      //     promptsVM.searchParams.Status = [];
      //     angular.forEach(promptsVM.dropDownData.Status, function(statusItem) {
      //         if (statusItem.isChecked) {
      //             promptsVM.searchParams.Status.push(statusItem.type);
      //         }
      //     });
      // }
      if (popupItem === "linkedCard") {
        promptsVM.searchParams.cardTypeSelected = "";
        promptsVM.searchParams.linkedCard = [];
        angular.forEach(
          promptsVM.searchParams.networkItem,
          function (linkedItem) {
            if (linkedItem.isChecked) {
              if (
                linkedItem.description === "Pooled Driver" ||
                linkedItem.description === "Fleetwide Driver"
              ) {
                promptsVM.searchParams.linkedCard.push("Driver ID");
              } else {
                promptsVM.searchParams.linkedCard.push(linkedItem.description);
              }
              if (promptsVM.searchParams.cardTypeSelected !== "") {
                promptsVM.searchParams.cardTypeSelected =
                  promptsVM.searchParams.cardTypeSelected +
                  ", " +
                  linkedItem.description;
              } else {
                promptsVM.searchParams.cardTypeSelected =
                  linkedItem.description;
              }
            }
          }
        );
      }
    }
    /*event handling of pop up clicks*/
    $rootScope.$on("hidePopup", function () {
      promptsVM.showNetworkPopup = false;
      promptsVM.showStatusPopup = false;
      promptsVM.showLinkedPopup = false;
      $scope.$apply();
    });
    /*To get all card suggestion list for smart search*/
    function getAllCards(searchKey) {
      return cardManagementService
        .getPromptSmartSearchList(searchKey)
        .then(getPromptSmartSearchListSuccess, getPromptSmartSearchListError);
    }
    /*success callback of getAllCards*/
    function getPromptSmartSearchListSuccess(apiResponse) {
      var results = [];
      angular.forEach(apiResponse.invoiceNumbers, function (item) {
        var invoices = item;
        results.push({ name: invoices });
      });
      $rootScope.dataLoading = false;
      return results;
    }
    /*error callback of getAllCards*/
    function getPromptSmartSearchListError() {}
    /*To clear the search parameters in advance search*/
    function clearSearchParams() {
      // promptsVM.searchParams.descType = promptsVM.dropDownData.descType[0];
      // promptsVM.searchParams.description = '';
      // promptsVM.searchParams.idType = promptsVM.dropDownData.idType[0];
      // promptsVM.searchParams.idNumFrom = '';
      // promptsVM.searchParams.idNumTo = '';
      promptsVM.searchParams.network = "";
      // promptsVM.searchParams.Status = '';
      promptsVM.searchParams.linkedCard = [];
      promptsVM.searchParams.dept = "";
    }
    /*To sort the card list*/
    function orderByColumn(column, icon) {
      promptsVM.searchParams.sortingParameter = column;
      angular.forEach(promptsVM.columnHeaders, function (headerItem) {
        if (headerItem.sortParam === column) {
          if (icon === "ic-filterNot" || icon === "ic-filterDesc") {
            headerItem.icon = "ic-filter";
            promptsVM.searchParams.sortingDirection = "asc";
          } else {
            headerItem.icon = "ic-filterDesc";
            promptsVM.searchParams.sortingDirection = "desc";
          }
        } else {
          headerItem.icon = "ic-filterNot";
        }
      });
      getPromptCardList();
    }
    /*pagination click*/
    function paginationClick(button) {
      var searchRequired = false;
      switch (button) {
        case "first":
          if (promptsVM.firstPageItem > 1) {
            promptsVM.firstPageItem = 1;
            promptsVM.secondPageItem = 2;
            promptsVM.currentPage = 1;
            searchRequired = true;
            $window.scrollTo(0, 0);
          }
          break;
        case "previous":
          if (promptsVM.firstPageItem > 1) {
            promptsVM.firstPageItem--;
            promptsVM.secondPageItem--;
            promptsVM.currentPage--;
            searchRequired = true;
            $window.scrollTo(0, 0);
          } else if (promptsVM.firstPageItem < promptsVM.currentPage) {
            promptsVM.currentPage--;
            searchRequired = true;
            $window.scrollTo(0, 0);
          } else {
            //sonar issue fix
          }
          break;
        case "next":
          if (promptsVM.secondPageItem < promptsVM.maxPage) {
            promptsVM.firstPageItem++;
            promptsVM.secondPageItem++;
            promptsVM.currentPage++;
            searchRequired = true;
            $window.scrollTo(0, 0);
          } else if (promptsVM.currentPage < promptsVM.secondPageItem) {
            promptsVM.currentPage++;
            searchRequired = true;
            $window.scrollTo(0, 0);
          } else {
            //sonar issue fix
          }
          break;
        case "last":
          if (
            promptsVM.secondPageItem < promptsVM.maxPage ||
            promptsVM.currentPage < promptsVM.secondPageItem
          ) {
            promptsVM.firstPageItem = promptsVM.maxPage - 1;
            promptsVM.secondPageItem = promptsVM.maxPage;
            promptsVM.currentPage = promptsVM.maxPage;
            searchRequired = true;
            $window.scrollTo(0, 0);
          }
          break;
        default:
          promptsVM.currentPage = button;
          searchRequired = true;
          $window.scrollTo(0, 0);
          break;
      }
      if (searchRequired) {
        getPromptCardList();
      }
    }
    /*Save the edit card details*/
    function editCardDetails(cardDetails, index) {
      promptsVM.submitClicked = true;
      var cardType =
        cardDetails.promptDetails.NetworkDescription +
        "-" +
        cardDetails.promptDetails.CardDescription;
      var cardData = promptsVM.promptDetails[index];
      var restrictData = promptsVM.promptRestrictions[index];
      if (cardData.Department === "Add New" || promptsVM.newDepartment) {
        // if (promptsVM.newDepartment) {
        cardData.Department = promptsVM.newDepartment;
      }
      // else {
      //     cardData.Department = '';
      // }
      // }
      switch (cardType.toUpperCase()) {
        case "PACPRIDE-DRIVER ID":
          validate_pacPridePooledDriver(
            cardDetails,
            cardData,
            restrictData,
            index
          );
          break;
        case "VOYAGER-DRIVER ID":
          validate_vfDriverId(cardDetails, cardData, restrictData, index);
          break;
        case "VOYAGER-VEHICLE ID":
          validate_vfVehicleId(cardDetails, cardData, restrictData, index);
          break;
        case "CFS STD-PIN":
          validate_cfPin(cardDetails, cardData, restrictData, index);
          break;
        case "CFN-DRIVER ID":
          validate_cfnFleetwideDriver(
            cardDetails,
            cardData,
            restrictData,
            index
          );
          break;
        case "VOYAGER-PIN":
          validate_vfPin(cardDetails, cardData, restrictData, index);
          break;
        case "VOYAGER-LINKED DRIVER ID":
          validate_vfPin(cardDetails, cardData, restrictData, index);
          break;
        default:
          toastr.error("This pin type is not editable");
          break;
      }
    }
    /*Pac Pride-Pooled Driver validations*/
    function validate_pacPridePooledDriver(
      cardDetails,
      cardData,
      resrictData,
      index
    ) {
      if (promptsVM.cardDetailsForm[index].$valid && resrictData.TimeProfile) {
        if (cardData.PinNo.length < 3) {
          toastr.error("Pin number requires min 3 digits");
        } else if (cardData.PinNo.length > 5) {
          toastr.error("Pin number requires max 5 digits");
        } else if (!utils.isValidNumberWithZero(cardData.PinNo)) {
          toastr.error("Enter valid pin number");
        } else if (!utils.isValidAlphaNumeric(cardData.PromptDescription)) {
          toastr.error("Enter valid description");
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else {
          saveCardDetails(cardDetails, cardData, resrictData, index);
        }
      } else {
        toastr.error("Please enter all the required fields");
      }
    }
    /*VF-Driver ID validations*/
    function validate_vfDriverId(cardDetails, cardData, resrictData, index) {
      if (
        promptsVM.restrictionsForm[index].$valid &&
        cardData.PinNo &&
        cardData.PromptDescription
      ) {
        if (
          cardData.PromptDescription &&
          !utils.isValidAlphaNumeric(cardData.PromptDescription)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in Driver Name "
          );
        } else if (
          (resrictData.DollarsPerDay &&
            !utils.isValidNumber(resrictData.DollarsPerDay)) ||
          (resrictData.DollarsPerWeek &&
            !utils.isValidNumber(resrictData.DollarsPerWeek)) ||
          (resrictData.DollarsPerMonth &&
            !utils.isValidNumber(resrictData.DollarsPerMonth)) ||
          (resrictData.DollarsPerCycle &&
            !utils.isValidNumber(resrictData.DollarsPerCycle))
        ) {
          toastr.error("Enter valid Dollar value");
        } else if (
          (resrictData.DollarsPerDay && resrictData.DollarsPerDay < 100) ||
          (resrictData.DollarsPerDay && resrictData.DollarsPerDay > 999999999)
        ) {
          // toastr.error("Dollars Per Day must be between $100 and $999,999,999");
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(errorMsg);
        } else if (
          resrictData.DollarsPerWeek &&
          (resrictData.DollarsPerWeek < 100 ||
            resrictData.DollarsPerWeek > 999999999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(errorMsg);
        } else if (
          resrictData.DollarsPerMonth &&
          (resrictData.DollarsPerMonth < 100 ||
            resrictData.DollarsPerMonth > 999999999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(errorMsg);
        } else if (
          (resrictData.DollarsPerCycle && resrictData.DollarsPerCycle < 100) ||
          (resrictData.DollarsPerCycle &&
            resrictData.DollarsPerCycle > 999999999)
        ) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        } else if (
          resrictData.TransactionsPerDay &&
          !utils.isValidNumber(resrictData.TransactionsPerDay)
        ) {
          toastr.error("Enter valid Trasactions Per Day");
        } else if (
          (resrictData.TransactionsPerDay &&
            resrictData.TransactionsPerDay < 1) ||
          (resrictData.TransactionsPerDay &&
            resrictData.TransactionsPerDay > 99)
        ) {
          toastr.error("Transactions Per Day must be between 1 and 99");
        } else if (
          resrictData.TransactionsPerWeek &&
          !utils.isValidNumber(resrictData.TransactionsPerWeek)
        ) {
          toastr.error("Enter valid Trasactions Per Week");
        } else if (
          parseInt(resrictData.TransactionsPerWeek) < 1 ||
          parseInt(resrictData.TransactionsPerWeek) > 999
        ) {
          toastr.error("Transactions Per Week must be between 1 and 999");
        } else if (
          resrictData.TransactionsPerMonth &&
          !utils.isValidNumber(resrictData.TransactionsPerMonth)
        ) {
          toastr.error("Enter valid Trasactions Per Month");
        } else if (
          parseInt(resrictData.TransactionsPerMonth) < 1 ||
          parseInt(resrictData.TransactionsPerMonth) > 9999
        ) {
          toastr.error("Transactions Per Month must be between 1 and 9999");
        } else if (
          parseInt(resrictData.TransactionsPerCycle) < 1 ||
          parseInt(resrictData.TransactionsPerCycle) > 9999
        ) {
          toastr.error("Transactions Per Cycle must be between 1 and 9999");
        } else if (
          resrictData.MaxDollarsPerTransactions &&
          !utils.isValidNumber(resrictData.MaxDollarsPerTransactions)
        ) {
          toastr.error("Enter valid Max Dollar Per Trasactions");
        } else if (
          parseInt(resrictData.MaxDollarsPerTransactions < 100) ||
          parseInt(resrictData.MaxDollarsPerTransactions) > 999999999
        ) {
          toastr.error(
            "Max Dollar Per Transactions must be between $100 and $999,999,999"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else {
          if (resrictData.StartTimeA || resrictData.EndTimeA) {
            if (resrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (resrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (resrictData.StartTimeA > resrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(resrictData));
          if (
            resrictData.StartTimeA &&
            typeof resrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(resrictData.StartTimeA)
            );
          }
          if (
            resrictData.EndTimeA &&
            typeof resrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(resrictData.EndTimeA)
            );
          }
          if (cardData.DriversLicenseState) {
            var selectedLicenseState = $filter("filter")(
              promptsVM.dropDownData.stateRestriction,
              { code: cardData.DriversLicenseState },
              true
            );
            if (selectedLicenseState.length > 0) {
              newCardData.DriversLicenseState =
                selectedLicenseState[0].description;
            }
          }
          saveCardDetails(cardDetails, newCardData, newRestrictData, index);
        }
      } else {
        // console.log(resrictData.StartTimeA);
        // console.log(promptsVM.promptRestrictions[promptsVM.currentIndex].StartTimeA);
        if (resrictData.StartTimeA === null || resrictData.EndTimeA === null) {
          if (promptsVM.restrictionsForm[index].$error.hasOwnProperty("time")) {
            toastr.error("Please clear invalid time");
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }
    /*VF-Veh ID validations*/
    function validate_vfVehicleId(cardDetails, cardData, resrictData, index) {
      if (
        promptsVM.restrictionsForm[index].$valid &&
        cardData.PinNo &&
        cardData.PromptDescription
      ) {
        if (
          (resrictData.DollarsPerDay &&
            !utils.isValidNumber(resrictData.DollarsPerDay)) ||
          (resrictData.DollarsPerWeek &&
            !utils.isValidNumber(resrictData.DollarsPerWeek)) ||
          (resrictData.DollarsPerMonth &&
            !utils.isValidNumber(resrictData.DollarsPerMonth)) ||
          (resrictData.DollarsPerCycle &&
            !utils.isValidNumber(resrictData.DollarsPerCycle))
        ) {
          toastr.error("Enter valid Dollar value");
        } else if (
          cardData.License &&
          !utils.isValidAlphaNumeric(cardData.License)
        ) {
          toastr.error("Enter valid License value");
        } else if (
          (resrictData.DollarsPerDay && resrictData.DollarsPerDay < 100) ||
          (resrictData.DollarsPerDay && resrictData.DollarsPerDay > 999999999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerDay;
          toastr.error(errorMsg);
        } else if (
          resrictData.DollarsPerWeek &&
          (resrictData.DollarsPerWeek < 100 ||
            resrictData.DollarsPerWeek > 999999999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerWeek;
          toastr.error(errorMsg);
        } else if (
          resrictData.DollarsPerMonth &&
          (resrictData.DollarsPerMonth < 100 ||
            resrictData.DollarsPerMonth > 999999999)
        ) {
          errorMsg = toastrMessage.cardControlErrorMessage.dollarPerMon;
          toastr.error(errorMsg);
        } else if (
          (resrictData.DollarsPerCycle && resrictData.DollarsPerCycle < 100) ||
          (resrictData.DollarsPerCycle &&
            resrictData.DollarsPerCycle > 999999999)
        ) {
          toastr.error(
            "Dollars Per Cycle must be between $100 and $999,999,999"
          );
        } else if (
          resrictData.TransactionsPerDay &&
          !utils.isValidNumber(resrictData.TransactionsPerDay)
        ) {
          toastr.error("Enter valid Trasactions Per Day");
        } else if (
          (resrictData.TransactionsPerDay &&
            resrictData.TransactionsPerDay < 1) ||
          (resrictData.TransactionsPerDay &&
            resrictData.TransactionsPerDay > 99)
        ) {
          toastr.error("Transactions Per Day must be between 1 and 99");
        } else if (
          resrictData.TransactionsPerWeek &&
          !utils.isValidNumber(resrictData.TransactionsPerWeek)
        ) {
          toastr.error("Enter valid Trasactions Per Week");
        } else if (
          parseInt(resrictData.TransactionsPerWeek) < 1 ||
          parseInt(resrictData.TransactionsPerWeek) > 999
        ) {
          toastr.error("Transactions Per Week must be between 1 and 999");
        } else if (
          resrictData.TransactionsPerMonth &&
          !utils.isValidNumber(resrictData.TransactionsPerMonth)
        ) {
          toastr.error("Enter valid Trasactions Per Month");
        } else if (
          parseInt(resrictData.TransactionsPerMonth) < 1 ||
          parseInt(resrictData.TransactionsPerMonth) > 9999
        ) {
          toastr.error("Transactions Per Month must be between 1 and 9999");
        } else if (
          parseInt(resrictData.TransactionsPerCycle) < 1 ||
          parseInt(resrictData.TransactionsPerCycle) > 9999
        ) {
          toastr.error("Transactions Per Cycle must be between 1 and 9999");
        } else if (
          resrictData.MaxDollarsPerTransactions &&
          !utils.isValidNumber(resrictData.MaxDollarsPerTransactions)
        ) {
          toastr.error("Enter valid Max Dollar Per Trasactions");
        } else if (
          parseInt(resrictData.MaxDollarsPerTransactions) < 100 ||
          parseInt(resrictData.MaxDollarsPerTransactions) > 999999999
        ) {
          toastr.error(
            "Max Dollar Per Transactions must be between $100 and $999,999,999"
          );
        } else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else {
          if (resrictData.StartTimeA || resrictData.EndTimeA) {
            if (resrictData.StartTimeA === null) {
              toastr.error("Please enter valid Start Time");
              return;
            } else if (resrictData.EndTimeA === null) {
              toastr.error("Please enter valid End Time");
              return;
            } else if (resrictData.StartTimeA > resrictData.EndTimeA) {
              toastr.error("End Time should be greater than Start Time");
              return;
            }
          }
          var newCardData = JSON.parse(JSON.stringify(cardData));
          var newRestrictData = JSON.parse(JSON.stringify(resrictData));
          if (
            resrictData.StartTimeA &&
            typeof resrictData.StartTimeA !== "string"
          ) {
            newRestrictData.StartTimeA = utils.getFromToTimeFormat(
              new Date(resrictData.StartTimeA)
            );
          }
          if (
            resrictData.EndTimeA &&
            typeof resrictData.EndTimeA !== "string"
          ) {
            newRestrictData.EndTimeA = utils.getFromToTimeFormat(
              new Date(resrictData.EndTimeA)
            );
          }
          saveCardDetails(cardDetails, newCardData, newRestrictData, index);
        }
      } else {
        if (resrictData.StartTimeA === null || resrictData.EndTimeA === null) {
          if (promptsVM.restrictionsForm[index].$error.hasOwnProperty("time")) {
            toastr.error("Please clear invalid time");
            return;
          }
        }
        toastr.error("Please enter all the required fields");
      }
    }
    /*CF-Pin validations*/
    function validate_cfPin(cardDetails, cardData, resrictData, index) {
      if (promptsVM.cardDetailsForm[index].$valid) {
        if (
          cardData.LinkedCardDesc &&
          !utils.isValidAlphaNumeric(cardData.LinkedCardDesc)
        ) {
          toastr.error("Enter valid Card Description");
        } else if (
          cardData.PromptDescription &&
          !utils.isValidAlphaNumeric(cardData.PromptDescription)
        ) {
          toastr.error("Enter valid Name");
        } else if (
          cardData.TieCard &&
          !utils.isValidNumberWithZero(cardData.TieCard)
        ) {
          toastr.error("Enter valid Linked Card #");
        } else if (promptsVM.alreadyLinked) {
          toastr.warning("This card is already linked");
        } else {
          saveCardDetails(cardDetails, cardData, resrictData, index);
        }
      } else {
        toastr.error("Please enter all the required fields");
      }
    }
    /*CFN-Fleetwide Driver validations*/
    function validate_cfnFleetwideDriver(
      cardDetails,
      cardData,
      resrictData,
      index
    ) {
      console.log("forms", promptsVM.cardDetailsForm[index]);
      if (
        promptsVM.cardDetailsForm[index].$valid &&
        resrictData.TimeProfile &&
        cardData.PinNo
      ) {
        if (cardData.PinNo && !utils.isValidNumberWithZero(cardData.PinNo)) {
          toastr.error("Enter valid Pin number");
        } else if (
          cardData.PromptDescription &&
          !utils.isValidAlphaNumeric(cardData.PromptDescription)
        ) {
          toastr.error("Enter valid description");
        }
        // else if (resrictData.CustomPrompt && !utils.isValidAlphaNumeric(resrictData.CustomPrompt)) {
        //     toastr.error("Enter valid custom prompt");
        // }
        else if (
          cardData.Department &&
          !utils.isValidAlphaNumeric(cardData.Department)
        ) {
          toastr.error(
            "Special symbols/characters are not allowed in New Department"
          );
        } else {
          saveCardDetails(cardDetails, cardData, resrictData, index);
        }
      } else {
        toastr.error("Please enter all the required fields");
      }
    }
    /*VF-Pin validations*/
    function validate_vfPin(cardDetails, cardData, resrictData, index) {
      if (
        promptsVM.cardDetailsForm[index].$valid &&
        cardData.TieCard &&
        cardData.PinNo &&
        cardData.PromptDescription
      ) {
        if (
          cardData.TieCard &&
          !utils.isValidNumberWithZero(cardData.TieCard)
        ) {
          toastr.error("Enter valid Linked card #");
        } else if (promptsVM.alreadyLinked) {
          toastr.warning("This card is already linked");
        } else {
          saveCardDetails(cardDetails, cardData, resrictData, index);
        }
      } else {
        toastr.error("Please enter all the required fields");
      }
    }
    /*saves card details and restrict details*/
    function saveCardDetails(cardDetails, cardData, resrictData, index) {
      // var payLoad = {
      //     "network": cardDetails.network,
      //     "cardType": cardDetails.linkedCard,
      //     "cardDetails": cardData,
      //     "promptRestrictions": resrictData
      // };

      $rootScope.dataLoading = true;
      var oldData = promptsVM.dataCopy[index];
      var payLoad = {
        AccountNo: localStorage.selectedAccount,
        CardNo: cardData.TieCard,
        OrgId: localStorage.orgID,
        CardTypeDescription: cardData.CardDescription,
        CardTypeCode: cardData.CardTypeCode,
        Network: cardData.NetworkDescription,
        NetworkCode: cardData.Network,
        Model: {
          PromptDetails: cardData,
          PromptRestrictions: resrictData,
        },
        DeltaModel: {
          PromptDetails: oldData.promptDetails,
          PromptRestrictions: oldData.promptRestrictions,
        },
      };
      //Api call
      cardManagementService
        .editDriverPinDetails(payLoad)
        .then(editDriverPinDetailsSuccess, editDriverPinDetailsError);
    }

    function editDriverPinDetailsSuccess(apiResponse) {
      if (apiResponse.statusCode === 200) {
        toastr.success("Saved Successfully.");
        // $state.go('root.prompts');
        $rootScope.dataLoading = false;
        getPromptCardList();
      } else {
        $rootScope.dataLoading = false;
      }
    }

    function editDriverPinDetailsError() {
      $rootScope.dataLoading = false;
      toastr.error("Failed to save");
    }

    /*To get the Time profile list*/
    function manageTimeProfile(index) {
      promptsVM.showTimeProfile[index] = !promptsVM.showTimeProfile[index];
      if (promptsVM.showTimeProfile[index]) {
        promptsVM.timeProfileDetails[index] = [
          {
            profileName: "D001412",
            profileDesc: "Philip",
          },
          {
            profileName: "D001246",
            profileDesc: "Gerald",
          },
          {
            profileName: "D001419",
            profileDesc: "Samuel",
          },
        ];
      }
    }
    /*To edit time profile*/
    function editProfileDetails(parent, index) {
      promptsVM.editProfile = [];
      promptsVM.editProfile[parent] = [];
      if (promptsVM.isAddNewProfile[parent]) {
        promptsVM.isAddNewProfile[parent] = false;
        promptsVM.timeProfileDetails[parent].splice(0, 1);
      }
      promptsVM.editProfile[parent][index] = true;
    }
    /*To add new time profile*/
    function addNewProfile(profileIndex) {
      if (!promptsVM.isAddNewProfile[profileIndex]) {
        var profileObj = {
          profileName: "",
          profileDesc: "",
        };
        promptsVM.timeProfileDetails[profileIndex].splice(0, 0, profileObj);
        promptsVM.isAddNewProfile[profileIndex] = true;
        promptsVM.editProfile = [];
        promptsVM.editProfile[profileIndex] = [];
        promptsVM.editProfile[profileIndex][0] = true;
      }
    }
    /*Save new Time Profile*/
    function saveNewProfile(profileIndex) {
      //save api call
    }
    /*cancel new Time profile*/
    function cancelAddProfile(parent, index) {
      promptsVM.editProfile = [];
      promptsVM.editProfile[parent] = [];
      if (promptsVM.isAddNewProfile[parent]) {
        promptsVM.isAddNewProfile[parent] = false;
        promptsVM.timeProfileDetails[parent].splice(0, 1);
      }
      promptsVM.editProfile[parent][index] = false;
    }
    /*delete Time profile*/
    function deleteProfileDetails(parent, index) {
      //delete api call
    }
    /*confirmation for locking */
    function changeStatusPin(Status, index) {
      if (Status === "A") {
        promptsVM.promptDetails[index].Status = "A";
      } else if (
        Status === "L" &&
        promptsVM.promptDetails[index].Status === "A" &&
        promptsVM.promptDetails[index].CardStatus === "A"
      ) {
        $confirm(
          {
            title: "Lock Card",
            text: "Pin linked to card, would you also like to lock card?",
            ok: "Yes",
            cancel: "No",
          },
          {
            size: "sm",
          }
        )
          .then(function () {
            promptsVM.promptDetails[index].Status = "L";
            promptsVM.promptDetails[index].CardStatus = "L";
          })
          .catch(function () {
            promptsVM.promptDetails[index].Status = "A";
          });
      } else {
        promptsVM.promptDetails[index].Status = "L";
        promptsVM.promptDetails[index].CardStatus = "L";
      }
    }

    function changeStatus(Status, index) {
      if (Status === "A") {
        promptsVM.promptDetails[index].Status = "A";
      } else if (Status === "L") {
        promptsVM.promptDetails[index].Status = "L";
      }
    }

    function onNetworkChange() {
      promptsVM.searchParams.linkedCard = null;
      if (promptsVM.searchParams.networkItem) {
        angular.forEach(promptsVM.searchParams.networkItem, function (item) {
          if (item.isChecked) {
            item.isChecked = false;
          }
        });
      }
      angular.forEach(promptsVM.dropDownData.network, function (item) {
        if (item.code === promptsVM.searchParams.network) {
          promptsVM.searchParams.networkItem = item.cardType;
          // promptsVM.networkCode = item.code;
          promptsVM.searchParams.cardTypeSelected = "";
        }
      });
    }

    function validatePin(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
        } else {
          // if (cardNo.length === 4) {
          if (parseInt(cardNo.slice(0, 2)) === 0) {
            toastr.error(toastrMessage.common.voyagerPinValidation);
            promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
            return;
          }
          // }
          // else if (cardNo.length > 4) {
          //     if (parseInt(cardNo.slice(0, 1)) === 0) {
          //         toastr.error(toastrMessage.common.voyagerPinValidation);
          //         promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
          //         return;
          //     }
          // }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: network,
          };
          cardManagementService
            .getPinValidation(requestData)
            .then(getPinValidationSuccess, getPinValidationError);
        }
      }
    }

    function validateCFSStdPin(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          errorMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
          toastr.error(errorMsg);
          promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error("Pin must be 4 digit");
          promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
        } else {
          if (parseInt(cardNo.slice(0, 1)) === 0) {
            errorMsg = toastrMessage.cardControlErrorMessage.invalidPinDriverId;
            toastr.error(errorMsg);
            promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: network,
          };
          cardManagementService
            .getPinValidation(requestData)
            .then(getPinValidationSuccess, getPinValidationError);
        }
      }
    }

    function getPinValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isExists) {
        promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
        toastr.warning("Pin already exist.");
      }
    }

    function getPinValidationError() {}

    function validateDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.voyagerPinValidation);
          promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
        } else {
          // if (cardNo.length === 4) {
          if (parseInt(cardNo.slice(0, 2)) === 0) {
            toastr.error(toastrMessage.common.voyagerPinValidation);
            promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
            return;
          }
          // }
          //  else if (cardNo.length > 4) {
          //     if (parseInt(cardNo.slice(0, 1)) === 0) {
          //         toastr.error(toastrMessage.common.voyagerPinValidation);
          //         promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
          //         return;
          //     }
          // }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: network,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function validateCFNDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.cfnPinValidation);
          promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
          return;
        }
        if (cardNo.length < 5) {
          toastr.error(toastrMessage.common.cfnPinValidation);
          promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
        } else {
          if (parseInt(cardNo.slice(0, 1)) === 0) {
            toastr.error(toastrMessage.common.cfnPinValidation);
            promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: network,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function validatePacprideDriver(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          toastr.error(toastrMessage.common.pacpridePinValidation);
          promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
          return;
        }
        if (cardNo.length < 4) {
          toastr.error(toastrMessage.common.pacpridePinValidation);
          promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
        } else {
          if (parseInt(cardNo.slice(0, 3)) === 0) {
            toastr.error(toastrMessage.common.pacpridePinValidation);
            promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
            return;
          }
          var requestData = {
            AccountNumber: localStorage.selectedAccount,
            PinNo: cardNo,
            NetworkCode: network,
          };
          cardManagementService
            .getDriverValidation(requestData)
            .then(getDriverValidationSuccess, getDriverValidationError);
        }
      }
    }

    function getDriverValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isExists) {
        promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
        toastr.warning("Driver already exist.");
      }
    }

    function getDriverValidationError() {}

    function validateVehicle(cardNo, network) {
      if (cardNo) {
        if (parseInt(cardNo) === 0) {
          errorMsg = toastrMessage.cardControlErrorMessage.invalidVhclId;
          toastr.error(errorMsg);
          promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
          return;
        }
        var requestData = {
          AccountNumber: localStorage.selectedAccount,
          PinNo: cardNo,
          NetworkCode: network,
        };
        cardManagementService
          .getVehicleValidation(requestData)
          .then(getVehicleValidationSuccess, getVehicleValidationError);
      }
    }

    function getVehicleValidationSuccess(apiResponse) {
      if (apiResponse.dataModel && apiResponse.dataModel.isExists) {
        promptsVM.promptDetails[promptsVM.currentIndex].PinNo = null;
        toastr.warning("Vehicle already exist.");
      }
    }

    function getVehicleValidationError() {}

    function printTable() {
      $state.go("root.printPrompt");
      // window.print();
    }

    function changeLinkedCard(item, index) {
      promptsVM.alreadyLinked = false;
      var selectedLinkedCard = $filter("filter")(
        promptsVM.linkedCardList,
        { code: item },
        true
      );
      if (selectedLinkedCard.length > 0) {
        if (
          selectedLinkedCard[0].linked === "N" &&
          promptsVM.TiedCardValueCopy != item &&
          promptsVM.CardStatus === "A"
        ) {
          toastr.warning(
            "This Pin was originally tied to Card " +
              promptsVM.TiedCardValueCopy +
              " This card will remain active unless cancelled."
          );
          promptsVM.promptDetails[index].LinkedCardDesc =
            selectedLinkedCard[0].description;
        } else if (
          selectedLinkedCard[0].linked === "N" &&
          promptsVM.TiedCardValueCopy != item
        ) {
          toastr.warning(
            "This Pin was originally tied to Card " +
              promptsVM.TiedCardValueCopy
          );
          promptsVM.promptDetails[index].LinkedCardDesc =
            selectedLinkedCard[0].description;
        } else if (
          selectedLinkedCard[0].linked === "Y" &&
          promptsVM.TiedCardValueCopy != item
        ) {
          promptsVM.alreadyLinked = true;
          promptsVM.promptDetails[index].LinkedCardDesc =
            selectedLinkedCard[0].description;
          $timeout(function () {
            promptsVM.promptDetails[index].TieCard =
              promptsVM.TiedCardValueCopy;
            promptsVM.promptDetails[index].LinkedCardDesc =
              promptsVM.LinkedCardDescCopy;
            promptsVM.alreadyLinked = false;
          }, 5000);
          toastr.warning("This card is already linked");
        } else {
          promptsVM.promptDetails[index].LinkedCardDesc =
            selectedLinkedCard[0].description;
        }
      }
    }

    function exportTable(fileType) {
      var payload = JSON.parse(localStorage.promptsPayload);
      payload.Filter.PageNo = 0;
      payload.Filter.PageSize = 0;
      // $rootScope.$emit('exportPaymentEvent');
      if (fileType === "xls") {
        $rootScope.dataLoading = true;
        cardManagementService
          .exportPrompts(payload)
          .then(exportPromptsSuccess, exportPromptsError);
      }
    }

    function exportPromptsSuccess(apiResponse) {
      $rootScope.dataLoading = false;
      $window.open(apiResponse.filePath);
    }
    /*error callback for export payment*/
    function exportPromptsError() {
      $rootScope.dataLoading = false;
      toastr.error(toastrMessage.common.systemNetworkFailure);
    }

    /** select/change network from dropdown */
    function selectNetwork() {
      if (promptsVM.searchParams.network) {
        promptsVM.showLinkedPopup = !promptsVM.showLinkedPopup;
      }
    }

    /*when account changed*/
    $scope.$on("accountChanged", function () {
      var hasPermission = false;
      promptsVM.searchParams.driverId = null;
      promptsVM.searchParams.linkedCard = null;
      promptsVM.searchParams.Status = "";
      promptsVM.searchParams.description = null;
      promptsVM.searchParams.network = null;
      promptsVM.searchParams.dept = null;
      promptsVM.searchParams.cardDescription = null;
      promptsVM.searchParams.networkItem = null;
      if (localStorage.accountPermission) {
        angular.forEach(
          JSON.parse(localStorage.accountPermission),
          function (item, key) {
            if (item.option.toLowerCase().indexOf("card management") !== -1) {
              if (item.displayName === "R" || item.displayName === "W") {
                hasPermission = true;
                promptsVM.searchParams.sortingParameter = "";
                promptsVM.searchParams.sortingDirection = "";
                init();
              }
            }
            var arrayLength =
              JSON.parse(localStorage.accountPermission).length - 1;
            if (key === arrayLength && !hasPermission) {
              $state.go("root.dashboard");
            }
          }
        );
      }
    });
  }
})();
