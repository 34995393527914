/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.techInsight')
        .controller('TechInsightController', TechInsightController);


    TechInsightController.$inject = ['$window', '$rootScope', '$scope', '$state', 'techInsightService', 'toastr', '$confirm', 'utils', 'toastrMessage'];

    function TechInsightController($window, $rootScope, $scope, $state, techInsightService, toastr, $confirm, utils, toastrMessage) {
        var techInsightVM = this;
        techInsightVM.showPopup = false;
        techInsightVM.showAuditSearchButton = true;
        techInsightVM.showAdvanceSearch = false;
        techInsightVM.auditParams = {};
        techInsightVM.maxPage = 2;
        techInsightVM.numOfRows = [{ "size": 10 }, { "size": 50 }, { "size": 100 }];

        techInsightVM.toggleSearch = toggleSearch;
        techInsightVM.getSearchAreaDetails = getSearchAreaDetails;
        techInsightVM.orderByColumn = orderByColumn;
        techInsightVM.paginationClick = paginationClick;
        techInsightVM.chooseEventTypes = chooseEventTypes;
        techInsightVM.applyEventTypes = applyEventTypes;
        techInsightVM.getAuditList = getAuditList;
        techInsightVM.loadAuditList = loadAuditList;
        techInsightVM.sortingIconClass = sortingIconClass;
        techInsightVM.clearCalender = clearCalender;

        init();

        /*function declarations*/

        /*initial loading*/
        function init() {
            techInsightVM.pageSize = techInsightVM.numOfRows[0];
            techInsightVM.scfId = null;
            techInsightVM.userEmailId = null;
            techInsightVM.companyName = null;
            techInsightVM.accountName = null;
            techInsightVM.accountNum = null;
            techInsightVM.datePicker = null;
            techInsightVM.selectedModulesIds = null;
            techInsightVM.datePicker = { startDate: null, endDate: null };
            techInsightVM.startYear = utils.calenderStartYear;
            techInsightVM.endYear = utils.calenderEndYear;
            techInsightVM.currentPage = 1;
            techInsightVM.firstPageItem = 1;
            techInsightVM.secondPageItem = 2;
            techInsightVM.sortingParameter = "Timestamp";
            techInsightVM.sortingDirection = "desc";
            $scope.originalAuditScope = angular.copy(techInsightVM);
            getColumnHeaders();
            getSearchAreaDetails();
        }
        /*toggles the advance search*/
        function toggleSearch() {
            techInsightVM.showAdvanceSearch = !techInsightVM.showAdvanceSearch;
            techInsightVM.searchInvoiceNo = '';
            if (techInsightVM.showAdvanceSearch) {
                techInsightVM.showAuditSearchButton = false;
            } else {
                techInsightVM.showAuditSearchButton = true;
            }
        }
        /*fetches audit module list*/
        function getSearchAreaDetails() {
            $rootScope.dataLoading = true;
            techInsightService.getAuditModulesList()
                .then(getAuditModulesListSuccess, getAuditModulesListError);
        }
        /*success callback for audit module list*/
        function getAuditModulesListSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                techInsightVM.moduleTypes = apiResponse.dataModel;
                angular.forEach(techInsightVM.moduleTypes, function(item) {
                    item.isChecked = false;
                });
            }
        }
        /*error callback for audit module list*/
        function getAuditModulesListError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches the column headers for table*/
        function getColumnHeaders() {
            //$rootScope.dataLoading = true;
            techInsightService.getAuditColumnHeaders()
                .then(getColumnHeadersSuccess, getColumnHeadersError);
        }
        /*success callback for column header*/
        function getColumnHeadersSuccess(apiResponse) {
            //$rootScope.dataLoading = false;
            techInsightVM.columnHeaders = [];
            angular.forEach(apiResponse.columnHeaders, function(item) {
                if(item === 'Timestamp'){
                    techInsightVM.columnHeaders.push({ columnName: item,sortingDirection:"desc"});
                }else{
                    techInsightVM.columnHeaders.push({ columnName: item,sortingDirection:""});
                }
            });
            techInsightVM.loadAuditList('default');
        }
         /*error callback for column header*/
        function getColumnHeadersError() {
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*fetches audit list according to search*/
        function getAuditList() {
            $rootScope.dataLoading = true;
            //clearAuditParams();

            var auditParams = {
                "CompanyName": techInsightVM.companyName,
                "OrgId": techInsightVM.scfId,
                "AccountName": techInsightVM.accountName,
                "AccountNumber": techInsightVM.accountNum,
                "UserEmailId": techInsightVM.userEmailId,
                "StartDate": techInsightVM.datePicker.startDate,
                "EndDate": techInsightVM.datePicker.endDate,
                "ModuleIds": techInsightVM.selectedModulesIds,
                "PageNumber": techInsightVM.currentPage,
                "PageSize": techInsightVM.pageSize.size,
                "SortingParameter": techInsightVM.sortingParameter,
                "SortingDirection": techInsightVM.sortingDirection
            };
            techInsightService.getAuditList(auditParams)
                .then(getAuditListSuccess, getAuditListError);
        }
         /*success callback for search audit list*/
        function getAuditListSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.dataModel !== null && apiResponse.dataModel.auditList !== null) {
                techInsightVM.auditList = apiResponse.dataModel.auditList;
                angular.forEach(techInsightVM.auditList,function(item){
                    // item.createdDate = new Date(item.createdDate+'UTC').getTime();
                    // var cDate = new Date(item.createdDate);
                    // var utcDate = new Date(cDate.getUTCFullYear(), cDate.getUTCMonth(), cDate.getUTCDate(),  cDate.getUTCHours(), cDate.getUTCMinutes(), cDate.getUTCSeconds());
                    item.createdDate = new Date(item.createdDate);
                });
                techInsightVM.searchCount = techInsightVM.auditList.length;
                if (techInsightVM.currentPage == 1) {
                    techInsightVM.totalCount = apiResponse.dataModel.totalCount;
                    techInsightVM.maxPage = Math.ceil(techInsightVM.totalCount / techInsightVM.pageSize.size);
                }
            }else{
                techInsightVM.auditList = [];
            }
        }
        /*error callback for search audit list*/
        function getAuditListError() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }
        /*to order the data by column header*/
        function orderByColumn(columnName, sortingDirection) {
            techInsightVM.sortingParameter = columnName;
            switch (sortingDirection) {
                case "":
                case "asc":
                    {
                        techInsightVM.sortingDirection = "desc";
                        break;
                    }
                case "desc":
                    {
                        techInsightVM.sortingDirection = "asc";
                        break;
                    }
                default:
                    {
                        break;
                    }
            }
            techInsightVM.columnHeaders.forEach(function(item) {
                if (item.columnName === techInsightVM.sortingParameter) {
                    item.sortingDirection = techInsightVM.sortingDirection;
                }
                else{
                    item.sortingDirection = "";
                }
            });  
            techInsightVM.loadAuditList('sorting');
        }
        /*defines the sorting icon*/
        function sortingIconClass(SortingDirection) {
            var icon;
            if(SortingDirection === 'asc'){
                icon = 'ic-filter';
            }
            else if(SortingDirection === 'desc'){
                icon = 'ic-filterDesc';
            }
            else{
                icon = 'ic-filterNot';
            }
            return icon;
        }
        /*pagination*/
        function paginationClick(button) {
            var isAuditLoadRequired = false;
            $window.scrollTo(0, 0);
            switch (button) {
                case 'first':
                    if (techInsightVM.firstPageItem > 1) {
                        techInsightVM.firstPageItem = 1;
                        techInsightVM.secondPageItem = 2;
                        techInsightVM.currentPage = 1;
                        isAuditLoadRequired = true;
                    }
                    break;
                case 'previous':
                    if (techInsightVM.firstPageItem > 1) {
                        techInsightVM.firstPageItem--;
                        techInsightVM.secondPageItem--;
                        techInsightVM.currentPage--;
                        isAuditLoadRequired = true;
                    } else if (techInsightVM.firstPageItem < techInsightVM.currentPage) {
                        techInsightVM.currentPage--;
                        isAuditLoadRequired = true;
                    }
                    break;
                case 'next':
                    if (techInsightVM.secondPageItem < techInsightVM.maxPage) {
                        techInsightVM.firstPageItem++;
                        techInsightVM.secondPageItem++;
                        techInsightVM.currentPage++;
                        isAuditLoadRequired = true;
                    } else if (techInsightVM.currentPage < techInsightVM.secondPageItem) {
                        techInsightVM.currentPage++;
                        isAuditLoadRequired = true;
                    }
                    break;
                case 'last':
                    if (techInsightVM.secondPageItem < techInsightVM.maxPage) {
                        techInsightVM.firstPageItem = techInsightVM.maxPage - 1;
                        techInsightVM.secondPageItem = techInsightVM.maxPage;
                        techInsightVM.currentPage = techInsightVM.maxPage;
                        isAuditLoadRequired = true;
                    }
                    break;
                default:
                    techInsightVM.currentPage = button;
                    isAuditLoadRequired = true;
                    break;
            }
            if (isAuditLoadRequired) {
                clearAuditParams();
                techInsightVM.getAuditList();
            }
        }
        /*toggle event type pop up*/
        function chooseEventTypes(event) {
            event.preventDefault();
            techInsightVM.showPopup = !techInsightVM.showPopup;
        }

        $rootScope.$on('hidePopup', function() {
            techInsightVM.showPopup = false;
            $scope.$apply();
        });
        /*click on apply button in pop up*/
        function applyEventTypes(eventTypes) {
            techInsightVM.showPopup = false;
            techInsightVM.selectedModulesIds = [];
            eventTypes.forEach(function(item) {
                if (item.isChecked) {
                    techInsightVM.selectedModulesIds.push(item.id);
                }
            });
        }
        /*loads the audit list*/
        function loadAuditList(loadTypes) {
            var isAuditLoadRequired = false;
            clearAuditParams();
            switch (loadTypes) {
                case 'advsearch':
                    {
                        if((techInsightVM.scfId === null || techInsightVM.scfId === "")&& techInsightVM.userEmailId === null &&
                            techInsightVM.companyName === null && techInsightVM.accountNum === null
                            && techInsightVM.accountName === null && techInsightVM.selectedModulesIds === null
                            && techInsightVM.datePicker.startDate === null && techInsightVM.datePicker.endDate === null){
                            toastr.error(toastrMessage.audit.auditSearchFieldRequired); 
                        }else {
                            isAuditLoadRequired = true;
                        }
                        break;
                    }
                case 'defaultsearch':
                case 'default':
                case 'sorting':
                case 'pagesize':
                    {
                        isAuditLoadRequired = true;
                        break;
                    }
                default:
                    {
                        break;
                    }
            }

            if (isAuditLoadRequired) {
                techInsightVM.currentPage = 1;
                techInsightVM.firstPageItem = 1;
                techInsightVM.secondPageItem = 2;
                techInsightVM.getAuditList();
            }
        }
        /*clear the values on the calender*/
        function clearCalender(){
           techInsightVM.datePicker = { startDate: null, endDate: null };
           $('#daterange').data('daterangepicker').setStartDate(new Date());
           $('#daterange').data('daterangepicker').setEndDate(new Date());
        }
        /*clears the search options*/
        function clearAuditParams() {
            if (techInsightVM.scfId === "") {
                techInsightVM.scfId = null;
            }
            if (techInsightVM.userEmailId === "") {
                techInsightVM.userEmailId = null;
            }
            if (techInsightVM.companyName === "") {
                techInsightVM.companyName = null;
            }
            if (techInsightVM.scfId === "") {
                techInsightVM.scfId = null;
            }
            if (techInsightVM.accountName === "") {
                techInsightVM.accountName = null;
            }
            if (techInsightVM.accountNum === "") {
                techInsightVM.accountNum = null;
            }
            if (techInsightVM.datePicker.startDate === "") {
                techInsightVM.datePicker.startDate = null;
            }
            if (techInsightVM.datePicker.endDate === "") {
                techInsightVM.datePicker.endDate = null;
            }
            if (techInsightVM.selectedModulesIds === []) {
                techInsightVM.selectedModulesIds = null;
            }
        }
    }
})();
