
/*jshint browser: true*/
/*global angular: true*/


(function(){
    'use strict';

    angular
        .module('scFuelsApp.dashBoard', ['scFuelsApp.login'])
        .config(routerConfiguration);

    routerConfiguration.$inject = ['$stateProvider'];
    function routerConfiguration ($stateProvider) {
        $stateProvider
            .state('root.dashboard', {
            url: '/dashboard',
            templateUrl: 'app/modules/dashboard/dashboardTemplate.html',
            controller: 'DashboardController',
            controllerAs : 'dashboardVM'
        });
    }
})();
