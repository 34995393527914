/*jshint browser: true*/
/*global angular: true*/
/*global jQuery: true*/
/*global moment: true*/

(function () {
    'use strict';

    angular.module('appConfig.module', ['toastr'])
        .constant('webServiceURL', webServiceURLCollection())
        .constant('jQuery', jQuery)
        .constant('moment', moment)
        .constant('CryptoJS', CryptoJS)
        .config(toastrConfiguration) // toastr config
        .run(disableGlobalAnimation);

    //--------------------------------------
    //function declarations

    //webservice URL constants
    function webServiceURLCollection() {
        return {
            BASE_URL: 'test/mockData/',
            // BASE_URL_DEV: 'http://172.16.9.132/CustomerPortal/api/', // Local            
            // BASE_URL_DEV: 'https://customerportal-app.azurewebsites.net/api/', // Dev
            BASE_URL_DEV:'https://customerportal-prod-web.azurewebsites.net/api/', //PROD
            // BASE_URL_DEV: 'https://customerportal-web-qa.azurewebsites.net/api/', // QA            
            // BASE_URL_DEV: 'https://customerportal-app.azurewebsites.net/api/', //New UAT            
            // BASE_URL_DEV:'https://naturalresource-webapi.azurewebsites.net/api/', // Jinran Dev

            // BASE_URL_DEV: 'https://customerportal-app-qa.azurewebsites.net/api/', //New QA
            // BASE_URL_DEV:'http://localhost:52179/api/', // Jinran local

            common: {
                metadata: ''
            },

            login: {
                loginUrl: 'Authentication/ValidateAuthentication',
                securityQuestionsURL: 'Common/GetSecurityQuestions',
                userRegistrationURL: 'Authentication/UserRegistration',
                resetPwdLinkURL: 'Authentication/ValidateEmailForReset?emailId=',
                resetPasswordURL: 'User/ResetPassword',
                validateTokenURL: 'Authentication/FetchUserByToken?expiryToken=',
                expireTokenURL: 'User/ExpireToken'
            },

            userManagement: {
                getUserAccountDetailsUrl: 'User/FetchUserList?orgId=',
                saveNewUserUrl: 'User/CreateUser',
                validateEmailUrl: 'User/ValidateEmail?emailId=',
                getAccountListUrl: 'User/FetchAccountList',
                getUserUrl: 'User/GetUser?userId=',
                getDefaultPermissionUrl: 'User/FetchDefaultPermissions',
                updateUserUrl: 'User/UpdateUser',
            },

            invoicePayments: {
                getSearchCriteriaUrl: 'commonlistcontrols', //'invoiceSearchData.json', //'commonlistcontrols', 
                getInvoicesUrl: 'invoice/invoicenumber/', //'invoiceSmartSearch.json',
                getColumnHeadersUrl: 'payment/columns',
                getPaymentHistorySearchUrl: 'payment/paymenthistorylist', //'paymentHistoryList.json' ,  
                getInvoiceColumnHeadersUrl: 'invoice/columns',
                fetchInquiryReasonsUrl: 'invoice/inquiries',
                getOpenInvoiceListUrl: 'invoice/invoicelist', //'openInvoiceList.json'
                getPaymentListUrl: 'payment/unpaidinvoices', //'paymentList.json',
                submitInquiryListUrl: 'invoice/submitInquiries',
                exportInvoicePaymentUrl: 'Payment/ExportPaymentInvoice',
                getPaymentColumnUrl: 'payment/makepaymentcolumns',
                exportPaymentsUrl: 'payment/exportmakepayments',
                makePaymentInvoiceListUrl: 'payment/submitpayment',
                // finishPaymentUrl: 'payment/finishpayment',
                saveColumnHeadersUrl: 'savecolumnheaders',
                saveMemoInvoicesUrl: 'invoice/saveMemo'
            },

            contactus: {
                contactDetailsURL: 'contactUsDropdown.json',
                contactDropdownURL: 'contactus/getdropdowns', //'contactUsDropdown.json',
                submitMessageURL: 'contactus/sendmessage'
            },

            dashboard: {
                getRecentActivitiesListURL: 'Audit/GetRecentActivities',
                getOpenInvoiceDataUrl: 'dashboard/openinvoice',
                getCriticalAlertUrl: 'dashboard/alert'
            },

            marketinginsight: {
                getGATypesURL: 'gaTypes.json',
                gaChartTypesURL: 'gaChartTypes.json',
                getOrgIdsURL: 'orgIds.json',
                getGaAuthTokenURL: 'https://www.googleapis.com/oauth2/v4/token'
            },

            techinsight: {
                getModulesURL: 'Audit/FetchModules',
                getAuditColumnHeadersURL: 'auditColumnHeaders.json',
                getAuditListURL: 'Audit/FetchAuditDetails'
            },

            headerMenu: {
                settingsPasswordUrl: 'Settings/ChangePassword',
                settingsQuestionUrl: 'Settings/ChangeSecurityQuestions',
                headerMessagesURL: 'notification/messages',
                headerNotificationsURL: 'notification/notifications',
                FAQURL: 'notification/faq',
                updateMessagesReadCountUrl: 'notification/updatemessagereadstatus',
                updateNotificationReadCountUrl: 'notification/updateNotificationReadStatus',
                headerAccountListUrl: 'user/FetchUserAccount',
                // headerAccountListUrl: 'User/FetchUserAccountListWithPagination?pageNumber=',
                getAccountPermissionUrl: 'User/FetchAccountWithPermissionForUser?accountId='
            },

            accountInfo: {
                getAccountListUrl: 'changeaccount/accountlist?pageNumber=',
                fetchAccountInformationUrl: 'changeaccount/contactinfo',
                emailValidationUrl: 'changeaccount/validateemail?email=',
                saveBillToAddressInfoUrl: 'changeaccount/savebilltoaddress',
                saveContactInformationUrl: 'changeaccount/savecontactinfo',
                savePaperlessInformationUrl: 'changeaccount/savepaperlessinfo',
                deleteContactInfoUrl: 'changeaccount/deletecontactinfo',
                deletePaperlessInfoUrl: 'changeaccount/deletepaperlessinfo',
                getStateListUrl: 'stateList.json'
            },

            manageBanner: {
                getCurrentBannersURL: 'dashboard/banner',
                saveBannerURL: 'dashboard/uploadbanner'
            },

            cardManagement: {
                getCardSmartSearchListUrl: 'invoiceSmartSearch.json',
                getLastTransactionsListUrl: 'transactions/transactiondetails', //'transactionsList.json',
                getTransactionDetailsUrl: 'transactionDetail.json',
                getTransactionParametersUrl: 'transactionParameters.json',
                getTransactionColumnsUrl: 'transactions/transactionscolumns', //'columnHeaders.json',
                exportTransactionUrl: 'transactions/exporttransactions',
                printTransactionUrl: 'transactions/printtransactions',
                saveColumnHeadersUrl: 'transactions/transactionscolumns',
                getFleetCardListUrl: 'cardmanagement/cardmanagementdetails', //'fleetCardList.json'
                getFleetCardDetailsUrl: 'fleetCardDetails.json', //'fleet/loadfleetcarddetail',
                getFleetCardDropdownListUrl: 'fleet/getdropdownfields', // 'fleetCardDropdown.json',
                getCardManagementDropdownListUrl: 'cardmanagement/getDropDownFields',
                getCardRestrictionDropdownListUrl: 'cardmanagement/getRestrictionDropDown',
                editFleetCardDetailsUrl: 'cardmanagement/editcard',
                saveFleetCardDetailsUrl: 'cardmanagement/createcard',
                editAndSaveFleetCardDetailsURL: 'cardmanagement/editThenCreatecard',  // merge edit and create action into one
                getFleetSmartSearchListUrl: 'invoiceSmartSearch.json',
                getPinValidationUrl: 'cardmanagement/validatePIN',
                getDriverValidationUrl: 'cardmanagement/validateDriver',
                getVehicleValidationUrl: 'cardmanagement/validateVehicle', 
                getPromptCardListUrl: 'promptmanagement/promptmanagementdetails', //'promptCardList.json',
                getPromptCardDetailsUrl: 'promptCardDetails.json',
                getPromptDropdownListUrl: 'promptmanagement/getdropdownfields', //'promptDropdownList.json',
                getPromptSmartSearchListUrl: 'invoiceSmartSearch.json',
                getPromptRestrictionDropdownListUrl: 'promptmanagement/getRestrictionDropDown',
                saveDriverPinDetailsUrl: 'promptmanagement/createcard',
                editDriverPinDetailsUrl: 'promptmanagement/editcard',
                printCardDetailsUrl:'cardmanagement/printcardmanagementdetails',
                exportCardManagementUrl : 'cardmanagement/exportcardmanagementdetails',
                getPromptLinkedCardListUrl: 'cardmanagement/unlinkedCards',
                exportPromptsUrl:'promptmanagement/exportpromptmanagementdetails',
                printPromptsUrl:'promptmanagement/printpromptmanagementdetails'
            },
            userActivity:{
                getUserActivity: 'useractivity/getUserActivity',
                getOtherUserActivity: 'useractivity/getOtherUserActivity'
            }
        };
    }

    toastrConfiguration.$inject = ['toastrConfig'];

    function toastrConfiguration(toastrConfig) {
        angular.extend(toastrConfig, {
            autoDismiss: true,
            containerId: 'toast-container',
            maxOpened: 1,
            newestOnTop: true,
            positionClass: 'toast-top-right',
            preventDuplicates: false,
            preventOpenDuplicates: false,
            target: 'body'
        });
    }

    disableGlobalAnimation.$inject = ['$animate'];

    function disableGlobalAnimation($animate) {
        $animate.enabled(false);
    }
})();