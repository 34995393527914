/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.login')
        .controller('settingsPasswordController', settingsPasswordController);


    settingsPasswordController.$inject = ['$rootScope', '$scope', '$state', 'toastr', '$confirm', 'toastrMessage', 'utils', 'headermenuService', '$stateParams'];

    function settingsPasswordController($rootScope, $scope, $state, toastr, $confirm, toastrMessage, utils, headermenuService, $stateParams) {
        var passwordVM = this;
        passwordVM.oldPassword = "";
        passwordVM.newPassword = "";
        passwordVM.confirmPassword = "";
        passwordVM.init = init;
        passwordVM.changePassword = changePassword;

        /*function declarations*/

        /*initial page load*/
        function init() {
            passwordVM.previousPage = $stateParams.previousPage ? $stateParams.previousPage : 'root.dashboard';
        }
        /*save change password*/
        function changePassword() {
            if (passwordVM.oldPassword && passwordVM.newPassword && passwordVM.confirmPassword) {
                if ((passwordVM.oldPassword === passwordVM.newPassword) && (passwordVM.oldPassword === passwordVM.confirmPassword)) {
                    toastr.error('Try another Password different from your Old Password.');
                } else {
                    if (passwordVM.newPassword === passwordVM.confirmPassword) {
                        if (utils.isValidPassword(passwordVM.newPassword)) {
                            $rootScope.dataLoading = true;
                            var payload = {
                                "password": passwordVM.oldPassword,
                                "newPassword": passwordVM.newPassword
                            }
                            headermenuService.settingsPassword(payload)
                                .then(settingsPasswordSuccess, settingsPasswordFailure);

                        } else {
                            toastr.error(toastrMessage.login.passwordValidation);
                        }
                    } else {
                        toastr.error(toastrMessage.login.newPasswordValidation);
                    }
                }
            } else {
                toastr.error(toastrMessage.common.mandatoryValidation);
            }
        }
        /*save change password success*/
        function settingsPasswordSuccess(apiResponse) {
            $rootScope.dataLoading = false;
            if (apiResponse.statusCode === 200) {
                $state.go('root.dashboard');
                toastr.success('Your Password has been successfully updated.');
            } else if (apiResponse.statusCode === 0 && apiResponse.statusMessage === 'Invalid credentials') {
                toastr.error('Old Password is not valid. Please try again.');
            } else if (apiResponse.statusCode === 0) {
                toastr.error(apiResponse.statusMessage);
            } else {
                toastr.error(toastrMessage.common.systemNetworkFailure);
            }
        }
        /*save change password failure*/
        function settingsPasswordFailure() {
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

    }
})();
