/*jshint browser: true*/
/*global angular: true*/

// Declare app level module which depends on views, and components
(function() {
    'use strict';

    angular
        .module('scFuelsApp', [
            'ui.router',
            'ui.bootstrap',
            'ngAnimate',
            'toastr',
            'ng-file-model',
            'angular-confirm',
            //'angular-nicescroll',
            'scFuelsApp.login',
            'scFuelsApp.marketingInsight',
            'scFuelsApp.techInsight',
            'scFuelsApp.bannerManagement',
            'scFuelsApp.dashBoard',
            'scFuelsApp.cardManagement',
            'scFuelsApp.userManagement',
            'scFuelsApp.invoicePayments',
            'scFuelsApp.contactus',
            'scFuelsApp.changeAccountInfo',
            'scFuelsApp.userActivity',
            'mgcrea.ngStrap',
            'appConfig.module',
            'toastrMessages',
            'ngMaterial',
            'daterangepicker',
            'fixed.table.header',
            'dnTimepicker',
            'angular-google-analytics',
            'ngIdle',
            'ngSanitize',
            'ui.bootstrap',
            'scFuelsApp.test',
        ])

    .config(routerConfiguration)
        .config(debugConfiguration)
        .config(analyticsProvider)
        .config(idleTimer)
        .factory('httpRequestInterceptor', httpRequestInterceptor)
        .run(configureAppEnvironment);

    //--------------------------------------
    //function definitions
    routerConfiguration.$inject = ['$stateProvider', '$urlRouterProvider', '$httpProvider'];

    function routerConfiguration($stateProvider, $urlRouterProvider, $httpProvider) {
        $urlRouterProvider.otherwise('/login');
        $httpProvider.interceptors.push('httpRequestInterceptor');

        $stateProvider
            .state('login', {
                url: '/login',
                templateUrl: 'app/modules/login/loginTemplate.html',
                controller: 'LoginController',
                controllerAs: 'loginVM',
                params:{
                    refresh:null
                },
                data: {
                    rootClass: 'page-login'
                }
            })
            .state('registration', {
                url: '/registration?Id',
                templateUrl: 'app/modules/login/registration/registrationTemplate.html',
                controller: 'registrationController',
                controllerAs: 'registrationVM'
            })
            .state('forgotPassword', {
                url: '/forgotPassword',
                templateUrl: 'app/modules/login/forgotPassword/forgotPasswordTemplate.html',
                controller: 'forgotPwdController',
                controllerAs: 'forgotPwdVM'
            })
            .state('resetPassword', {
                url: '/resetPassword?Id',
                templateUrl: 'app/modules/login/resetPassword/resetPasswordTemplate.html',
                controller: 'resetPasswordController',
                controllerAs: 'resetPasswordVM'
            })
            .state('root', {
                abstract: true,
                views: {
                    '': {
                        templateUrl: 'app/modules/layout/contentArea/contentAreaTemplate.html'
                    },
                    'sidemenu@root': {
                        templateUrl: 'app/modules/layout/sidemenuArea/sidemenuTemplate.html',
                        controller: 'SidemenuController',
                        controllerAs: 'sidemenuVM'
                    },
                    'headermenu@root': {
                        templateUrl: 'app/modules/layout/headermenuArea/headermenuTemplate.html',
                        controller: 'HeadermenuController',
                        controllerAs: 'headermenuVM'
                    }
                }
            })
            .state('test', {
                url: '/test',
                templateUrl: 'app/modules/test/testTemplate.html',
                controller: 'TestController',
                controllerAs: 'testVM',               
            });
    }

    httpRequestInterceptor.$inject = ['$q', '$rootScope'];

    function httpRequestInterceptor($q, $rootScope) {
        var interceptObject = {};
        interceptObject.request = interceptRequest;
        interceptObject.response = interceptResponseSuccess;
        interceptObject.responseError = interceptResponseError;
        return interceptObject;

        function interceptRequest(config) {
            if (config.url.indexOf('Authentication') >= 0) {
                config.headers['Authorization'] = 'Basic ' + localStorage.loginDetails;
            } else {
                var access_token = localStorage.loggedInToken ? localStorage.loggedInToken : null;
                var userID = localStorage.userID ? localStorage.userID : null;
                config.headers['Bearer'] = access_token;
                config.headers['UserId'] = userID;
            }
            return config;
        }

        function interceptResponseSuccess(response) {
            return response;
        }

        function interceptResponseError(response) {
            if (response.status === 401) {
                $rootScope.unauthorized = true;
                $rootScope.dataLoading = false;
                $rootScope.$broadcast('unauthorized');
            } else {
                return $q.reject(response);
            }
        }
    }

    configureAppEnvironment.$inject = ['$rootScope', '$state', 'toastr', '$timeout','Analytics','Idle','$window'];

    function configureAppEnvironment($rootScope, $state, toastr, $timeout,Analytics,Idle,$window) {
        $rootScope.sideBarVisible = true;
        $rootScope.$on('$stateChangeStart', globalStateChangeHandler);
        $(document).ready(function() {
            $(document).on('click', '#modalOne, #modalTwo, #modalThree, #modalFour, #modalFive', function(e) {
                $('.adduser-tables .modal-dialog').css({
                    'margin-top': e.clientY - 40,
                    'margin-left': e.clientX - 20
                });
            });

            $(document).on('click', '#inquiryId,#invoiceAttachments', function(e) {
                document.getElementsByClassName('no-more-tables')[0].scrollTop = (e.clientY+100);
            });

            $(document).bind('click', function(e) {
                var $clicked = $(e.target);
                if (!$clicked.parents().hasClass('custom-popup-container') && !$clicked.hasClass('custom-popup-container')) {
                    $rootScope.$broadcast('hidePopup');
                }                             
            });
        });
        $rootScope.$on('unauthorized', function() {
            toastr.clear();
            toastr.error('User authentication failed. Please login again');
            $timeout(function() {
                $state.go('login');
            }, 1000);
        });

        function globalStateChangeHandler(event, toState, toParams, fromState) {
            if ((fromState.name === 'login' && $rootScope.unauthorized) && (toState.name !== 'registration' && toState.name !== 'forgotPassword' && toState.name !== 'resetPassword')) {
                event.preventDefault();
                window.history.forward();
                window.location.reload();
            }
        }

        Idle.watch();
        $rootScope.$on('IdleTimeout', function() {
            $rootScope.unauthorized = true;
            $state.go('login',{refresh:true});           
        });
    }

    debugConfiguration.$inject = ['$compileProvider'];
    function debugConfiguration($compileProvider) {
        $compileProvider.debugInfoEnabled(true);
        $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|ftp|file|blob|ms-appx-web|chrome-extension):|data:image\//);
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension):/);
    }

    analyticsProvider.$inject = ['AnalyticsProvider'];
    function analyticsProvider(AnalyticsProvider){
        AnalyticsProvider.setAccount('UA-103110998-1');  
    }

    idleTimer.$inject = ['KeepaliveProvider', 'IdleProvider'];
    function idleTimer(KeepaliveProvider, IdleProvider){
        IdleProvider.idle(300);
        IdleProvider.timeout(30);
        KeepaliveProvider.interval(10);
    }
})();
