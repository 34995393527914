/*jshint browser: true*/
/*global angular: true*/

(function(){
    'use strict';
    
    angular.module('scFuelsApp.techInsight')
        .factory('techInsightService', techInsightService);

    techInsightService.$inject = ['$q', '$http', 'apiURLService'];

    function techInsightService ($q, $http, apiURLService) {
        var techInsightObject = {};
        techInsightObject.getAuditModulesList = getAuditModulesList;
        techInsightObject.getAuditColumnHeaders = getAuditColumnHeaders;
        techInsightObject.getAuditList = getAuditList;

        return techInsightObject;

       function getAuditModulesList() {
            var url = apiURLService.getAuditModulesURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });
        }

        function getAuditColumnHeaders(){
            var url = apiURLService.getAuditColumnHeadersURL();
            return $http.get(url).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });    
        }

        function getAuditList(params){
            var url = apiURLService.getAuditListURL();
            return $http.post(url,params).then(function(apiResponse) {
                return apiResponse.data;
            }).catch(function(apiResponse) {
                return $q.reject(apiResponse.data);
            });       
        }
    }
})();
