/*jshint browser: true*/
/*global angular: true*/


(function() {
    'use strict';

    angular
        .module('scFuelsApp.invoicePayments')
        .controller('makePaymentsController', makePaymentsController);


    makePaymentsController.$inject = ['$scope', '$state', 'toastr', '$confirm', '$stateParams', 'invoicePaymentsService','toastrMessage','$rootScope','dataStoreService'];

    function makePaymentsController($scope, $state, toastr, $confirm, $stateParams, invoicePaymentsService,toastrMessage,$rootScope,dataStoreService) {
        var makePaymentsVM = this; 
        makePaymentsVM.subTotal = 0;
        makePaymentsVM.surCharge = 0;
        makePaymentsVM.totalAmount = 0; 
        makePaymentsVM.init = init;
        makePaymentsVM.finishAndPay = finishAndPay;
         makePaymentsVM.returnURL = "https://customerportal-web.azurewebsites.net/gatewayRedirect/Payment/Index"; // Dev
       // makePaymentsVM.returnURL = " https://customerportal.scfuels.com/gatewayRedirect/Payment/Index"; // PROD
       // makePaymentsVM.paymentAllInvoices = 2;
        /*function declarations*/

        /*initial loading*/
        function init(){
            getInvoiceList();
        }   
        /*fetches the selected invoices from payments*/
        function getInvoiceList(){
            // makePaymentsVM.paymentData = dataStoreService.getpaymentData();

            // makePaymentsVM.paymentAllInvoices = invoicePaymentsService.getIsAllInvoice();
            // console.log(makePaymentsVM.paymentAllInvoice);
            makePaymentsVM.paymentData = JSON.parse(localStorage.selectedPaymentDetails);
            var invoiceList = makePaymentsVM.paymentData.invoiceList;
            var invoices = [];
            invoiceList.forEach(function(item){
                var listObj = {};
                listObj.invoiceNumber = item.number;
                listObj.accountNumber = item.accountNumber;
               // listObj.memo = item.memo;
                invoices.push(listObj);
            });
            if(invoiceList.length == 0){
             var Payload = {
                //"invoiceList": invoices,
                "paymentType":makePaymentsVM.paymentData.paymentType,
                "operation": "btnPaymentSubmission",
                "isAllPaymentSelected" : true,
                "accountId":makePaymentsVM.paymentData.accountId
            };
            }else{
            var Payload = {
                "invoiceList": invoices,
                "paymentType":makePaymentsVM.paymentData.paymentType,
                "operation": "btnPaymentSubmission",
                "isAllPaymentSelected" : false,
                "accountId" :makePaymentsVM.paymentData.accountId
            };
        }
            $rootScope.dataLoading = true;
            invoicePaymentsService.makePaymentInvoiceList(Payload)
                .then(getInvoiceListSuccess, getInvoiceListError); 
        }
        /*success callback for invoice list*/
        function getInvoiceListSuccess(apiResponse){
            $rootScope.dataLoading = false;
            makePaymentsVM.invoiceList = apiResponse.invoiceList;
            makePaymentsVM.subTotal = apiResponse.subTotal;
            makePaymentsVM.surCharge = apiResponse.surchargeAmount;
            makePaymentsVM.surChargeRate = apiResponse.surchargeRate*100;
            makePaymentsVM.totalAmount = makePaymentsVM.subTotal+makePaymentsVM.surCharge;
             // makePaymentsVM.totalAmount = 0.01;
            makePaymentsVM.sessionID = apiResponse.sessionID;
        }
        /*error callback for invoice list*/
        function getInvoiceListError(){
            $rootScope.dataLoading = false;
            toastr.error(toastrMessage.common.systemNetworkFailure);
        }

        /*clicks on finish and pay button*/
        function finishAndPay() {
            if(makePaymentsVM.paymentData.paymentType === 'CC'){
                var firstString = "If paying with credit card, please be advised that ";
                var secondString = firstString.concat('<b>','credit card','</b>');
                var thirdString = secondString.concat(' services are provided for a surcharge of 3% of the amount being paid. Customer credit card information is not stored by SC Fuels or the bank processing the credit card payment.');
                $confirm({
                    title: "Information",
                    text: thirdString, 
                    ok: "OK",
                    cancel: "Cancel"
                }, {
                    size: 'sm'
                }).then(function() {
                    var sessionData = getSessionData();
                    var url = 'https://epayment.epymtservice.com/epay.jhtml';
                    var method = 'POST';
                    var params = {
                        session: sessionData,
                        billerId: 'LSC',
                        billerGroupId: 'FUE'
                    };

                    invoicePaymentsService.makePayment(url, method, params);                    
                }).catch(function() {
                    
                });
            }else{
                var sessionData = getSessionData();
                var url = 'https://epayment.epymtservice.com/epay.jhtml';
                var method = 'POST';
                var params = {
                    session: sessionData,
                    billerId: 'LSC',
                    billerGroupId: 'FUE'
                };

                invoicePaymentsService.makePayment(url, method, params);
            }
        }              

        // /*clicks on finish and pay button*/
        // function finishAndPay() {
        //     if(makePaymentsVM.paymentData.paymentType === 'CC'){
        //         var firstString = "If paying with credit card, please be advised that ";
        //         var secondString = firstString.concat('<b>','credit card','</b>');
        //         var thirdString = secondString.concat(' services are provided for a surcharge of 3% of the amount being paid. Customer credit card information is not stored by SC Fuels or the bank processing the credit card payment.');
        //         $confirm({
        //             title: "Information",
        //             text: thirdString, 
        //             ok: "OK",
        //             cancel: "Cancel"
        //         }, {
        //             size: 'sm'
        //         }).then(function() {
        //             finishPayment();
        //         }).catch(function() {
                    
        //         });
        //     }else{
        //          finishPayment();
        //     }
        // }

        // function finishPayment(){
        //     var invoiceList = makePaymentsVM.paymentData.invoiceList;
        //             var invoices = [];
        //             invoiceList.forEach(function(item){
        //             var listObj = {};
        //             listObj.invoiceNumber = item.number;
        //             listObj.accountNumber = item.accountNumber;
        //             invoices.push(listObj);
        //     });
        //     var Payload = {
        //         "invoiceList": invoices,
        //     };

        //         invoicePaymentsService.finishPayment(Payload)
        //              .then(finishAndPaySuccess, finishAndPayError); 
        // }

        // function completePayment(){
        // var sessionData = getSessionData();
        //         var url = 'https://epayment.epymtservice.com/epay.jhtml';
        //         var method = 'POST';
        //         var params = {
        //             session: sessionData,
        //             billerId: 'LSC',
        //             billerGroupId: 'FUE'
        //         };

        //         invoicePaymentsService.makePayment(url, method, params);
        // }

 

        // /*success callback for finish and pay*/
        // function finishAndPaySuccess(apiResponse){
        //     $rootScope.dataLoading = false;
        //    completePayment();
        // }

        // /*error callback for finish and pay*/
        // function finishAndPayError(){
        //     $rootScope.dataLoading = false;
        //     toastr.error(toastrMessage.common.systemNetworkFailure);
        // }  

        /*fetches the session data*/
        function getSessionData() {
            var text = CryptoJS.enc.Utf8.parse("productCode=invoicePayments|amountDue="+makePaymentsVM.totalAmount.toFixed(2)+"|sessionID="+makePaymentsVM.sessionID+"|disallowLogin=Y|returnURL="+makePaymentsVM.returnURL);
            var key = CryptoJS.enc.Base64.parse("dsST7wt+T9Kq2M7FaFc+R4Ha1IDM8M8bjRItxnwKrNQ=");
            var iv = CryptoJS.enc.Utf8.parse("X5xwzs2dEGd5DOFX");
            var encryptString = CryptoJS.AES.encrypt(text, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                keySize: 256,
                padding: CryptoJS.pad.Pkcs7
            }).ciphertext.toString(CryptoJS.enc.Base64);

            return encryptString;
        }  

        $scope.$on('$stateChangeSuccess', onStateChangeStart);
        /*state change handler for stateChangeSuccess*/
        function onStateChangeStart(event, toState, toParams, fromState) {
            if(fromState.name === ""){
               $state.go("root.makePayments");
            }
        } 
    }
})();
